import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Spin } from 'antd';
import { __, compose, includes, findIndex, equals, find, length, map, prop, propEq, path, filter, take } from 'ramda';
import Select, { components } from 'react-select';
import { VariableSizeList as List } from 'react-window';
import styled from 'styled-components';
import cx from 'classnames';
import { withFieldWrapper } from '@meconsultant/common';

import { HEIGHT, HEIGHT_WITH_SMALL } from '../../../constants/select';

const Option = styled.div`
    .search-input__option {
        height: ${({ small }) => small ? HEIGHT_WITH_SMALL : HEIGHT}px;
    }
`;

const Wrapper = styled.div`
    .search-input {
        line-height: 1.5;
        .toolbar .ant-form-item-control & {
            margin-top: 0;
        }
        .search-input__menu {
            min-width: 100%;
            z-index: 3;
            overflow: hidden;
        }
        .search-input__value-container {
            height: 32px;
        }
        .search-input__control {
            min-height: 32px;
            height: 32px;
            border-radius: 0;
            border-color: #d9d9d9;
            &.search-input__control--is-focused {
                border-color: #f54d2e;
                box-shadow: initial;
            }
            &--is-disabled {
                background: #f5f5f5;
            }
            &:hover {
                border-color: #f54d2e;
            }
            &:focus {
                border-color: #f54d2e;
                box-shadow: 0 0 0 1px red;
            }
        }
        .search-input__option {
            padding-bottom: 0;
            padding-top: 5px;
            text-align: left;
            & > div,
            small {
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            &:not(.search-input__option--is-focused):not(.search-input__option--is-selected) small {
                color: #aaa;
            }
            &--is-focused {
                background-color: #f6dcd7;
            }
            &--is-selected {
                background-color: #f54d2e;
                &:hover {
                    background-color: #f54d2e;
                }
            }
        }
        .search-input__clear-indicator {
            padding: 0;
        }
        .search-input__dropdown-indicator {
            padding: 0;
            padding-right: 8px;
        }
        .ant-spin.ant-spin-sm.ant-spin-spinning {
            line-height: 100%;
            height: 100%;
        }
        &.search-multi {
            .search-input__value-container {
                height: auto;
            }
            .search-input__control {
                height: auto;
            }
        }
        .search-input__single-value {
            & > div {
                padding: 0;
            }
        }
    }
    .no-options {
        height: 30px;
        text-align: center;
        line-height: 30px;
    }
`;

export class SelectComponent extends Component {
    static defaultProps = {
        options: [],
        placeholder: '',
        searchable: false,
        valuePath: 'id',
        namePath: 'value'
    };

    getOptionsHeight = childrens => {
        if (Array.isArray(childrens)) {
            const smallLength = length(filter(path(['props', 'data', 'small']), childrens));

            return smallLength * HEIGHT_WITH_SMALL + (childrens.length - smallLength) * HEIGHT;
        }

        return HEIGHT;
    }

    getOffset = (options, value) => {
        const index = findIndex(equals(value), options);

        return index < 0 ? 0 : this.getOptionsHeight(take(index, options));
    }

    getMenuList = options => ({ children, maxHeight, getValue }) => {
        const [ value ] = getValue();
        const optionsHeight = this.getOptionsHeight(children);

        const listHeight = options ? optionsHeight > maxHeight ? maxHeight : optionsHeight : 0;
        const initialOffset = optionsHeight > maxHeight ? this.getOffset(options, value) : 0;

        return <List
            height={listHeight}
            itemCount={children.length || 1}
            itemSize={index => options[index] && options[index].small ? HEIGHT_WITH_SMALL : HEIGHT}
            initialScrollOffset={initialOffset}>
            { ({ index, style }) => <div style={style}>{ Array.isArray(children) ? children[index] : children }</div> }
        </List>;
    }

    getOptions = () => {
        const { asyncAction, options, valuePath, namePath, getNamePath, renderSmall, parseAsync, asyncActionPath, parseItemsAfter } = this.props;
        const serverOptions = asyncAction && (parseAsync ? parseAsync(this.props.asyncAction.data) : path(asyncActionPath || ['asyncAction', 'data', 'items'], this.props));
        const resultOptions = serverOptions || options;
        const items = resultOptions.map(item => ({
            value: prop(valuePath, item),
            label: getNamePath ? getNamePath(item) : prop(namePath, item),
            small: renderSmall ? renderSmall(item) : null,
            option: item,
            disabled: !!item.disabled
        }));

        return parseItemsAfter ? parseItemsAfter(items) : items;
    }

    onChange = (event, { action }) => {
        const { isMulti, onChange } = this.props;

        if (action !== 'clear') {
            if (isMulti) {
                const value = map(prop('value'), event || []);

                onChange(value.length ? value : null);
            } else {
                onChange(event.value, event);
            }
        } else {
            onChange(null);
        }
    }

    getOption = props => {
        const { renderLabel } = this.props;
        const { label, data: { small } } = props;

        return <Option small={small}>
            <components.Option {...props}>
                <div>
                    { renderLabel ? renderLabel(props) : label }
                </div>
                { small && <small>{ small }</small> }
            </components.Option>
        </Option>;
    }

    getMultiValueLabel = props => {
        const { renderMultiLabel } = this.props;

        return <components.MultiValueLabel {...props}>
            { renderMultiLabel ? renderMultiLabel(props) : props.children }
        </components.MultiValueLabel>;
    }

    render() {
        const { placeholder, input, searchable, allowClear, isMulti, renderSmall, disabled, hide } = this.props;
        const options = this.getOptions();

        const MenuList = this.getMenuList(options);
        const value = isMulti ?
            filter(compose(includes(__, input.value || []), prop('value')), options)
            : find(propEq('value', input.value), options);
        const pending = path(['asyncAction', 'meta', 'pending'], this.props);
        const lastSucceedAt = path(['asyncAction', 'meta', 'lastSucceedAt'], this.props);

        return !hide ? (
            <Wrapper>
                <Select
                    classNamePrefix='search-input'
                    className={cx('search-input', { 'search-multi': isMulti })}
                    value={value || ''}
                    components={{
                        MenuList,
                        Option: this.getOption,
                        MultiValueLabel: this.getMultiValueLabel,
                        IndicatorSeparator: () => null,
                        LoadingIndicator: () => <Spin size='small' />,
                        LoadingMessage: () => <div className='no-options'>Загрузка...</div>,
                        NoOptionsMessage: () => <div className='no-options'>Ничего не найдено</div>
                    }}
                    options={options}
                    onChange={this.onChange}
                    isLoading={!lastSucceedAt && pending}
                    placeholder={placeholder}
                    isClearable={allowClear}
                    isSearchable={searchable}
                    isMulti={isMulti}
                    maxMenuHeight={(renderSmall ? HEIGHT_WITH_SMALL : HEIGHT) * 6}
                    isOptionDisabled={option => option.disabled}
                    isDisabled={disabled}
                />
            </Wrapper>
        ) : null;
    }
}

export default withFieldWrapper(withAsyncActions(({ action, input: { name } }) => action ? ({
    asyncAction: action
        .withParams(() => ({ name }))
        .withDefaultPayload(({ filter, payload = {} }) => ({
            ...payload,
            q: filter,
            offset: 0,
            limit: 0
        }))
}) : {}, { dispatchOnMount: true, dispatchOnUpdate: true })(SelectComponent));
