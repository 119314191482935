import { append, remove, assocPath } from 'ramda';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
    CLOSE_MODAL,
    HIDE_MODAL,
    SHOW_MODAL,
    LOGOUT,
    OPEN_COMPANY_MODAL,
    OPEN_PARTICIPANT_MODAL,
    OPEN_PARTICIPATION_REQUEST_MODAL,
    OPEN_ADMIN_MODAL,
    OPEN_STAGE_MODAL,
    OPEN_MATERIAL_MODAL,
    OPEN_CANDIDATE_FORM_MODAL,
    OPEN_FAQ_MODAL,
    OPEN_MATERIAL_VIEW_MODAL,
    OPEN_PARTICIPANT_IMPORT_MODAL,
    OPEN_PARTICIPANT_DOCUMENT_MODAL,
    OPEN_TOPIC_MODAL,
    OPEN_MENTION_MODAL,
    OPEN_NEWS_MODAL,
    OPEN_COMPANY_SETTINGS_MODAL,
    OPEN_ADMIN_CLIENT_MODAL,
    OPEN_COMPANY_ROLES_MODAL,
    OPEN_IMPORT_INVITES_MODAL,
    OPEN_INVITE_MODAL,
    OPEN_WEBINAR_MODAL,
    OPEN_TEST_MODAL,
    OPEN_COMPANY_WEBINAR_MODAL,
    OPEN_EVENT_PARTICIPANTS_MODAL,
    OPEN_PARTICIPANT_STATUS_MODAL,
    OPEN_EVENT_PARTICIPANTS_ADD_MODAL,
    OPEN_CONSULTANT_SELECT_MODAL,
    OPEN_CONSULTATION_VIEW_MODAL,
    OPEN_CONSULTATION_TITLE_MODAL,
    OPEN_EVENT_VIEW_MODAL,
    OPEN_ADD_MEETING_MODAL,
    OPEN_CANCEL_CONSULTATION_MODAL,
    OPEN_STATUS_COMMENT_MODAL,
    OPEN_PARTICIPANT_COMMENT_MODAL,
    OPEN_PARTICIPANTS_TABLE_SETTINGS_MODAL,
    OPEN_FEEDBACK_MODAL,
    OPEN_PARTICIPANT_FEEDBACK_MODAL,
    OPEN_LINK_MODAL,
    OPEN_LINK_PREVIEW_MODAL,
    OPEN_COMPANY_MESSAGE_TEMPLATE_MODAL
} from '../constants/actionTypes';

export default (state = [], action) => {
    switch (action.type) {
        case OPEN_LINK_MODAL:
        case OPEN_LINK_PREVIEW_MODAL:
        case OPEN_COMPANY_MODAL:
        case OPEN_PARTICIPANT_MODAL:
        case OPEN_PARTICIPATION_REQUEST_MODAL:
        case OPEN_ADMIN_MODAL:
        case OPEN_STAGE_MODAL:
        case OPEN_MATERIAL_MODAL:
        case OPEN_CANDIDATE_FORM_MODAL:
        case OPEN_FAQ_MODAL:
        case OPEN_MATERIAL_VIEW_MODAL:
        case OPEN_PARTICIPANT_IMPORT_MODAL:
        case OPEN_PARTICIPANT_DOCUMENT_MODAL:
        case OPEN_TOPIC_MODAL:
        case OPEN_MENTION_MODAL:
        case OPEN_NEWS_MODAL:
        case OPEN_COMPANY_SETTINGS_MODAL:
        case OPEN_ADMIN_CLIENT_MODAL:
        case OPEN_COMPANY_ROLES_MODAL:
        case OPEN_IMPORT_INVITES_MODAL:
        case OPEN_INVITE_MODAL:
        case OPEN_WEBINAR_MODAL:
        case OPEN_TEST_MODAL:
        case OPEN_COMPANY_WEBINAR_MODAL:
        case OPEN_EVENT_PARTICIPANTS_MODAL:
        case OPEN_PARTICIPANT_STATUS_MODAL:
        case OPEN_EVENT_PARTICIPANTS_ADD_MODAL:
        case OPEN_CONSULTANT_SELECT_MODAL:
        case OPEN_CONSULTATION_VIEW_MODAL:
        case OPEN_CONSULTATION_TITLE_MODAL:
        case OPEN_EVENT_VIEW_MODAL:
        case OPEN_ADD_MEETING_MODAL:
        case OPEN_CANCEL_CONSULTATION_MODAL:
        case OPEN_STATUS_COMMENT_MODAL:
        case OPEN_PARTICIPANT_COMMENT_MODAL:
        case OPEN_PARTICIPANTS_TABLE_SETTINGS_MODAL:
        case OPEN_FEEDBACK_MODAL:
        case OPEN_PARTICIPANT_FEEDBACK_MODAL:
        case OPEN_COMPANY_MESSAGE_TEMPLATE_MODAL: {
            const modal = {
                type: action.type,
                params: action.payload,
                visible: true
            };

            return append(modal, state);
        }
        case CLOSE_MODAL:
            return remove(action.payload, 1, state);
        case HIDE_MODAL:
            return assocPath([action.payload, 'visible'], false, state);
        case SHOW_MODAL:
            return assocPath([action.payload, 'visible'], true, state);
        case LOGOUT:
        case LOCATION_CHANGE:
            return [];
        default:
            return state;
    }
};
