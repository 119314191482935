import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Button } from 'antd';
import * as yup from 'yup';
import { connect } from 'react-redux';

import { getAdmins, getCompanyRoles, getUser } from '../../actions/asyncActions';
import { withFormWrapper } from '@meconsultant/common';
import SearchSelect from './formComponents/SearchSelect';
import Input from './formComponents/Input';
import { isAdminRole } from './ParticipantForm';

class InviteForm extends Component {
    static defaultProps = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting, values, user, item } = this.props;

        return <Fragment>
            <Field
                name='companyRole'
                component={SearchSelect}
                label='Роль'
                action={getCompanyRoles}
                filter={values.company ? { company: values.company } : null}
                namePath='name' />
            <Field
                name='email'
                component={Input}
                label='Email'
                type='email' />
            { isAdminRole(this.props) && user.superAdmin &&
                <Field
                    name='consultant'
                    component={SearchSelect}
                    action={getAdmins}
                    label='Консультант'
                    namePath='name'
                    filter={{ company: item.company }}
                    getNamePath={item => `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`}
                    allowClear
                />
            }
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
        </Fragment>;
    }
}

const validationSchema = props => yup.object().shape({
    company: isAdminRole(props) ? yup.string().nullable().required() : yup.string().nullable(),
    companyRole: yup.string().nullable().required(),
    email: yup.string().email().nullable().required()
});

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(withFormWrapper(InviteForm, {
    validationSchema,
    subscriptions: { values: true },
    mapPropsToValues: ({ item }) => item
}));
