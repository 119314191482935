import React, { Component, Fragment } from 'react';
import { Button } from 'antd';
import { withFormWrapper, Textarea } from '@meconsultant/common';
import { Field } from 'react-final-form';
import * as yup from 'yup';

import Input from './formComponents/Input';
import ImageLoader from './formComponents/ImageLoader';

class MentionForm extends Component {
    render() {
        const { isSubmitting } = this.props;

        return <Fragment>
            <Field
                name='personName'
                component={Input}
                label='Имя пользователя' />
            <Field
                name='text'
                component={Textarea}
                label='Отзыв' />
            <Field
                name='personAvatar'
                component={ImageLoader}
                label='Аватар пользователя' />
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
        </Fragment>
    }
}

const validationSchema = yup.object().shape({
    personName: yup.string().required(),
    text: yup.string().required()
});

export default withFormWrapper(MentionForm, {
    mapPropsToValues: ({ data }) => data,
    validationSchema
});
