import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Button } from 'antd';
import moment from 'moment';

import { withFormWrapper } from '@meconsultant/common';
import RadioButtons from './formComponents/RadioButtons';
import DatePicker from './formComponents/DatePicker';
import Select from './formComponents/Select';
import MoodSlider from './formComponents/MoodSlider';
import Checkboxes from './formComponents/Checkboxes';
import { YEARS, JOB_BREAK, CAREER_EXPECTATION, GENDER } from '../../constants/dictionaries';

class CandidateForm extends Component {
    static defaultProps = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting } = this.props;

        return <Fragment>
            <Field
                name='welcomeForm.gender'
                component={RadioButtons}
                label='Пол'
                options={GENDER} />
            <Field
                name='welcomeForm.birthDate'
                component={DatePicker}
                label='День рождения'
                max={moment()} />
            <Field
                name='welcomeForm.jobExperience'
                component={Select}
                label='Общий стаж работы'
                options={YEARS} />
            <Field
                name='welcomeForm.lastJobExperience'
                component={Select}
                label='На последнем месте работал(а)'
                options={YEARS} />
            <Field
                name='welcomeForm.lastJobSearch'
                component={Select}
                label='Последний раз искал(а) работу'
                options={YEARS} />
            <Field
                name='welcomeForm.hasJobBreaks'
                component={Select}
                label='Были ли длительные перерывы в работе за последний год?'
                options={JOB_BREAK} />
            <Field
                name='welcomeForm.careerExpectations'
                component={Checkboxes}
                label='Планирует ли значительные перемены в карьере?'
                options={CAREER_EXPECTATION} />
            <Field
                name='welcomeForm.mood'
                component={MoodSlider}
                label='Настроение' />
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
        </Fragment>;
    }
}

export default withFormWrapper(CandidateForm, {
    mapPropsToValues: ({ item }) => ({
        ...item,
        welcomeForm: item.welcomeForm || { mood: 50 }
    })
});
