import { Tabs } from 'antd';
import React, { Component } from 'react';

import ModulesStatistic from './ModulesStatistic';
import ParticipantsStatistic from './ParticipantsStatistic';

class ClientAdminDashboard extends Component {
    render() {
        return <Tabs>
            <Tabs.TabPane tab='Участники' key='participants'>
                <ParticipantsStatistic />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Сервисы' key='modules'>
                <ModulesStatistic />
            </Tabs.TabPane>
        </Tabs>;
    }
}

export default ClientAdminDashboard;
