import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postEvent, putEvent, deleteEvent } from '../../actions/asyncActions';
import CompanyWebinarForm from '../forms/CompanyWebinarForm';

class CompanyWebinarModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        params: PropTypes.object,
        postEvent:  PropTypes.object,
        putEvent: PropTypes.object,
        deleteEvent: PropTypes.object
    };

    render() {
        const { modal, params, postEvent, putEvent, deleteEvent } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактирование мероприятия' : 'Добавление мероприятия'}
            footer={null}>
            <CompanyWebinarForm
                formAction={params.item.id ? putEvent : postEvent}
                deleteCompany={deleteEvent}
                item={params.item}
                themes={params.themes}
                deleteEvent={deleteEvent} />
        </Modal>;
    }
}

export default withAsyncActions({
    postEvent: postEvent
        .withSuccessHandler(({ close }) => {
            message.success('Мероприятие было успешно добавлено');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putEvent: putEvent
        .withSuccessHandler(({ close }) => {
            message.success('Мероприятие было успешно отредактировано');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    deleteEvent: deleteEvent
        .withSuccessHandler(({ close }) => {
            message.success('Мероприятие было успешно удалено');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
})(CompanyWebinarModal);
