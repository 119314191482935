import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Modal, Input, Button, InputNumber } from 'antd';
import { withAsyncActions, toSuccess } from 'react-async-client';
import { contains, append, without } from 'ramda';
import { EditOutlined, AlignLeftOutlined, ClockCircleOutlined, EyeInvisibleOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';

import { patchStage } from '../../../actions/asyncActions';
import { takeEvery } from 'redux-saga/effects';
import { PATCH_STAGE } from '../../../constants/actionTypes';

export const EditButton = styled.div`
    width: 30px;
    height: 30px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    position: absolute;
    background: #fff;
    top: -15px;
    right: -15px;
    align-items: center;
    justify-content: center;
    display: none;
    cursor: pointer;
`;

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }
`;

const FormWrapper = styled.div`
    position: relative;
`;

const Buttons = styled.div`
    position: absolute;
    bottom: -50px;
    left: -10px;
`;

const ActionButtons = styled.div`
    position: absolute;
    top: ${({ top }) => -9 - top}px;
    right: -113px;
    display: flex;
    flex-direction: column;
    button {
        background: rgba(0,0,0,.6);
        border-radius: 3px;
        color: #e6e6e6;
        padding: 6px 12px 6px 8px;
        border: 0;
        margin-bottom: 5px;
    }
`;

class LeadModalContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lead: props.lead
        };
    }

    onChange = e => this.setState({ lead: e.target.value });

    save = () => this.props.onSave(this.state.lead);

    render() {
        return <Fragment>
            <Input.TextArea
                autosize={{ minRows: 3 }}
                value={this.state.lead}
                onChange={this.onChange} />
            <div style={{ textAlign: 'right', marginTop: 15 }}>
                <Button type='primary' onClick={this.save}>Сохранить</Button>
            </div>
        </Fragment>;
    }
}

class TimeModalContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            time: props.time
        };
    }

    onChange = time => this.setState({ time });

    save = () => this.props.onSave(this.state.time);

    render() {
        return <Fragment>
            <InputNumber
                style={{ width: '100%' }}
                value={this.state.time}
                onChange={this.onChange} />
            <div style={{ marginTop: 15 }}>
                <Button style={{ width: '100%' }} type='primary' onClick={this.save}>Сохранить</Button>
            </div>
        </Fragment>;
    }
}

class FormComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: props.material.title,
            modules: props.material.modules || [],
            leadOpened: false,
            lead: props.material.lead,
            timeOpened: false,
            time: props.material.time
        };
    }

    componentDidMount() {
        this.props.setHeight(this.container.getBoundingClientRect().height);
    }

    onChange = e => this.setState({ title: e.target.value });

    onKeyPress = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }

    openLead = () => this.setState({ leadOpened: true });

    closeLead = () => this.setState({ leadOpened: false });

    onChangeLead = lead => this.setState({ lead, leadOpened: false });

    openTime = () => this.setState({ timeOpened: true });

    closeTime = () => this.setState({ timeOpened: false });

    onChangeTime = time => this.setState({ time, timeOpened: false });

    save = () => {
        const { patchStage, materialIndex, themeIndex, stage } = this.props;

        patchStage.dispatch({
            id: stage,
            data: [
                { op: 'replace', path: `/themes/${themeIndex}/materials/${materialIndex}/title`, value: this.state.title },
                { op: 'replace', path: `/themes/${themeIndex}/materials/${materialIndex}/modules`, value: this.state.modules },
                { op: 'replace', path: `/themes/${themeIndex}/materials/${materialIndex}/lead`, value: this.state.lead },
                { op: 'replace', path: `/themes/${themeIndex}/materials/${materialIndex}/time`, value: this.state.time }
            ]
        });
    }

    changePublished = () => {
        const { patchPublished, materialIndex, themeIndex, stage, material } = this.props;

        patchPublished.dispatch({
            id: stage,
            data: [
                { op: 'replace', path: `/themes/${themeIndex}/materials/${materialIndex}/published`, value: !material.published },
            ]
        });
    }

    delete = () => {
        const { deleteStage, materialIndex, themeIndex, stage } = this.props;

        deleteStage.dispatch({
            id: stage,
            data: [
                { op: 'remove', path: `/themes/${themeIndex}/materials/${materialIndex}` },
            ]
        });
    }

    selectModule = (e, value) => {
        e.stopPropagation();

        this.setState(prev => ({
            modules: contains(value, prev.modules) ? without([value], prev.modules) : append(value, prev.modules)
        }));
    }

    render() {
        const { material, patchPublished, patchStage, deleteStage } = this.props;
        const publishedPending = patchPublished.meta.pending;
        const patchPending = patchStage.meta.pending;
        const deletePending = deleteStage.meta.pending;

        return <FormWrapper ref={node => this.container = node}>
            <Input.TextArea
                value={this.state.title}
                onChange={this.onChange}
                autosize={{ minRows: 2 }}
                onKeyPress={this.onKeyPress} />
            <Buttons>
                <Button
                    type='primary'
                    onClick={this.save}
                    loading={patchPending}
                    disabled={!this.state.title || patchPending || deletePending}>
                    Сохранить
                </Button>
            </Buttons>
            <ActionButtons top={this.props.buttonsTop}>
                <Button
                    icon={<AlignLeftOutlined />}
                    onClick={this.openLead}>
                    Тизер
                </Button>
                <Button
                    icon={<ClockCircleOutlined />}
                    onClick={this.openTime}>
                    Время
                </Button>
                <Button
                    loading={publishedPending}
                    disabled={publishedPending}
                    icon={material.visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                    onClick={this.changePublished}>
                    { material.published ? 'Скрыть' : 'Опубликовать' }
                </Button>
                <Button
                    icon={<DeleteOutlined />}
                    loading={deletePending}
                    disabled={deletePending}
                    onClick={this.delete}>
                    Удалить
                </Button>
            </ActionButtons>
            <Modal
                title='Тизер'
                footer={null}
                onCancel={this.closeLead}
                visible={this.state.leadOpened}
                destroyOnClose>
                <LeadModalContent
                    lead={this.state.lead}
                    onSave={this.onChangeLead} />
            </Modal>
            <Modal
                title='Время (минуты)'
                footer={null}
                onCancel={this.closeTime}
                visible={this.state.timeOpened}
                width={200}
                destroyOnClose>
                <TimeModalContent
                    time={this.state.time}
                    onSave={this.onChangeTime} />
            </Modal>
        </FormWrapper>;
    }
}

const Form = withAsyncActions({
    patchPublished: patchStage
        .withParams(() => ({ type: 'published' }))
        .withOptions({ resetOnUnmount: true }),
    deleteStage: patchStage
        .withParams(() => ({ type: 'delete' }))
        .withOptions({ resetOnUnmount: true }),
    patchStage: patchStage
        .withParams(() => ({ type: 'stage' }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PATCH_STAGE)], ({ requestAction }) => {
                if (requestAction.params.type === 'delete') {
                    getProps().setSelected(null);
                }

                if (requestAction.params.type !== 'published') {
                    getProps().onClose();
                }
            });
        })
        .withOptions({ resetOnUnmount: true })
})(FormComponent);

class EditMaterial extends Component {
    state = {
        visible: false,
        elementRef: null,
        height: null
    };

    componentDidMount() {
        this.setState({ elementRef: this.props.getElementRef() });
    }

    open = e => {
        e.stopPropagation();
        this.setState({ visible: true, elementRef: this.props.getElementRef() });
    }

    close = e => {
        e && e.stopPropagation();
        this.setState({ visible: false });
    }

    getPosition = () => {
        const ref = this.state.elementRef;

        if (!ref) {
            return {};
        }

        const { top, left } = (ref.buttonNode || ref).getBoundingClientRect();
        const height = document.documentElement.clientHeight;

        return {
            top: height > top + this.state.height ? top : `calc(100% - ${this.state.height}px - 65px)`,
            left: left - 10,
            buttonsTop: height > top + 110 ? 0 : 15
        };
    }

    setHeight = height => this.setState({ height });

    render() {
        const { material, materialIndex, themeIndex, stage, setSelected } = this.props;
        const position = this.getPosition();

        return <Fragment>
            <span ref={node => this.ancorEl = node}>
                <EditButton onClick={this.open}>
                    <EditOutlined />
                </EditButton>
            </span>
            <StyledModal
                visible={this.state.visible}
                title={null}
                footer={null}
                closable={false}
                width='calc(25% - 27px)'
                style={{ left: position.left, top: position.top, margin: 0 }}
                maskClosable
                onCancel={this.close}
                destroyOnClose>
                <div onClick={e => e.stopPropagation()} style={{ padding: 10 }}>
                    <Form
                        material={material}
                        onClose={this.close}
                        setHeight={this.setHeight}
                        buttonsTop={position.buttonsTop}
                        materialIndex={materialIndex}
                        themeIndex={themeIndex}
                        stage={stage}
                        setSelected={setSelected} />
                </div>
            </StyledModal>
        </Fragment>;
    }
}

export default EditMaterial;
