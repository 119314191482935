"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isMobile = void 0;

var isMobile = function isMobile() {
  return document.body.clientWidth < 768;
};

exports.isMobile = isMobile;