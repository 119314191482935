import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postInvites } from '../../actions/asyncActions';
import InviteForm from '../forms/InviteForm';

class InviteModal extends Component {
    render() {
        const { modal, postInvites, params } = this.props;

        return <Modal
            {...modal}
            title='Добавить инвайт'
            footer={null}>
            <InviteForm formAction={postInvites} item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postInvites: postInvites
        .withSuccessHandler(({ close }) => {
            message.success('Инвайт успешно добавлен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(InviteModal);
