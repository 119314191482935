import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import ParticipantForm from '../forms/ParticipantForm';
import { postParticipant, putParticipant } from '../../actions/asyncActions';

class ParticipantModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        postParticipant: PropTypes.object,
        putParticipant: PropTypes.object,
        params: PropTypes.object
    };

    render() {
        const { modal, postParticipant, params, putParticipant } = this.props;

        return <Modal
            {...modal}
            title={params.id ? 'Редактировать участника' : 'Добавить участника'}
            footer={null}>
            <ParticipantForm
                item={params}
                formAction={params.id ? putParticipant : postParticipant} />
        </Modal>
    }
}

export default withAsyncActions({
    postParticipant: postParticipant
        .withSuccessHandler(({ close }) => {
            message.success('Участник был успешно добавлен');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putParticipant: putParticipant
        .withSuccessHandler(({ close }) => {
            message.success('Участник был успешно отредактирован');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(ParticipantModal);
