import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, Input } from 'antd';
import { withAsyncHandlers } from 'react-async-client';
import { assocPath } from 'ramda';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

const AddMaterialBtn = styled.div`
    padding: 5px 10px;
    color: #aaa;
    cursor: pointer;
`;

const Buttons = styled.div`
    display: flex;
    margin-top: 10px;
    .ant-btn-background-ghost {
        border: none;
        color: rgba(0, 0, 0, 0.65);
    }
`;

const AddThemeButton = styled.div`
    padding: 10px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65);
    background: #fafafa;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.08);
`;

const ThemeFormWrapper = styled.div`
    padding: 10px;
    background: #fafafa;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.08);
`;

class FormComponent extends Component {
    state = {
        value: ''
    };

    onChange = e => this.setState({ value: e.target.value });

    onKeyPress = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            if (this.state.value) {
                this.save();
            }
        }
    }

    save = () =>  this.props.onSave(this.state.value);

    render() {
        return <div>
            <Input.TextArea
                value={this.state.value}
                onChange={this.onChange}
                autosize={{ minRows: 2 }}
                placeholder={this.props.placeholder}
                onKeyPress={this.onKeyPress} />
            <Buttons>
                <Button type='primary' disabled={!this.state.value} onClick={this.save}>
                    Добавить
                </Button>
                <Button ghost icon={<CloseOutlined />} onClick={this.props.onClose} />
            </Buttons>
        </div>;
    }
}

const Form = withAsyncHandlers({
    putStage: {
        successHandler: ({ onClose }) => onClose()
    }
})(FormComponent);

export class AddMaterial extends Component {
    state = {
        form: false
    };

    showForm = () => this.setState({ form: true });

    closeForm = () => this.setState({ form: false });

    onSave = title => {
        const { stage, putStage, index } = this.props;
        const themes = assocPath([index, 'materials'], [...stage.themes[index].materials, { title, published: true }], stage.themes);

        putStage.dispatch({
            ...stage,
            themes
        });
    }

    render() {
        return this.state.form ?
            <Form
                onClose={this.closeForm}
                onSave={this.onSave}
                putStage={this.props.putStage}
                placeholder='Введите заголовок материала' /> :
            <AddMaterialBtn onClick={this.showForm}>
                <PlusOutlined /> Добавить материал
            </AddMaterialBtn>;
    }
}

export class AddTheme extends Component {
    state = {
        form: false
    };

    showForm = () => this.setState({ form: true });

    closeForm = () => this.setState({ form: false });

    onSave = title => {
        const { stage, putStage } = this.props;

        putStage.dispatch({
            ...stage,
            themes: stage.themes.concat({ title, materials: [] })
        });
    }

    render() {
        return this.state.form ?
            <ThemeFormWrapper>
                <Form
                    onClose={this.closeForm}
                    onSave={this.onSave}
                    putStage={this.props.putStage}
                    placeholder='Введите заголовок темы' />
            </ThemeFormWrapper> :
            <AddThemeButton onClick={this.showForm}>
                <PlusOutlined /> Добавить тему
            </AddThemeButton>;
    }
}
