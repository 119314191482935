import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { Col, Rate, Row } from 'antd';
import { connect } from 'react-redux';
import { prepend } from 'ramda';

import withFilterForm from '../hocs/withFilterForm';
import SearchSelect from '../forms/formComponents/SearchSelect';
import Select from '../forms/formComponents/Select';
import { getAdmins, getParticipants, getUser } from '../../actions/asyncActions';
import DatePicker from '../forms/formComponents/DatePicker';

export const SERVICES = [
    { id: 'events', name: 'Вебинары и семинары' },
    { id: 'consultations', name: 'Консультация' },
    { id: 'promotion', name: 'Продвижение' }
];

class CompanyFeedbacksFilter extends Component {
    render() {
        return (
            <Fragment>
                <Row gutter={16}>
                    { this.props.user.superAdmin &&
                        <Col span={5}>
                            <Field
                                name='consultant'
                                component={SearchSelect}
                                action={getAdmins}
                                placeholder='Консультант'
                                namePath='name'
                                getNamePath={item => `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`}
                                parseItemsAfter={items => prepend({ value: false, label: 'Без консультанта' }, items)}
                                allowClear
                            />
                        </Col>
                    }
                    <Col span={this.props.user.superAdmin ? 5 : 6}>
                        <Field
                            name='participant'
                            component={SearchSelect}
                            action={getParticipants}
                            placeholder='Участник'
                            namePath='name'
                            filter={{ depersonalized: false, company: this.props.company }}
                            getNamePath={item => `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`}
                            allowClear
                        />
                    </Col>
                    <Col span={this.props.user.superAdmin ? 5 : 6}>
                        <Field
                            name='module'
                            component={Select}
                            placeholder='Сервис'
                            options={SERVICES} />
                    </Col>
                    <Col span={this.props.user.superAdmin ? 5 : 6}>
                        <Field
                            name='rating'
                            component={Select}
                            placeholder='Оценка'
                            options={[
                                { id: 1, name: <Rate style={{ fontSize: 16 }} disabled value={1} /> },
                                { id: 2, name: <Rate style={{ fontSize: 16 }} disabled value={2} /> },
                                { id: 3, name: <Rate style={{ fontSize: 16 }} disabled value={3} /> },
                                { id: 4, name: <Rate style={{ fontSize: 16 }} disabled value={4} /> },
                                { id: 5, name: <Rate style={{ fontSize: 16 }} disabled value={5} /> }
                            ]} />
                    </Col>
                    <Col span={this.props.user.superAdmin ? 4 : 6}>
                        <Field
                            name='createdAt'
                            component={DatePicker}
                            placeholder='Дата'
                            style={{ width: '100%' }}
                        />
                </Col>
                </Row>
            </Fragment>
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(withFilterForm(CompanyFeedbacksFilter));
