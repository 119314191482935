import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Avatar, Button } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined, PlusOutlined } from '@ant-design/icons';

import TableList from './TableList';
import { getUser, getAdminClients, patchAdminClient } from '../actions/asyncActions';
import { getFileView } from '../constants/urls';
import { POST_ADMIN_CLIENT, PUT_ADMIN_CLIENT, PATCH_ADMIN_CLIENT } from '../constants/actionTypes';
import { openAdminClientModal } from '../actions/modalActions';
import AdminClientFilter from './filters/AdminClientFilter';
import { ADMIN_CLIENT_ROLES } from '../constants/roles';
import EnabledSwitch from './EnabledSwitch';

const initFilters = { enabled: true };

class ClientAdmins extends Component {
    static propTypes = {
        openAdminModal: PropTypes.func
    };

    columns = [
        {
            dataIndex: 'photo',
            key: 'photo',
            render: photo => <Avatar src={photo ? getFileView(photo) : null} icon={<UserOutlined />} />,
            width: 32
        },
        {
            title: 'ФИО',
            dataIndex: 'lastName',
            key: 'name',
            render: (lastName, { firstName, middleName, id }) =>
                <Link to={`/client/${id}`}>{ lastName } { firstName } { middleName || '' }</Link>
        },
        {
            title: 'Компания',
            dataIndex: ['_embedded', 'company', 'name'],
            key: 'company'
        },
        {
            title: 'Роль',
            dataIndex: 'clientAdmin',
            key: 'clientAdmin',
            render: clientAdmin => clientAdmin ? ADMIN_CLIENT_ROLES.clientAdmin : ADMIN_CLIENT_ROLES.clientManager
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'Активен',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (enabled, item) => <EnabledSwitch
                id={item.id}
                enabled={enabled}
                action={patchAdminClient}
                disabled={item.id === this.props.user.id} />
        },
    ];

    renderToolbarButtons = () => {
        return <Button icon={<PlusOutlined />} type='primary' onClick={() => this.props.openAdminClientModal()}>
            Добавить
        </Button>
    }

    render() {
        return (
            <TableList
                action={getAdminClients}
                columns={this.columns}
                buttons={this.renderToolbarButtons()}
                refreshActions={[POST_ADMIN_CLIENT, PUT_ADMIN_CLIENT, PATCH_ADMIN_CLIENT]}
                filterForm={<AdminClientFilter initFilters={initFilters} />}
                initFilters={initFilters}
            />
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps, { openAdminClientModal })(ClientAdmins);
