import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { Button, Popconfirm, message } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import TableList from './TableList';
import { getNews, deleteNews } from '../actions/asyncActions';
import { openNewsModal } from '../actions/modalActions';
import { POST_NEWS, PUT_NEWS, DELETE_NEWS } from '../constants/actionTypes';
import NewsFilter from './filters/NewsFilter';

class News extends Component {
    getColumns = () => {
        return [
            {
                title: 'Заголовок',
                key: 'title',
                dataIndex: 'title',
                render: (title, item) => <span className='span-link' onClick={() => this.props.openNewsModal(item)}>{ title }</span>
            },
            {
                align: 'right',
                key: 'actions',
                render: item =>
                    <Popconfirm
                        title='Вы уверены, что хотите удалить новость?'
                        okText='Да'
                        cancelText='Нет'
                        onConfirm={() => this.delete(item.id)}>
                        <Button
                            icon={<DeleteOutlined />}
                            danger />
                    </Popconfirm>
            }
        ]
    }

    delete = id => {
        this.props.deleteNews.dispatch(id);
    }

    renderButtons = () => {
        return <Button type='primary' icon={<PlusOutlined />} onClick={() => this.props.openNewsModal()}>
            Добавить
        </Button>;
    }

    render() {
        return <TableList
            action={getNews}
            columns={this.getColumns()}
            buttons={this.renderButtons()}
            refreshActions={[POST_NEWS, PUT_NEWS, DELETE_NEWS]}
            filterForm={<NewsFilter />} />
    }
}

export default asyncConnect({
    deleteNews: deleteNews
        .withSuccessHandler(() => message.success('Новость успешно удалена'))
        .withOptions({ resetOnUnmount: true })
}, null, { openNewsModal })(News);
