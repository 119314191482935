import React, { Component } from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';
import { Switch } from 'react-router-dom';

import LinksList from './LinksList';
import LinksRequestList from './LinksRequestList';
import LinksEmployeeList from './LinksEmployeeList';
import Route from './Route';

const StyledTabs = styled(Tabs)`
    .ant-tabs-bar {
        margin: 0;
    }
`;

class Feedbacks extends Component {
    onChangeTab = key => {
        const { history } = this.props;

        history.replace(`/links${key === 'main' ? '' : `/${key}`}`);
    }

    render() {
        const { match: { params: { type }}} = this.props;

        return <StyledTabs onChange={this.onChangeTab} animated={false} activeKey={type || 'main'}>
            <Tabs.TabPane key={'main'} tab='Ссылка на таблицу'>
                <Route path={`/links`} exact component={LinksList} />
            </Tabs.TabPane>
            <Tabs.TabPane key={'list'} tab='Запросы'>
                <Switch>
                    <Route path={`/links/list`} exact component={LinksRequestList} />
                    <Route path={`/links/list/:id`} exact component={LinksEmployeeList} />
                </Switch>
            </Tabs.TabPane>
        </StyledTabs>;
    }
}

export default Feedbacks;
