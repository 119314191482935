import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined, PlusOutlined, LoginOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { asyncConnect } from 'react-async-client';

import TableList from './TableList';
import { getAdmins, patchAdmin, getUser, postCalcom } from '../actions/asyncActions';
import { getFileView } from '../constants/urls';
import { POST_ADMIN, PATCH_ADMIN } from '../constants/actionTypes';
import { openAdminModal } from '../actions/modalActions';
import AdminFilter from './filters/AdminFilter';
import EnabledSwitch from './EnabledSwitch';

const initFilters = { enabled: true };

class Admins extends Component {
    static propTypes = {
        openAdminModal: PropTypes.func
    };

    addCalCom = id => {
        this.props.postCalcom.dispatch(id);
    }

    columns = [
        {
            dataIndex: 'photo',
            key: 'photo',
            render: photo => <Avatar src={photo ? getFileView(photo) : null} icon={<UserOutlined />} />,
            width: 32
        },
        {
            title: 'ФИО',
            dataIndex: 'lastName',
            key: 'name',
            render: (lastName, { firstName, middleName, id }) =>
                <Link to={`/admin/${id}`}>{ lastName } { firstName } { middleName || '' }</Link>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'Активен',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (enabled, { id }) => <EnabledSwitch
                id={id}
                enabled={enabled}
                action={patchAdmin}
                disabled={id === this.props.user.id} />
        },
        {
            title: 'cal.com',
            dataIndex: 'id',
            key: 'calcom',
            align: 'center',
            render: (id, item) => item.calcomId ? <CheckCircleTwoTone /> : <Button icon={<LoginOutlined />} size='small' onClick={() => this.addCalCom(id)}>cal.com</Button>
        }
    ];

    renderToolbarButtons = () => {
        return <Button icon={<PlusOutlined />} type='primary' onClick={() => this.props.openAdminModal({ superAdmin: !this.props.consultant })}>
            Добавить
        </Button>
    }

    render() {
        return (
            <TableList
                action={getAdmins}
                columns={this.columns}
                buttons={this.renderToolbarButtons()}
                refreshActions={[POST_ADMIN, PATCH_ADMIN]}
                filterForm={<AdminFilter initFilters={initFilters} />}
                initFilters={initFilters}
                staticFilter={{ superAdmin: !this.props.consultant }}
                param={() => ({ type: this.props.consultant ? 'consultant' : 'admin' })}
            />
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    postCalcom: postCalcom
        .withSuccessHandler(() => message.success('Учетная запись CalCom успешно создана'))
        .withOptions({ resetOnUnmount: true })
}, stateToProps, { openAdminModal })(Admins);
