import Rollbar from 'rollbar';
import { merge, always, any } from 'ramda';

import { getUser } from '../actions/asyncActions';
import { store } from '../index';

const defaults = {
    getPayload: always(null),
    domains: ['.*?']
};

const ignoredMessages = [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
];

export default function (options) {
    options = merge(defaults, options);

    window._rollbarConfig = {
        enabled: true,
        accessToken: options.token,
        captureUncaught: true,
        ignoredMessages,
        checkIgnore: function(isUncaught, args) {
            if (isUncaught && any(message => args[0] && ((args[0].indexOf(message) !== -1) || (args[0].indexOf('Script error') === 0)), ignoredMessages)) {
                return true;
            }

            return true;
        },
        payload: {
            environment: options.environment,
            client: {
                javascript: {
                    source_map_enabled: true,
                    code_version: options.version,
                    guest_uncaught_frames: true
                }
            }
        },
        transform: payload => {
            const person = getUser.selectData(store.getState());
            payload.person = person;
        }
    };

    Rollbar.init(window._rollbarConfig);
}

export const pushRollbarError = (error, info) => {
    if (window._rollbarConfig) {
        Rollbar.configure({
            payload: {
                info
            }
        });

        Rollbar.error(error);
    }
}
