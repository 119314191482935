import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import { Field } from 'react-final-form';
import { toPairs } from 'ramda';
import * as yup from 'yup';

import { withFormWrapper } from '@meconsultant/common';
import Input from './formComponents/Input';
import SearchSelect from './formComponents/SearchSelect';
import { getIndustryDictionary } from '../../actions/asyncActions';
import DatePicker from './formComponents/DatePicker';
import RadioButtons from './formComponents/RadioButtons';
import { getAdmins } from '../../actions/asyncActions';
import { COMPANY_BILLING } from '../../constants/dictionaries';

class CompanyForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool,
        item: PropTypes.object,
        deleteCompany: PropTypes.object
    };

    deleteCompany = () => {
        const { deleteCompany, item } = this.props;

        deleteCompany.dispatch(item.id);
    }

    render() {
        const { isSubmitting, item, deleteCompany } = this.props;
        const disabled = isSubmitting || deleteCompany.meta.pending;

        return <div className='company-form'>
            <Field
                name='name'
                component={Input}
                label='Название' />
            <Row gutter={16}>
                <Col span={12}>
                    <Field
                        name='dateStart'
                        component={DatePicker}
                        label='Срок начала взаимодействия'
                        style={{ width: '250px' }}
                        allowClear />
                </Col>
                <Col span={12}>
                    <Field
                        name='dateFinish'
                        component={DatePicker}
                        label='Срок окончания взаимодействия'
                        style={{ width: '250px' }}
                        allowClear />
                </Col>
            </Row>
            <Field
                name='industry'
                component={SearchSelect}
                label='Отрасль'
                action={getIndustryDictionary}
                parseAsync={items => items ? toPairs(items).map(([id, value]) => ({ id, value })) : []}
                allowClear />
            <Field
                name='consultants'
                component={SearchSelect}
                label='Консультанты'
                action={getAdmins}
                getNamePath={item => `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`}
                isMulti />
            <Field
                name='billingType'
                component={RadioButtons}
                label='Тип биллинга'
                options={COMPANY_BILLING} />
            <Button
                type='primary'
                htmlType='submit'
                disabled={disabled}>
                Сохранить
            </Button>
            { item &&
                <Button
                    danger
                    onClick={this.deleteCompany}
                    disabled={disabled}>
                    Удалить
                </Button>
            }
        </div>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    billingType: yup.string().required(),
});

export default withFormWrapper(CompanyForm, {
    mapPropsToValues: ({ item }) => item,
    validationSchema,
    subscriptions: { values: true }
});
