import { Col, Row } from 'antd';
import { includes, pathOr } from 'ramda';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getUser } from '../../actions/asyncActions';
import ConsultationsByCompany from './modules/ConsultationsByCompany';
import ConsultationsByRole from './modules/ConsultationsByRole';
import ContentByCompany from './modules/ContentByCompany';
import EventsAverageAttendance from './modules/EventsAverageAttendance';
import PromotionByCompany from './modules/PromotionByCompany';
import PromotionByMonths from './modules/PromotionByMonths';
import PromotionByRoles from './modules/PromotionByRoles';
import WebinarsByCompany from './modules/WebinarsByCompany';
import WebinarsBySpeakers from './modules/WebinarsBySpeakers';
import WebinarsByThemes from './modules/WebinarsByThemes';
import PromotionByConsultant from './modules/PromotionByConsultant';
import { ADMIN, SUPER_ADMIN } from '../../constants/roles';

const Header = styled.div`
    background: #2B3D4F;
    padding: 16px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
`;

export const Content = styled.div`
    margin: 0 15px 15px;
`;

class ModulesStatistic extends Component {
    allowModule = type => {
        const modules = this.props.modules || pathOr([], ['_embedded', 'company', 'modules'], this.props.user).map(({ id }) => id);

        return includes(type, modules);
    }

    render() {
        const { company, themes, user } = this.props;
        const isAdmin = includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles);

        return <div>
            { this.allowModule('events') &&
                <Fragment>
                    <Header>ВЕБИНАРЫ И СЕМИНАРЫ</Header>
                    <Content>
                        <Row gutter={15}>
                            <Col sm={12}>
                                <WebinarsByCompany id={company} themes={themes} />
                            </Col>
                            <Col sm={12}>
                                <EventsAverageAttendance id={company} themes={themes} />
                            </Col>
                        </Row>
                        <WebinarsByThemes id={company} />
                        <WebinarsBySpeakers id={company} themes={themes} />
                    </Content>
                </Fragment>
            }
            { this.allowModule('consultations') &&
                <Fragment>
                    <Header>КОНСУЛЬТАЦИИ</Header>
                    <Content>
                        <ConsultationsByCompany id={company} />
                        <ConsultationsByRole id={company} />
                    </Content>
                </Fragment>
            }
            { this.allowModule('content') &&
                <Fragment>
                     <Header>ОБУЧЕНИЕ</Header>
                    <Content>
                        <ContentByCompany id={company} />
                    </Content>
                </Fragment>
            }
            { this.allowModule('promotion') &&
                <Fragment>
                    <Header>ПРОФЕССИОНАЛЬНАЯ ПОДДЕРЖКА</Header>
                    <Content>
                        <Row gutter={15}>
                            <Col sm={12}>
                                <PromotionByCompany id={company} />
                            </Col>
                            <Col sm={12}>
                                <PromotionByMonths id={company} />
                            </Col>
                        </Row>
                        <PromotionByRoles id={company} />
                        { isAdmin && <PromotionByConsultant id={company} /> }
                    </Content>
                </Fragment>
            }
        </div>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(ModulesStatistic);
