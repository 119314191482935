import React, { Component, Fragment } from 'react';
import { Textarea, withFormWrapper } from '@meconsultant/common';
import { Button, Col, Row } from 'antd';
import { Field } from 'react-final-form';
import { any, filter, find, includes, keys, propEq, toPairs } from 'ramda';
import moment from 'moment';

import Select from './formComponents/Select';
import SearchSelect from './formComponents/SearchSelect';
import { PARTICIPANT_STATUSES } from '../../constants/statuses';
import { getParticipantsStatuses, getUser, getIndustryDictionary } from '../../actions/asyncActions';
import { DatePicker } from '@meconsultant/common/lib/components/formComponents/DatePicker';
import { asyncConnect } from 'react-async-client';
import Cascader from './formComponents/Cascader';

class ParticipantStatusForm extends Component {
    getStatuses = () => {
        const { getParticipantsStatuses: { data }, user: { roles }, item } = this.props;
        const filterStatuses = items => filter(status => !status.hide && (!status.roles || any(role => includes(role, status.roles), roles || []) || status.id === item.status), items)

        return filterStatuses(keys(data).map(key => ({
            ...find(propEq('id', key), PARTICIPANT_STATUSES),
            value: key,
            label: find(propEq('id', key), PARTICIPANT_STATUSES).title,
            children: filterStatuses(data[key].map(id => ({
                ...find(propEq('id', id), PARTICIPANT_STATUSES),
                value: id,
                label: (find(propEq('id', id), PARTICIPANT_STATUSES).secondLevel || find(propEq('id', id), PARTICIPANT_STATUSES).title)
            })))
        })));
    }

    onChangeStatus = status => {
        if (!(status === this.props.values.status && status === 'finished')) {
            this.props.form.change('programFinishedAt', status !== this.props.values.status && status === 'finished' ? moment().toDate() : null);
        }

        if (!this.isEmployed(this.props.values)) {
            this.props.form.change('industry', null);
        }
    }

    isEmployed = (values) => {
        return includes(values.additionalStatus, ['employed_in_program', 'employed_before_program', 'employed_with_ancor']);
    }

    render() {
        const { isSubmitting, close, modules, values, getParticipantsStatuses: { meta } } = this.props;

        return <Fragment>
            <Row gutter={16}>
                <Col span={values.status === 'finished' ? 18 : 24}>
                    <Field
                        component={Cascader}
                        name='status'
                        label='Статус'
                        options={this.getStatuses()}
                        loading={meta.pending}
                        namePath='title'
                        additionalNames={['additionalStatus']}
                        onChange={this.onChangeStatus}
                        disableClear />
                </Col>
                { values.status === 'finished' &&
                    <Col span={6}>
                        <Field
                            component={DatePicker}
                            name='programFinishedAt'
                            label='Дата'
                            max={moment()} />
                    </Col>
                }
            </Row>
            { this.isEmployed(values) &&
                <Field
                    name='industry'
                    component={SearchSelect}
                    label='Отрасль'
                    action={getIndustryDictionary}
                    parseAsync={items => items ? toPairs(items).map(([id, value]) => ({ id, value })) : []}
                    allowClear />
            }
            <h3><strong>Комментарий</strong></h3>
            <Field
                name='module'
                component={Select}
                label='Сервис'
                placeholder='Выберите сервис'
                options={modules}
                namePath='title' />
            <Field
                name='date'
                component={DatePicker}
                label='Дата'
                allowClear={false} />
            <Field
                component={Textarea}
                name='comment'
                label='Комментарий'
                placeholder='Введите текст комментария' />
            <div>
                <Button
                    type='primary'
                    htmlType='submit'
                    disabled={isSubmitting}>
                    СОХРАНИТЬ
                </Button>
                <Button style={{ marginLeft: 10 }} onClick={close}>
                    ОТМЕНА
                </Button>
            </div>
        </Fragment>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    getParticipantsStatuses: getParticipantsStatuses
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, stateToProps)(withFormWrapper(ParticipantStatusForm, {
    mapPropsToValues: ({ item }) => ({
        ...item,
        date: item.date || moment().toDate(),
        status: item.status === 'new' ? null : item.status
    }),
    subscriptions: { values: true }
}));
