import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Button, Tabs, message, Popconfirm } from 'antd';
import { takeEvery } from 'redux-saga/effects';
import { ArrowLeftOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { getStage, deleteStage } from '../actions/asyncActions';
import { openStageModal, openMaterialModal } from '../actions/modalActions';
import { PUT_STAGE, PATCH_STAGE } from '../constants/actionTypes';
import StageInfo from './StageInfo';
import StageThemes from './StageThemes';
import Route from './Route';

class Stage extends Component {
    onChangeTab = key => {
        this.props.history.push(`/stages/${this.props.match.params.id}${key === 'info' ? '' : `/${key}`}`);
    };

    edit = () => {
        const { getStage: { data }, openStageModal, openMaterialModal, match } = this.props;

        match.params.type === 'themes' ?
            openMaterialModal({ stage: match.params.id }) :
            openStageModal({ data, tab: match.params.type || 'info' });
    }

    delete = () => {
        const { deleteStage, match: { params: { id }}} = this.props;

        deleteStage.dispatch(id);
    }

    render() {
        const { history, getStage: { data, meta }, openMaterialModal, match: { params: { type, id }}, deleteStage: { meta: deleteMeta }} = this.props;

        return <Fragment>
            <div className='toolbar-row detail-toolbar'>
                <Button shape='circle' icon={<ArrowLeftOutlined />} onClick={history.goBack} />
                <h1>
                    { data.title }
                </h1>
                <div className='toolbar-actions'>
                    <Button.Group>
                        <Button
                            type='primary'
                            icon={<EditOutlined />}
                            disabled={meta.pending && !meta.lastSucceedAt}
                            onClick={this.edit}
                        >
                            Редактировать
                        </Button>
                        <Popconfirm
                            title='Вы уверены, что хотите удалить этап?'
                            okText='Да'
                            cancelText='Нет'
                            onConfirm={this.delete}
                            placement='left'>
                            <Button
                                danger
                                loading={deleteMeta.pending}
                                icon={<DeleteOutlined />}>
                                Удалить
                            </Button>
                        </Popconfirm>
                    </Button.Group>
                </div>
            </div>
            <Tabs onChange={this.onChangeTab} activeKey={!type ? 'info' : type}>
                <Tabs.TabPane tab='Информация' key='info'>
                    <Route
                        path={`/stages/${id}`}
                        render={props => <StageInfo {...props} data={data} meta={meta} />} />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Темы' key='themes'>
                    <Route
                        path={`/stages/${id}/themes`}
                        render={props => <StageThemes {...props} data={data} meta={meta} stage={id} openMaterialModal={openMaterialModal} />} />
                </Tabs.TabPane>
            </Tabs>
        </Fragment>
    }
}

export default asyncConnect({
    getStage: getStage
        .withParams(() => ({ type: 'tab' }))
        .withPayload(({ match: { params: { id }}}) => id)
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_STAGE), toSuccess(PATCH_STAGE)], () => {
                getProps().getStage.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
    deleteStage: deleteStage
        .withSuccessHandler(({ history }) => {
            message.success('Этап успешно удален');
            history.push('/stages');
        })
        .withOptions({ resetOnUnmount: true })
}, null, { openStageModal, openMaterialModal })(Stage);
