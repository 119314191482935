import { createAsyncAction, toSuccess, toError } from 'react-async-client';
import { put, call, race, take } from 'redux-saga/effects';

import { isTokenExpired, getRefreshToken, expiredDate } from './token';
import { logout, postRefreshToken } from '../actions/appActions';

function checkTokenExpiration(handler) {
    return function* (action) {
        if (!isTokenExpired()) {
            return yield call(handler, action);
        } else {
            const refreshToken = getRefreshToken();

            if (refreshToken) {
                yield put(postRefreshToken(refreshToken));

                const { success, error } = yield race({
                    success: take(toSuccess(postRefreshToken.type)),
                    error: take(toError(postRefreshToken.type))
                });

                if (success) {
                    localStorage.setItem('expiredDate', expiredDate(success.payload.token));
                    return yield call(handler, action);
                }

                if (error) {
                    yield put(logout());
                }
            }

            yield put(logout());
            throw new Error('Token is expired');
        }
    }
}

export default function(...params) {
    params[1] = checkTokenExpiration(params[1]);
    return createAsyncAction(...params);
}
