import React, { Component } from 'react';
import { Button, message, Modal } from 'antd';
import moment from 'moment';
import { includes, path } from 'ramda';
import pluralize from 'pluralize-ru';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
import qs from 'qs';

import { openCancelConsultationModal, openConsultationTitleModal } from '../../actions/modalActions';
import { getUser } from '../../actions/asyncActions';
import { ADMIN, SUPER_ADMIN } from '../../constants/roles';
import { getOnlineIcon } from '../../utils/events';
import { GoogleOutlined } from '@ant-design/icons';

const Centered = styled.div`
    display: flex;
    align-items: center;
    img {
        margin-right: 5px;
    }
    button {
        margin-left: 5px;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ButtonsGroup = styled.div`
    .ant-btn + .ant-btn {
        margin-left: 8px;
    }
`;

class ConsultationViewModal extends Component {
    onCopy = (_, result) => {
        result ? message.success('Ссылка успешно скопирована') : message.error('Не удалось скопировать ссылку');
    };

    getGoogleLink = () => {
        const { params: { item }} = this.props;
        const consultant = path(['_embedded', 'consultant'], item);

        return `https://www.google.com/calendar/event?${qs.stringify({
            action: 'TEMPLATE',
            text: `Консультация (${consultant.lastName} ${consultant.firstName}${consultant.middleName ? ` ${consultant.middleName}` : ''})`,
            details: item.description,
            location: item.location,
            dates: moment(item.start).format('YYYYMMDDTHHmmss') + '/' + moment(item.end).format('YYYYMMDDTHHmmss')
        })}`;
    }

    render() {
        const { params: { item, onCloseCancel }, modal, user } = this.props;
        const duration = moment(item.end).diff(moment(item.start), 'minutes');
        const hours = Math.floor(duration / 60);
        const minutes = duration - hours * 60;
        const consultant = path(['_embedded', 'consultant'], item);
        const participant = path(['_embedded', 'participant'], item);

        return <Modal
            {...modal}
            title={<strong>Консультация</strong>}
            footer={null}>
            <div className="modal-item">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="3.00244" y="6" width="18" height="15" rx="2" stroke="#A7B9CF" strokeWidth="2"/>
                    <path d="M3.00244 10C3.00244 8.11438 3.00244 7.17157 3.58823 6.58579C4.17401 6 5.11682 6 7.00244 6H17.0024C18.8881 6 19.8309 6 20.4167 6.58579C21.0024 7.17157 21.0024 8.11438 21.0024 10H3.00244Z" fill="#A7B9CF"/>
                    <path d="M7.00244 3L7.00244 6" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M17.0024 3L17.0024 6" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round"/>
                    <rect x="7.00244" y="12" width="4" height="2" rx="0.5" fill="#A7B9CF"/>
                    <rect x="7.00244" y="16" width="4" height="2" rx="0.5" fill="#A7B9CF"/>
                    <rect x="13.0024" y="12" width="4" height="2" rx="0.5" fill="#A7B9CF"/>
                    <rect x="13.0024" y="16" width="4" height="2" rx="0.5" fill="#A7B9CF"/>
                </svg>
                { moment(item.start).format('DD.MM.YYYY') }, { moment(item.start).format('dddd') }
            </div>
            <div className="modal-item">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.0024" cy="12" r="9" stroke="#A7B9CF" strokeWidth="2"/>
                    <path d="M16.5024 12H12.2524C12.1144 12 12.0024 11.8881 12.0024 11.75V8.5" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round"/>
                </svg>
                { moment(item.start).format('HH:mm') }-{ moment(item.end).format('HH:mm') }
                <div style={{ fontSize: 11, fontWeight: 600, color: '#fff', background: '#2B3D4F', borderRadius: 4, padding: '3px 8px', display: 'inline-block', marginLeft: 10 }}>
                    { `${hours ? `${hours} ${pluralize(hours, '', 'час', 'часа', 'часов')} ` : ''}${minutes || ''} ${pluralize(minutes, '', 'минута', 'минуты', 'минут')}` }
                </div>
            </div>
            <div className="modal-item">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.798 20.7059C17.4561 19.6427 16.7029 18.7033 15.655 18.0332C14.6071 17.3632 13.3232 17 12.0024 17C10.6816 17 9.39774 17.3632 8.34987 18.0332C7.30201 18.7033 6.54874 19.6427 6.20689 20.7059" stroke="#A7B9CF" strokeWidth="2"/>
                    <circle cx="12.0024" cy="10" r="3" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round"/>
                    <rect x="3.00244" y="3" width="18" height="18" rx="3" stroke="#A7B9CF" strokeWidth="2"/>
                </svg>
                <div style={{ lineHeight: 1.3 }}>
                    <div>{ participant.lastName } { participant.firstName } { participant.middleName || '' }</div>
                    <div><small style={{ marginLeft: 0 }}>Участник</small></div>
                </div>
            </div>
            <div className="modal-item">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.798 20.7059C17.4561 19.6427 16.7029 18.7033 15.655 18.0332C14.6071 17.3632 13.3232 17 12.0024 17C10.6816 17 9.39774 17.3632 8.34987 18.0332C7.30201 18.7033 6.54874 19.6427 6.20689 20.7059" stroke="#A7B9CF" strokeWidth="2"/>
                    <circle cx="12.0024" cy="10" r="3" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round"/>
                    <rect x="3.00244" y="3" width="18" height="18" rx="3" stroke="#A7B9CF" strokeWidth="2"/>
                </svg>
                <div style={{ lineHeight: 1.3 }}>
                    <div>{ consultant.lastName } { consultant.firstName } { consultant.middleName || '' }</div>
                    <div><small style={{ marginLeft: 0 }}>Консультант</small></div>
                </div>
            </div>
            { item.location &&
                <Centered className="modal-item">
                    { getOnlineIcon(item.location) ? <img src={path(['icon'], getOnlineIcon(item.location))} alt='icon' /> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 4V16C8 16.5304 8.21071 17.0391 8.58579 17.4142C8.96086 17.7893 9.46957 18 10 18H18C18.5304 18 19.0391 17.7893 19.4142 17.4142C19.7893 17.0391 20 16.5304 20 16V7.242C20 6.97556 19.9467 6.71181 19.8433 6.46624C19.7399 6.22068 19.5885 5.99824 19.398 5.812L16.083 2.57C15.7094 2.20466 15.2076 2.00007 14.685 2H10C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4V4Z" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16 18V20C16 20.5304 15.7893 21.0391 15.4142 21.4142C15.0391 21.7893 14.5304 22 14 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V9C4 8.46957 4.21071 7.96086 4.58579 7.58579C4.96086 7.21071 5.46957 7 6 7H8" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>}
                    { getOnlineIcon(item.location) && path(['text'], getOnlineIcon(item.location)) }
                    <CopyToClipboard text={item.location} onCopy={this.onCopy}><Button size="small" type='dashed' danger>Скопировать ссылку</Button></CopyToClipboard>
                </Centered>
            }
            { item.description && <div className="modal-item">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="5.00244" y="4" width="14" height="17" rx="2" stroke="#A7B9CF" strokeWidth="2"/>
                    <path d="M9.00244 9H15.0024" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M9.00244 13H15.0024" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M9.00244 17H13.0024" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round"/>
                </svg>
                { item.description }
            </div>}
            { (item.consultant === user.id || ((includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles)) && user.superAdmin)) &&
                <ButtonsWrapper>
                    <ButtonsGroup>
                        <Button onClick={() => this.props.openCancelConsultationModal({ externalId: item.externalId, onClose: onCloseCancel })}>
                            отменить или перенести
                        </Button>
                        <Button onClick={() => this.props.openConsultationTitleModal({ item })}>
                            изменить тему встречи
                        </Button>
                    </ButtonsGroup>
                    { !moment().isAfter(moment(item.start)) && <Button
                        target='_blank'
                        href={this.getGoogleLink()}
                        icon={<GoogleOutlined />} />
                    }
                </ButtonsWrapper>
            }
        </Modal>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps, { openCancelConsultationModal, openConsultationTitleModal })(ConsultationViewModal);
