import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message, Spin } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { withState } from 'recompose';
import styled from 'styled-components';

import MaterialForm from '../../forms/MaterialForm';
import { putMaterial, getMaterial } from '../../../actions/asyncActions';
import MaterialSelector from './MaterialSelector';

const ThemesColumn = styled.div`
    width: 25%;
    display: inline-block;
    padding: 15px 15px 15px 25px;
    float: left;
    height: calc(100vh - 55px);
    overflow: auto;
`;

const MaterialColumn = styled.div`
    width: 75%;
    display: inline-block;
    float: right;
    height: 100%;
    overflow: auto;
`;

const CenterBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 60px);
`;

class MaterialModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        putMaterial: PropTypes.object,
        getMaterial: PropTypes.object,
        show: PropTypes.func,
        hide: PropTypes.func,
        params: PropTypes.object,
        setSelected: PropTypes.func,
        selected: PropTypes.string
    };

    state = {
        dirty: false
    };

    setDirty = dirty => this.setState({ dirty });

    render() {
        const { modal, putMaterial, getMaterial, show, hide, params: { stage }, setSelected, selected } = this.props;

        return <Modal
            {...modal}
            width='100%'
            className='material-modal'
            title='Редактировать материал'
            footer={null}>
            <ThemesColumn>
                <MaterialSelector stage={stage} setSelected={setSelected} selected={selected} hasChanges={this.state.dirty}  />
            </ThemesColumn>
            { !!selected &&
                <MaterialColumn>
                    { getMaterial.meta.pending ? <CenterBlock><Spin /></CenterBlock> :
                        getMaterial.meta.error ? <CenterBlock style={{ color: 'red' }}>Не удалось отобразить данные</CenterBlock> :
                        <MaterialForm
                            formAction={putMaterial}
                            item={getMaterial.data}
                            show={show}
                            hide={hide}
                            stage={stage}
                            selected={selected}
                            setSelected={setSelected}
                            getMaterial={getMaterial}
                            onChangeDirty={this.setDirty} />
                    }
                </MaterialColumn>
            }
        </Modal>;
    }
}

export default withState('selected', 'setSelected', ({ params }) => params.id)(
    withAsyncActions(props => ({
        putMaterial: putMaterial
            .withSuccessHandler(() => {
                message.success('Материал был успешно отредактирован');
            })
            .withOptions({ resetOnUnmount: true }),
        getMaterial: getMaterial
            .withParams(() => ({ type: 'modal' }))
            .withPayload(({ selected }) => selected)
            .withShouldUpdate(({ selected }, { selected: nextSelected }) => selected !== nextSelected && nextSelected)
            .withOptions({ dispatchOnMount: !!props.selected, resetOnUnmount: true, dispatchOnUpdate: true })
    }))(MaterialModal)
);
