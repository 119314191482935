import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { isEmpty } from 'ramda';
import { withRouter } from 'react-router-dom';

import { LINES_PER_PAGE, ORDER_TYPES } from '../constants/lists';
import { getSorting, extendSearchPath, getUrlPagination } from '../utils/urls';

class TableComponent extends Component {
    static propTypes = {
        state: PropTypes.object,
        setOffset: PropTypes.func,
        action: PropTypes.object,
        columns: PropTypes.array,
        rowKey: PropTypes.string
    };

    static defaultProps = {
        rowKey: 'id'
    };

    getPaginationConfig() {
        const { action: { data: { _meta }}, setOffset, location, stateLess, showSizeChanger, setLimit } = this.props;
        const { offset = 0, limit = LINES_PER_PAGE } = getUrlPagination(location);

        if (!_meta || (_meta.count <= (stateLess ? this.props.limit : limit) && !showSizeChanger)) {
            return false;
        }

        return {
            total: _meta.count,
            pageSize: stateLess ? this.props.limit : Number(limit),
            current: stateLess ? ((this.props.offset / this.props.limit) + 1) : (Number(offset) / Number(limit)) + 1,
            defaultCurrent: 0,
            onChange: (page, pageSize) => setOffset((page - 1) * (pageSize || this.props.limit || Number(limit)), pageSize || this.props.limit || Number(limit)),
            ...(showSizeChanger ? {
                showSizeChanger: true,
                onShowSizeChange:  (_, pageSize) => setLimit(pageSize)
            } : {})
        };
    }

    onChange = (_, filter, sorter) => {
        const currentSorting = getSorting(this.props.location);

        if (currentSorting.sort_by !== sorter.columnKey || currentSorting.sort_order !== ORDER_TYPES[sorter.order]) {
            this.props.history.replace(extendSearchPath(this.props.location, {
                sorting: isEmpty(sorter) ? null : {
                    sort_by: sorter.columnKey,
                    sort_order: sorter.order ? (sorter.order === 'descend' ? ORDER_TYPES.descend : ORDER_TYPES.ascend) : undefined
                }
            }));
        }
    }

    getColumns = () => {
        const sorting = getSorting(this.props.location);

        return this.props.columns.map(column => {
            return sorting.sort_by === column.key ? {
                ...column,
                defaultSortOrder: sorting.sort_order === 'desc' ? 'descend' : 'ascend'
            } : column;
        });
    }

    render() {
        const { action: { data, meta: { pending } }, rowKey, showHeader, rowSelection, scroll, bordered, expandable } = this.props;
        const pagination = this.getPaginationConfig();

        return <Table
            className='table'
            columns={this.getColumns()}
            expandable={expandable}
            dataSource={data.items}
            pagination={pagination}
            loading={pending}
            rowKey={rowKey}
            onChange={this.onChange}
            showHeader={showHeader}
            rowSelection={rowSelection}
            scroll={scroll}
            bordered={bordered} />;
    }
}

export default withRouter(TableComponent);
