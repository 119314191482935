import { Spin } from 'antd';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { connect } from 'react-redux';
import ReactHighcharts from 'react-highcharts';

import { getCompanyStatisticEventsThemes, getUser } from '../../../actions/asyncActions';
import { Block, BlockContent, BlockHeader } from './WebinarsByCompany';
import { pathOr } from 'ramda';

class WebinarsByCompany extends Component {
    getConfig = () => {
        const { getCompanyStatisticEventsThemes: { data }} = this.props;
        const average = Math.ceil(pathOr([], ['themes'], data).map(item => item.countAverageParticipated).reduce((res, cur) => res + cur, 0) / pathOr([], ['themes'], data).length);

        return {
            chart: {
                type: 'column'
            },
            title: {
                text: null
            },
            xAxis: {
                categories: pathOr([], ['themes'], data).map(item => item.title),
                gridLineDashStyle: 'dash',
                gridLineWidth: 1
            },
            yAxis: [{
                allowDecimals: false,
                min: 0,
                title: {
                    text: 'Вебинары'
                },
                gridLineDashStyle: 'dash',
                gridLineWidth: 1
            }, {
                allowDecimals: false,
                min: 0,
                title: {
                    text: 'Среднее количество участников'
                },
                opposite: true,
                gridLineDashStyle: 'dash',
                gridLineWidth: 1
            }],
            credits: {
                enabled: false
            },
            tooltip: {
                enabled: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Вебинары (план)',
                color: '#959EA7',
                data: pathOr([], ['themes'], data).map(item => item.countEventsScheduled)
            }, {
                name: 'Вебинары (факт)',
                color: '#2B3D4F',
                data: pathOr([], ['themes'], data).map(item => item.countEvents)
            }, {
                name: 'Среднее количество участников',
                color: '#47AAC4',
                data: pathOr([], ['themes'], data).map(item => item.countAverageParticipated)
            }, {
                name: null,
                type: 'line',
                data: pathOr([], ['themes'], data).map(() => average),
                marker: {
                    enabled: false
                },
                color: '#2B3D4F',
                dashStyle: 'ShortDash',
                lineWidth: 1,
                yAxis: 1,
                enableMouseTracking: false,
                showInLegend: false,
            }]
        };
    }

    render() {
        const { getCompanyStatisticEventsThemes: { meta }} = this.props;

        return <Block>
            <BlockHeader>
                <h3>ВЕБИНАРЫ ПО ТЕМАМ</h3>
            </BlockHeader>
            <BlockContent>
                { meta.pending ? <Spin /> : <div>
                    <ReactHighcharts config={this.getConfig()} />
                </div> }
            </BlockContent>
        </Block>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(asyncConnect({
    getCompanyStatisticEventsThemes: getCompanyStatisticEventsThemes
        .withPayload(({ id, user }) => id || user.company )
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(WebinarsByCompany));
