import { Progress, Select, Spin } from 'antd';
import { pathOr, propEq, find } from 'ramda';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { connect } from 'react-redux';
import { withState } from 'recompose';
import styled from 'styled-components';
import { getCompanyStatisticEvents, getUser } from '../../../actions/asyncActions';

export const Block = styled.div`
    border: 1px solid #2B3D4F;
    margin-top: 15px;
`;

export const BlockHeader = styled.div`
    padding: 16px;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
        margin: 0;
        color:  #2B3D4F;
        font-weight: bold;
        font-size: 14px;
    }
`;

export const BlockContent = styled.div`
    padding: 16px;
    .ant-spin {
        margin-bottom: 20px;
    }
`;

export const Info = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const InfoBlock = styled.div`
    text-align: center;
    line-height: 1;
    margin-bottom: 8px;
`;

export const InfoDivider = styled.div`
    height: 40px;
    width: 3px;
    background #2B3D4F;
    border-radius: 3px;
    margin-left: 16px;
    margin-right: 16px;
`;

export const FactText = styled.div`
    color: #2B3D4F;
    font-weight: bold;
    font-size: 32px;
`;

export const PlanText = styled(FactText)`
    color: #A7B9CF;
`;

export const FactDesc = styled.div`
    font-size: 18px;
    color: #2B3D4F;
`;

export const PlanDesc = styled.div`
    font-size: 18px;
    color: #A7B9CF;
`;

class WebinarsByCompany extends Component {
    render() {
        const { eventTheme, setEventTheme, themes, user, getCompanyStatisticEvents: { meta, data }, consultant } = this.props;
        const eventThemes = themes || pathOr([], ['_embedded', 'themes'], find(propEq('id', 'events'), pathOr([], ['_embedded', 'company', 'modules'], user)));

        return <Block>
            <BlockHeader>
                <h3>{ consultant ? 'ВЕБИНАРЫ' : 'ВЕБИНАРЫ ПО КОМПАНИИ' }</h3>
                { !consultant && <Select
                    value={eventTheme || undefined}
                    onChange={value => setEventTheme(value)}
                    placeholder='Все темы'
                    style={{ width: 250 }}
                    allowClear>
                    { eventThemes.map(item => <Select.Option key={item.id} value={item.id}>{ item.title }</Select.Option>) }
                </Select>}
            </BlockHeader>
            <BlockContent>
                { meta.pending ? <Spin /> : <div>
                    <Info>
                        <InfoBlock>
                            <FactText>{ data.countEvents }</FactText>
                            <FactDesc>факт</FactDesc>
                        </InfoBlock>
                        <InfoDivider />
                        <InfoBlock>
                            <PlanText>{ data.countEventsScheduled }</PlanText>
                            <PlanDesc>план</PlanDesc>
                        </InfoBlock>
                    </Info>
                    <Progress percent={data.countEventsPercents || 0} strokeColor='#2B3D4F' trailColor='#A7B9CF' />
                </div> }
            </BlockContent>
        </Block>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default withState('eventTheme', 'setEventTheme', null)(connect(stateToProps)(asyncConnect({
    getCompanyStatisticEvents: getCompanyStatisticEvents
        .withPayload(({ id, user, eventTheme, consultant }) => ({
            id: id || user.company,
            q: {
                eventTheme,
                consultant
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(WebinarsByCompany)));
