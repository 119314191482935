import React, { Component } from 'react';
import { Row, Col, Spin } from 'antd';
import { pathOr } from 'ramda';
import { pluralize } from 'numeralize-ru';

import { Image } from './forms/formComponents/ImageLoader';

export default class StageInfo extends Component {
    render() {
        const { data, meta } = this.props;
        const topics = pathOr([], ['_embedded', 'topics'], data);

        return meta.pending && !meta.lastSucceedAt ?
            <Spin /> :
            <div className='detail-block'>
                <Row className='detail-row'>
                    <Col span={8}>Название</Col>
                    <Col span={16}>{ data.title }</Col>
                </Row>
                <Row className='detail-row'>
                    <Col span={8}>Порядковый номер</Col>
                    <Col span={16}>{ data.order }</Col>
                </Row>
                <Row className='detail-row'>
                    <Col span={8}>Опубликован</Col>
                    <Col span={16}>{ !data.hidden ? 'Да' : 'Нет' }</Col>
                </Row>
                { !!topics.length &&
                    <Row className='detail-row'>
                        <Col span={8}>Категории этапа</Col>
                        <Col span={16}>{ topics.map((topic, index) => <span key={topic.id}>{ `${topic.title}${index < topics.length - 1 ? ', ' : ''}` }</span>)}</Col>
                    </Row>
                }
                { !!data.subtitle &&
                    <Row className='detail-row'>
                        <Col span={8}>Подзаголовок</Col>
                        <Col span={16}>{ data.subtitle }</Col>
                    </Row>
                }
                { !!data.lead &&
                    <Row className='detail-row'>
                        <Col span={8}>Тизер</Col>
                        <Col span={16}>{ data.lead }</Col>
                    </Row>
                }
                { !!data.time &&
                    <Row className='detail-row'>
                        <Col span={8}>Время</Col>
                        <Col span={16}>{ data.time } { pluralize(data.time, 'минута', 'минуты', 'минут') }</Col>
                    </Row>
                }
                { data.image &&
                    <Row className='detail-row'>
                        <Col span={8}>Изображение</Col>
                        <Col span={16}>
                            <Image image={data.image} />
                        </Col>
                    </Row>
                }
            </div>;
    }
}
