import React, { Component, Fragment } from 'react';
import { Upload, Button, message } from 'antd';
import styled from 'styled-components';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { withFieldWrapper } from '@meconsultant/common';

import { FILE_URL, getFileView } from '../../../constants/urls';
import { getJWTToken } from '../../../utils/token';

export const Image = styled.div`
    width: 400px;
    height: 250px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${({ image }) => getFileView(image)});
`;

class ImageLoader extends Component {
    onChange = info => {
        const { status, response } = info.file;

        switch(status) {
            case 'done':
                this.props.onChange(response.id)
                break;
            case 'error':
                message.error('Не удалось загрузить изображение');
                break;
            default:
                return;
        }
    }

    delete = () => {
        this.props.onChange(null);
    }

    render() {
        const { input: { value }} = this.props;

        return <Upload
            showUploadList={false}
            accept='.png, .jpg, .jpeg'
            action={FILE_URL}
            headers={{ Authorization: getJWTToken() }}
            onChange={this.onChange}
            disabled={!!value}>
            { value ?
                <Fragment>
                    <Image image={value} />
                    <Button style={{ marginTop: 15 }} danger icon={<DeleteOutlined />} onClick={this.delete}>Удалить</Button>
                </Fragment> :
                <Button icon={<UploadOutlined />}>Загрузить</Button>
            }
        </Upload>
    }
}

export default withFieldWrapper(ImageLoader);
