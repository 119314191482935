import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { prop } from 'ramda';
import { Field } from 'react-final-form';
import * as yup from 'yup';

import { withFormWrapper } from '@meconsultant/common';
import FormBuilderField from './formComponents/FormBuidlerField';
import { withAsyncHandlers } from 'react-async-client/lib/withAsyncHandlers';

class MaterialForm extends Component {
    static propTypes = {
        hide: PropTypes.func,
        show: PropTypes.func,
        isSubmitting: PropTypes.bool,
        setSelected: PropTypes.func,
        selected: PropTypes.string,
        stage: PropTypes.string,
        getMaterial: PropTypes.object,
        form: PropTypes.object,
        dirty: PropTypes.bool,
        onChangeDirty: PropTypes.func
    };

    componentDidMount() {
        this.props.onChangeDirty(false);
    }

    componentDidUpdate(prev) {
        if (prev.dirty !== this.props.dirty) {
            this.props.onChangeDirty(this.props.dirty);
        }
    }

    render() {
        const { hide, show, isSubmitting } = this.props;

        return <Fragment>
            <Field
                name='content'
                component={FormBuilderField}
                onPreviewOpen={hide}
                onPreviewClose={show} />
            <div className='material-modal-footer'>
                <Button
                    type='primary'
                    htmlType='submit'
                    disabled={isSubmitting}>
                    Сохранить
                </Button>
            </div>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    content: yup.object().shape({
        items: yup.array().required(),
        elements: yup.object().required()
    })
});

export default withFormWrapper(withAsyncHandlers({
    formAction: {
        successHandler: props => {
            props.form.initialize(props.formAction.data);
        }
    }
})(MaterialForm), {
    mapPropsToValues: prop('item'),
    validationSchema,
    subscriptions: { dirty: true }
});
