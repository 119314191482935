import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Avatar, Tooltip, Popover, message, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined, PlusOutlined, SendOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { filter, includes } from 'ramda';
import { asyncConnect } from 'react-async-client';
import { withRouter } from 'react-router';

import { getParticipants, getUser, putResendEmailParticipant, putSetConsultant } from '../actions/asyncActions';
import { openParticipantModal, openParticipantImportModal, openConsultantSelectModal } from '../actions/modalActions';
import TableList from './TableList';
import { POST_PARTICIPANT, POST_PARTICIPANT_FILE, PUT_SET_CONSULTANT } from '../constants/actionTypes';
import { getFileView } from '../constants/urls';
import ParticipantFilter from './filters/ParticipantFilter';
import { getUrlParams } from '../utils/urls';
import { ADMIN, SUPER_ADMIN } from '../constants/roles';
import { withState } from 'recompose';

class Participants extends Component {
    static propTypes = {
        openParticipantModal: PropTypes.func,
        location: PropTypes.object,
        openParticipantImportModal: PropTypes.func,
    };

    componentDidUpdate(prev) {
        if (this.props.location.search !== prev.location.search) {
            this.onChangeSelected([]);
        }
    }

    getColumns = () => {
        const { user } = this.props;
        const columns = [
            {
                dataIndex: 'photo',
                key: 'photo',
                render: photo => <Avatar src={photo ? getFileView(photo) : null} icon={<UserOutlined />} />,
                width: 32
            },
            {
                title: 'ФИО',
                dataIndex: 'lastName',
                key: 'name',
                render: (lastName, { firstName, middleName, id }) =>
                    <Link to={`/participants/${id}`}>{ lastName } { firstName } { middleName || '' }</Link>
            },
            {
                title: 'Компания',
                dataIndex: ['_embedded', 'company', 'name'],
                key: 'company',
                hide: !(includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles)) || this.props.company
            },
            {
                title: 'Роль',
                dataIndex: ['_embedded', 'companyRole', 'name'],
                key: 'companyRole'
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email'
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                key: 'phone'
            },
            {
                title: 'Консультант',
                dataIndex: ['_embedded', 'consultant'],
                key: 'consultant',
                render: consultant => consultant ? <span>{ consultant.lastName } { consultant.firstName } { consultant.middleName || '' }</span> : null
            },
            {
                title: 'Регистрация',
                dataIndex: 'registrationAt',
                key: 'registrationAt',
                render: registrationAt => registrationAt ? new Date(registrationAt).toLocaleDateString('ru') : null,
            },
            {
                title: 'Первый вход',
                dataIndex: 'firstLogin',
                key: 'firstLogin',
                render: firstLogin => firstLogin ? new Date(firstLogin).toLocaleDateString('ru') : null,
            },
            {
                title: 'Последний вход',
                dataIndex: 'lastLogin',
                key: 'lastLogin',
                render: lastLogin => lastLogin ? new Date(lastLogin).toLocaleDateString('ru') : null,
            },
            {
                title: 'Окончания доступа',
                dataIndex: 'expiredAt',
                key: 'expiredAt',
                render: expiredAt => expiredAt ? new Date(expiredAt).toLocaleDateString('ru') : 'бессрочно',
            },
            {
                title: 'Дата завершения программы',
                dataIndex: 'programFinishedAt',
                key: 'programFinishedAt',
                render: programFinishedAt => programFinishedAt ? new Date(programFinishedAt).toLocaleDateString('ru') : null
            },
            {
                title: 'Трудоустроился',
                dataIndex: 'employedAt',
                key: 'employedAt',
                render: (employedAt, item) => employedAt ? (
                    <span>
                        {new Date(employedAt).toLocaleDateString('ru')}
                        <Popover title='Компания трудоустройства' content={item.employedIn} placement='topLeft' trigger='click'>
                            <InfoCircleOutlined />
                        </Popover>
                    </span>
                ) : null
            },
            {
                key: 'actions',
                align: 'right',
                render: item => !item.firstLogin && item.email && <Tooltip title='Отправить письмо регистрации повторно' placement='left'>
                    <Button
                        icon={<SendOutlined />}
                        onClick={() => this.props.putResendEmailParticipant.dispatch(item.id)} />
                </Tooltip>
            }
        ];

        return filter(({ hide }) => !hide, columns);
    }

    onChangeSelected = selectedRowKeys => this.props.setSelectedRowKeys(selectedRowKeys);

    setConsultant = () => {
        this.props.putSetConsultant.dispatch({
            participants: this.props.selectedRowKeys,
            consultant: this.props.user.id
        });
    }

    renderToolbarButtons = () => {
        const { openParticipantModal, openParticipantImportModal, location, openConsultantSelectModal } = this.props;
        const isAdmin = includes(ADMIN, this.props.user.roles) || includes(SUPER_ADMIN, this.props.user.roles);
        const menu = <Menu>
            <Menu.Item key='import' onClick={() => openParticipantImportModal()}>Импортировать</Menu.Item>
        </Menu>;

        return (
            <Fragment>
                { isAdmin &&
                    <Button
                        className='toolbar-button'
                        icon={<EditOutlined />}
                        disabled={!this.props.selectedRowKeys.length}
                        onClick={() => this.props.user.superAdmin ? openConsultantSelectModal({
                            participants: this.props.selectedRowKeys,
                            onSuccess: () => this.onChangeSelected([])
                        }) : this.setConsultant()}>
                        Консультант
                    </Button>
                }
                <Dropdown.Button
                    type='primary'
                    onClick={() => openParticipantModal({ company: getUrlParams(location).company || this.props.company })}
                    overlay={menu}
                >
                    <PlusOutlined /> Добавить
                </Dropdown.Button>
            </Fragment>
        );
    }

    render() {
        return (
            <TableList
                action={getParticipants}
                staticFilter={{ company: this.props.company }}
                columns={this.getColumns()}
                buttons={this.renderToolbarButtons()}
                refreshActions={[POST_PARTICIPANT, POST_PARTICIPANT_FILE, PUT_SET_CONSULTANT]}
                filterForm={<ParticipantFilter company={this.props.company} hideCompany={!!this.props.company} />}
                rowSelection={{
                    type: 'checkbox',
                    onChange: this.onChangeSelected,
                    selectedRowKeys: this.props.selectedRowKeys
                }}
            />
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default withState('selectedRowKeys', 'setSelectedRowKeys', [])(withRouter(asyncConnect({
    putResendEmailParticipant: putResendEmailParticipant
        .withSuccessHandler(() => message.success('Письмо регистрации успешно отправлено'))
        .withOptions({ resetOnUnmount: true }),
    putSetConsultant: putSetConsultant
        .withParams(() => ({ type: 'list' }))
        .withSuccessHandler(({ setSelectedRowKeys }) => {
            setSelectedRowKeys([]);
            message.success('Консультант успешно назначен');
        })
        .withErrorHandler(() => message.error('Не удалось назначить консультанта'))
        .withOptions({ resetOnUnmount: true })
}, stateToProps, { openParticipantModal, openParticipantImportModal, openConsultantSelectModal })(Participants)));
