import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Button } from 'antd';
import { prop, pathOr, assocPath } from 'ramda';
import * as yup from 'yup';

import { withFormWrapper, Textarea } from '@meconsultant/common';
import Input from './formComponents/Input';
import ImageLoader from './formComponents/ImageLoader';
import InputNumber from './formComponents/InputNumber';
import Select from './formComponents/Select';
import Switch from './formComponents/Switch';
import { withAsyncActions } from 'react-async-client/lib/withAsyncActions';
import { getTopics } from '../../actions/asyncActions';

class StageForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool,
        item: PropTypes.string,
        getTopics: PropTypes.object
    };

    render() {
        const { isSubmitting, item, getTopics } = this.props;

        return <Fragment>
            { !item &&
                <Field
                    name='id'
                    component={Input}
                    label='ID' />
            }
            <Field
                name='title'
                component={Input}
                label='Название этапа' />
            <Field
                name='order'
                component={InputNumber}
                label='Порядковый номер' />
            <Field
                name='published'
                component={Switch}
                label='Опубликован' />
            <Field
                name='topics'
                component={Select}
                label='Категории этапа'
                options={pathOr([], ['data', 'items'], getTopics)}
                namePath='title'
                mode='multiple' />
            <Field
                name='subtitle'
                component={Input}
                label='Подзаголовок' />
            <Field
                name='lead'
                component={Textarea}
                label='Тизер' />
            <Field
                name='time'
                component={InputNumber}
                label='Время (минуты)' />
            <Field
                name='image'
                component={ImageLoader}
                label='Изображение' />
            <Button
                disabled={isSubmitting}
                type='primary'
                htmlType='submit'>
                Сохранить
            </Button>
        </Fragment>;
    }
}

const validationSchema = props => yup.object().shape({
    id: props.item ? yup.string() : yup.string().required(),
    title: yup.string().required(),
    order: yup.number().required()
});

export default withAsyncActions({
    getTopics: getTopics
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(
    withFormWrapper(StageForm, {
        mapPropsToValues: props => {
            const item = prop('item', props);

            return assocPath(['published'], !prop('hidden', item), item);
        },
        mapBeforeSubmit: values => assocPath(['hidden'], !prop('published', values), values),
        validationSchema
    })
);
