import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { path } from 'ramda';

import { putStage, postStage } from '../../actions/asyncActions';
import StageForm from '../forms/StageForm';

class StageModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        params: PropTypes.object,
        putStage: PropTypes.object,
        postStage: PropTypes.object
    };

    render() {
        const { modal, putStage, postStage, params } = this.props;
        const data = path(['data'], params);

        return <Modal
            {...modal}
            title={data ? 'Редактировать этап' : 'Добавить этап'}
            footer={null}>
            <StageForm
                item={data}
                formAction={data ? putStage : postStage} />
        </Modal>;
    }
}

export default withAsyncActions({
    postStage: postStage
        .withSuccessHandler(({ close }) => {
            message.success('Этап был успешно добавлен');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putStage: putStage
        .withSuccessHandler(({ close }) => {
            message.success('Этап был успешно отредактирован');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(StageModal);
