import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Button } from 'antd';
import * as yup from 'yup';
import { pick } from 'ramda';
import { UnlockOutlined } from '@ant-design/icons';

import { withFormWrapper } from '@meconsultant/common';
import Input from './formComponents/Input';

class ResetForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting } = this.props;

        return <Fragment>
            <Field
                name='password'
                component={Input}
                placeholder='Пароль'
                htmlType='password'
                icon={<UnlockOutlined />} />
            <Field
                name='passwordRepeat'
                component={Input}
                htmlType='password'
                placeholder='Пароль повторно'
                icon={<UnlockOutlined />}
                extra='Пароль должен содержать более 6 символов, строчные и заглавные буквы латинского алфавита и цифры'
            />
            <Button
                type='primary'
                htmlType='submit'
                size='large'
                disabled={isSubmitting}>
                Отправить
            </Button>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    password: yup.string().match('passwordRepeat').min(6).required(),
    passwordRepeat: yup.string().match('password').min(6).required(),
});

export default withFormWrapper(ResetForm, {
    mapPropsToValues: pick(['id']),
    validationSchema
});
