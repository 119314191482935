import { withFormWrapper } from '@meconsultant/common';
import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { Button } from 'antd';

import { getAdmins } from '../../actions/asyncActions';
import SearchSelect from './formComponents/SearchSelect';

class ConsultantSelectForm extends Component {
    render() {
        const { isSubmitting, close } = this.props;

        return <Fragment>
            <Field
                name='consultant'
                component={SearchSelect}
                label='Консультант'
                action={getAdmins}
                placeholder='Выберите консультанта'
                getNamePath={item => `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`}  />
            <div style={{ marginTop: 30 }}>
                <Button
                    type='primary'
                    htmlType='submit'
                    disabled={isSubmitting}>
                    СОХРАНИТЬ
                </Button>
                <Button
                    onClick={close}
                    style={{ marginLeft: 10 }}>
                    ОТМЕНА
                </Button>
            </div>
        </Fragment>
    }
}

const validationSchema = yup.object().shape({
    consultant: yup.string().nullable().required()
});

export default withFormWrapper(ConsultantSelectForm, {
    validationSchema,
    mapPropsToValues: ({ item }) => item
});
