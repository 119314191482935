import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { Row, Col } from 'antd';

import withFilterForm from '../hocs/withFilterForm';
import Select from '../forms/formComponents/Select';

class CompanyWebinarsFilter extends Component {
    render() {
        return (
            <Row gutter={16} style={{ textAlign: 'left' }}>
                <Col span={12}>
                    <Field
                        name='themes'
                        component={Select}
                        placeholder='Темы вебинаров'
                        namePath='title'
                        mode='multiple'
                        options={this.props.themes}
                        allowClear
                    />
                </Col>
            </Row>
        );
    }
}

export default withFilterForm(CompanyWebinarsFilter);
