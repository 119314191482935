import React, { Component } from 'react';

import TableList from './TableList';
import { getFeedbackStatistics } from '../actions/asyncActions';
import FeedbackFilter from './filters/FeedbackFilter';

export default class FeedbackStatistics extends Component {
    getColumns = () => {
        return [
            {
                title: 'Материал',
                key: 'material',
                dataIndex: ['_embedded', 'material', 'title'],
            },
            {
                title: 'Оценка полезности',
                key: 'usefulness',
                dataIndex: 'usefulness',
                align: 'center'
            },
            {
                title: 'Оценка удобности',
                key: 'convenience',
                dataIndex: 'convenience',
                align: 'center'
            },
            {
                title: 'Количество отзывов',
                key: 'countReviews',
                dataIndex: 'countReviews',
                align: 'center'
            }
        ];
    }

    render() {
        return <TableList
            action={getFeedbackStatistics}
            columns={this.getColumns()}
            filterForm={<FeedbackFilter />}
            rowKey='material' />
    }
}

