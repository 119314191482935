"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCoverLetterTextHtml = exports.getCoverLetterText = void 0;

var _ramda = require("ramda");

var _coverLetter = require("../constants/coverLetter");

var getCoverLetterText = function getCoverLetterText(values) {
  return _coverLetter.COVER_LETTER_FIELDS.reduce(function (res, cur, index) {
    var value = (0, _ramda.path)(['content', cur.name], values);
    return value ? res + "".concat(!!index ? '\n\n' : '').concat((0, _ramda.path)(['content', cur.name], values)) : res;
  }, '');
};

exports.getCoverLetterText = getCoverLetterText;

var getCoverLetterTextHtml = function getCoverLetterTextHtml(values) {
  return _coverLetter.COVER_LETTER_FIELDS.reduce(function (res, cur, index) {
    var value = (0, _ramda.path)(['content', cur.name], values);
    return value ? res + "<p>".concat((0, _ramda.path)(['content', cur.name], values), "</p>") : res;
  }, '');
};

exports.getCoverLetterTextHtml = getCoverLetterTextHtml;