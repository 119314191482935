import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { Col, Row } from 'antd';

import withFilterForm from '../hocs/withFilterForm';
import SearchInput from '../forms/formComponents/SearchInput';
import SearchSelect from '../forms/formComponents/SearchSelect';
import { ADMIN_ENABLED, ADMIN_CLIENT_ENABLED } from '../../constants/dictionaries';
import { getCompanies } from '../../actions/asyncActions';

class AdminClientFilter extends Component {
    render() {
        return (
            <Row gutter={16}>
                <Col span={6}>
                    <Field
                        name='name'
                        component={SearchInput}
                        placeholder='Клиент'
                    />
                </Col>
                <Col span={6}>
                    <Field
                        name='company'
                        component={SearchSelect}
                        action={getCompanies}
                        placeholder='Компания'
                        namePath='name'
                        allowClear
                    />
                </Col>
                <Col span={6}>
                    <Field
                        name='clientAdmin'
                        component={SearchSelect}
                        options={ADMIN_CLIENT_ENABLED} />
                </Col>
                <Col span={6}>
                    <Field
                        name='enabled'
                        component={SearchSelect}
                        options={ADMIN_ENABLED} />
                </Col>
            </Row>
        );
    }
}

export default withFilterForm(AdminClientFilter);
