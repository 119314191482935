import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { withFormWrapper, Textarea } from '@meconsultant/common';
import { Button, message } from 'antd';
import * as yup from 'yup';
import { withAsyncActions } from 'react-async-client';
import { putParticipantComment } from '../../actions/asyncActions';

class ParticipantCommentForm extends Component {
    delete = () => {
        this.props.putParticipantComment.dispatch({ ...this.props.item, comment: null });
    }

    render() {
        const { isSubmitting, close } = this.props;

        return <Fragment>
            <Field
                name='comment'
                component={Textarea}
                autosize={{ minRows: 8 }}
                placeholder='Введите комментарий...' />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <Button
                        type='primary'
                        htmlType='submit'
                        disabled={isSubmitting}>
                        СОХРАНИТЬ
                    </Button>
                    <Button style={{ marginLeft: 10 }} onClick={close}>
                        ОТМЕНА
                    </Button>
                </div>
                <Button danger onClick={this.delete} disabled={!this.props.item.comment}>
                    УДАЛИТЬ
                </Button>
            </div>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    comment: yup.string().nullable().required()
});

export default withAsyncActions({
    putParticipantComment: putParticipantComment
        .withParams(() => ({ type: 'delete' }))
        .withSuccessHandler(({ close, onSuccess }) => {
            message.success('Комментарий успешно удален');
            onSuccess(putParticipantComment.data.comment);
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(withFormWrapper(ParticipantCommentForm, {
    mapPropsToValues: ({ item }) => item,
    validationSchema
}));
