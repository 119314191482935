import { takeEvery, put } from 'redux-saga/effects';
import { message } from 'antd';
import { path, pathOr } from 'ramda';

import { REQUEST_ERROR } from '../constants/regexp';
import { logout } from '../actions/appActions';

export default function* error() {
    yield takeEvery((action) => REQUEST_ERROR.test(action.type), function* (action) {
        if (action.payload.status === 401) {
            yield put(logout());
        } else if (action.payload.status !== 422) {
            message.error(path(['payload', 'data', 'errors', 0, 'message'], action) || pathOr('Ошибка соединения с сервером', ['payload', 'data', 'message'], action));
        }
    });
}
