"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OnSubmitContext = void 0;

var _react = require("react");

var OnSubmitContext = (0, _react.createContext)();
exports.OnSubmitContext = OnSubmitContext;