import React from 'react';
import {
    GlobalOutlined,
    UsergroupAddOutlined,
    TeamOutlined,
    BarsOutlined,
    LineChartOutlined,
    ReadOutlined,
    QuestionCircleOutlined,
    MessageOutlined,
    LikeOutlined,
    SettingOutlined,
    SafetyOutlined,
    VideoCameraOutlined,
    ContainerOutlined,
    CalendarOutlined,
    DashboardOutlined,
    SelectOutlined,
} from '@ant-design/icons';
import { ADMINS, CLIENT_ADMIN, CLIENT_MANAGER, SUPER_ADMIN } from './roles';

export default [
    { key: '/', route: '/dashboard', icon: <DashboardOutlined />, title: 'Главная', roles: [CLIENT_ADMIN, CLIENT_MANAGER] },
    { key: '/', route: '/companies', icon: <GlobalOutlined />, title: 'Компании', roles: ADMINS },
    { key: '/invites', icon: <UsergroupAddOutlined />, title: 'Инвайты', roles: [CLIENT_ADMIN, CLIENT_MANAGER] },
    { key: '/roles', icon: <SafetyOutlined />, title: 'Роли', roles: [CLIENT_ADMIN, CLIENT_MANAGER] },
    { key: '/participants', icon: <TeamOutlined />, title: 'Участники', roles: [CLIENT_ADMIN, CLIENT_MANAGER] },
    { key: '/participation_requests', icon: <TeamOutlined />, title: 'Заявки', roles: [CLIENT_ADMIN, CLIENT_MANAGER] },
    { key: '/stages', icon: <BarsOutlined />, title: 'Курсы', roles: ADMINS },
    { key: '/themes', icon: <ContainerOutlined />, title: 'Темы вебинаров', roles: [...ADMINS, CLIENT_MANAGER] },
    { key: '/test_themes', icon: <ContainerOutlined />, title: 'Темы тестирований', roles: [...ADMINS, CLIENT_MANAGER] },
    { key: '/calendar', icon: <CalendarOutlined />, title: 'Календарь', roles: ADMINS },
    { key: '/webinars', icon: <VideoCameraOutlined />, title: 'Вебинары', roles: [CLIENT_MANAGER, CLIENT_ADMIN] },
    { key: '/statistics', icon: <LineChartOutlined />, title: 'Аналитика', roles: ADMINS },
    // { key: '/topics', icon: <ApartmentOutlined />, title: 'Категории этапов' },
    { key: '/news', icon: <ReadOutlined />, title: 'Новости', roles: ADMINS },
    { key: '/faq', icon: <QuestionCircleOutlined />, title: 'FAQ', roles: ADMINS },
    { key: '/mentions', icon: <MessageOutlined />, title: 'Отзывы', roles: ADMINS },
    { key: '/feedbacks', icon: <LikeOutlined />, title: 'Обратная связь', roles: ADMINS },
    { key: '/links', icon: <SelectOutlined />, title: 'Рассылки', roles: [SUPER_ADMIN] },
    { key: '/admins', icon: <UsergroupAddOutlined />, title: 'Администраторы', hide: ({ superAdmin }) => !superAdmin, roles: [SUPER_ADMIN] },
    { key: '/settings', icon: <SettingOutlined />, title: 'Настройки', roles: ADMINS }
];
