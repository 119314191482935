import React, { Component } from 'react';
import { Rate } from 'antd';

import { withFieldWrapper } from '@meconsultant/common';

class Starts extends Component {
    render() {
        const { max, input: { value }, onChange } = this.props;

        return <Rate
            count={max}
            value={value || 0}
            onChange={onChange} />;
    }
}

export default withFieldWrapper(Starts);
