import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import AdminForm from '../forms/AdminForm';
import { postAdmin, putAdmin } from '../../actions/asyncActions';

class AdminModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        postAdmin: PropTypes.object,
        putAdmin: PropTypes.object,
        params: PropTypes.object
    };

    render() {
        const { modal, postAdmin, params, putAdmin } = this.props;

        return <Modal
            {...modal}
            title={params.id ? `Редактировать ${params.superAdmin ? 'администратора' : 'консультанта'}` : `Добавить ${params.superAdmin ? 'администратора' : 'консультанта'}`}
            footer={null}>
            <AdminForm
                item={params}
                formAction={params.id ? putAdmin : postAdmin} />
        </Modal>
    }
}

export default withAsyncActions({
    postAdmin: postAdmin
        .withSuccessHandler(({ close, params }) => {
            message.success(`${params.superAdmin ? 'Администратор' : 'Консультант'} был успешно добавлен`);
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putAdmin: putAdmin
        .withSuccessHandler(({ close, params }) => {
            message.success(`${params.superAdmin ? 'Администратор' : 'Консультант'} был успешно отредактирован`);
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(AdminModal);
