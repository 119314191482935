import React, { Component, Fragment } from 'react';
import { withFormWrapper } from '@meconsultant/common';
import { Button } from 'antd';
import { Field } from 'react-final-form';

import Editor from './formComponents/Editor';

class SettingsForm extends Component {
    render() {
        const { isSubmitting } = this.props;

        return <Fragment>
            <Field
                name='settings.welcomeText'
                component={Editor}
                label='Приветственный текст' />
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
        </Fragment>;
    }
}

export default withFormWrapper(SettingsForm, {
    mapPropsToValues: ({ data }) => ({
        ...(data || {}),
        id: 'main'
    })
});
