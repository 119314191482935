import React, { Component } from 'react';
import styled from 'styled-components';
import { Spin, message } from 'antd';
import { withAsyncActions, toSuccess } from 'react-async-client';
import { takeEvery } from 'redux-saga/effects';

import { getSettingsPath, putSettingsPath } from '../../actions/asyncActions';
import { PUT_SETTINGS_PATH } from '../../constants/actionTypes';
import SettingsForm from '../forms/SettingsForm';

export const Wrapper = styled.div`
    padding: 0 15px 15px;
`;

export const Center = styled.div`
    text-align: center;
`;

class MainSettings extends Component {
    render() {
        const { putSettingsPath, getSettingsPath: { meta, data }} = this.props;

        return <Wrapper>
            { meta.pending && !meta.lastSucceedAt ?
                <Center>
                    <Spin />
                </Center> :
                <SettingsForm
                    formAction={putSettingsPath}
                    data={data} />
            }
        </Wrapper>;
    }
}

export default withAsyncActions({
    getSettingsPath: getSettingsPath
        .withPayload(() => 'main')
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_SETTINGS_PATH)], () => {
                getProps().getSettingsPath.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    putSettingsPath: putSettingsPath
        .withSuccessHandler(() => message.success('Настройки успешно сохранены'))
        .withOptions({ resetOnUnmount: true })
})(MainSettings);
