import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { SagaProvider } from 'react-async-client';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import { PersonalAgreement } from '@meconsultant/common';

import { history, sagaMiddleware } from '../store/middlewares';
import SecureLayout from './layout/SecureLayout';
import { pushRollbarError } from '../utils/rollbar';
import { Route, Switch } from 'react-router';

export default class App extends Component {
    static propTypes = {
        store: PropTypes.object
    };

    state = {
        error: false
    };

    componentDidCatch(error) {
        pushRollbarError(error);
        this.setState({ error: true });
    }

    render() {
        const { store } = this.props;

        return this.state.error ?
            <div>Не удалось запустить приложение</div> :
            <SagaProvider sagaMiddleware={sagaMiddleware}>
                <ConfigProvider locale={ruRU}>
                    <Provider store={store}>
                        <ConnectedRouter history={history}>
                            <Switch>
                                <Route path='/offer' component={PersonalAgreement} />
                                <Route>
                                    <SecureLayout />
                                </Route>
                            </Switch>
                        </ConnectedRouter>
                    </Provider>
                </ConfigProvider>
            </SagaProvider>;
    }
}
