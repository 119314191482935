"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PRESENTATION_DATA = void 0;
var PRESENTATION_DATA = [{
  field: 'about',
  label: 'Коротко о себе',
  placeholder: 'Расскажите, кем Вы явлетесь и в чём Ваша уникальность, как специалиста.'
}, {
  field: 'experience',
  label: 'Про опыт работы',
  placeholder: 'Обозначьте каким опытом Вы обладаете, сколько лет работаете в отрасли, и в чём ценность Вашей экспертизы для работодателя.'
}, {
  field: 'lastWork',
  label: 'Про последнее место работы',
  placeholder: 'Расскажите о компании, в которой работали, обозначьте период трудовой деятельности в штате данного работодателя и назовите должность, которую занимали.'
}, {
  field: 'tasks',
  label: 'Про ключевые задачи и зону ответственности',
  placeholder: 'Расскажите о тех обязанностях, которые составляли основу Вашей трудовой деятельности и занимали большую часть рабочего времени, а также выделите те из них, которые соотносятся с обязанностями вакансии, на которую Вы претендуете.'
}, {
  field: 'projects',
  label: 'Про проекты',
  placeholder: 'Расскажите об успешно реализованых проектах и Вашем вкладе в развитие бизнеса, если работа подразумевала проектную деятельность.'
}, {
  field: 'achivements',
  label: 'Про достижения',
  placeholder: 'Опишите ключевые результаты, которых Вам удалось достичь, подчеркните его ценность для компании.'
}, {
  field: 'competencies',
  label: 'Про сильные стороны и компетенции',
  placeholder: 'Сделайте акцент на тех компетенциях, которые развиты у Вас в большей степени, и на которые Вы сможете опереться при выполнении задач должности, на которую претендуете.'
}, {
  field: 'motivation',
  label: 'Про карьерные цели и мотивацию',
  placeholder: 'Расскажите о том чего Вы планируете достичь в обозримом будущем, как видите свою карьеру, что Вас мотивирует профессионально развиваться.'
}];
exports.PRESENTATION_DATA = PRESENTATION_DATA;