import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { withRouter } from 'react-router';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withState } from 'recompose';
import { Editor } from '@tinymce/tinymce-react';

import { getLinkUrl } from '../../utils/urls';

const getDefaultText = item => `<em>Уважаемые партнеры!</em><br /><br /> `
    + `Хотим безвозмездно поделиться с Вами информацией об участниках наших программ по аутплейсменту и карьерному консультированию.<br /> `
    + `В случае заинтересованности одним или несколькими участниками, просим сообщить об этом нам, заполнив форму по `
    + `<a href="${getLinkUrl(item.id)}">ссылке</a>. `
    + `Код для просмотра - <b>${item.authCode}</b>.<br /> `
    + 'Мы будем рады передать Вам контакты этих участников в случае заинтересованности с их стороны.<br /><br /> '
    + '<em>С уважением,</em><br />'
    + `<em>команда АНКОР Консалтинг</em>`;

class LinkPreviewModal extends Component {
    onCopy = (_, result) => {
        result ? message.success('Текст успешно скопирован') : message.error('Не удалось скопировать текст');
    };

    static propTypes = {
        modal: PropTypes.object,
        params: PropTypes.object,
        postEmployeetLink:  PropTypes.object,
        putEmployeeLink: PropTypes.object,
        deleteEmployeeLink: PropTypes.object
    };

    render() {
        const { modal, text, setText } = this.props;

        return <Modal
            {...modal}
            title={'Текст ссылки'}
            footer={null}>
            <div>
                <p>
                    <Editor
                        value={text}
                        onEditorChange={value => setText(value || '')}
                        init={{
                            plugins: 'link lists autoresize',
                            language: 'ru',
                            menubar: false,
                            forced_root_block: false,
                            language_url: '/translations/ru.json',
                            toolbar: 'undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | forecolor backcolor | bullist numlist outdent indent | link | removeformat',
                        }}
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    />
                </p>
                <p style={{ border: '1px solid #eee', padding: 5 }} dangerouslySetInnerHTML={{ __html: text }} />
                <div>
                    <CopyToClipboard text={text} onCopy={this.onCopy} options={{ format: 'text/html' }}>
                        <span style={{ cursor: 'pointer' }}><CopyOutlined style={{ color: '#C2CFE0', marginLeft: 5 }} />Скопировать текст</span>
                    </CopyToClipboard>
                </div>
            </div>
        </Modal>;
    }
}

export default withRouter(
    withState('text', 'setText', ({ params }) => params ? getDefaultText(params) : '')(LinkPreviewModal)
);
