import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message, Spin } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { putCompanySettings, getCompanySettings } from '../../actions/asyncActions';
import CompanySettingsForm from '../forms/CompanySettingsForm';

class CompanySettingsModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        params: PropTypes.object,
        postCompany:  PropTypes.object,
        putCompany: PropTypes.object,
        deleteCompany: PropTypes.object
    };

    render() {
        const { modal, params, putCompanySettings, getCompanySettings } = this.props;

        return <Modal
            {...modal}
            title='Редактировать настройки лендинга компании'
            footer={null}>
            { getCompanySettings.meta.pending ?
                <div style={{ textAlign: 'center' }}><Spin /></div> :
                <CompanySettingsForm
                    formAction={putCompanySettings}
                    item={{
                        ...params,
                        ...getCompanySettings.data
                    }} />
            }
        </Modal>;
    }
}

export default withAsyncActions({
    getCompanySettings: getCompanySettings
        .withPayload(({ params }) => params)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    putCompanySettings: putCompanySettings
        .withSuccessHandler(({ close }) => {
            message.success('Настройки лендинга компании были успешно отредактированы');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(CompanySettingsModal);
