import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { Field } from 'react-final-form';
import { Button } from 'antd';

import { withFormWrapper } from '@meconsultant/common';
import Select from './formComponents/Select';
import { CONSULTATION_TITLES, CONSULTATION_PROMOTION_TITLES } from '../../constants/dictionaries';

class ConsultationTitleForm extends Component {
    static defaultProps = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting, isPromo } = this.props;

        return <Fragment>
            <Field
                name='theme'
                component={Select}
                label='Тема консультации'
                options={isPromo ? CONSULTATION_PROMOTION_TITLES : CONSULTATION_TITLES} />
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
        </Fragment>;
    }
}

export default withFormWrapper(ConsultationTitleForm, {
    mapBeforeSubmit: (values, { item }) => ({
        id: item.id,
        title: `${values.theme}`
            .replace('#name', item.participantName)
            .replace('#company', path(['_embedded', 'participant', '_embedded', 'company', 'name'], item) || '')
    })
});
