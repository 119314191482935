import { Button, DatePicker, Table } from 'antd';
import { filter, find, flatten, omit, path, pathOr, propEq } from 'ramda';
import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { withRouter } from 'react-router';
import moment from 'moment';
import qs from 'qs';
import { FileExcelOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { COMPANY_BILLING_BY_PARTICIPANT } from '../constants/dictionaries';
import { getCompanyBilling } from '../actions/asyncActions';
import { extendSearchPath, getFilters } from '../utils/urls';
import { getToken } from '../utils/token';

const AMOUNT_TITLE = {
    events: 'шт',
    testing: 'шт',
    consultations: 'шт',
    promotion: 'месяц',
    chat: 'месяц',
    content: 'шт',
};

const COUNT_PROP = {
    events: 'countEvents',
    testing: 'countEvents',
    consultations: 'countConsultations',
    content: 'countParticipants',
    promotion: 'countPromotions',
    chat: 'countChats',
};

const TITLES = {
    events: 'Вебинары и семинары',
    testing: 'Тестирования',
    consultations: 'Консультации',
    content: 'Курсы',
    promotion: 'Профессиональная поддержка',
    chat: 'Чат',
};

const ORDER = ['consultations', 'events', 'promotion', 'chat', 'content', 'testing'];

const getCost = cost => `${(cost || 0).toLocaleString('ru-RU')} ₽`;

const Footer = styled(Table.Summary.Row)`
    td {
        background: #2B3D4F;
        color: #fff;
        border-color: #fff;
    }
    .align-center {
        text-align: center;
        font-weight: bold;
    }
`;

class Billing extends Component {
    getColumns = () => {
        const data = this.getData();

        return [
            {
                title: 'Сервис',
                key: 'blockTitle',
                dataIndex: 'blockTitle',
                render: (title, record) => {
                    const items = filter(propEq('type', record.type), data);

                    return {
                        children: <strong>{ title }</strong>,
                        props: {
                            rowSpan: items[0].id === record.id ? items.length : 0
                        }
                    }
                }
            },
            {
                title: 'Тип программы или Тема',
                key: 'name',
                render: item => {
                    const name = {
                        events: item.title,
                        consultations: item.name,
                        content: 'разовая оплата (пакет)',
                        promotion: item.name,
                        chat: item.name,
                    };

                    return name[item.type];
                }
            },
            {
                title: 'ед измерения',
                align: 'center',
                key: 'unit',
                render: item => AMOUNT_TITLE[item.type]
            },
            {
                title: 'кол-во единиц',
                align: 'center',
                key: 'amount',
                render: item => item[COUNT_PROP[item.type]] || 0
            },
            {
                title: 'Стоимость за ед. руб без НДС',
                align: 'center',
                key: 'unitPrice',
                dataIndex: 'unitPrice',
                render: getCost
            },
            {
                title: 'Стоимость всего руб. без НДС',
                align: 'center',
                key: 'totalPrice',
                dataIndex: 'totalPrice',
                render: getCost
            },
            {
                title: 'Стоимость всего руб. с НДС',
                align: 'center',
                key: 'totalPriceWithTax',
                dataIndex: 'totalPriceWithTax',
                render: getCost
            },
            {
                title: 'Стоимость за сервис руб. с НДС',
                align: 'center',
                key: 'moduleTotalWithTax',
                dataIndex: 'moduleTotalWithTax',
                render: (moduleTotalWithTax, record) => {
                    const items = filter(propEq('type', record.type), data);

                    return {
                        children: <strong>{ getCost(moduleTotalWithTax) }</strong>,
                        props: {
                            rowSpan: items[0].id === record.id ? items.length : 0
                        }
                    }
                }
            }
        ];
    }

    getParticipantColumns = () => {
        return [
            {
                title: 'Роль',
                key: 'name',
                dataIndex: 'name',
                render: name => {
                    return <strong>{ name }</strong>
                }
            },
            {
                title: 'ед измерения',
                align: 'center',
                key: 'measure',
                dataIndex: 'measure',
            },
            {
                title: 'кол-во единиц',
                align: 'center',
                key: 'count',
                dataIndex: 'count',
            },
            {
                title: 'Стоимость за ед. руб без НДС',
                align: 'center',
                key: 'unitPrice',
                dataIndex: 'unitPrice',
                render: getCost
            },
            {
                title: 'Стоимость всего руб. без НДС',
                align: 'center',
                key: 'totalPrice',
                dataIndex: 'totalPrice',
                render: getCost
            },
            {
                title: 'Стоимость всего руб. с НДС',
                align: 'center',
                key: 'totalPriceWithTax',
                dataIndex: 'totalPriceWithTax',
                render: getCost
            },
        ];
    }

    getData = () => {
        const { company, getCompanyBilling: { data }} = this.props;
        const dataProp = {
            events: 'themes',
            consultations: 'roles',
            promotion: 'roles',
            chat: 'roles'
        };

        return flatten(filter(item => !!item, filter(item => !!item, ORDER.map(key => find(propEq('id', key), company.modules || []))).map(item => {
            if (data[item.id]) {
                const i = data[item.id];
                const items = i[dataProp[item.id]] ? i[dataProp[item.id]].map(x => omit([dataProp[item.id]], { ...x, ...i,  blockTitle: TITLES[item.id], type: item.id })) : [{ ...i, blockTitle: TITLES[item.id], type: item.id }];

                return items;
            }

            return null;
        })));
    }

    getParticipantData = () => {
        const { getCompanyBilling: { data } } = this.props;
        return pathOr([], ['participants'], data);
    }

    onChangeMonth = date => {
        this.props.history.replace(extendSearchPath(this.props.location, { filter: date ? JSON.stringify({ date: date.toDate() }) : null }));
    }

    getTotlalModuleCost = () => {
        return getCost(ORDER.reduce((res, cur) => res + pathOr(0, [cur, 'moduleTotalWithTax'], this.props.getCompanyBilling.data), 0));
    }

    getParticipantTotlalModuleCost = (data) => {
        return getCost(data.reduce((res, item) => res + (item.unitPrice || 0), 0));
    }

    getDownloadLink = () => {
        const { location } = this.props;
        const searchPath = qs.stringify({
            q: JSON.stringify({
                minDate: moment((getFilters(location) || {}).date).startOf('month'),
                maxDate: moment((getFilters(location) || {}).date).endOf('month')
            }),
            token: getToken()
        }, { addQueryPrefix: true, strictNullHandling: true });

        return `/api/admin/companies/export/${this.props.id}/billing.xlsx${searchPath}`;
    }

    render() {
        const { getCompanyBilling: { meta, data }, location, company } = this.props;

        return <div>
            <div style={{ padding: 15, paddingTop: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <DatePicker
                    picker='month'
                    value={moment((getFilters(location) || {}).date)}
                    onChange={this.onChangeMonth}
                    format='MMMM'
                    allowClear={false} />
                <Button
                    icon={<FileExcelOutlined />}
                    href={this.getDownloadLink()}
                    target='_blank'
                    rel='noopener noreferrer'
                    download>
                    Скачать
                </Button>
            </div>
            <Table
                dataSource={path(['billingType'], company) === COMPANY_BILLING_BY_PARTICIPANT ? this.getParticipantData() : this.getData()}
                columns={path(['billingType'], company) === COMPANY_BILLING_BY_PARTICIPANT ? this.getParticipantColumns() : this.getColumns()}
                bordered
                rowKey='id'
                loading={meta.pending}
                pagination={false}
                summary={() => {
                    return path(['billingType'], company) === COMPANY_BILLING_BY_PARTICIPANT ?
                        <Footer>
                            <Table.Summary.Cell index={0}><strong>Итого</strong></Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2} className='align-center'>{ getCost(pathOr(0, ['total', 'count'], data)) }</Table.Summary.Cell>
                            <Table.Summary.Cell index={5} className='align-center'>
                                { this.getParticipantTotlalModuleCost(this.getParticipantData()) }
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3} className='align-center'>{ getCost(pathOr(0, ['total', 'total'], data)) }</Table.Summary.Cell>
                            <Table.Summary.Cell index={4} className='align-center'>{ getCost(pathOr(0, ['total', 'totalWithTax'], data)) }</Table.Summary.Cell>
                        </Footer>
                        : <Footer>
                            <Table.Summary.Cell index={0}><strong>Итого</strong></Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2}></Table.Summary.Cell>
                            <Table.Summary.Cell index={3}></Table.Summary.Cell>
                            <Table.Summary.Cell index={4}></Table.Summary.Cell>
                            <Table.Summary.Cell index={5} className='align-center'>{ getCost(pathOr(0, ['total', 'total'], data)) }</Table.Summary.Cell>
                            <Table.Summary.Cell index={6} className='align-center'>{ getCost(pathOr(0, ['total', 'totalWithTax'], data)) }</Table.Summary.Cell>
                            <Table.Summary.Cell index={7} className='align-center'>{ this.getTotlalModuleCost() }</Table.Summary.Cell>
                        </Footer>
                }} />
        </div>;
    }
}

export default withRouter(withAsyncActions({
    getCompanyBilling: getCompanyBilling
        .withPayload(({ id, location }) => ({
            id,
            params: {
                q: {
                    minDate: moment((getFilters(location) || {}).date).startOf('month'),
                    maxDate: moment((getFilters(location) || {}).date).endOf('month')
                }
            }
        }))
        .withOptions({ dispatchOnMount: true, dispatchOnUpdate: true, resetOnUnmount: true })
})(Billing));
