import React, { Component } from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { any, filter, find, includes } from 'ramda';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { isMobile } from '../../../utils/screen';
import MENU_ITEMS from '../../../constants/menu';
import { getUser } from '../../../actions/asyncActions';

const filterItems = (items, { user }) => (
    filter(
        item => (!item.roles || any(role => includes(role, user.roles || []), item.roles)),
        items
    )
);

class Sidebar extends Component {
    state = {
        collapsed: false
    };

    onCollapse = () => this.setState(prev => ({ collapsed: !prev.collapsed }));

    onClickMenuItem = ({ key }) => {
        this.props.history.push(key);

        if (isMobile()) {
            this.setState({ collapsed: true });
        }
    }

    getSelectedKey() {
        const selected = find(item =>
            (item.key === '/' && item.key === this.props.location.pathname) ||
            matchPath(this.props.location.pathname, {
                path: item.route || item.key
            }), MENU_ITEMS
        );

        return selected ? [selected.key] : null;
    }

    render() {
        return <Layout.Sider
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}>
            <Menu
                theme='dark'
                selectable={false}
                selectedKeys={this.getSelectedKey()}
                onClick={this.onClickMenuItem}>
                { filterItems(MENU_ITEMS, this.props).map(item =>
                    (!item.hide || (item.hide && !item.hide(this.props))) &&
                        <Menu.Item key={item.key}>
                            <Link to={item.key}>{ item.icon }<span>{ item.title }</span></Link>
                        </Menu.Item>
                )}
            </Menu>
        </Layout.Sider>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(withRouter(Sidebar));
