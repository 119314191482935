import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { Col, Row } from 'antd';

import withFilterForm from '../hocs/withFilterForm';
import SearchSelect from '../forms/formComponents/SearchSelect';
import SearchInput from '../forms/formComponents/SearchInput';
import { getAdmins, getCompanies, getCompanyRoles, getParticipantsStatuses, getUser } from '../../actions/asyncActions';
import { find, includes, keys, prepend, propEq } from 'ramda';
import { ADMIN, SUPER_ADMIN } from '../../constants/roles';
import Select from '../forms/formComponents/Select';
import Cascader from '../forms/formComponents/Cascader';
import { PARTICIPANT_STATUSES } from '../../constants/statuses';
import { asyncConnect } from 'react-async-client';

class ParticipantFilter extends Component {
    getStatuses = () => {
        const { getParticipantsStatuses: { data }} = this.props;

        return keys(data).map(key => ({
            ...find(propEq('id', key), PARTICIPANT_STATUSES),
            value: key,
            label: find(propEq('id', key), PARTICIPANT_STATUSES).title,
            children: data[key].map(id => ({
                ...find(propEq('id', id), PARTICIPANT_STATUSES),
                value: id,
                label: (find(propEq('id', id), PARTICIPANT_STATUSES).secondLevel || find(propEq('id', id), PARTICIPANT_STATUSES).title)
            }))
        }));
    }

    render() {
        const { getParticipantsStatuses: { meta } } = this.props;
        const isAdmin = includes(ADMIN, this.props.user.roles) || includes(SUPER_ADMIN, this.props.user.roles);

        return (
            <Fragment>
                <Row gutter={16}>
                    <Col span={6}>
                        <Field
                            name='name'
                            component={SearchInput}
                            placeholder='Участник'
                        />
                    </Col>
                    <Col span={6}>
                        <Field
                            name='companyRole'
                            component={SearchSelect}
                            action={getCompanyRoles}
                            placeholder='Роль'
                            namePath='name'
                            filter={{ company: this.props.company }}
                            allowClear
                        />
                    </Col>
                    { isAdmin && !this.props.hideCompany &&
                        <Col span={6}>
                            <Field
                                name='company'
                                component={SearchSelect}
                                action={getCompanies}
                                placeholder='Компания'
                                namePath='name'
                                allowClear
                            />
                        </Col>
                    }
                    { isAdmin &&
                        <Col span={6}>
                            { isAdmin && !this.props.user.superAdmin ?
                                <Field
                                    name='consultant'
                                    component={Select}
                                    placeholder='Консультант'
                                    options={[
                                        { id: this.props.user.id, name: 'Мои участники' },
                                        { id: false, name: 'Без консультанта' }
                                    ]}
                                    allowClear /> :
                                <Field
                                    name='consultant'
                                    component={SearchSelect}
                                    action={getAdmins}
                                    placeholder='Консультант'
                                    namePath='name'
                                    getNamePath={item => `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`}
                                    parseItemsAfter={items => prepend({ value: false, label: 'Без консультанта' }, items)}
                                    allowClear
                                />
                            }
                        </Col>
                    }
                    <Col span={6}>
                        <Field
                            component={Cascader}
                            name='lastStatus'
                            placeholder='Статус'
                            options={this.getStatuses()}
                            loading={meta.pending}
                            namePath='title'
                            changeOnSelect={false}
                            disableClear />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    getParticipantsStatuses: getParticipantsStatuses
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, stateToProps)(withFilterForm(ParticipantFilter));
