import React, { Component, Fragment } from 'react';
import { Button } from 'antd';
import { withFormWrapper, FileLoader, DOCUMENT_TYPES } from '@meconsultant/common';
import { Field } from 'react-final-form';
import * as yup from 'yup';

class DocumentForm extends Component {
    render() {
        const { isSubmitting, cancel } = this.props;

        return <Fragment>
            <Field
                name='document'
                component={FileLoader}
                accept={DOCUMENT_TYPES}
                label='Документ' />
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
            { cancel && (
                <Button style={{ marginLeft: '8px' }} disabled={isSubmitting} onClick={cancel}>
                    Отмена
                </Button>
            )}
        </Fragment>
    }
}

const validationSchema = yup.object().shape({
    document: yup.string().nullable().required(),
});

export default withFormWrapper(DocumentForm, {
    mapPropsToValues: ({ item }) => ({
        id: item.id
    }),
    validationSchema
});
