import React, { Component } from 'react';
import { connect } from 'react-redux';

import Stage from '../StageItem';
import { openMaterialViewModal } from '../../actions/modalActions';
import { pathOr } from 'ramda';
import { LoaderWrapper } from '../Stages';
import { Spin } from 'antd';

class StagesInfo extends Component {
    openMaterial = ({ title, id }) => {
        const { openMaterialViewModal, data: { email }} = this.props;

        openMaterialViewModal({ email, title, id });
    }

    render() {
        const { getParticipantStages } = this.props;
        const items = pathOr([], ['data', 'items'], getParticipantStages);

        return getParticipantStages.meta.pending && !getParticipantStages.meta.lastSucceedAt ?
            <LoaderWrapper><Spin /></LoaderWrapper> :
            <div style={{ marginTop: -16 }}>
                { items.map(stage =>
                    <Stage key={stage.id} stage={stage} onOpenMaterial={this.openMaterial} />
                )}
            </div>;
    }
}

export default connect(null, { openMaterialViewModal })(StagesInfo);
