import { Textarea, withFormWrapper } from '@meconsultant/common';
import { Button, Col, Row } from 'antd';
import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';

import { SERVICES } from '../filters/CompanyFeedbacksFilter';
import Select from './formComponents/Select';
import SearchSelect from './formComponents/SearchSelect';
import DatePicker from './formComponents/DatePicker';
import Stars from './formComponents/Stars';
import { getConsultations, getEventParticpation, getParticipants } from '../../actions/asyncActions';
import { path } from 'ramda';

class ParticipantFeedbackForm extends Component {
    render() {
        const { values, isSubmitting, close, item, deleteFeedback, form } = this.props;

        return <Fragment>
            { !item && <Row gutter={16}>
                <Col span={10}>
                    <Field
                        name='module'
                        label='Сервис'
                        component={Select}
                        options={SERVICES}
                        placeholder='Выберите сервис'
                        onChange={() => form.change('id', null)} />
                </Col>
                <Col span={14}>
                    <Field
                        key={`${values.module}-${values.participant}`}
                        name='id'
                        label='Встреча'
                        component={SearchSelect}
                        placeholder='Выберите встречу'
                        action={values.module === 'events' ? getEventParticpation : getConsultations}
                        disabled={!values.module || !values.participant}
                        filter={{ participant: values.participant, moduleType: values.module }}
                        valuePath={values.module === 'events' ? 'event' : 'id'}
                        getNamePath={item => {
                            const consultant = path(['_embedded', 'consultant'], item);

                            return values.module === 'events' ? path(['_embedded', 'event', '_embedded', 'theme', 'title'], item) :
                                `Консультация (${consultant.lastName || ''} ${consultant.firstName || ''} ${consultant.middleName || ''})`;
                        }} />
                </Col>
            </Row>}
            { !item && <Field
                name='participant'
                component={SearchSelect}
                action={getParticipants}
                label='Участник'
                namePath='name'
                filter={{ depersonalized: false, company: this.props.company }}
                getNamePath={item => `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`}
                placeholder='Выберите сервис'
                onChange={() => form.change('id', null)}
            />}
            <Field
                name='createdAt'
                component={DatePicker}
                label='Дата' />
            <Field
                name='rating'
                component={Stars}
                label='Оценка' />
            <Field
                name='comment'
                component={Textarea}
                label='Комментарий' />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <Button
                        type='primary'
                        htmlType='submit'
                        disabled={isSubmitting}>
                        СОХРАНИТЬ
                    </Button>
                    <Button style={{ marginLeft: 10 }} onClick={close}>
                        ОТМЕНА
                    </Button>
                </div>
                { item &&
                    <Button danger onClick={deleteFeedback}>
                        УДАЛИТЬ
                    </Button>
                }
            </div>
        </Fragment>
    }
}

const validationSchema = ({ item }) => yup.object().shape({
    participant: !item ? yup.string().nullable().required() : yup.string().nullable(),
    module: !item ? yup.string().nullable().required() : yup.string().nullable(),
    id:  !item ? yup.string().nullable().required() : yup.string().nullable(),
    rating: yup.number().nullable().required()
});

export default withFormWrapper(ParticipantFeedbackForm, {
    mapPropsToValues: ({ item }) => item,
    validationSchema,
    subscriptions: { values: true }
});
