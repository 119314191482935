import React, { Component, Fragment } from 'react';
import { Textarea, withFormWrapper } from '@meconsultant/common';
import { Button, message } from 'antd';
import { Field } from 'react-final-form';
import * as yup from 'yup';

import { withAsyncActions } from 'react-async-client';
import { deleteParticipantStatuses } from '../../actions/asyncActions';

class StatusCommentForm extends Component {
    delete = () => {
        this.props.deleteParticipantStatuses.dispatch(this.props.item.id);
    }

    render() {
        const { isSubmitting, close } = this.props;

        return <Fragment>
            <Field
                component={Textarea}
                name='comment'
                label='Комментарий'
                placeholder='Введите текст комментария' />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <Button
                        type='primary'
                        htmlType='submit'
                        disabled={isSubmitting}>
                        СОХРАНИТЬ
                    </Button>
                    <Button style={{ marginLeft: 10 }} onClick={close}>
                        ОТМЕНА
                    </Button>
                </div>
                <Button danger onClick={this.delete}>
                    УДАЛИТЬ
                </Button>
            </div>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    comment: yup.string().nullable().required()
});

export default withAsyncActions({
    deleteParticipantStatuses: deleteParticipantStatuses
        .withSuccessHandler(({ close }) => {
            message.success('Комментарий был успешно удален');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(withFormWrapper(StatusCommentForm, {
    mapPropsToValues: ({ item }) => item,
    validationSchema
}));
