import { take, put } from 'redux-saga/effects';
import { toSuccess, toError } from 'react-async-client';

import { POST_LOGIN, GET_USER, LOGOUT, POST_PASSWORD, POST_RESET } from '../constants/actionTypes';
import { getUser } from '../actions/asyncActions';
import { handleTokenChange } from '../utils/token';

export default function* () {
    while(true) {
        const action = yield take([toSuccess(POST_LOGIN), toSuccess(POST_PASSWORD), toSuccess(POST_RESET)]);
        handleTokenChange(action.payload.token, action.payload.refresh_token);
        yield put(getUser());

        yield take([LOGOUT, toError(GET_USER)]);
        yield put(getUser.reset());
        handleTokenChange(null);
    }
}
