"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormServerErrorsContext = void 0;

var _react = require("react");

var FormServerErrorsContext = (0, _react.createContext)({});
exports.FormServerErrorsContext = FormServerErrorsContext;