import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map, pick } from 'ramda';

import { closeModal, hideModal, showModal } from '../actions/modalActions';
import { mapIndexed } from '../utils/ramdaAdditions';

import {
    OPEN_COMPANY_MODAL,
    OPEN_PARTICIPANT_MODAL,
    OPEN_PARTICIPATION_REQUEST_MODAL,
    OPEN_ADMIN_MODAL,
    OPEN_STAGE_MODAL,
    OPEN_MATERIAL_MODAL,
    OPEN_CANDIDATE_FORM_MODAL,
    OPEN_FAQ_MODAL,
    OPEN_MATERIAL_VIEW_MODAL,
    OPEN_PARTICIPANT_IMPORT_MODAL,
    OPEN_PARTICIPANT_DOCUMENT_MODAL,
    OPEN_TOPIC_MODAL,
    OPEN_MENTION_MODAL,
    OPEN_NEWS_MODAL,
    OPEN_COMPANY_SETTINGS_MODAL,
    OPEN_ADMIN_CLIENT_MODAL,
    OPEN_COMPANY_ROLES_MODAL,
    OPEN_IMPORT_INVITES_MODAL,
    OPEN_INVITE_MODAL,
    OPEN_WEBINAR_MODAL,
    OPEN_TEST_MODAL,
    OPEN_COMPANY_WEBINAR_MODAL,
    OPEN_EVENT_PARTICIPANTS_MODAL,
    OPEN_PARTICIPANT_STATUS_MODAL,
    OPEN_EVENT_PARTICIPANTS_ADD_MODAL,
    OPEN_CONSULTANT_SELECT_MODAL,
    OPEN_CONSULTATION_VIEW_MODAL,
    OPEN_CONSULTATION_TITLE_MODAL,
    OPEN_EVENT_VIEW_MODAL,
    OPEN_ADD_MEETING_MODAL,
    OPEN_CANCEL_CONSULTATION_MODAL,
    OPEN_STATUS_COMMENT_MODAL,
    OPEN_PARTICIPANT_COMMENT_MODAL,
    OPEN_PARTICIPANTS_TABLE_SETTINGS_MODAL,
    OPEN_FEEDBACK_MODAL,
    OPEN_PARTICIPANT_FEEDBACK_MODAL,
    OPEN_LINK_MODAL,
    OPEN_LINK_PREVIEW_MODAL,
    OPEN_COMPANY_MESSAGE_TEMPLATE_MODAL
} from '../constants/actionTypes';

import CompanyModal from './modals/CompanyModal';
import ParticipantModal from './modals/ParticipantModal';
import ParticipationRequestModal from './modals/ParticipationRequestModal';
import AdminModal from './modals/AdminModal';
import StageModal from './modals/StageModal';
import MaterialModal from './modals/materials/MaterialModal';
import CandidateFormModal from './modals/CandidateFormModal';
import FAQModal from './modals/FAQModal';
import MaterialViewModal from './modals/MaterialViewModal';
import ParticipantsImportModal from './modals/ParticipantsImportModal';
import TopicModal from './modals/TopicModal';
import MentionModal from './modals/MentionModal';
import NewsModal from './modals/NewsModal';
import CompanySettingsModal from './modals/CompanySettingsModal';
import AdminClientModal from './modals/AdminClientModal';
import CompanyRolesModal from './modals/CompanyRolesModal';
import ImportInvitesModal from './modals/ImportInvitesModal';
import InviteModal from './modals/InviteModal';
import WebinarModal from './modals/WebinarModal';
import TestModal from './modals/TestModal';
import CompanyWebinarModal from './modals/CompanyWebinarModal';
import EventPartcipantsModal from './modals/EventPartcipantsModal';
import ParticipantStatusModal from './modals/ParticipantStatusModal';
import EventParticipantsAddModal from './modals/EventParticipantsAddModal';
import ConsultantSelectModal from './modals/ConsultantSelectModal';
import ConsultationViewModal from './modals/ConsultationViewModal';
import ConsultationTitleModal from './modals/ConsultationTitleModal';
import EventViewModal from './modals/EventViewModal';
import AddMeetingModal from './modals/AddMeetingModal';
import CancelConsultationModal from './modals/CancelConsultationModal';
import StatusCommentModal from './modals/StatusCommentModal';
import ParticipantCommentModal from './modals/ParticipantCommentModal';
import ParticipantsTableSettingsModal from './modals/ParticipantsTableSettingsModal';
import FeedbackModal from './modals/FeedbackModal';
import ParticipantFeedbackModal from './modals/ParticipantFeedbackModal';
import DocumentModal from './modals/DocumentModal';
import LinkModal from './modals/LinkModal';
import LinkPreviewModal from './modals/LinkPreviewModal';
import CompanyMessageTemplateModal from './modals/CompanyMessageTemplateModal';

const modalComponents = {
    [OPEN_COMPANY_MODAL]: CompanyModal,
    [OPEN_PARTICIPANT_MODAL]: ParticipantModal,
    [OPEN_PARTICIPATION_REQUEST_MODAL]: ParticipationRequestModal,
    [OPEN_ADMIN_MODAL]: AdminModal,
    [OPEN_STAGE_MODAL]: StageModal,
    [OPEN_MATERIAL_MODAL]: MaterialModal,
    [OPEN_CANDIDATE_FORM_MODAL]: CandidateFormModal,
    [OPEN_FAQ_MODAL]: FAQModal,
    [OPEN_MATERIAL_VIEW_MODAL]: MaterialViewModal,
    [OPEN_PARTICIPANT_IMPORT_MODAL]: ParticipantsImportModal,
    [OPEN_PARTICIPANT_DOCUMENT_MODAL]: DocumentModal,
    [OPEN_TOPIC_MODAL]: TopicModal,
    [OPEN_MENTION_MODAL]: MentionModal,
    [OPEN_NEWS_MODAL]: NewsModal,
    [OPEN_COMPANY_SETTINGS_MODAL]: CompanySettingsModal,
    [OPEN_ADMIN_CLIENT_MODAL]: AdminClientModal,
    [OPEN_COMPANY_ROLES_MODAL]: CompanyRolesModal,
    [OPEN_IMPORT_INVITES_MODAL]: ImportInvitesModal,
    [OPEN_INVITE_MODAL]: InviteModal,
    [OPEN_WEBINAR_MODAL]: WebinarModal,
    [OPEN_TEST_MODAL]: TestModal,
    [OPEN_COMPANY_WEBINAR_MODAL]: CompanyWebinarModal,
    [OPEN_EVENT_PARTICIPANTS_MODAL]: EventPartcipantsModal,
    [OPEN_PARTICIPANT_STATUS_MODAL]: ParticipantStatusModal,
    [OPEN_EVENT_PARTICIPANTS_ADD_MODAL]: EventParticipantsAddModal,
    [OPEN_CONSULTANT_SELECT_MODAL]: ConsultantSelectModal,
    [OPEN_CONSULTATION_VIEW_MODAL]: ConsultationViewModal,
    [OPEN_CONSULTATION_TITLE_MODAL]: ConsultationTitleModal,
    [OPEN_EVENT_VIEW_MODAL]: EventViewModal,
    [OPEN_ADD_MEETING_MODAL]: AddMeetingModal,
    [OPEN_CANCEL_CONSULTATION_MODAL]: CancelConsultationModal,
    [OPEN_STATUS_COMMENT_MODAL]: StatusCommentModal,
    [OPEN_PARTICIPANT_COMMENT_MODAL]: ParticipantCommentModal,
    [OPEN_PARTICIPANTS_TABLE_SETTINGS_MODAL]: ParticipantsTableSettingsModal,
    [OPEN_FEEDBACK_MODAL]: FeedbackModal,
    [OPEN_PARTICIPANT_FEEDBACK_MODAL]: ParticipantFeedbackModal,
    [OPEN_LINK_MODAL]: LinkModal,
    [OPEN_LINK_PREVIEW_MODAL]: LinkPreviewModal,
    [OPEN_COMPANY_MESSAGE_TEMPLATE_MODAL]: CompanyMessageTemplateModal
};

class Modals extends Component {
    static propTypes = {
        close: PropTypes.func.isRequired,
        hide: PropTypes.func.isRequired,
        show: PropTypes.func.isRequired,
        modals: PropTypes.array.isRequired
    };

    getModalComponent = ({ type, params, visible }, index, length) => {
        const actions = map(action => action.bind(null, index), pick(['close', 'hide', 'show'], this.props));
        const ModalComponent = modalComponents[type];

        const modalProps = {
            visible: index + 1 === length && visible,
            width: 768,
            onCancel: actions.close
        };

        return <ModalComponent {...actions} key={index} index={index} modal={modalProps} params={params} />;
    }

    render() {
        const { modals } = this.props;

        return (
            <div>
                {mapIndexed((modal, index) => this.getModalComponent(modal, index, modals.length), modals)}
            </div>
        );
    }
}

const stateToProps = pick(['modals']);

export default connect(stateToProps, { close: closeModal, hide: hideModal, show: showModal })(Modals);
