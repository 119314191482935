import React, { useContext, useEffect } from 'react';
import BreadcrumbContext from '../contexts/BreadcrumbContext';

const withBreadcrumbContext = WrappedComponent => props => {
    const { setBreadcrumb, breadcrumb } = useContext(BreadcrumbContext);

    useEffect(() => {
        return () => setBreadcrumb(null);
    }, [setBreadcrumb]);

    return <WrappedComponent {...props} breadcrumb={breadcrumb} setBreadcrumb={setBreadcrumb} />;
}

export default withBreadcrumbContext;
