import React, { Component, Fragment } from 'react';
import { Button } from 'antd';
import { withFormWrapper, Textarea } from '@meconsultant/common';
import { Field } from 'react-final-form';
import * as yup from 'yup';

import Input from './formComponents/Input';
import Editor from './formComponents/Editor';
import ImageLoader from './formComponents/ImageLoader';

class NewsForm extends Component {
    render() {
        const { isSubmitting } = this.props;

        return <Fragment>
            <Field
                name='title'
                component={Input}
                label='Заголовок' />
            <Field
                name='lead'
                component={Textarea}
                label='Тизер' />
            <Field
                name='text'
                component={Editor}
                label='Текст' />
            <Field
                name='leadImage'
                component={ImageLoader}
                label='Изображение тизера' />
            <Field
                name='detailImage'
                component={ImageLoader}
                label='Изображение детальной' />
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    title: yup.string().required(),
    lead: yup.string().required(),
    text: yup.string().required()
});

export default withFormWrapper(NewsForm, {
    mapPropsToValues: ({ data }) => data,
    validationSchema
});
