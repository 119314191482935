"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESUME_TYPES = exports.LANGUAGES_LEVEL = exports.LANGUAGES = exports.resume = void 0;
var resume = {
  addres: 'Введите адрес...',
  phone: 'Введите телефон...',
  email: 'Введите Email...',
  linkedIn: 'Введите имя профиля LinkedIn',
  position: 'Например: Менеджен по продажам',
  about: 'Например: Разрабатывал и обеспечивал реализацию мероприятий и созданию сети сбыта товаров (разраюотка и построене каналов движения товаров к потребителям; построение отношений с оптовыми и розничными торговыми предприятиями, иними посредниками; развитие дилерских отношений) в крупной компании.',
  company: 'Введите название компании...',
  workPosition: 'Введите названте позиции...',
  workRange: ['Дата начала работы', 'Дата окончания работы'],
  taskDescription: 'Описание задач (обязанностей)...',
  achievements: 'Достижения....',
  addWorkPlace: 'Добавить место работы',
  educationPlace: 'Введите название учебного заваедения...',
  educationYear: 'Год',
  educationRange: ['Дата начала учебы', 'Дата окончания учебы'],
  specialty: 'Введите название специальности...',
  addEducation: 'Добавить информацию об образовании',
  additionalEducationCourse: 'Введите название курса',
  addAdditionalEducation: 'Добавить информацию о дополнительном образовании',
  computerSkills: 'Компьютерные навыки',
  languages: 'Иностранные языки',
  languageLevel: 'Уровень владения',
  addLanguage: 'Добавить язык'
};
exports.resume = resume;
var LANGUAGES = [{
  name: 'Русский',
  id: 'Russian'
}, {
  name: 'Английский',
  id: 'English'
}, {
  name: 'Французский',
  id: 'French'
}, {
  name: 'Немецкий',
  id: 'German'
}];
exports.LANGUAGES = LANGUAGES;
var LANGUAGES_LEVEL = [{
  name: 'Базовый',
  id: 1
}, {
  name: 'Читаю проф. лит.',
  id: 2
}, {
  name: 'Средний',
  id: 3
}, {
  name: 'Разговорный',
  id: 4
}, {
  name: 'Свободный',
  id: 5
}];
exports.LANGUAGES_LEVEL = LANGUAGES_LEVEL;
var RESUME_TYPES = [{
  name: 'Хронологическое резюме',
  id: 'chronological'
}, {
  name: 'Функциональное резюме',
  id: 'functional'
}];
exports.RESUME_TYPES = RESUME_TYPES;