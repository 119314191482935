import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAsyncActions, toSuccess } from 'react-async-client';
import { takeEvery } from 'redux-saga/effects';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'ramda';
import qs from 'qs';

import Table from './Table';
import Toolbar from './Toolbar';
import { getUrlParams, getSorting, getUrlPagination, getFilters } from '../utils/urls';
import { LINES_PER_PAGE } from '../constants/lists';

class TableList extends Component {
    static propTypes = {
        action: PropTypes.func,
        param:  PropTypes.oneOfType([null, PropTypes.object, PropTypes.func]),
        refreshActions: PropTypes.array,
        asyncActions: PropTypes.array
    };

    constructor(props) {
        super(props);

        const action = props.action
            .withDefaultPayload(({ location, initFilters, staticFilter = {}, payload, defaultSort = {}, parseFilter }) => {
                const q = {
                    ...staticFilter,
                    ...(getFilters(location) || initFilters || {})
                };

                return {
                    ...(payload || {}),
                    ...getUrlPagination(location),
                    ...(isEmpty(getSorting(location)) ? defaultSort : getSorting(location)),
                    q: parseFilter ? parseFilter(q) : q
                };
            })
            .withParams(props.param)
            .withOptions({
                dispatchOnMount: true,
                dispatchOnUpdate: true,
                resetOnUnmount: true,
            })
            .withSaga(function* (getProps) {
                const { refreshActions = [] } = getProps();
                const successActions = refreshActions.map(toSuccess);

                yield takeEvery(successActions, () => getProps().action.refresh());
            });

        this.component = withAsyncActions({
            action,
            ...props.asyncActions
        })(ListTableStateless);
    }

    componentDidUpdate(props) {
        const prevParams = getUrlParams(props.location);

        if (getUrlParams(this.props.location).filter !== prevParams.filter && Number(prevParams.offset) > 0) {
            this.setOffset(0);
        }
    }

    setOffset = (offset, limit) => {
        const { history, location } = this.props;
        const params = getUrlParams(location);
        const search = { ...params, offset, limit };

        history.push({
            pathname: history.location.pathname,
            search: qs.stringify(search),
        });
    }

    setLimit = (limit = LINES_PER_PAGE) => {
        const { history, location } = this.props;
        const params = getUrlParams(location);
        const search = { ...params, offset: 0, limit };

        history.push({
            pathname: history.location.pathname,
            search: qs.stringify(search)
        });
    }

    render() {
        const Component = this.component;

        return <Component
            {...this.props}
            setOffset={this.setOffset}
            setLimit={this.setLimit} />;
    }
}

class ListTableStateless extends Component {
    static propTypes = {
        action: PropTypes.object,
        setOffset: PropTypes.func,
        state: PropTypes.object,
        columns: PropTypes.array,
        className: PropTypes.string,
        buttons: PropTypes.node,
        filterForm: PropTypes.node,
        rowKey: PropTypes.string
    };

    render() {
        const {
            action,
            setOffset,
            state,
            columns,
            className,
            buttons,
            filterForm,
            rowKey,
            showHeader,
            filterSpan,
            buttonsSpan,
            rowSelection,
            scroll,
            bordered,
            showSizeChanger,
            setLimit,
            expandable,
        } = this.props;

        return (
            <div className={className}>
                { (buttons || filterForm) &&
                    <Toolbar buttons={buttons} filterForm={filterForm} filterSpan={filterSpan} buttonsSpan={buttonsSpan} />
                }
                <Table
                    action={action}
                    setOffset={setOffset}
                    state={state}
                    columns={columns}
                    rowKey={rowKey}
                    showHeader={showHeader}
                    rowSelection={rowSelection}
                    scroll={scroll}
                    bordered={bordered}
                    showSizeChanger={showSizeChanger}
                    setLimit={setLimit}
                    expandable={expandable}
                />
            </div>
        );
    }
}

export default withRouter(TableList);
