import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Field, FormSpy } from 'react-final-form';
import * as yup from 'yup';
import { assocPath, compose, includes, omit } from 'ramda';
import { withFormWrapper, GENDER } from '@meconsultant/common';
import { connect } from 'react-redux';

import Input from './formComponents/Input';
import SearchSelect from './formComponents/SearchSelect';
import { getCompanies, getUser, getCompanyRoles, getAdmins } from '../../actions/asyncActions';
import RadioButtons from './formComponents/RadioButtons';
import { ADMIN, SUPER_ADMIN } from '../../constants/roles';
import { PhoneInput } from './formComponents/MaskedInput';

const isAdminRole = props => includes(ADMIN, props.user.roles) || includes(SUPER_ADMIN, props.user.roles);

class ParticipationRequestForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting } = this.props;
        const isAdmin = isAdminRole(this.props);

        return <Fragment>
            { isAdmin &&
                <Field
                    name='company'
                    component={SearchSelect}
                    label='Компания'
                    action={getCompanies}
                    disabled
                    namePath='name' />
            }
            <Field
                name='lastName'
                component={Input}
                disabled
                label='Фамилия' />
            <Field
                name='firstName'
                component={Input}
                disabled
                label='Имя' />
            <Field
                name='middleName'
                component={Input}
                disabled
                label='Отчество' />
            <Field
                name='gender'
                component={RadioButtons}
                label='Пол'
                disabled
                options={GENDER} />
            <Field
                name='email'
                component={Input}
                label='Email'
                disabled
                type='email' />
            <Field
                name='phone'
                component={PhoneInput}
                disabled
                label='Телефон' />
            <Field
                name='location'
                component={Input}
                disabled
                label='Город' />
            <Field
                name='position'
                component={Input}
                disabled
                label='Должность' />
            { isAdminRole(this.props) &&
                <Field
                    name='consultant'
                    component={SearchSelect}
                    action={getAdmins}
                    label='Консультант'
                    namePath='name'
                    getNamePath={item => `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`}
                    allowClear
                />
            }
            <FormSpy subscription={{ values: true }}>
                {({ values }) => (
                    <Field
                        name='companyRole'
                        component={SearchSelect}
                        label='Роль'
                        action={getCompanyRoles}
                        filter={{
                            company: values.company,
                        }}
                        namePath='name'
                    />
                )}
            </FormSpy>
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Принять
            </Button>
        </Fragment>;
    }
}

const validationSchema = props => {
    return yup.object().shape({
        companyRole: yup.string().required(),
    });
};

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(withFormWrapper(ParticipationRequestForm, {
    mapPropsToValues: props => {
        const isAdmin = isAdminRole(props);
        const item = (isAdmin || props.item.id) ? props.item : {
            company: props.user.company
        };

        return compose(
            assocPath(['action'], 'approve'),
            omit(['createdAt', 'updatedAt'])
        )(item);
    },
    validationSchema
}));
