import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Button } from 'antd';

import { withFormWrapper } from '@meconsultant/common';
import Editor from './formComponents/Editor';
import ImageLoader from './formComponents/ImageLoader';

class CompanySettingsForm extends Component {
    static defaultProps = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting } = this.props;

        return <Fragment>
            <Field
                name='logo'
                component={ImageLoader}
                label='Логотип' />
            <Field
                name='text'
                component={Editor}
                label='Текст' />
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
        </Fragment>;
    }
}

export default withFormWrapper(CompanySettingsForm, {
    mapPropsToValues: ({ item }) => item
});
