import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Upload, message, Modal } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { EyeOutlined, UserOutlined, DeleteOutlined } from '@ant-design/icons';

import { deleteFile } from '../actions/asyncActions';
import { FILE_URL, getFileView } from '../constants/urls';
import { getJWTToken } from '../utils/token';

class Photo extends Component {
    static propTypes = {
        item: PropTypes.object,
        deleteFile: PropTypes.object,
        saveAction: PropTypes.object,
    };

    static defaultProps = {
        item: {}
    };

    state = {
        preview: false
    };

    openPreview = () => this.setState({ preview: true });

    closePreview = () => this.setState({ preview: false });

    deletePhoto = () => {
        this.props.deleteFile.dispatch(this.props.item.photo);
    }

    onChange = info => {
        const { status, response } = info.file;
        const { saveAction, item } = this.props;

        switch(status) {
            case 'done':
                saveAction.dispatch({ ...item, photo: response.id });
                message.success('Фотография была успешно сохранена');
                break;
            case 'error':
                message.error('Не удалось сохранить фотографию');
                break;
            default:
                return;
        }
    }

    render() {
        const { item: { photo, depersonalized }} = this.props;

        return <Upload
            className='photo-container'
            showUploadList={false}
            accept='.png, .jpg, .jpeg'
            onChange={this.onChange}
            action={FILE_URL}
            headers={{ Authorization: getJWTToken() }}
            disabled={!!photo || depersonalized}>
            { photo ?
                <Fragment>
                    <img
                        className='photo'
                        alt=''
                        style={{
                            backgroundImage: `url(${getFileView(photo)})`
                        }}/>
                    <Modal
                        className='photo-modal'
                        visible={this.state.preview}
                        title='Фотография'
                        footer={null}
                        onCancel={this.closePreview}
                        width={768}>
                        <img src={getFileView(photo)} alt='' />
                    </Modal>
                    <div className='photo-hover'>
                        <button onClick={this.openPreview}>
                           <EyeOutlined />
                        </button>
                        <button onClick={this.deletePhoto}>
                            <DeleteOutlined />
                        </button>
                    </div>
                </Fragment> :
                <div className='photo-empty'>
                    <UserOutlined />
                </div>
            }
        </Upload>;
    }
}

export default withAsyncActions(props => ({
    saveAction: props.action
        .withOptions({ resetOnUnmount: true }),
    deleteFile: deleteFile
        .withSuccessHandler(({ saveAction, item }) => {
            message.success('Фотография была успешно удалена');
            saveAction.dispatch({ ...item, photo: null });
        })
        .withOptions({ resetOnUnmount: true }),
}))(Photo);
