import React, { Component } from 'react';
import { Modal } from 'antd';
import { includes } from 'ramda';

export default class CancelConsultationModal extends Component {
    close = () => {
        this.props.params.onClose && this.props.params.onClose();
        this.props.close();
    }

    render() {
        const { params, modal } = this.props;
        const isDev = window.location.hostname === 'localhost' || includes('.dev.', window.location.hostname);
        const isDemo = includes('.demo.', window.location.hostname);

        return <Modal
            {...modal}
            onCancel={this.close}
            title='Редактирование консультации'
            footer={null}>
            <iframe
                src={`https://${!(isDev || isDemo) ? 'cal.meconsultant.ru' : `cal.meconsultant.${isDev ? 'dev' : 'demo'}.experium.net`}/cancel/${params.externalId}`}
                title='cancel'
                frameBorder='0'
                width="100%"
                height={710}
                allowFullScreen />
        </Modal>;
    }
}
