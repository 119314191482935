import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { prop } from 'ramda';

import { withFormWrapper } from '@meconsultant/common';
import Input from './formComponents/Input';
import Switch from './formComponents/Switch';
import { PhoneInput } from './formComponents/MaskedInput';

class AdminForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting, item } = this.props;

        return <Fragment>
            <Field
                name='lastName'
                component={Input}
                label='Фамилия' />
            <Field
                name='firstName'
                component={Input}
                label='Имя' />
            <Field
                name='middleName'
                component={Input}
                label='Отчество' />
            <Field
                name='email'
                component={Input}
                label='Email'
                type='email' />
            <Field
                name='phone'
                component={PhoneInput}
                label='Телефон' />
            { item &&
                <Field
                    name='enabled'
                    component={Switch}
                    label='Активен' />
            }
            { item && item.id ?
                <Field
                    name='superAdmin'
                    component={Switch}
                    label='Администратор' />
                : null
            }
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    email: yup.string().email().required()
});

export default withFormWrapper(AdminForm, {
    mapPropsToValues: prop('item'),
    validationSchema
});
