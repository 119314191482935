import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { asyncConnect } from 'react-async-client';
import { RollbackOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { getParticipationRequests, getUser, putParticipationRequests } from '../actions/asyncActions';
import { openParticipationRequestModal } from '../actions/modalActions';
import { PUT_PARTICIPATION_REQUESTS } from '../constants/actionTypes';
import ParticipationRequestsFilter from './filters/ParticipationRequestsFilter';
import TableList from './TableList';
import { filter, includes } from 'ramda';
import { ADMIN, SUPER_ADMIN } from '../constants/roles';

class ParticipationRequests extends Component {
    static propTypes = {
        openParticipationRequestModal: PropTypes.func,
        putParticipationRequests: PropTypes.object,
        location: PropTypes.object,
    };

    reject = id => {
        this.props.putParticipationRequests.dispatch({ id, action: 'reject' });
    }

    pending = id => {
        this.props.putParticipationRequests.dispatch({ id, action: 'pending' });
    }

    getColumns = () => filter(({ hide }) => !hide, [
        {
            title: 'ФИО',
            dataIndex: 'lastName',
            key: 'name',
            render: (lastName, { firstName, middleName, participant }) => {
                const name = (
                    <span>{ lastName } { firstName } { middleName || '' }</span>
                );

                return participant ? <Link to={`/participants/${participant}`}>{ name }</Link> : name;
            },
        },
        {
            title: 'Компания',
            dataIndex: ['_embedded', 'company', 'name'],
            key: 'company',
            hide: !(includes(ADMIN, this.props.user.roles) || includes(SUPER_ADMIN, this.props.user.roles)) || this.props.company
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'Консультант',
            dataIndex: ['_embedded', 'consultant'],
            key: 'consultant',
            render: consultant => consultant ? <span>{ consultant.lastName } { consultant.firstName } { consultant.middleName || '' }</span> : null
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: createdAt => createdAt ? new Date(createdAt).toLocaleDateString('ru') : null,
        },
        {
            key: 'actions',
            align: 'center',
            render: item => {
                switch(item.status) {
                    case 'pending':
                        return (
                            <Button.Group>
                                <Button
                                    icon={<CheckOutlined />}
                                    disabled={this.props.putParticipationRequests.meta.pending}
                                    onClick={() => this.props.openParticipationRequestModal(item)}
                                />
                                <Popconfirm
                                    title='Вы уверены, что хотите отклонить заявку?'
                                    placement='topRight'
                                    okText='Да'
                                    cancelText='Нет'
                                    onConfirm={() => this.reject(item.id)}
                                >
                                    <Button
                                        icon={<CloseOutlined />}
                                        disabled={this.props.putParticipationRequests.meta.pending}
                                    />
                                </Popconfirm>
                            </Button.Group>
                        );
                    case 'approved':
                        return (
                            <CheckOutlined />
                        );
                    case 'rejected':
                        return (
                            <Popover
                                content={(
                                    <Button
                                        disabled={this.props.putParticipationRequests.meta.pending}
                                        icon={<RollbackOutlined />}
                                        onClick={() => this.pending(item.id)}
                                    >
                                        Вернуть
                                    </Button>
                                )}
                            >
                                <CloseOutlined />
                            </Popover>
                        );
                    default:
                        return item.status;
                }
            }
        },
    ]);

    render() {
        return (
            <TableList
                action={getParticipationRequests}
                columns={this.getColumns()}
                staticFilter={{ company: this.props.company }}
                defaultSort={{
                    sort_by: { createdAt: 'desc' }
                }}
                refreshActions={[PUT_PARTICIPATION_REQUESTS]}
                filterForm={<ParticipationRequestsFilter hideCompany={!!this.props.company} />}
            />
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    putParticipationRequests: putParticipationRequests
        .withOptions({ resetOnUnmount: true })
}, stateToProps, { openParticipationRequestModal })(ParticipationRequests);
