"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_ERROR = exports["default"] = void 0;
var _default = {
  'this value is already used.': 'Название $value уже существует',
  'This value should not be blank.': 'значение не должно быть пустым',
  'This value is not a valid email address.': 'невалидный email',
  'invite was already activated.': 'Код уже был активирован',
  'invite not found.': 'Неверный код',
  'user with that email already exists.': 'Пользователь с таким e-mail уже существует',
  'Invalid credentials.': 'Неверный логин или пароль'
};
exports["default"] = _default;
var DEFAULT_ERROR = 'Ошибка соединения с сервером';
exports.DEFAULT_ERROR = DEFAULT_ERROR;