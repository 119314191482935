import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postCompany, putCompany, deleteCompany } from '../../actions/asyncActions';
import CompanyForm from '../forms/CompanyForm';
import { withRouter } from 'react-router';

class CompanyModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        params: PropTypes.object,
        postCompany:  PropTypes.object,
        putCompany: PropTypes.object,
        deleteCompany: PropTypes.object
    };

    render() {
        const { modal, params, postCompany, putCompany, deleteCompany } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать компанию' : 'Добавить компанию'}
            footer={null}>
            <CompanyForm
                formAction={params ? putCompany : postCompany}
                deleteCompany={deleteCompany}
                item={params} />
        </Modal>;
    }
}

export default withRouter(withAsyncActions({
    postCompany: postCompany
        .withSuccessHandler(({ close }) => {
            message.success('Компания была успешно добавлена');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putCompany: putCompany
        .withSuccessHandler(({ close }) => {
            message.success('Компания была успешно отредактирована');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    deleteCompany: deleteCompany
        .withSuccessHandler(({ close, params, history }) => {
            message.success('Компания была успешно удалена');
            if (params) {
                history.push('/');
            } else {
                close();
            }
        })
        .withOptions({ resetOnUnmount: true }),
})(CompanyModal));
