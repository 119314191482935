import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormBuilder } from '@experium/react-editor';
import {
    CoverLetter,
    CoverLetterComponent,
    CheckList,
    CheckListComponent,
    Feedback,
    FeedbackComponent,
    MotivationAnalysis,
    MotivationAnalysisComponent,
    PersonalityAnalysis,
    PersonalityAnalysisComponent,
    SelectionCriteria,
    SelectionCriteriaComponent,
    EditorSettingsContext,
    Presentation,
    PresentationComponent,
    TextImage,
    TextImageComponent
} from '@meconsultant/common';
import uniqid from 'uniqid';
import { range } from 'ramda';
import { withAsyncActions } from 'react-async-client';

import { getFileView, getUploadUrl } from '../../../constants/urls';
import Resume, { ResumeComponent } from './Resume';
import { getSettingsPath } from '../../../actions/asyncActions';
import { getToken } from '../../../utils/token';

export const components = [
    {
        type: 'Resume',
        name: 'Резюме',
        icon: 'address-card-o',
        component: Resume,
        formComponent: ResumeComponent,
        props: {
            label: 'Резюме'
        },
        fields: [
            { type: 'editor', label: 'Название поля', prop: 'label', props: { short: true }}
        ]
    },
    {
        type: 'Feedback',
        name: 'Форма обратной связи',
        icon: 'comment',
        component: Feedback,
        formComponent: FeedbackComponent,
        props: {
            label: 'Форма обратной связи',
            max: 10,
            commentLimit: 4
        },
        fields: [
            { type: 'editor', label: 'Название поля', prop: 'label', props: { short: true }},
            { type: 'input', label: 'Максимальное значение', prop: 'max', props: { number: true, min: 3 }},
            { type: 'input', label: 'Порог для комментария', prop: 'commentLimit', props: { number: true, min: 0 }},
            { type: 'switch', label: 'Проверять обязательность полей', prop: 'required' },
            { type: 'radiobuttons', label: 'Тип', prop: 'fieldType', props: { options: ['Слайдер', 'Кнопки'], defaultValue: 'Слайдер' }},
            { type: 'radiobuttons', label: 'Тип кнопок', prop: 'buttonsType', props: { options: ['Цифры', 'Звезды'], defaultValue: 'Цифры', cond: values => values.fieldType === 'Кнопки' }}
        ]
    },
    {
        type: 'CoverLetter',
        name: 'Сопроводительное письмо',
        icon: 'envelope',
        component: CoverLetter,
        formComponent: CoverLetterComponent,
        props: {
            label: 'Сопроводительное письмо'
        },
        fields: [
            { type: 'editor', label: 'Название поля', prop: 'label', props: { short: true }}
        ]
    },
    {
        type: 'MotivationAnalysis',
        name: 'Анализ мотивации',
        icon: 'line-chart',
        component: MotivationAnalysis,
        formComponent: MotivationAnalysisComponent,
        props: {
            label: 'Анализ мотивации',
            scale: range(0, 5).map(i => ({
                label: `${i}`,
                id: uniqid('scale_')
            }))
        },
        fields: [
            { type: 'editor', label: 'Название поля', prop: 'label', props: { short: true }}
        ]
    },
    {
        type: 'PersonalityAnalysis',
        name: 'Анализ личностных качеств',
        icon: 'user',
        component: PersonalityAnalysis,
        formComponent: PersonalityAnalysisComponent,
        props: {
            label: 'Анализ личностных качеств'
        },
        fields: [
            { type: 'editor', label: 'Название поля', prop: 'label', props: { short: true }}
        ]
    },
    {
        type: 'SelectionCriteria',
        name: 'Выбор критериев и категорий для поиска компании-работодателя',
        icon: 'list',
        component: SelectionCriteria,
        formComponent: SelectionCriteriaComponent,
        props: {
            label: 'Выбор критериев и категорий для поиска компании-работодателя'
        },
        fields: [
            { type: 'editor', label: 'Название поля', prop: 'label', props: { short: true }}
        ]
    },
    {
        type: 'Presentation',
        name: 'Самопрезентация',
        icon: 'file-text',
        component: Presentation,
        formComponent: PresentationComponent,
        props: {
            label: 'Самопрезентация'
        },
        fields: [
            { type: 'editor', label: 'Название поля', prop: 'label', props: { short: true }}
        ]
    },
    {
        type: 'TextImage',
        name: 'Текст и изображение',
        icon: 'id-card-o',
        component: TextImage,
        formComponent: TextImageComponent,
        staticContent: true,
        fields: [
            { type: 'editor', label: 'Текст', prop: 'text', props: { short: true }},
            { type: 'uploader', label: 'Изображение', prop: 'image', props: { withoutUrl: true, accept: 'image/*' }},
            { type: 'radiobuttons', label: 'Расположение текста', prop: 'align', props: { options: ['Слева', 'Справа'], defaultValue: 'Справа' }},
        ]
    },
    {
        type: 'CheckList',
        name: 'Чек-лист',
        icon: 'check-square-o',
        component: CheckList,
        formComponent: CheckListComponent,
        props: {
            label: 'Чек-лист',
            options: range(0, 3).map(i => ({
                label: `Задача ${i}`,
                id: uniqid('checkboxes_option_')
            }))
        },
        fields: [
            { type: 'editor', label: 'Название поля', prop: 'label', props: { short: true }},
            { type: 'multiple', label: 'Задачи', prop: 'options', fieldArray: true },
            { type: 'switch', label: 'Обязательное поле', prop: 'required' },
        ]
    },
];

class FormBuidlerField extends Component {
    static propTypes = {
        meta: PropTypes.object,
        input: PropTypes.object,
        onPreviewOpen: PropTypes.func,
        hide: PropTypes.func,
        onPreviewClose: PropTypes.func,
    };

    render() {
        const { meta: { submitFailed, error }, input: { onChange, value }, onPreviewOpen, onPreviewClose, getSettingsPath } = this.props;

        return <Fragment>
            { submitFailed && error &&
                <div className='material-modal-content-error'>Необходимо добавить содержимое</div>
            }
            <EditorSettingsContext.Provider value={getSettingsPath.data || {}}>
                <FormBuilder
                    onChange={onChange}
                    data={value || null}
                    onPreviewOpen={onPreviewOpen}
                    onPreviewClose={onPreviewClose}
                    components={components}
                    uploadUrl={getUploadUrl()}
                    downloadUrl={getFileView}
                    uploadImages
                    mceLanguageUrl='/translations/ru.json'
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    authParams={{
                        token: getToken()
                    }} />
            </EditorSettingsContext.Provider>
        </Fragment>;
    }
}

export default withAsyncActions({
    getSettingsPath: getSettingsPath
        .withPayload(() => 'editor')
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(FormBuidlerField);
