import { Select, Spin } from 'antd';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import ReactHighcharts from 'react-highcharts';
import { connect } from 'react-redux';
import { pathOr, path, find, propEq, keys, flatten, filter, forEach } from 'ramda';

import { getCompanyRoles, getCompanyStatisticStatuses, getUser } from '../../../actions/asyncActions';
import { Block, BlockContent, BlockHeader } from '../modules/WebinarsByCompany';
import { withState } from 'recompose';
import { PARTICIPANT_STATUSES } from '../../../constants/statuses';

require('highcharts-funnel')(ReactHighcharts.Highcharts);

class Statuses extends Component {
    getConfig = () => {
        const { getCompanyStatisticStatuses: { data }} = this.props;

        const series = filter(({ y }) => !!y, flatten(keys(pathOr({}, ['statuses'], data)).map(key =>
            keys(pathOr({}, ['statuses', key, 'additionalStatuses'], data)).map(addKey => ([
                {
                    name: path(['title'], find(propEq('id', addKey), PARTICIPANT_STATUSES)),
                    color: path(['chartColor'], find(propEq('id', addKey), PARTICIPANT_STATUSES)),
                    y: data.statuses[key].additionalStatuses[addKey].countParticipants,
                    percent: data.statuses[key].additionalStatuses[addKey].countPercents
                }
            ]))
        )));

        return {
            chart: {
                type: 'funnel',
                width: 270,
                height: 340,
                events: {
                    load: function() {
                        if (this) {
                            forEach((p, i) => {
                                p.dataLabel.attr({
                                    x: (this.plotWidth - this.plotLeft) / 2,
                                    'text-anchor': 'middle'
                                })
                            }, this.series[0].data)
                        }
                    },
                    redraw: function() {
                        if (this) {
                            forEach((p, i) => {
                                p.dataLabel.attr({
                                    x: (this.plotWidth - this.plotLeft) / 2,
                                    'text-anchor': 'middle'
                                })
                            }, this.series[0].data)
                        }
                    }
                }
            },
            title: {
                text: null
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        connectorWidth: 0,
                        distance: 0,
                        style: {
                            fontWeight: 'bold',
                            color: 'white',
                            textOutline: 0,
                            fontSize: 12
                        },
                        formatter: function() {
                            return '<span>' + series[this.colorIndex].percent + '%</span>'
                        }
                    },
                    center: ['40%', '50%'],
                    neckWidth: '40%',
                    neckHeight: '25%',
                    width: '60%'
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Пользователи',
                data: series
            }],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    inside: true
                                },
                                center: ['50%', '50%'],
                                width: '100%'
                            }
                        }
                    }
                }]
            }
        };
    }

    render() {
        const { getCompanyStatisticStatuses: { meta, data }, companyRole, setCompanyRole, getCompanyRoles } = this.props;
        return <Block>
            <BlockHeader>
                <h3>СТАТУСЫ</h3>
                <Select
                    value={companyRole || undefined}
                    onChange={value => setCompanyRole(value)}
                    style={{ width: 250 }}
                    placeholder='Все программы'
                    allowClear>
                    { pathOr([], ['data', 'items'], getCompanyRoles).map(item => <Select.Option key={item.id} value={item.id}>{ item.name }</Select.Option>) }
                </Select>
            </BlockHeader>
            <BlockContent>
                { meta.pending ? <Spin /> :
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ReactHighcharts
                                config={this.getConfig()} />
                            <div style={{ width: '100%', marginLeft: 10 }}>
                                { keys(pathOr({}, ['statuses'], data)).map(key =>
                                    <div key={`status-${key}`}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', background: '#F1F1F1', padding: '2px 10px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ background: path(['chartColor'], find(propEq('id', key), PARTICIPANT_STATUSES)), width: 8, height: 8, borderRadius: '50%', marginRight: 5 }} />
                                                <strong>{ path(['title'], find(propEq('id', key), PARTICIPANT_STATUSES)) }</strong>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div><strong>{ pathOr(0, ['statuses', key, 'countPercents'], data) }%</strong></div>
                                                <div style={{ width: 33, textAlign: 'right' }}><strong>{ pathOr(0, ['statuses', key, 'countParticipants'], data) }</strong></div>
                                            </div>
                                        </div>
                                        { keys(pathOr({}, ['statuses', key, 'additionalStatuses'], data)).map(additional =>
                                            <div key={`status-${key}-${additional}`}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '2px 10px' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ background: path(['chartColor'], find(propEq('id', additional), PARTICIPANT_STATUSES)), width: 8, height: 8, borderRadius: '50%', marginRight: 5 }} />
                                                        { path(['secondLevel'], find(propEq('id', additional), PARTICIPANT_STATUSES)) || path(['title'], find(propEq('id', additional), PARTICIPANT_STATUSES)) }
                                                    </div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div>{ pathOr(0, ['statuses', key, 'additionalStatuses', additional, 'countPercents'], data) }%</div>
                                                        <div style={{ width: 33, textAlign: 'right' }}><strong>{ pathOr(0, ['statuses', key, 'additionalStatuses', additional, 'countParticipants'], data) }</strong></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                }
            </BlockContent>
        </Block>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default withState('companyRole', 'setCompanyRole', null)(connect(stateToProps)(asyncConnect({
    getCompanyRoles: getCompanyRoles
        .withPayload(({ id }) => ({
            q: {
                company: id
            },
            limit: 0
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getCompanyStatisticStatuses: getCompanyStatisticStatuses
        .withPayload(({ id, user, companyRole, consultant }) => ({
            id: id || user.company,
            q: {
                companyRole,
                consultant
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(Statuses)));
