import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';

import { withFieldWrapper } from '@meconsultant/common';

class InputNumberComponent extends Component {
    static propTypes = {
        placeholder: PropTypes.string,
        input: PropTypes.object,
        onChange: PropTypes.func,
        min: PropTypes.number
    };

    render() {
        const { placeholder, input: { value }, onChange, min, style, formatter, parser } = this.props;

        return (
            <InputNumber
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                min={min}
                style={style}
                formatter={formatter}
                parser={parser}
            />
        )
    }
}

export default withFieldWrapper(InputNumberComponent);
