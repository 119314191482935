import React, { Component, Fragment } from 'react';
import { withFormWrapper } from '@meconsultant/common';
import { Button, Form } from 'antd';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import * as yup from 'yup';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import InputNumber from './formComponents/InputNumber';
import Input from './formComponents/Input';

const CriteriaItem = styled.div`
    display: flex;
    align-items: center;
    .ant-form-item {
        width: 100%;
        margin-bottom: 0;
        margin-right: 10px;
    }
`;

class CriteriaSettingsForm extends Component {
    renderCategoriesFields = ({ fields }) => {
        return <div style={{ marginLeft: 30 }}>
            { fields.map((name, index) =>
                <CriteriaItem key={name}>
                    <Field
                        name={name}
                        component={Input}
                        displayError={false} />
                    <Button
                        icon={<DeleteOutlined />}
                        danger
                        onClick={() => fields.remove(index)} />
                </CriteriaItem>
            )}
            <Button
                icon={<PlusOutlined />}
                onClick={() => fields.push()}>
                Добавить категорию
            </Button>
        </div>;
    }

    renderItemsFields = ({ fields }) => {
        return <div>
            { fields.map((name, index) =>
                <div key={name}>
                    <CriteriaItem>
                        <Field
                            name={`${name}.title`}
                            component={Input}
                            displayError={false} />
                        <Button
                            icon={<DeleteOutlined />}
                            danger
                            onClick={() => fields.remove(index)} />
                    </CriteriaItem>
                    <FieldArray name={`${name}.categories`}>
                        { this.renderCategoriesFields }
                    </FieldArray>
                </div>
            )}
            <Button
                icon={<PlusOutlined />}
                onClick={() => fields.push({})}>
                Добавить критерий
            </Button>
        </div>;
    }

    render() {
        const { isSubmitting } = this.props;

        return <Fragment>
            <Form.Item label='Критерии и категории'>
                <FieldArray name='criteria.items'>
                    { this.renderItemsFields }
                </FieldArray>
            </Form.Item>
            <Field
                name='criteria.min'
                component={InputNumber}
                label='Минимальное количество критериев' />
            <Field
                name='criteria.max'
                component={InputNumber}
                label='Максимальное количество критериев' />
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    criteria: yup.object().shape({
        items: yup.array().of(
            yup.object().shape({
                title: yup.string().required(),
                categories: yup.array().of(yup.string().required())
            })
        )
    })
});

export default withFormWrapper(CriteriaSettingsForm, {
    validationSchema,
    mapPropsToValues: ({ data }) => ({
        ...(data || {}),
        id: 'editor'
    })
});
