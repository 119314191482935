import { Avatar } from 'antd';
import { any, filter, find, includes, keys, path, pathOr, propEq } from 'ramda';
import React, { Component, Fragment } from 'react';
import { asyncConnect } from 'react-async-client';
import moment from 'moment';
import { DownOutlined, EditOutlined, UpOutlined, UserOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';

import { getParticipantsHistory, getPromotionRequestsThemes } from '../actions/asyncActions';
import { openEventViewModal, openStatusCommentModal } from '../actions/modalActions';
import { DELETE_PARTICIPANT_STATUSES, POST_PARTICIPANT_STATUSES, PUT_PARTICIPANT_STATUSES, PUT_SET_CONSULTANT } from '../constants/actionTypes';
import { PARTICIPANT_STATUSES } from '../constants/statuses';
import TableList from './TableList';
import ParticipantHistoryFilter from './filters/ParticipantHistoryFilter';
import { getFileView } from '../constants/urls';
import { EVENT_PARTICIPANT_STATUSES } from './modals/EventPartcipantsModal';
import { Link } from 'react-router-dom';
import { STATUSES as PROMOTION_STATUSES } from './Promotion';
import pluralize from 'pluralize-ru';
import { ADMIN, SUPER_ADMIN } from '../constants/roles';
import { Status } from './Participant';

const ICONS = {
    companyRole: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#2B3D4F"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2343 11.313C11.4615 11.5178 10.2583 12.8612 10.1384 14.6419C10.0605 15.7982 10 17.3791 10 19.5C10 21.6209 10.0605 23.2018 10.1384 24.3581C10.2583 26.1388 11.4615 27.4822 13.2343 27.687C13.8565 27.7588 14.6051 27.8261 15.5004 27.8797C15.5317 29.5309 17.4506 30.4395 18.7494 29.4005L20.5016 27.9987C23.3664 27.9843 25.3798 27.8471 26.7657 27.687C28.5385 27.4822 29.7417 26.1388 29.8616 24.3581C29.9395 23.2018 30 21.6209 30 19.5C30 17.3791 29.9395 15.7982 29.8616 14.6419C29.7417 12.8612 28.5385 11.5178 26.7657 11.313C25.3002 11.1438 23.1333 11 20 11C16.8667 11 14.6998 11.1438 13.2343 11.313Z" fill="#47AAC4"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8933 15.59C15.0766 15.6195 14.5 16.2546 14.5 17.0719C14.5 17.8253 15.0021 18.4031 15.7549 18.4354C16.5612 18.4699 17.8737 18.5 20 18.5C22.0122 18.5 23.2955 18.4731 24.1108 18.4409C24.9433 18.4081 25.5 17.7716 25.5 16.9385C25.5 16.1705 25.0129 15.5869 24.2458 15.5506C23.4395 15.5124 22.1269 15.4857 20 15.5083C17.9913 15.5295 16.7088 15.5605 15.8933 15.59ZM15.8249 21.0402C15.0294 21.076 14.5 21.7037 14.5 22.5C14.5 23.2963 15.0294 23.924 15.8249 23.9598C16.3382 23.983 17.0415 24 18 24C18.9585 24 19.6618 23.983 20.1751 23.9598C20.9706 23.924 21.5 23.2963 21.5 22.5C21.5 21.7037 20.9706 21.076 20.1751 21.0402C19.6618 21.017 18.9585 21 18 21C17.0415 21 16.3382 21.017 15.8249 21.0402Z" fill="#2B3D4F"/>
    </svg>,
    materialView: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#2B3D4F"/>
        <path d="M9.62941 28.7744C11.9022 28.0007 16.1763 26.9935 19.7542 28.7145C19.9159 28.7923 20.1054 28.792 20.2669 28.7136C23.8403 26.9803 28.1169 27.9727 30.3917 28.7386C31.1522 28.9946 32.0002 28.4387 31.9992 27.6376L31.9838 15.7457C31.983 15.0981 31.6011 14.5033 30.983 14.3065C27.7453 13.2755 23.2112 13.3773 20.2121 14.6072C20.071 14.6651 19.9137 14.6653 19.7725 14.608C16.7702 13.3884 12.2358 13.3022 9.00085 14.3443C8.38328 14.5433 8.00292 15.1394 8.00376 15.787L8.01914 27.6789C8.02018 28.48 8.86966 29.033 9.62941 28.7744Z" fill="#47AAC4"/>
        <path d="M9.9109 26.8903C12.7596 25.8433 16.7703 25.8774 19.5304 26.9969C19.8384 27.1218 20.1838 27.1212 20.4915 26.9952C23.2487 25.8663 27.2592 25.8183 30.1106 26.8555C30.4881 26.9928 30.9098 26.7246 30.9093 26.3234L30.893 13.7268C30.8925 13.3092 30.6547 12.924 30.2646 12.7735C27.4043 11.6702 23.2863 11.6968 20.4731 12.8487C20.1655 12.9747 19.8201 12.9752 19.5122 12.8503C16.696 11.7081 12.578 11.6958 9.72055 12.8089C9.33084 12.9607 9.09404 13.3467 9.09458 13.7643L9.11086 26.361C9.11138 26.7622 9.53376 27.0289 9.9109 26.8903Z" fill="#DBEEFB"/>
        <path d="M19.4556 12.8086C19.4768 12.8172 19.498 12.8258 19.5191 12.8345C19.8222 12.9597 20.1621 12.9592 20.4649 12.8329C20.486 12.8241 20.5072 12.8154 20.5284 12.8067L20.5465 26.9867C20.5253 26.9954 20.5042 27.0041 20.4831 27.0128C20.1803 27.1391 19.8403 27.1397 19.5371 27.0145C19.516 27.0058 19.4949 26.9971 19.4737 26.9886L19.4556 12.8086Z" fill="#47AAC4"/>
        <path d="M11.9934 18.5113C11.6355 18.5959 11.278 18.3341 11.2775 17.9665L11.2768 17.4203C11.2763 17.0634 11.5071 16.7442 11.8536 16.6573C13.5851 16.2232 15.5025 16.2199 17.2351 16.648C17.5818 16.7337 17.8134 17.0521 17.8139 17.4091L17.8146 17.9552C17.815 18.3228 17.4582 18.5858 17.1 18.5025C15.4498 18.1185 13.6427 18.1217 11.9934 18.5113Z" fill="#47AAC4"/>
        <path d="M11.9991 22.8676C11.6412 22.9522 11.2836 22.6904 11.2831 22.3228L11.2824 21.7767C11.282 21.4197 11.5128 21.1005 11.8592 21.0136C13.5907 20.5795 15.5081 20.5762 17.2408 21.0044C17.5874 21.09 17.819 21.4084 17.8195 21.7654L17.8202 22.3115C17.8207 22.6791 17.4638 22.9422 17.1057 22.8588C15.4554 22.4749 13.6483 22.478 11.9991 22.8676Z" fill="#47AAC4"/>
        <path d="M28.0078 18.4981C28.3659 18.5814 28.7228 18.3184 28.7223 17.9508L28.7216 17.4047C28.7211 17.0477 28.4895 16.7293 28.1428 16.6436C26.4102 16.2155 24.4928 16.2188 22.7613 16.6529C22.4148 16.7398 22.184 17.059 22.1845 17.4159L22.1852 17.9621C22.1857 18.3297 22.5433 18.5915 22.9012 18.5069C24.5504 18.1173 26.3575 18.1141 28.0078 18.4981Z" fill="#47AAC4"/>
        <path d="M28.0134 22.8544C28.3715 22.9378 28.7284 22.6747 28.7279 22.3071L28.7272 21.761C28.7267 21.404 28.4951 21.0856 28.1485 21C26.4158 20.5718 24.4985 20.5751 22.7669 21.0092C22.4205 21.0961 22.1897 21.4153 22.1901 21.7723L22.1908 22.3184C22.1913 22.686 22.5489 22.9478 22.9068 22.8632C24.556 22.4736 26.3632 22.4705 28.0134 22.8544Z" fill="#47AAC4"/>
    </svg>,
    participantStatus: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#2B3D4F"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2197 16.3096L16.2456 15.3508L18.6858 10.7925C19.2514 9.73584 20.7486 9.73584 21.3142 10.7925L23.7544 15.3508L28.7803 16.3096C29.9407 16.531 30.4017 17.9635 29.5929 18.835L26.0748 22.626L26.7324 27.7892C26.8839 28.9791 25.6744 29.8665 24.6056 29.3496L20 27.1223L15.3944 29.3496C14.3256 29.8665 13.1161 28.9791 13.2676 27.7892L13.9252 22.626L10.4071 18.835C9.59835 17.9635 10.0593 16.531 11.2197 16.3096ZM21.9029 17.1869C22.0458 17.4539 22.2999 17.6416 22.5946 17.6978L26.5315 18.4489L23.7754 21.4186C23.5712 21.6387 23.475 21.9398 23.5132 22.2393L24.0269 26.2729L20 24.3255L20 13.6324L21.9029 17.1869Z" fill="#FFD34F"/>
    </svg>,
    eventParticipation: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#2B3D4F"/>
        <path d="M20.0183 24.5431C23.5709 24.537 25.9417 25.842 27.4798 27.2947C29.2647 28.9806 27.8221 31.4329 25.3704 31.5751C23.8725 31.6619 22.0655 31.7289 20.0276 31.7324C17.9898 31.7359 16.1826 31.6752 14.6845 31.5935C12.2325 31.4598 10.7834 29.0124 12.564 27.3204C14.0983 25.8624 16.4658 24.5492 20.0183 24.5431Z" fill="#75AD56"/>
        <ellipse rx="9.98998" ry="9.98549" transform="matrix(0.999996 -0.00172589 0.00129048 0.999997 20.0228 18.9682)" fill="#E2FFD1"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0313 25.6261C23.7096 25.6197 26.6876 22.634 26.6829 18.9573C26.6781 15.2806 23.6924 12.3052 20.0141 12.3115C16.3358 12.3179 13.3579 15.3036 13.3626 18.9803C13.3673 22.657 16.353 25.6324 20.0313 25.6261ZM20.0296 23.9619C22.7884 23.9572 25.022 21.7178 25.0184 18.9601C25.0148 16.2024 22.7755 13.9708 20.0167 13.9755C17.2579 13.9803 15.0244 16.2197 15.0279 18.9773C15.0315 21.735 17.2708 23.9667 20.0296 23.9619Z" fill="#75AD56"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.3354 18.624C23.2949 18.2288 22.7583 18.1472 22.375 18.2521C22.2634 18.2826 22.146 18.299 22.0248 18.2992C21.2892 18.3005 20.6921 17.7054 20.6912 16.9702C20.691 16.8482 20.7073 16.73 20.7379 16.6178C20.8423 16.2343 20.7603 15.6973 20.365 15.6572C20.2512 15.6456 20.1357 15.6398 20.0188 15.64C18.1798 15.6432 16.6909 17.1359 16.6933 18.9741C16.6956 20.8122 18.1884 22.2998 20.0274 22.2966C21.8664 22.2934 23.3552 20.8007 23.3529 18.9626C23.3527 18.8483 23.3468 18.7353 23.3354 18.624Z" fill="#75AD56"/>
    </svg>,
    promotionRequest: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#2B3D4F"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 8.99422C24.7826 8.99191 28.8095 12.1769 30.0054 16.5092C31.1238 16.601 32.0025 17.5229 32.0028 18.647L32.0035 20.7933C32.0038 21.8934 31.1628 22.8006 30.0786 22.9259C29.8061 27.2763 25.8612 30.9914 21.3701 30.9936C20.9182 30.9938 20.5518 30.6336 20.5517 30.1891C20.5516 29.7446 20.9178 29.384 21.3696 29.3838C24.9516 29.3821 28.1603 26.395 28.4372 22.9414L28.1859 22.9415L27.095 22.942L27.0941 19.7225C27.0929 15.87 23.9173 12.7484 20.0011 12.7503C16.0849 12.7522 12.9111 15.8768 12.9123 19.7293L12.9132 22.9489L11.8223 22.9494L10.3665 22.9501L10.1859 22.9502C8.98094 22.9507 8.00382 21.9903 8.00348 20.8049L8.00284 18.6585C8.00251 17.5344 8.88068 16.6117 9.99907 16.5188C11.1924 12.1854 15.2174 8.99652 20 8.99422Z" fill="#8C4484"/>
        <path d="M28.0025 19.4918C28.0039 24.1862 24.1994 27.9936 19.505 27.9959C14.8106 27.9982 11.0039 24.1944 11.0025 19.5C11.0011 14.8056 14.8056 10.9982 19.5 10.9959C24.1944 10.9936 28.0011 14.7974 28.0025 19.4918Z" fill="#F3E7FF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7791 21.3858C18.2393 21.3855 18.6125 21.732 18.6126 22.1596C18.6128 22.8722 19.2348 23.4496 20.0019 23.4492C20.769 23.4488 21.3906 22.8708 21.3904 22.1582C21.3903 21.7306 21.7633 21.3838 22.2235 21.3836C22.6838 21.3834 23.0569 21.7298 23.0571 22.1574C23.0575 23.7252 21.6899 24.9968 20.0024 24.9976C18.3148 24.9984 16.9464 23.7281 16.946 22.1604C16.9458 21.7328 17.3188 21.386 17.7791 21.3858Z" fill="#8C4484"/>
        <path d="M17.2225 18.0312C17.2227 18.6013 16.7254 19.0637 16.1117 19.064C15.4981 19.0643 15.0005 18.6024 15.0003 18.0323C15.0001 17.4622 15.4975 16.9998 16.1111 16.9995C16.7248 16.9992 17.2224 17.4611 17.2225 18.0312Z" fill="#8C4484"/>
        <path d="M25.0003 18.0274C25.0005 18.5975 24.5031 19.0599 23.8895 19.0602C23.2758 19.0605 22.7782 18.5986 22.7781 18.0285C22.7779 17.4584 23.2752 16.996 23.8889 16.9957C24.5025 16.9954 25.0001 17.4573 25.0003 18.0274Z" fill="#8C4484"/>
    </svg>,
    consultation: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#2B3D4F"/>
        <path d="M20.0183 24.5431C23.5709 24.537 25.9417 25.842 27.4798 27.2947C29.2647 28.9806 27.8221 31.4329 25.3704 31.5751C23.8725 31.6619 22.0655 31.7289 20.0276 31.7324C17.9898 31.7359 16.1826 31.6752 14.6845 31.5935C12.2325 31.4598 10.7834 29.0124 12.564 27.3204C14.0983 25.8624 16.4658 24.5492 20.0183 24.5431Z" fill="#75AD56"/>
        <ellipse rx="9.98998" ry="9.98549" transform="matrix(0.999996 -0.00172589 0.00129048 0.999997 20.0228 18.9682)" fill="#E2FFD1"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0313 25.6261C23.7096 25.6197 26.6876 22.634 26.6829 18.9573C26.6781 15.2806 23.6924 12.3052 20.0141 12.3115C16.3358 12.3179 13.3579 15.3036 13.3626 18.9803C13.3673 22.657 16.353 25.6324 20.0313 25.6261ZM20.0296 23.9619C22.7884 23.9572 25.022 21.7178 25.0184 18.9601C25.0148 16.2024 22.7755 13.9708 20.0167 13.9755C17.2579 13.9803 15.0244 16.2197 15.0279 18.9773C15.0315 21.735 17.2708 23.9667 20.0296 23.9619Z" fill="#75AD56"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.3354 18.624C23.2949 18.2288 22.7583 18.1472 22.375 18.2521C22.2634 18.2826 22.146 18.299 22.0248 18.2992C21.2892 18.3005 20.6921 17.7054 20.6912 16.9702C20.691 16.8482 20.7073 16.73 20.7379 16.6178C20.8423 16.2343 20.7603 15.6973 20.365 15.6572C20.2512 15.6456 20.1357 15.6398 20.0188 15.64C18.1798 15.6432 16.6909 17.1359 16.6933 18.9741C16.6956 20.8122 18.1884 22.2998 20.0274 22.2966C21.8664 22.2934 23.3552 20.8007 23.3529 18.9626C23.3527 18.8483 23.3468 18.7353 23.3354 18.624Z" fill="#75AD56"/>
    </svg>,
    promotion: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#2B3D4F"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 8.99422C24.7826 8.99191 28.8095 12.1769 30.0054 16.5092C31.1238 16.601 32.0025 17.5229 32.0028 18.647L32.0035 20.7933C32.0038 21.8934 31.1628 22.8006 30.0786 22.9259C29.8061 27.2763 25.8612 30.9914 21.3701 30.9936C20.9182 30.9938 20.5518 30.6336 20.5517 30.1891C20.5516 29.7446 20.9178 29.384 21.3696 29.3838C24.9516 29.3821 28.1603 26.395 28.4372 22.9414L28.1859 22.9415L27.095 22.942L27.0941 19.7225C27.0929 15.87 23.9173 12.7484 20.0011 12.7503C16.0849 12.7522 12.9111 15.8768 12.9123 19.7293L12.9132 22.9489L11.8223 22.9494L10.3665 22.9501L10.1859 22.9502C8.98094 22.9507 8.00382 21.9903 8.00348 20.8049L8.00284 18.6585C8.00251 17.5344 8.88068 16.6117 9.99907 16.5188C11.1924 12.1854 15.2174 8.99652 20 8.99422Z" fill="#8C4484"/>
        <path d="M28.0025 19.4918C28.0039 24.1862 24.1994 27.9936 19.505 27.9959C14.8106 27.9982 11.0039 24.1944 11.0025 19.5C11.0011 14.8056 14.8056 10.9982 19.5 10.9959C24.1944 10.9936 28.0011 14.7974 28.0025 19.4918Z" fill="#F3E7FF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7791 21.3858C18.2393 21.3855 18.6125 21.732 18.6126 22.1596C18.6128 22.8722 19.2348 23.4496 20.0019 23.4492C20.769 23.4488 21.3906 22.8708 21.3904 22.1582C21.3903 21.7306 21.7633 21.3838 22.2235 21.3836C22.6838 21.3834 23.0569 21.7298 23.0571 22.1574C23.0575 23.7252 21.6899 24.9968 20.0024 24.9976C18.3148 24.9984 16.9464 23.7281 16.946 22.1604C16.9458 21.7328 17.3188 21.386 17.7791 21.3858Z" fill="#8C4484"/>
        <path d="M17.2225 18.0312C17.2227 18.6013 16.7254 19.0637 16.1117 19.064C15.4981 19.0643 15.0005 18.6024 15.0003 18.0323C15.0001 17.4622 15.4975 16.9998 16.1111 16.9995C16.7248 16.9992 17.2224 17.4611 17.2225 18.0312Z" fill="#8C4484"/>
        <path d="M25.0003 18.0274C25.0005 18.5975 24.5031 19.0599 23.8895 19.0602C23.2758 19.0605 22.7782 18.5986 22.7781 18.0285C22.7779 17.4584 23.2752 16.996 23.8889 16.9957C24.5025 16.9954 25.0001 17.4573 25.0003 18.0274Z" fill="#8C4484"/>
    </svg>,
    participant: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#2B3D4F"/>
        <path d="M24.723 28.8166C27.8641 27.1304 30 23.8146 30 20C30 14.4772 25.5228 10 20 10C14.4772 10 10 14.4772 10 20C10 23.8147 12.136 27.1305 15.2772 28.8167C11.9095 30.0233 9.13878 32.4836 7.52734 35.6351C10.9472 38.3669 15.283 40 20.0002 40C24.7174 40 29.0532 38.3669 32.473 35.6352C30.8616 32.4836 28.0907 30.0232 24.723 28.8166Z" fill="#A7B9CF"/>
    </svg>,
    consultant: <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="21" cy="21" r="21" fill="#2B3D4F"/>
        <path d="M1.6709 29.2227C2.86438 26.1751 5.44112 23.8213 8.62877 22.9338C6.5667 21.8986 5.15152 19.7646 5.15152 17.3C5.15152 13.8206 7.97213 11 11.4515 11C14.9309 11 17.7515 13.8206 17.7515 17.3C17.7515 19.7646 16.3363 21.8986 14.2743 22.9338C17.269 23.7676 19.7245 25.8955 21.0004 28.677C22.2763 25.8955 24.7318 23.7676 27.7265 22.9338C25.6644 21.8986 24.2492 19.7646 24.2492 17.3C24.2492 13.8206 27.0698 11 30.5492 11C34.0286 11 36.8492 13.8206 36.8492 17.3C36.8492 19.7646 35.434 21.8986 33.372 22.9338C36.5593 23.8212 39.1358 26.1746 40.3295 29.2216C37.1302 36.7335 29.6801 41.9999 21 41.9999C12.3202 41.9999 4.87036 36.734 1.6709 29.2227Z" fill="#A7B9CF"/>
    </svg>,
    comment: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" fill="#2B3D4F"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2343 11.313C11.4615 11.5178 10.2583 12.8612 10.1384 14.6419C10.0605 15.7982 10 17.3791 10 19.5C10 21.6209 10.0605 23.2018 10.1384 24.3581C10.2583 26.1388 11.4615 27.4822 13.2343 27.687C13.8565 27.7588 14.6051 27.8261 15.5004 27.8797C15.5317 29.5309 17.4506 30.4395 18.7494 29.4005L20.5016 27.9987C23.3664 27.9843 25.3798 27.8471 26.7657 27.687C28.5385 27.4822 29.7417 26.1388 29.8616 24.3581C29.9395 23.2018 30 21.6209 30 19.5C30 17.3791 29.9395 15.7982 29.8616 14.6419C29.7417 12.8612 28.5385 11.5178 26.7657 11.313C25.3002 11.1438 23.1333 11 20 11C16.8667 11 14.6998 11.1438 13.2343 11.313Z" fill="#A7B9CF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8933 15.59C15.0766 15.6195 14.5 16.2546 14.5 17.0719C14.5 17.8253 15.0021 18.4031 15.7549 18.4354C16.5612 18.4699 17.8737 18.5 20 18.5C22.0122 18.5 23.2955 18.4731 24.1108 18.4409C24.9433 18.4081 25.5 17.7716 25.5 16.9385C25.5 16.1705 25.0129 15.5869 24.2458 15.5506C23.4395 15.5124 22.1269 15.4857 20 15.5083C17.9913 15.5295 16.7088 15.5605 15.8933 15.59ZM15.8249 21.0402C15.0294 21.076 14.5 21.7037 14.5 22.5C14.5 23.2963 15.0294 23.924 15.8249 23.9598C16.3382 23.983 17.0415 24 18 24C18.9585 24 19.6618 23.983 20.1751 23.9598C20.9706 23.924 21.5 23.2963 21.5 22.5C21.5 21.7037 20.9706 21.076 20.1751 21.0402C19.6618 21.017 18.9585 21 18 21C17.0415 21 16.3382 21.017 15.8249 21.0402Z" fill="#2B3D4F"/>
    </svg>
};

const TITLES = {
    companyRole: 'роль',
    materialView: 'курсы',
    participantStatus: 'статус',
    eventParticipation: 'вебинар',
    promotionRequest: 'профессиональная поддержка',
    consultation: 'консультация',
    promotion: 'профессиональная поддержка',
    participant: 'участник'
};

const ContentWrapper = styled.div`
    background: #F3F6F9;
    position: relative;
    cursor: pointer;

    & > div {
        padding: 8px;
    }
`;

const Toggler = styled.div`
    padding: 0px !important;
    position: absolute;
    top: 5px;
    right: 5px;
    &:hover {
        svg {
            fill: #2B3D4F;
        }
    }
    svg {
        fill: #A7B9CF;
    }
`;

const EditBtn = styled.div`
    padding: 0px !important;
    position: absolute;
    top: 5px;
    right: 25px;
    width: 20px;
    height: 20px;
    &:hover {
        svg {
            fill: #2B3D4F;
        }
    }
    svg {
        fill: #A7B9CF;
    }
`;

const Comment = styled.div`
    padding-top: 0px !important;
    margin-top: 5px;
    width: 600px;
    white-space: pre-wrap;
    ${({ opened }) => !opened && css`
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-bottom: 12px !important;
    `}
`;

const StatusWrapper = styled.div`
    color: #fff;
    font-weight: bold;
    background-color: ${({ color }) => color};
    padding: 0 5px;
    display: inline-block;
`;

const MODULES = {
    consultations: 'Консультации',
    promotion: 'Продвижение на рынке труда специалистов',
    events: 'Вебинары',
    content: 'Контент'
};

const LOGGED_PARTICIPANT_FIELDS = {
    lastName: { field: 'Фамилия' },
    firstName: { field: 'Имя' },
    middleName: { field: 'Отчество' },
    email: { field: 'Email' },
    depersonalized: { field: 'Удалены персональные данные', parse: value => value ? 'да' : 'нет' },
    programFinishedAt: { field: 'Дата завершения программы', parse: value => value ? moment(value).format('DD.MM.YYYY') : null },
    expiredAt: { field: 'Дата окончания доступа', parse: value => value ? moment(value).format('DD.MM.YYYY') : null }
};

class Content extends Component {
    state = {
        opened: false
    };

    toggle = () => this.setState(prev => ({ opened: !prev.opened }));

    render() {
        return <ContentWrapper onClick={this.toggle}>
            { this.props.buttons }
            { this.props.comment && <Toggler>{ this.state.opened ? <UpOutlined /> : <DownOutlined /> }</Toggler>}
            { this.props.content }
            { this.props.comment ? <Comment opened={this.state.opened}>{ this.props.comment }</Comment> : null }
        </ContentWrapper>;
    }
}

const statuses = [
    ...PARTICIPANT_STATUSES,
    { id: 'employed', title: 'Трудоустроенный', color: '#8C4484' }
];

class ParticipantHistory extends Component {
    editStatusComment = (e, item) => {
        e.stopPropagation();

        this.props.openStatusCommentModal(item);
    }

    renderStatusContent = item => {
        const status = path(['relatedModelData', 'status'], item);
        const module = path(['relatedModelData', 'module'], item);
        const previousStatus = path(['relatedModelData', 'previousStatus'], item);

        const content = <div>
            <div>{ module && <span style={{ marginRight: 15 }}><span style={{ color: '#A7B9CF' }}>Сервис: </span><span>{ path(['title'], find(propEq('id', module), this.props.modules || [])) }</span></span> }</div>
            { status && (status !== previousStatus || path(['relatedModelData', 'previousAdditionalStatus'], item) !== path(['relatedModelData', 'additionalStatus'], item)) &&
                <div>
                    { previousStatus && previousStatus !== status && <Status status={previousStatus} additionalStatus={path(['relatedModelData', 'previousAdditionalStatus'], item)} /> }
                    { previousStatus && previousStatus !== status &&
                        <svg style={{ marginRight: 10, marginLeft: 10 }} width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0672 0.328897C10.1315 -0.374935 8.99724 0.107719 8.92345 1.2365C8.88042 1.89473 8.84214 2.73564 8.81876 3.80195C5.9907 3.8394 3.31705 3.94463 1.96885 4.02268C1.10233 4.07285 0.77297 4.58118 0.696627 5.39937C0.677968 5.59935 0.666504 5.82358 0.666504 6.07269C0.666504 6.34895 0.680602 6.59461 0.702977 6.81052C0.784054 7.59289 1.06995 8.08964 1.89727 8.16231C3.2365 8.27995 5.93996 8.44248 8.81861 8.49965C8.84198 9.56872 8.8803 10.4115 8.9234 11.071C8.99719 12.2 10.1318 12.6827 11.0677 11.9787C11.6988 11.5039 12.4981 10.868 13.4805 10.0262C14.9875 8.73485 15.8483 7.76645 16.3322 7.12108C16.778 6.52668 16.7779 5.78151 16.3321 5.18714C15.8482 4.54182 14.9874 3.57347 13.4805 2.28201C12.4979 1.43983 11.6984 0.80374 11.0672 0.328897Z" fill="#2B3D4F"/>
                        </svg>
                    }
                    <Status status={status} additionalStatus={path(['relatedModelData', 'additionalStatus'], item)} />
                </div>
            }
            { <div>
                <span style={{ color: '#A7B9CF' }}>
                    { item.action === 'edited' && path(['relatedModelData', 'data', 'edited', 'comment', 'new'], item) !== path(['relatedModelData', 'data', 'edited', 'comment', 'old'], item)
                        ? 'комментарий изменен'
                        : item.action === 'removed'
                            ? 'комментарий удален'
                            : null
                    }
                </span>
            </div> }
        </div>;

        return <Content
            content={content}
            comment={path(['relatedModelData', 'data', 'edited', 'comment', 'new'], item) || item.relatedModelData.comment}
            buttons={item.action !== 'removed' && <EditBtn onClick={e => this.editStatusComment(e, { ...item.relatedModelData, participant: item.participant })}><EditOutlined /></EditBtn>} />;
    }

    renderEventContent = item => {
        const status = path(['relatedModelData', 'status'], item);
        const eventTheme = path(['relatedModelData', 'eventTheme'], item);
        const id = path(['relatedModelData', 'eventId'], item);
        const statusData = find(propEq('id', status), EVENT_PARTICIPANT_STATUSES);

        const content = item.action === 'removed' ? <div>
            <span>отменил запись на вебинар: <span
                style={{ color: '#62B6CC', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => this.props.openEventViewModal({ eventId: id })}>
                { eventTheme }
            </span></span>
        </div> : item.action === 'created' ? <div>
            <span>записался на вебинар: <span
                style={{ color: '#62B6CC', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => this.props.openEventViewModal({ eventId: id })}>
                { eventTheme }
            </span></span>
        </div> : <div>
            <div><span>добавил статус для вебинара: <span
                style={{ color: '#62B6CC', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => this.props.openEventViewModal({ eventId: id })}>
                { eventTheme }
            </span></span></div>
            <StatusWrapper color={statusData.color}>{ statusData.value }</StatusWrapper>
        </div>;

        return <Content content={content} comment={item.relatedModelData.comment} />;
    }

    renderPromotionContent = item => {
        const status = path(['relatedModelData', 'status'], item);
        const theme = path(['relatedModelData', 'theme'], item);
        const id = path(['relatedModelData', 'id'], item);
        const statusData = PROMOTION_STATUSES[status];
        const oldStatus = path(['relatedModelData', 'data', 'edited', 'status', 'old'], item);
        const newStatus = path(['relatedModelData', 'data', 'edited', 'status', 'new'], item);

        const content = <div>
            <div><span>{item.action === 'created' ? 'создал запрос' :
                path(['relatedModelData', 'data', 'edited', 'comment'], item) ? 'ответил на запрос' : 'изменен запрос'}: <Link
                style={{ color: '#62B6CC', textDecoration: 'underline' }}
                to={`/participants/${this.props.id}/promotion/${id}`}>
                { this.props.getPromotionRequestsThemes.data[theme] }
            </Link></span></div>
            { path(['relatedModelData', 'data', 'edited', 'status'], item) ?
                <div>
                    { oldStatus && <StatusWrapper color={PROMOTION_STATUSES[oldStatus].background}>{ PROMOTION_STATUSES[oldStatus].text }</StatusWrapper> }
                    { oldStatus && <svg style={{ marginRight: 10, marginLeft: 10 }} width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.0672 0.328897C10.1315 -0.374935 8.99724 0.107719 8.92345 1.2365C8.88042 1.89473 8.84214 2.73564 8.81876 3.80195C5.9907 3.8394 3.31705 3.94463 1.96885 4.02268C1.10233 4.07285 0.77297 4.58118 0.696627 5.39937C0.677968 5.59935 0.666504 5.82358 0.666504 6.07269C0.666504 6.34895 0.680602 6.59461 0.702977 6.81052C0.784054 7.59289 1.06995 8.08964 1.89727 8.16231C3.2365 8.27995 5.93996 8.44248 8.81861 8.49965C8.84198 9.56872 8.8803 10.4115 8.9234 11.071C8.99719 12.2 10.1318 12.6827 11.0677 11.9787C11.6988 11.5039 12.4981 10.868 13.4805 10.0262C14.9875 8.73485 15.8483 7.76645 16.3322 7.12108C16.778 6.52668 16.7779 5.78151 16.3321 5.18714C15.8482 4.54182 14.9874 3.57347 13.4805 2.28201C12.4979 1.43983 11.6984 0.80374 11.0672 0.328897Z" fill="#2B3D4F"/>
                    </svg>}
                    { <StatusWrapper color={PROMOTION_STATUSES[newStatus].background}>{ PROMOTION_STATUSES[newStatus].text }</StatusWrapper> }
                </div> :
                <StatusWrapper color={statusData.background}>{ statusData.text }</StatusWrapper>
            }
        </div>;

        return <Content content={content} comment={item.action === 'created' ? item.relatedModelData.text : path(['relatedModelData', 'data', 'edited', 'comment', 'new'], item)} />;
    }

    renderMaterialContent = item => {
        const content = <div>
            <div><span style={{ color: '#A7B9CF' }}>Этап:</span> { path(['relatedModelData', 'title'], item) } { path(['relatedModelData', 'data', 'stage', 'status'], item) === 'finished' && '(курс завершен)' }</div>
        </div>;

        return <Content content={content} />;
    }

    renderRoleContent = item => {
        const content = <div>
            <div><span>{ path(['relatedModelData', 'name'], item) }</span></div>
            { !!pathOr([], ['relatedModelData', 'data', 'edited', 'modules', 'added'], item).length &&
                <div><span style={{ color: '#A7B9CF' }}>Добавлены модули:</span> { pathOr([], ['relatedModelData', 'data', 'edited', 'modules', 'added'], item).reduce((res, cur, index) => res + (index ? `, ${MODULES[cur]}` : MODULES[cur]), '') }</div>
            }
            { !!pathOr([], ['relatedModelData', 'data', 'edited', 'modules', 'removed'], item).length &&
                <div><span style={{ color: '#A7B9CF' }}>Удалены модули:</span> { pathOr([], ['relatedModelData', 'data', 'edited', 'modules', 'removed'], item).reduce((res, cur, index) => res + (index ? `, ${MODULES[cur]}` : MODULES[cur]), '') }</div>
            }
        </div>;

        return <Content content={content} />;
    }

    renderConsultationContent = item => {
        const start = path(['relatedModelData', 'start'], item);
        const end = path(['relatedModelData', 'end'], item);

        const content = <div>
            <span>{ item.action === 'created' ? 'записался на консультацию' : item.action === 'removed' ? 'отменил консультацию' : 'изменил дату и время консультации'}</span>
            <div><small>{ moment(start).format('DD.MM.YYYY') }, { moment(start).format('HH:mm') }-{ moment(end).format('HH:mm') }</small></div>
        </div>;

        return <Content content={content} comment={item.relatedModelData.comment} />;
    }

    renderPromotionDataContent = item => {
        const duration = path(['relatedModelData', 'duration'], item);
        const start = path(['relatedModelData', 'start'], item);
        const end = path(['relatedModelData', 'end'], item);
        const active = path(['relatedModelData', 'active'], item);

        const content = item.action === 'created' ? <div>
            <div><span>Активировано продвижение ({duration || 0} {pluralize(duration, '', 'день', 'дня', 'дней')})</span></div>
            <div><small>{ moment(start).format('DD.MM.YYYY') }-{ moment(end).format('DD.MM.YYYY') }</small></div>
            <StatusWrapper color={active ? '#549E38' : '#A7B9CF'}>{ active ? 'активирован' : 'не активирован' }</StatusWrapper>
        </div> : <div>
            <div><span>Изменено продвижение ({duration || 0} {pluralize(duration, '', 'день', 'дня', 'дней')})</span></div>
            <div><small>{ moment(start).format('DD.MM.YYYY') }-{ moment(end).format('DD.MM.YYYY') }</small></div>
            <StatusWrapper color={active ? '#549E38' : '#A7B9CF'}>{ active ? 'активирован' : 'не активирован' }</StatusWrapper>
        </div>;

        return <Content content={content} comment={item.relatedModelData.comment} />;
    }

    renderParticipantContent = item => {
        const oldConsultant = path(['relatedModelData', 'data', 'edited', 'consultant', 'old', 'name'], item);
        const isEmpty = path(['relatedModelData', 'data', 'edited'], item) === undefined;
        const newStatus = path(['relatedModelData', 'data', 'edited', 'status', 'new'], item);
        const oldStatus = path(['relatedModelData', 'data', 'edited', 'status', 'old'], item);
        const statusData = find(propEq('id', newStatus), statuses);
        const previousStatusData = find(propEq('id', oldStatus), statuses);

        const content = item.action === 'created' ? <div>
            <span>добавлен</span>
        </div> : <Fragment>
            { path(['relatedModelData', 'data', 'edited', 'status'], item) ? <div>
                <div><span>Изменен статус</span></div>
                { oldStatus && oldStatus !== newStatus && <StatusWrapper color={previousStatusData.color}>{ previousStatusData.title }</StatusWrapper> }
                { oldStatus && oldStatus !== newStatus &&
                    <svg style={{ marginRight: 10, marginLeft: 10 }} width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.0672 0.328897C10.1315 -0.374935 8.99724 0.107719 8.92345 1.2365C8.88042 1.89473 8.84214 2.73564 8.81876 3.80195C5.9907 3.8394 3.31705 3.94463 1.96885 4.02268C1.10233 4.07285 0.77297 4.58118 0.696627 5.39937C0.677968 5.59935 0.666504 5.82358 0.666504 6.07269C0.666504 6.34895 0.680602 6.59461 0.702977 6.81052C0.784054 7.59289 1.06995 8.08964 1.89727 8.16231C3.2365 8.27995 5.93996 8.44248 8.81861 8.49965C8.84198 9.56872 8.8803 10.4115 8.9234 11.071C8.99719 12.2 10.1318 12.6827 11.0677 11.9787C11.6988 11.5039 12.4981 10.868 13.4805 10.0262C14.9875 8.73485 15.8483 7.76645 16.3322 7.12108C16.778 6.52668 16.7779 5.78151 16.3321 5.18714C15.8482 4.54182 14.9874 3.57347 13.4805 2.28201C12.4979 1.43983 11.6984 0.80374 11.0672 0.328897Z" fill="#2B3D4F"/>
                    </svg>
                }
                <StatusWrapper color={statusData.color}>{ statusData.title }</StatusWrapper>
            </div> : !isEmpty && <div>
                { path(['relatedModelData', 'data', 'edited', 'consultant'], item) && <div><span style={{ color: '#A7B9CF' }}>Изменен консультант</span></div>}
                { path(['relatedModelData', 'data', 'edited', 'consultant'], item) && <div>
                    { oldConsultant }
                    { oldConsultant && <svg style={{ marginRight: 10, marginLeft: 10 }} width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.0672 0.328897C10.1315 -0.374935 8.99724 0.107719 8.92345 1.2365C8.88042 1.89473 8.84214 2.73564 8.81876 3.80195C5.9907 3.8394 3.31705 3.94463 1.96885 4.02268C1.10233 4.07285 0.77297 4.58118 0.696627 5.39937C0.677968 5.59935 0.666504 5.82358 0.666504 6.07269C0.666504 6.34895 0.680602 6.59461 0.702977 6.81052C0.784054 7.59289 1.06995 8.08964 1.89727 8.16231C3.2365 8.27995 5.93996 8.44248 8.81861 8.49965C8.84198 9.56872 8.8803 10.4115 8.9234 11.071C8.99719 12.2 10.1318 12.6827 11.0677 11.9787C11.6988 11.5039 12.4981 10.868 13.4805 10.0262C14.9875 8.73485 15.8483 7.76645 16.3322 7.12108C16.778 6.52668 16.7779 5.78151 16.3321 5.18714C15.8482 4.54182 14.9874 3.57347 13.4805 2.28201C12.4979 1.43983 11.6984 0.80374 11.0672 0.328897Z" fill="#2B3D4F"/>
                    </svg>}
                    { path(['relatedModelData', 'data', 'edited', 'consultant', 'new', 'name'], item) }
                </div>}
            </div>}
            { any(key => !!path(['relatedModelData', 'data', 'edited', key], item), keys(LOGGED_PARTICIPANT_FIELDS)) &&
                <div>
                    { filter(key => !!path(['relatedModelData', 'data', 'edited', key], item), keys(LOGGED_PARTICIPANT_FIELDS)).map(key => {
                        const oldValue = path(['relatedModelData', 'data', 'edited', key, 'old'], item);
                        const newValue = path(['relatedModelData', 'data', 'edited', key, 'new'], item);

                        return LOGGED_PARTICIPANT_FIELDS[key] ? <div key={`field-${key}`}><span style={{ color: '#A7B9CF' }}>Изменено поле "{ LOGGED_PARTICIPANT_FIELDS[key].field }":</span> { LOGGED_PARTICIPANT_FIELDS[key].parse ? LOGGED_PARTICIPANT_FIELDS[key].parse(oldValue) : oldValue }
                        { oldValue && <svg style={{ marginRight: 10, marginLeft: 10 }} width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0672 0.328897C10.1315 -0.374935 8.99724 0.107719 8.92345 1.2365C8.88042 1.89473 8.84214 2.73564 8.81876 3.80195C5.9907 3.8394 3.31705 3.94463 1.96885 4.02268C1.10233 4.07285 0.77297 4.58118 0.696627 5.39937C0.677968 5.59935 0.666504 5.82358 0.666504 6.07269C0.666504 6.34895 0.680602 6.59461 0.702977 6.81052C0.784054 7.59289 1.06995 8.08964 1.89727 8.16231C3.2365 8.27995 5.93996 8.44248 8.81861 8.49965C8.84198 9.56872 8.8803 10.4115 8.9234 11.071C8.99719 12.2 10.1318 12.6827 11.0677 11.9787C11.6988 11.5039 12.4981 10.868 13.4805 10.0262C14.9875 8.73485 15.8483 7.76645 16.3322 7.12108C16.778 6.52668 16.7779 5.78151 16.3321 5.18714C15.8482 4.54182 14.9874 3.57347 13.4805 2.28201C12.4979 1.43983 11.6984 0.80374 11.0672 0.328897Z" fill="#2B3D4F"/>
                        </svg>}
                        { LOGGED_PARTICIPANT_FIELDS[key].parse ? LOGGED_PARTICIPANT_FIELDS[key].parse(newValue) : newValue }</div> : null;
                    })}
                </div>
            }
        </Fragment>;

        return <Content content={content} comment={item.relatedModelData.comment} />;
    }

    renderContent = item => {
        const content = {
            participantStatus: this.renderStatusContent,
            eventParticipation: this.renderEventContent,
            promotionRequest: this.renderPromotionContent,
            materialView: this.renderMaterialContent,
            companyRole: this.renderRoleContent,
            consultation: this.renderConsultationContent,
            promotion: this.renderPromotionDataContent,
            participant: this.renderParticipantContent
        };

        return content[item.relatedModelClassName] ? content[item.relatedModelClassName](item) : null;
    }

    isCommentChange = item => {
        return item.relatedModelClassName === 'participantStatus' &&
            item.relatedModelData.status === item.relatedModelData.previousStatus &&
            item.relatedModelData.additionalStatus === item.relatedModelData.previousAdditionalStatus;
    };

    getColumns = () => {
        return [
            {
                key: 'icon',
                dataIndex: 'relatedModelClassName',
                width: 40,
                render: (relatedModelClassName, item) => {
                    if (path(['relatedModelData', 'data', 'edited', 'consultant'], item)) {
                        return ICONS.consultant;
                    }

                    if (this.isCommentChange(item)) {
                        return ICONS.comment;
                    }

                    return ICONS[relatedModelClassName];
                }
            },
            {
                key: 'createdAt',
                dataIndex: 'createdAt',
                render: createdAt => <span>{ moment(createdAt).format('DD.MM.YYYY') } в { moment(createdAt).format('HH:mm') }</span>
            },
            {
                key: 'title',
                dataIndex: 'relatedModelClassName',
                render: (relatedModelClassName, item) => {
                    if (path(['relatedModelData', 'data', 'edited', 'consultant'], item)) {
                        return <strong>консультант</strong>;
                    }

                    if (this.isCommentChange(item)) {
                        return <strong>комментарий</strong>;
                    }

                    return <strong>{ TITLES[relatedModelClassName] }</strong>;
                }
            },
            {
                key: 'content',
                width: 635,
                render: this.renderContent
            },
            {
                key: 'user',
                render: item => {
                    const user = path(['_embedded', 'updatedBy'], item) || path(['_embedded', 'createdBy'], item);

                    return user ? <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            style={{ marginRight: 10 }}
                            size='small'
                            icon={<UserOutlined />}
                            src={user.photo ? getFileView(user.photo) : null} />
                        <div>
                            <div>{ `${ user.lastName } ${ user.firstName }` }</div>
                            { user.roles ? (
                                <div style={{ color: '#A7B9CF', fontSize: 12 }}>
                                    {(includes(ADMIN, user.roles || []) || includes(SUPER_ADMIN, user.roles || []))
                                        ? 'Консультант'
                                        : 'Участник'
                                    }
                                </div>
                            ) : null}
                        </div>
                    </div> : null;
                }
            }
        ];
    }

    render() {
        const { id } = this.props;

        return <TableList
            action={getParticipantsHistory}
            columns={this.getColumns()}
            showHeader={false}
            refreshActions={[POST_PARTICIPANT_STATUSES, PUT_PARTICIPANT_STATUSES, DELETE_PARTICIPANT_STATUSES, PUT_SET_CONSULTANT]}
            staticFilter={{ id }}
            filterForm={<ParticipantHistoryFilter modules={this.props.modules} />}
            buttons={() => <div />}
            rowKey={item => `${item.relatedModelData.id}-${item.updatedAt}-${item.createdAt}`} />;
    }
}

export default asyncConnect({
    getPromotionRequestsThemes: getPromotionRequestsThemes
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, null, { openEventViewModal, openStatusCommentModal })(ParticipantHistory);
