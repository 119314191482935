import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postTestThemes, putTestThemes } from '../../actions/asyncActions';
import TestForm from '../forms/TestForm';

class TestModal extends Component {
    render() {
        const { modal, params, putTestThemes, postTestThemes } = this.props;

        return <Modal
            {...modal}
            title={`${params ? 'Редактировать' : 'Добавить'} тестирование`}
            footer={null}>
            <TestForm
                formAction={params ? putTestThemes : postTestThemes}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postTestThemes: postTestThemes
        .withSuccessHandler(({ close }) => {
            message.success('Тестирование успешно добавлено');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
        putTestThemes: putTestThemes
        .withSuccessHandler(({ close }) => {
            message.success('Тестирование успешно изменено');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(TestModal);
