import React, { Component } from 'react';
import { Switch, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

class EnabledSwitch extends Component {
    onChange = value => {
        this.props.patchAction.dispatch({
            id: this.props.id,
            data: [
                { op: 'replace', path: '/enabled', value }
            ]
        });
    }

    onChangeItem = value => {
        this.props.putAction.dispatch({
            ...this.props.item,
            [this.props.itemField || 'enabled']: value
        });
    }

    render() {
        const { enabled, patchAction, putAction, disabled } = this.props;

        return <Switch
            checked={!!enabled}
            loading={(patchAction || putAction).meta.pending}
            onChange={putAction ? this.onChangeItem : this.onChange}
            disabled={disabled} />;
    }
}

export default withAsyncActions(({ action, actionItem }) => ({
    [actionItem ? 'putAction' : 'patchAction']: (action || actionItem)
        .withParams(({ id }) => ({ id }))
        .withErrorHandler(() => message.error('Не удалось изменить состояние'))
        .withOptions({ resetOnUnmount: true }),
}))(EnabledSwitch)
