import React, { Component } from 'react';
import { Avatar, message } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { path } from 'ramda';
import { CopyOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';

import TableList from './TableList';
import { getEventParticpation } from '../actions/asyncActions';
import pluralize from 'pluralize-ru';
import { getFileView } from '../constants/urls';
import { withState } from 'recompose';
import { StatusSelect } from './modals/EventPartcipantsModal';
import { DELETE_EVENT_PARTICIPATION, PUT_EVENT_PARTICIPATION_ATTEND, PUT_EVENT_PARTICIPATION_ATTEND_CANCEL } from '../constants/actionTypes';
import { getOnlineIcon } from '../utils/events';
import { Centered } from './CompanyWebinars';
import locationIcon from '../assets/img/location.svg';

class ParticipantEvents extends Component {
    onCopy = (_, result) => {
        result ? message.success('Ссылка успешно скопирована') : message.error('Не удалось скопировать ссылку');
    };

    getColumns = () => {
        return [
            {
                key: 'theme',
                dataIndex: ['_embedded', 'event'],
                render: item => <div>
                    <span style={{ fontWeight: 600 }}>{ path(['_embedded', 'theme', 'title'], item) }</span>
                    { item.link ?
                        (getOnlineIcon(item.link) ? <CopyToClipboard text={item.link} onCopy={this.onCopy}>
                            <Centered><img src={getOnlineIcon(item.link).icon} alt='icon' /> { getOnlineIcon(item.link).text } <CopyOutlined style={{ color: '#C2CFE0', cursor: 'pointer', marginLeft: 5 }} /></Centered>
                        </CopyToClipboard> : <CopyToClipboard text={item.link} onCopy={this.onCopy}>
                            <CopyOutlined style={{ color: '#C2CFE0', cursor: 'pointer', marginLeft: 5 }} />
                        </CopyToClipboard>) : null
                    }
                    { item.locationName && <Centered><img src={locationIcon} alt='location' /> { item.locationName }</Centered>}
                </div>
            },
            {
                key: 'date',
                align: 'center',
                dataIndex: ['_embedded', 'event', 'date'],
                render: (date, item) => <div>
                    <div style={{ color: '#333333', textAlign: 'center' }}>{ moment(date).format('DD.MM.YYYY') }</div>
                    <div style={{ fontSize: 12, color: '#333333', textAlign: 'center' }}>{ moment(date).format('HH:mm') } - { moment(date).add(item.duration, 'minutes').format('HH:mm') }</div>
                </div>
            },
            {
                key: 'duration',
                dataIndex: ['_embedded', 'event', 'duration'],
                align: 'center',
                render: duration => {
                    const hours = Math.floor(duration / 60);
                    const minutes = duration - hours * 60;

                    return <div style={{ fontSize: 11, fontWeight: 600, color: '#fff', background: '#90A0B7', padding: '3px 8px', display: 'inline-block' }}>
                        { `${hours ? `${hours} ${pluralize(hours, '', 'час', 'часа', 'часов')} ` : ''}${minutes || ''} ${pluralize(minutes, '', 'минута', 'минуты', 'минут')}` }
                    </div>;
                }
            },
            {
                key: 'speakers',
                dataIndex: ['_embedded', 'event', '_embedded', 'speakers'],
                render: (speakers, item) => (speakers || []).map(speaker =>
                    <div key={`speaker-${item.id}-${speaker.id}`} style={{ marginBottom: 5 }}>
                        <Avatar size='small' src={speaker.photo ? getFileView(speaker.photo) : null} icon={<UserOutlined />} /> { speaker.lastName } { speaker.firstName } { speaker.middleName || '' }
                    </div>
                )
            },
            {
                key: 'status',
                render: item => {
                    return <StatusSelect item={item} edit={this.props.edit} setEdit={this.props.setEdit} />
                }
            }
        ]
    }

    render() {
        return <TableList
            action={getEventParticpation}
            columns={this.getColumns()}
            payload={{ limit: 0 }}
            staticFilter={{ participant: this.props.participant.id }}
            refreshActions={[PUT_EVENT_PARTICIPATION_ATTEND, PUT_EVENT_PARTICIPATION_ATTEND_CANCEL, DELETE_EVENT_PARTICIPATION]}
            showHeader={false} />;
    }
}

export default withState('edit', 'setEdit', null)(ParticipantEvents);
