import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Button } from 'antd';
import * as yup from 'yup';
import { MailOutlined } from '@ant-design/icons';

import { withFormWrapper } from '@meconsultant/common';
import Input from './formComponents/Input';

class RecoveryForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting } = this.props;

        return <Fragment>
            <Field
                name='email'
                component={Input}
                placeholder='Email'
                htmlType='email'
                icon={<MailOutlined />} />
            <Button
                type='primary'
                htmlType='submit'
                size='large'
                disabled={isSubmitting}>
                Отправить
            </Button>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    email: yup.string().email().required()
});

export default withFormWrapper(RecoveryForm, {
    validationSchema
});
