import { message, Modal, Rate } from 'antd';
import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';
import styled from 'styled-components';

import { deleteParticipantFeedback, postParticipantFeedbackConsultation, postParticipantFeedbackEvent, putParticipantFeedback } from '../../actions/asyncActions';
import ParticipantFeedbackForm from '../forms/ParticipantFeedbackForm';

const RateStyled = styled(Rate)`
    font-size: 30px;
`;

const Comment = styled.div`
    margin-top: 32px;
    background: #ECF0F4;
    padding: 16px 24px;
    position: relative;
    font-size: 16px;
    font-style: italic;
    color: #2c3d4e;
    svg {
        width: 30px;
        height: 20px;
        position: absolute;
        opacity: .8;
        &.left {
            top: -9px;
            left: -6px;
        }
        &.right {
            bottom: -9px;
            right: -6px;
        }
    }
`;

class ParticipantFeedbackModal extends Component {
    deleteFeedback = () => {
        this.props.deleteParticipantFeedback.dispatch(this.props.params.item.id);
    }

    render() {
        const { modal, params, postParticipantFeedbackEvent, putParticipantFeedback, close } = this.props;

        return <Modal
            {...modal}
            footer={null}
            title={params.header || 'Добавить отзыв'}>
            { params.view ?
                <Fragment>
                    <div><RateStyled disabled value={params.item.rating} /></div>
                    {params.item.comment &&
                        <Comment>
                            <svg className="left" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.08329 0.0832928H7.33329L6.16663 2.41663V5.91663H9.66663V2.41663H7.91663L9.08329 0.0832928ZM3.24996 0.0832928H1.49996L0.333293 2.41663V5.91663H3.83329V2.41663H2.08329L3.24996 0.0832928Z" fill="#2B3D4F"/>
                            </svg>
                            { params.item.comment }
                            <svg className="right" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.916646 5.91665H2.66665L3.83331 3.58331V0.083313H0.333313V3.58331H2.08331L0.916646 5.91665ZM6.74998 5.91665H8.49998L9.66665 3.58331V0.083313H6.16665V3.58331H7.91665L6.74998 5.91665Z" fill="#2B3D4F"/>
                            </svg>
                        </Comment>
                    }
                </Fragment> :
                <ParticipantFeedbackForm
                    formAction={(!params.item || params.create) ? postParticipantFeedbackEvent : putParticipantFeedback}
                    item={params.item}
                    close={close}
                    deleteFeedback={this.deleteFeedback} />
            }
        </Modal>;
    }
}

export default withAsyncActions({
    postParticipantFeedbackEvent: postParticipantFeedbackEvent
        .withSuccessHandler(({ close, params, postParticipantFeedbackEvent }) => {
            message.success('Отзыв успешно добавлен');
            params.onSuccess && params.onSuccess(postParticipantFeedbackEvent.data);
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    postParticipantFeedbackConsultation: postParticipantFeedbackConsultation
        .withSuccessHandler(({ close, params, postParticipantFeedbackConsultation }) => {
            message.success('Отзыв успешно добавлен');
            params.onSuccess && params.onSuccess(postParticipantFeedbackConsultation.data);
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putParticipantFeedback: putParticipantFeedback
        .withSuccessHandler(({ close, params, putParticipantFeedback }) => {
            message.success('Отзыв успешно изменен');
            params.onSuccess && params.onSuccess(putParticipantFeedback.data);
            close();
        })
        .withErrorHandler(() => message.error('Не удалось изменить отзыв'))
        .withOptions({ resetOnUnmount: true }),
    deleteParticipantFeedback: deleteParticipantFeedback
        .withSuccessHandler(({ close }) => {
            message.success('Отзыв успешно удален');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось удалить отзыв'))
        .withOptions({ resetOnUnmount: true })
})(ParticipantFeedbackModal);
