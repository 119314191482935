export const ADMIN_CLIENT_ROLES = {
    clientAdmin: 'Администратор клиента',
    clientManager: 'Менеджер клиента'
};

export const ADMIN_CLIENT_ROLES_OPTIONS = [
    { name: ADMIN_CLIENT_ROLES.clientAdmin, id: true },
    { name: ADMIN_CLIENT_ROLES.clientManager, id: false }
];

export const SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ADMIN = 'ROLE_ADMIN';
export const CLIENT_ADMIN = 'ROLE_CLIENT_ADMIN';
export const CLIENT_MANAGER = 'ROLE_CLIENT_MANAGER';

export const ADMINS = [SUPER_ADMIN, ADMIN];
export const CLIENT_ADMINS = [...ADMINS, CLIENT_ADMIN];
