import React, { Component, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Button, Tabs, Popconfirm, message, Tag, Tooltip, Dropdown, Menu, Breadcrumb } from 'antd';
import { path, isEmpty, find, propEq, includes, last, contains, toLower, filter, keys, any } from 'ramda';
import { takeEvery } from 'redux-saga/effects';
import { ArrowLeftOutlined, EditOutlined, SendOutlined } from '@ant-design/icons';
import moment from 'moment';

import { getParticipant, getMood, getUser, putDepersonalize, getParticipantStatistic, putResendEmailParticipant, getParticipantContentStages, putSetConsultant, postPromotions, deleteParticipant, getParticipantsStatuses, postParticipantStatuses, postParticipantSendResume } from '../actions/asyncActions';
import { openParticipantModal, openMaterialViewModal, openParticipantStatusModal, openConsultantSelectModal, openAddMeetingModal } from '../actions/modalActions';
import { POST_PARTICIPANT_STATUSES, POST_PROMOTIONS, PUT_DEPERSONALIZE, PUT_PARTICIPANT, PUT_SET_CONSULTANT, POST_PARTICIPANT_DOCUMENTS, DELETE_PARTICIPANT_DOCUMENTS } from '../constants/actionTypes';
import { ADMIN, SUPER_ADMIN } from '../constants/roles';
import { connect } from 'react-redux';
import ParticipantEvents from './ParticipantEvents';
import { PARTICIPANT_STATUSES } from '../constants/statuses';
import Promotion from './Promotion';
import ProfileInfo from './participant/ProfileInfo';
import { Route } from 'react-router';
import ParticipantModules from './ParticipantModules';
import ParticipantPromotion from './ParticipantPromotion';
import Statistic from './participant/Statistic';
import ParticipantHistory from './ParticipantHistory';
import StagesInfo from './participant/StagesInfo';
import { StyledBreadcrumb } from './Company';
import { Link } from 'react-router-dom';
import BreadcrumbContext from './contexts/BreadcrumbContext';
import { withStateHandlers } from 'recompose';

const StatusComponent = ({ stateStatus, stateAdditionalStatus, status, additionalStatus, setStatus, setAdditioanlStatus, editable, getParticipantsStatuses: { data }, user: { roles }, item, postParticipantStatuses }) => {
    const statusData = find(propEq('id', stateStatus), PARTICIPANT_STATUSES);
    const additionalStatusData = find(propEq('id', stateAdditionalStatus), PARTICIPANT_STATUSES);

    const edit = (status, additionalStatus) => {
        postParticipantStatuses.dispatch({
            ...item,
            status,
            additionalStatus,
            ...(status !== item.status && status === 'finished' ? {
                programFinishedAt: status !== item.status && status === 'finished' ? moment().toDate() : null
            } : {})
        });
    }

    useEffect(() => {
        setStatus(status);
    }, [status, setStatus]);

    useEffect(() => {
        setAdditioanlStatus(additionalStatus);
    }, [additionalStatus, setAdditioanlStatus]);

    if (!statusData) {
        return null;
    }

    const getStatuses = () => {
        const filterStatuses = items => {
            return filter(statusItem => !statusItem.hide && (!statusItem.roles || any(role => includes(role, statusItem.roles)), roles || []), items);
        }

        return filterStatuses(keys(data).map(key => ({
            ...find(propEq('id', key), PARTICIPANT_STATUSES),
            value: key,
            label: find(propEq('id', key), PARTICIPANT_STATUSES).title,
            children: filterStatuses(data[key].map(id => ({
                ...find(propEq('id', id), PARTICIPANT_STATUSES),
                value: id,
                label: (find(propEq('id', id), PARTICIPANT_STATUSES).secondLevel || find(propEq('id', id), PARTICIPANT_STATUSES).title)
            })))
        })));
    }

    const tag = <Tooltip title={`${statusData.title}${stateAdditionalStatus ? ` -> ${additionalStatusData.secondLevel || additionalStatusData.title}` : ''}`}>
        <Tag color={ stateAdditionalStatus ? additionalStatusData.color : statusData.color }>
            { stateAdditionalStatus ? additionalStatusData.icon : statusData.icon } { toLower(stateAdditionalStatus ? (additionalStatusData.secondLevel || additionalStatusData.title) : statusData.title) }
        </Tag>
    </Tooltip>;

    const getMenu = () => {
        if (!editable) {
            return null;
        }

        const items = getStatuses();

        return <Menu selectedKeys={[status, `${status}-${additionalStatus}`]}>
            { items.map(item => item.children && item.children.length ?
                <Menu.SubMenu key={item.value} title={item.label}>
                    { item.children.map(child =>
                        <Menu.Item key={`${item.value}-${child.value}`} onClick={() => edit(item.value, child.value)}>
                            { child.label }
                        </Menu.Item>
                    )}
                </Menu.SubMenu>:
                <Menu.Item key={item.value} onClick={() => edit(item.value)}>
                    { item.label }
                </Menu.Item>
            )}
        </Menu>;
    }

    return editable ? <Dropdown trigger={['click']} overlay={getMenu()}>{ tag }</Dropdown> : tag;
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export const Status = withStateHandlers(({ status, additionalStatus }) => ({
    stateStatus: status,
    stateAdditionalStatus: additionalStatus
}), {
    setStatus: () => stateStatus => ({ stateStatus }),
    setAdditioanlStatus: () => stateAdditionalStatus => ({ stateAdditionalStatus })
})(asyncConnect(({ editable }) => ({
    getParticipantsStatuses: getParticipantsStatuses
        .withOptions({ dispatchOnMount: !!editable, resetOnUnmount: true }),
    postParticipantStatuses: postParticipantStatuses
        .withParams(({ item }) => ({ id: item ? item.participant : null }))
        .withSuccessHandler(({ postParticipantStatuses: { data }, setStatus, setAdditioanlStatus }) => {
            setStatus(data.status);
            setAdditioanlStatus(data.additionalStatus);
            message.success('Статус успешно изменен');
        })
        .withErrorHandler(() => message.error('Не удалось изменить статус'))
        .withOptions({ resetOnUnmount: true })
}), stateToProps)(StatusComponent));

class Participant extends Component {
    static propTypes = {
        getParticipant: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object,
        openCandidateFormModal: PropTypes.func,
        openParticipantModal: PropTypes.func,
        openMaterialViewModal: PropTypes.func,
        getParticipantStages: PropTypes.object,
        getMood: PropTypes.object
    };

    state = {
        visible: false,
        breadcrumb: null
    };

    deleteData = () => {
        this.props.putDepersonalize.dispatch(this.props.match.params.id);
    }

    setConsultant = () => {
        this.props.putSetConsultant.dispatch({
            participants: [this.props.match.params.id],
            consultant: this.props.user.id
        });
    }

    handleMenuClick = e => {
        this.setState({ visible: e.key === 'depersonalized' || e.key === 'delete' });
    };

    handleVisibleChange = visible => this.setState({ visible });

    activePromotion = () => {
        this.props.postPromotions.dispatch({ id: this.props.match.params.id, type: 'promotion' });
    }

    onChange = key => {
        this.props.history.push(`/participants/${this.props.match.params.id}/${key === 'item_0' ? '' : key}`);
    };

    delete = () => this.props.deleteParticipant.dispatch(this.props.match.params.id);

    sendToExperium = () => {
        this.props.postParticipantSendResume.dispatch(this.props.match.params.id);
    }

    getTabs = () => {
        const { getParticipant: { data }, user, match } = this.props;

        return filter(({ hide }) => !hide, [
            { tab: 'Профиль', key: '', children: <Route path='/participants/:id' render={props => <ProfileInfo {...props} getParticipant={this.props.getParticipant} />} /> },
            { tab: 'Модули', key: 'modules', children: <Route path='/participants/:id/modules' render={props => <ParticipantModules {...props} getParticipant={this.props.getParticipant} id={match.params.id} />} /> },
            { tab: 'Курсы', key: 'courses', children: <Route path='/participants/:id/courses' render={props => <StagesInfo {...props} data={data} getParticipantStages={this.props.getParticipantStages} />} />, hide: (!(includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles)) || data.depersonalized) },
            { tab: 'Профессиональная поддержка', key: 'promotion', children: <Fragment>
                <Route path='/participants/:participant/promotion/:promotion' render={props => <ParticipantPromotion {...props} />} />
                <Route path='/participants/:id/promotion' exact render={props => <Promotion {...props} participant={match.params.id} />} />
            </Fragment> },
            { tab: 'Вебинары', key: 'webinars', children: <Route path='/participants/:id/webinars' render={props => <ParticipantEvents {...props} participant={data} />} /> },
            { tab: 'Статистика', key: 'statistic', children: <Route path='/participants/:id/statistic' render={props => <Statistic {...props} getParticipant={this.props.getParticipant} />} /> },
            { tab: 'История', key: 'history', children: <Route path='/participants/:id/history' render={props => <ParticipantHistory {...props} data={data} id={match.params.id} modules={data.modules} />} /> },
        ]);
    };

    getCurrentTab = activeKey => {
        const tab = find(propEq('key', activeKey), this.getTabs());

        return <Link to={`/participants/${this.props.match.params.id}/${tab.key}`}>
            { tab.tab }
        </Link>;
    }

    setBreadcrumb = breadcrumb => this.setState({ breadcrumb });

    render() {
        const { history, getParticipant: { data, meta }, openParticipantModal, openParticipantStatusModal, user, match, openConsultantSelectModal, getParticipantStatistic, location } = this.props;
        const hasData = !isEmpty(data);
        const isAdmin = includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles);
        const activeKey = contains('promotion', location.pathname) ? 'promotion' : last(location.pathname.replace(match.url, '').split('/'));
        const company = path(['_embedded', 'company'], data);
        const menu = <Menu onClick={this.handleMenuClick}>
            <Menu.Item
                key='status'
                onClick={() => openParticipantStatusModal({
                    participant: data.id,
                    status: data.status,
                    modules: data.modules,
                    additionalStatus: data.additionalStatus
                })}>
                Добавить отчет
            </Menu.Item>
            {isAdmin &&
                <Menu.Item
                    key='consultant'
                    onClick={() => user.superAdmin ? openConsultantSelectModal({
                        participants: [match.params.id],
                        consultant: data.consultant,
                        hideAmount: true
                    }) : this.setConsultant()}>
                    Консультант
                </Menu.Item>
            }
            {find(propEq('id', 'promotion'), data.modules || []) && !path(['data', 'promotion'], getParticipantStatistic) &&
                <Menu.Item key='promotion' onClick={this.activePromotion}>
                    Активировать сервис "Продвижение"
                </Menu.Item>
            }
            {data.resume &&
                <Menu.Item key='experiumLetter' onClick={this.sendToExperium}>
                    Отправить в Experium
                </Menu.Item>
            }
            {!data.depersonalized &&
                <Menu.Item key='depersonalized'>
                    <Popconfirm
                        title='Вы уверены, что хотите удалить персональные данные участника?'
                        okText='Да'
                        cancelText='Нет'
                        onConfirm={this.deleteData}
                        placement='left'>
                        <div style={{ padding: '5px 12px', margin: '-5px -12px' }}>Удалить персональные данные</div>
                    </Popconfirm>
                </Menu.Item>
            }
            {user.superAdmin &&
                <Menu.Item key='delete'>
                    <Popconfirm
                        title='Вы уверены, что хотите удалить участника?'
                        okText='Да'
                        cancelText='Нет'
                        onConfirm={this.delete}
                        placement='left'>
                        <div style={{ padding: '5px 12px', margin: '-5px -12px' }}>Удалить участника</div>
                    </Popconfirm>
                </Menu.Item>
            }
        </Menu>;

        return <BreadcrumbContext.Provider value={{ setBreadcrumb: this.setBreadcrumb, breadcrumb: this.state.breadcrumb }}>
            <div>
                { meta.lastSucceedAt &&
                    <StyledBreadcrumb>
                        <Breadcrumb.Item>
                            <Link to='/'>Компании</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/company/${company.id}`}>{ company.name }</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/company/${company.id}/participants`}>Участники</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/participants/${match.params.id}`}>{data.lastName} {data.firstName} {data.middleName ? `${data.middleName}` : ''}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            { this.getCurrentTab(activeKey) }
                        </Breadcrumb.Item>
                        { this.state.breadcrumb }
                    </StyledBreadcrumb>
                }
                <div className='toolbar-row detail-toolbar'>
                    <Button shape='circle' icon={<ArrowLeftOutlined />} onClick={history.goBack} />
                    {hasData &&
                        <Fragment>
                            <h1>
                                <span style={{ marginRight: 10 }}>{data.lastName} {data.firstName} {data.middleName ? `${data.middleName}` : ''}</span>
                                <Status status={data.status} additionalStatus={data.additionalStatus} />
                                {
                                    !data.firstLogin && data.email && <Tooltip title='Отправить письмо регистрации повторно'>
                                        <Button
                                            style={{ marginLeft: 5 }}
                                            icon={<SendOutlined />}
                                            onClick={() => this.props.putResendEmailParticipant.dispatch(data.id)} />
                                    </Tooltip>
                                }
                            </h1>
                            <div className='toolbar-actions'>
                                { (includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles)) &&
                                    <Button style={{ marginRight: 10 }} onClick={() => this.props.openAddMeetingModal({ participant: data })}>Добавить встречу</Button>
                                }
                                <Dropdown.Button
                                    trigger={['click']}
                                    type='primary'
                                    onClick={() => openParticipantModal(data)}
                                    overlay={menu}
                                    onVisibleChange={this.handleVisibleChange}
                                    visible={this.state.visible}
                                >
                                    <EditOutlined /> Редактировать
                                </Dropdown.Button>
                            </div>
                        </Fragment>
                    }
                </div>
                <Tabs
                    animated={{ tabPane: false }}
                    activeKey={activeKey}
                    onChange={this.onChange}>
                    { this.getTabs().map(tab =>
                        <Tabs.TabPane tab={tab.tab} key={tab.key}>
                            { tab.children }
                        </Tabs.TabPane>
                    )}
                </Tabs>
            </div>
        </BreadcrumbContext.Provider>;
    }
}

export default asyncConnect({
    getParticipant: getParticipant
        .withPayload(({ match }) => match.params.id)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
        .withSaga(function* (getProps) {
            yield takeEvery([
                toSuccess(PUT_PARTICIPANT),
                toSuccess(PUT_DEPERSONALIZE),
                toSuccess(POST_PARTICIPANT_STATUSES),
                toSuccess(PUT_SET_CONSULTANT),
                toSuccess(POST_PARTICIPANT_DOCUMENTS),
                toSuccess(DELETE_PARTICIPANT_DOCUMENTS),
        ], () => {
                getProps().getParticipant.refresh();
            });
        })
})(connect(stateToProps)(asyncConnect(({ user, getParticipant }) => ({
    getMood: getMood
        .withPayload(({ match }) => ({
            q: { participant: match.params.id }
        }))
        .withOptions({ dispatchOnMount: includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles), resetOnUnmount: true, dispatchOnUpdate: includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles) }),
    getParticipantStages: getParticipantContentStages
        .withPayload(({ match }) => match.params.id)
        .withOptions({ dispatchOnMount: (includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles)) && !getParticipant.data.depersonalized, dispatchOnUpdate: (includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles)) && !getParticipant.data.depersonalized, resetOnUnmount: true }),
    putDepersonalize: putDepersonalize
        .withSuccessHandler(() => message.success('Персональные данные участника успешно удалены'))
        .withErrorHandler(() => message.error('Не удалось удалить персональные данные'))
        .withOptions({ resetOnUnmount: true }),
    postParticipantSendResume: postParticipantSendResume
        .withSuccessHandler(() => message.success('Информация об участнике успешно отправлена'))
        .withErrorHandler(() => message.error('Не удалось отправить информацию об участнике'))
        .withOptions({ resetOnUnmount: true }),
    deleteParticipant: deleteParticipant
        .withSuccessHandler(({ history, getParticipant }) => {
            message.success('Участник успешно удален');
            history.replace(`/company/${getParticipant.data.company}/participants`);
        })
        .withErrorHandler(() => message.error('Не удалось удалить участника'))
        .withOptions({ resetOnUnmount: true }),
    getParticipantStatistic: getParticipantStatistic
        .withPayload(({ match }) => match.params.id)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(POST_PROMOTIONS)], () => {
                getProps().getParticipantStatistic.refresh();
            });
        }),
    putResendEmailParticipant: putResendEmailParticipant
        .withSuccessHandler(() => message.success('Письмо регистрации успешно отправлено'))
        .withOptions({ resetOnUnmount: true }),
    putSetConsultant: putSetConsultant
        .withParams(() => ({ type: 'list' }))
        .withSuccessHandler(() => message.success('Консультант успешно назначен'))
        .withErrorHandler(() => message.error('Не удалось назначить консультанта'))
        .withOptions({ resetOnUnmount: true }),
    postPromotions: postPromotions
        .withSuccessHandler(() => message.success('Сервис "Персональный менеджер и продвижение" успешно активирован'))
        .withErrorHandler(() => message.error('Не удалось активировать сервис "Персональный менеджер и продвижение"'))
        .withOptions({ resetOnUnmount: true })
}), null, { openParticipantModal, openMaterialViewModal, openParticipantStatusModal, openConsultantSelectModal, openAddMeetingModal })(Participant)));
