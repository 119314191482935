import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    .inline-edit {
        min-height: 21px;
        position: relative;
        &:empty::before {
            content: '${({ placeholder }) => placeholder}';
            position: absolute;
            color: #aaa;
            font-weight: 400;
        }
    }
`;

export default class InlineEdit extends Component {
    state = {
        editing: false
    };

    toggleEdit = e => {
        e.stopPropagation();

        if (!this.state.editing) {
            this.edit();
        }
    }

    edit = () => this.setState({ editing: true }, () => this.element.focus());

    save = () => this.setState({ editing: false }, () => {
        if (this.isValueChanged()) {
            this.props.onChange(this.element.textContent);
        }
    });

    cancel = () => this.setState({ editing: false });

    isValueChanged = () => this.props.value !== this.element.textContent;

    handleKeyDown = e => {
        switch (e.key) {
            case 'Enter':
                this.save();
                break;
            default:
                return false;
        }
    }

    render() {
        const { Element, value, placeholder } = this.props;

        return <Wrapper placeholder={placeholder}>
            <Element
                className='inline-edit'
                onClick={this.toggleEdit}
                contentEditable={this.state.editing}
                suppressContentEditableWarning
                ref={node => this.element = node}
                onBlur={this.save}
                onKeyDown={this.handleKeyDown}>
                { value }
            </Element>
        </Wrapper>;
    }
}
