import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm } from 'antd';
import { includes } from 'ramda';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';

import { deleteEventThemes, getEventThemes, getUser } from '../actions/asyncActions';
import { openWebinarModal } from '../actions/modalActions';
import { DELETE_EVENT_THEMES, POST_EVENT_THEMES, PUT_EVENT_THEMES } from '../constants/actionTypes';
import { CLIENT_MANAGER } from '../constants/roles';
import WebinarsFilter from './filters/WebinarsFilter';
import TableList from './TableList';

class WebinarThemes extends Component {
    getColumns = () => {
        return [
            {
                title: 'Название',
                dataIndex: 'title',
                key: 'title'
            },
            {
                title: 'Описание',
                dataIndex: 'description',
                key: 'description'
            },
            {
                key: 'actions',
                align: 'right',
                render: item => !includes(CLIENT_MANAGER, this.props.user.roles || []) &&
                    <Button.Group>
                        <Button
                            icon={<EditOutlined />}
                            onClick={() => this.props.openWebinarModal(item)} />
                        <Popconfirm
                            title='Вы уверены, что хотите удалить тему вебинара?'
                            okText='Да'
                            cancelText='Нет'
                            placement='left'
                            onConfirm={() => this.props.deleteEventThemes.dispatch(item.id)}>
                            <Button icon={<DeleteOutlined />} danger />
                        </Popconfirm>
                    </Button.Group>
            }
        ];
    }

    getButtons = () => {
        const { openWebinarModal, user } = this.props;

        return !includes(CLIENT_MANAGER, user.roles || []) && <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => openWebinarModal()}>
            Добавить
        </Button>;
    }

    render() {
        return <TableList
            action={getEventThemes}
            columns={this.getColumns()}
            buttons={this.getButtons()}
            refreshActions={[POST_EVENT_THEMES, PUT_EVENT_THEMES, DELETE_EVENT_THEMES]}
            filterForm={<WebinarsFilter />} />
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    deleteEventThemes: deleteEventThemes
        .withSuccessHandler(() => message.success('Тема вебинара была успешна удалена'))
        .withOptions({ resetOnUnmount: true })
}, stateToProps, { openWebinarModal })(WebinarThemes);
