import React, { Component } from 'react';
import styled from 'styled-components';
import { assocPath } from 'ramda';

import InlineEdit from './InlineEdit';

const ThemeTitle = styled.div`
    color: rgba(0, 0, 0, 0.65);
    font-weight: bold;
    padding: 0 10px;
    cursor: text;
`;

export default class MaterialTheme extends Component {
    onChange = value => {
        this.props.putStage.dispatch(assocPath(['themes', this.props.index, 'title'], value, this.props.stage));
    }

    render() {
        const { theme, placeholder } = this.props;

        return <InlineEdit
            value={theme.title}
            onChange={this.onChange}
            Element={ThemeTitle}
            placeholder={placeholder} />;
    }
}
