import { message, Modal } from 'antd';
import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import { postParticipantStatuses } from '../../actions/asyncActions';
import ParticipantStatusForm from '../forms/ParticipantStatusForm';

class ParticipantStatusModal extends Component {
    render() {
        const { modal, params: { modules, ...item }, close } = this.props;

        return <Modal
            {...modal}
            title={<strong>Добавление отчета</strong>}
            footer={null}>
            <ParticipantStatusForm
                formAction={this.props.postParticipantStatuses}
                item={item}
                modules={modules}
                close={close} />
        </Modal>;
    }
}

export default withAsyncActions({
    postParticipantStatuses: postParticipantStatuses
        .withSuccessHandler(({ close }) => {
            message.success('Отчет успешно добавлен');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось добавить отчет'))
        .withOptions({ resetOnUnmount: true })
})(ParticipantStatusModal);
