import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Button, Badge, Spin } from 'antd';
import { takeEvery } from 'redux-saga/effects';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';
import { propEq, find } from 'ramda';
import { BarsOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';

import { getFAQ, putFAQ } from '../actions/asyncActions';
import Toolbar from './Toolbar';
import { openFAQModal } from '../actions/modalActions';
import { POST_FAQ, PUT_FAQ } from '../constants/actionTypes';
import withStateHolder from './hocs/withStateHolder';
import { reorder } from '../utils/lists';
import { LoaderWrapper } from './Stages';

class FAQ extends Component {
    static propTypes = {
        getFAQ: PropTypes.object,
        openFAQModal: PropTypes.func,
        state: PropTypes.object,
        setState: PropTypes.func,
        putFAQ: PropTypes.object,
    };

    renderItem = (item, index) => {
        return <Draggable key={item.id} draggableId={item.id} index={index}>
            { (provided, snapshot) =>
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={cx('faq-item', { draggable: snapshot.isDragging })}>
                    <div className='faq-item-left'>
                        <BarsOutlined />
                        <Badge status={item.published ? 'success' : 'default'} />
                    </div>
                    <div className='faq-item-content'>
                        <h4>{ item.question }</h4>
                        <span>{ item.answer }</span>
                    </div>
                    <Button
                        shape='circle'
                        icon={<EditOutlined />}
                        onClick={() => this.props.openFAQModal(item)} />
                </div>
            }
        </Draggable>;
    }

    renderCreateButton = () => {
        return <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => this.props.openFAQModal()}>
            Добавить
        </Button>;
    }

    onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const { state, setState, putFAQ } = this.props;
        const item = find(propEq('id', result.draggableId), state.items)
        const order = result.destination.index + 1;

        if (order !== item.order) {
            const items = reorder(state.items, result.source.index, result.destination.index);

            setState({ items });
            putFAQ.dispatch({ ...item, order });
        }
    }

    render() {
        const { state: { items }, getFAQ: { meta }} = this.props;

        return <Fragment>
            <Toolbar buttons={this.renderCreateButton()} />
            { meta.pending && !meta.lastSucceedAt ?
                <LoaderWrapper><Spin /></LoaderWrapper> :
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId='droppable'>
                        { provided =>
                            <div ref={provided.innerRef} className='faq-container'>
                                { items.map(this.renderItem) }
                            </div>
                        }
                    </Droppable>
                </DragDropContext>
            }
        </Fragment>;
    }
}

export default withStateHolder(
    asyncConnect({
        getFAQ: getFAQ
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
            .withSuccessHandler(({ getFAQ: { data: { items }}, setState }) => setState({ items }))
            .withSaga(function* (getProps) {
                yield takeEvery([toSuccess(POST_FAQ), toSuccess(PUT_FAQ)], () => {
                    getProps().getFAQ.refresh();
                });
            }),
        putFAQ: putFAQ
            .withOptions({ resetOnUnmount: true })
    }, null, { openFAQModal })(FAQ),
    { items: [] }
);
