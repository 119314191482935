import React, { Component } from 'react';
import { Modal, Button, Checkbox, message } from 'antd';
import styled from 'styled-components';
import { all, any, assocPath, dissocPath, forEach, path, prop } from 'ramda';

const Block = styled.div`
    border: 1px solid #2B3D4F;
    margin-bottom: 24px;
`;

const BlockHeader = styled.div`
    background: #2B3D4F;
    padding: 8px;
    .ant-checkbox-wrapper {
        color: #fff;
    }
`;

const BlockBody = styled.div`
    padding: 16px 8px;
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin: 0 16px 0 0;
    }
`;

class ParticipantsTableSettingsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            settings: props.settings || {}
        };
    }

    isChecked = key => {
        return !path(['hide', key], this.state.settings);
    }

    isCheckedAll = keys => {
        return all(key => !path(['hide', key], this.state.settings), keys);
    }

    toggle = key => {
        this.setState(prev => ({ settings: this.isChecked(key) ? assocPath(['hide', key], true, prev.settings) : dissocPath(['hide', key], prev.settings) }));
    }

    toggleAll = keys => {
        this.setState(prev => {
            let settings = prev.settings;

            forEach(key => {
                settings = this.isCheckedAll(keys) ? assocPath(['hide', key], true, settings) : dissocPath(['hide', key], settings);
            }, keys);

            return { settings };
        });
    }

    isIndeterminate = keys => {
        return !this.isCheckedAll(keys) && any(key => this.isChecked(key), keys);
    }

    renderBlock = (title, data) => {
        return <Block>
            <BlockHeader>
                <Checkbox
                    indeterminate={this.isIndeterminate(data.map(prop('id')))}
                    checked={this.isCheckedAll(data.map(prop('id')))}
                    onChange={() => this.toggleAll(data.map(prop('id')))}>
                    { title }
                </Checkbox>
            </BlockHeader>
            <BlockBody>
                { data.map(item => item.nextRow ?
                    <span key={item.id}><br /><Checkbox checked={this.isChecked(item.id)} onChange={() => this.toggle(item.id)}>{ item.title }</Checkbox></span> :
                    <Checkbox key={item.id} checked={this.isChecked(item.id)} onChange={() => this.toggle(item.id)}>{ item.title }</Checkbox>
                )}
            </BlockBody>
        </Block>;
    }

    showAll = () => {
        this.setState({ settings: {} });
    }

    save = () => this.props.save(this.state.settings);

    render() {
        const { close } = this.props;

        return <div>
            { this.renderBlock(
                'Персональные и контактные данные',
                [
                    { title: 'Консультант', id: 'consultant' },
                    { title: 'Участник', id: 'participant' },
                    { title: 'Контакты', id: 'contacts' },
                    { title: 'Роль', id: 'role' },
                    { title: 'Город', id: 'location' }
                ]
            )}
            { this.renderBlock(
                'Отраслевая и функциональная специализация',
                [
                    { title: 'Отрасль', id: 'sector' },
                    { title: 'Должность', id: 'position' },
                    { title: 'Функция', id: 'function' }
                ]
            )}
            { this.renderBlock(
                'Активность на портале',
                [
                    { title: 'Регистрация на портале', id: 'registrationAt' },
                    { title: 'Начало программы', id: 'startedAt' },
                    { title: 'Первый вход', id: 'firstLogin' },
                    { title: 'Последний вход', id: 'lastLogin' },
                    { title: 'Окончание доступа', id: 'endedAt' },
                    { title: 'Завершение программы', id: 'programFinishedAt', nextRow: true }
                ]
            )}

            { this.renderBlock(
                'Комментарии и взаимодействие',
                [
                    { title: 'Дата последнего комментария по участнику', id: 'lastCommentDate' },
                    { title: 'Краткое содержание последнего комментария', id: 'lastComment' },
                    { title: 'Дата запланированной активности', id: 'lastActivity', nextRow: true }
                ]
            )}
            { this.renderBlock(
                'Вебинары / семинары',
                [
                    { title: 'Тема вебинаров', id: 'theme' },
                    { title: 'Фактическое количество вебинаров, которое посетил участник', id: 'eventsAmount' }
                ]
            )}
            { this.renderBlock(
                'Консультации',
                [
                    { title: 'Консультации', id: 'consultations' },
                    { title: 'Фактическое количество консультаций, которые посетил участник', id: 'consultationAmount' }
                ]
            )}
            { this.renderBlock(
                'Профессиональная поддержка',
                [
                    { title: 'Планируемая дата начала профессиональной поддержки', id: 'promotionPlan' },
                    { title: 'Фактическая дата начала профессиональной поддержки', id: 'promotionStart' },
                    { title: 'Фактическая дата завершения профессиональной поддержки', id: 'promotionEnd' },
                    { title: 'Фактическое оказание дней оказанной профессиональной поддержки', id: 'promotionDays' },
                    { title: 'Количество обращений со стороны участника', id: 'promotionRequestsAmount' }
                ]
            )}
            { this.renderBlock(
                'Курсы',
                [
                    { title: 'Прогресс по прохождению курса', id: 'content' }
                ]
            )}
            { this.renderBlock(
                'Обратная связь',
                [
                    { title: 'Вебинары/семинары', id: 'eventsFeedback' },
                    { title: 'Консультации', id: 'consultationsFeedback' },
                    { title: 'Профессиональная поддержка/программы', id: 'promotionFeedback' }
                ]
            )}
            { this.renderBlock(
                'Примечание',
                [
                    { title: 'Комментарий', id: 'comment' }
                ]
            )}
             <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <Button
                        type='primary'
                        htmlType='submit'
                        onClick={this.save}>
                        СОХРАНИТЬ
                    </Button>
                    <Button style={{ marginLeft: 10 }} onClick={close}>
                        ОТМЕНА
                    </Button>
                </div>
                <Button onClick={this.showAll}>
                    ПОКАЗАТЬ ВСЕ
                </Button>
            </div>
        </div>;
    }
}

class ParticipantsTableSettingsModal extends Component {
    save = settings => {
        this.props.params.onSave(settings);
        message.success('Настройки успешно сохранены');
        this.props.close();
    }

    render() {
        const { modal, params, close } = this.props;

        return <Modal
            {...modal}
            title={<strong>Настройки отображения колонок</strong>}
            width={980}
            footer={null}
            destroyOnClose>
            <ParticipantsTableSettingsForm
                settings={params.settings}
                close={close}
                save={this.save} />
        </Modal>;
    }
}

export default ParticipantsTableSettingsModal;
