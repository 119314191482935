"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MOTIVATION_ANALISYS_FIELDS = void 0;
var MOTIVATION_ANALISYS_FIELDS = [{
  name: 'leader',
  label: 'Непосредственный руководитель',
  subLabel: 'Ваш непосредственный руководитель: взаимодействие,  отношения с ним.'
}, {
  name: 'tasks',
  label: 'Выполняемые задачи',
  subLabel: 'Ваша ежедневная работа, включая интересность задач, целей и обязанностей; удовлетворенность результатом своей работы.'
}, {
  name: 'recognition',
  label: 'Признание',
  subLabel: 'Ценность своей работы для организации. Насколько справедливо оцениваются Ваши усилия, достижения и вклад в работу.'
}, {
  name: 'professionalDevelopment',
  label: 'Профессиональное развитие',
  subLabel: 'Возможности развивать профессиональные навыки  для выполнения должностных обязанностей в настоящий момент и в будущем.'
}, {
  name: 'brand',
  label: 'Бренд компании',
  subLabel: 'Восприятие компании на рынке. Считается ли компания престижным работодателем.'
}, {
  name: 'perspective',
  label: 'Перспектива',
  subLabel: 'Возможность развития, роста внутри компании и продвижения по карьерной лестнице.'
}, {
  name: 'topManagment',
  label: 'Топ-менеджмент',
  subLabel: 'Высшее руководство компании (топ-менеджеров, начальников) и стратегию компании на рынке.'
}, {
  name: 'attitude',
  label: 'Отношение',
  subLabel: 'Атмосфера в компании, отношения с коллегами, психологический климат.'
}, {
  name: 'reliability',
  label: 'Надежность компании',
  subLabel: 'Насколько Вы можете быть уверены в завтрашнем дне, работая в компании.'
}, {
  name: 'communication',
  label: 'Коммуникации',
  subLabel: 'Качество взаимодействия между отделами.'
}, {
  name: 'oraganization',
  label: 'Организация процесса',
  subLabel: 'Насколько процессы внутри компании позволяют  Вам быть эффективным.'
}, {
  name: 'benefits',
  label: 'Бенефиты',
  subLabel: 'Льготы и социальный пакет, предоставляемые компанией.'
}, {
  name: 'collective',
  label: 'Коллектив',
  subLabel: 'Отношения с коллегами, которые Вас окружают.'
}, {
  name: 'workplace',
  label: 'Рабочее место/Условия труда',
  subLabel: 'Удобство и обустроенность рабочих мест, а так же созданных условий труда. Ресурсы, доступные Вам для того, чтобы быть эффективным.'
}, {
  name: 'remuneration',
  label: 'Вознаграждение',
  subLabel: 'Справедливость вознаграждения по отношению к затраченным усилиям.'
}, {
  name: 'balance',
  label: 'Баланс работы и личной жизни',
  subLabel: 'Существующий баланс работы и личной жизни.'
}];
exports.MOTIVATION_ANALISYS_FIELDS = MOTIVATION_ANALISYS_FIELDS;