import { Col, Row } from 'antd';
import { includes } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser } from '../../actions/asyncActions';
import { ADMIN, SUPER_ADMIN } from '../../constants/roles';
import { Content } from './ModulesStatistic';
import Participants from './participants/Participants';
import ParticipantsAverageLifetime from './participants/ParticipantsAverageLifetime';
import Statuses from './participants/Statuses';

class ParticipantsStatistic extends Component {
    render() {
        const { company, user } = this.props;
        const isAdmin = includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles);

        return <Content>
            <Row gutter={16}>
                <Col sm={12}>
                    <Participants id={company} />
                </Col>
                <Col sm={12}>
                    <Statuses id={company} />
                </Col>
            </Row>
            { isAdmin && <ParticipantsAverageLifetime id={company} /> }
        </Content>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(ParticipantsStatistic);
