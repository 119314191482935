import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import TableList from './TableList';
import { getFeedbackResults } from '../actions/asyncActions';
import FeedbackFilter from './filters/FeedbackFilter';

export default class FeedbackList extends Component {
    getColumns = () => {
        return [
            {
                title: 'Материал',
                key: 'material',
                dataIndex: ['_embedded', 'material', 'title']
            },
            {
                title: 'Участник',
                key: 'participant',
                dataIndex: ['_embedded', 'participant'],
                render: ({ lastName, firstName, middleName, id }) =>
                    <Link to={`/participants/${id}`}>{ lastName } { firstName } { middleName || '' }</Link>
            },
            {
                title: 'Оценка полезности',
                key: 'usefulness',
                dataIndex: 'usefulness',
                align: 'center'
            },
            {
                title: 'Комментарий (к оценке полезности)',
                key: 'usefulnessComment',
                dataIndex: 'usefulnessComment'
            },
            {
                title: 'Оценка удобности',
                key: 'convenience',
                dataIndex: 'convenience',
                align: 'center'
            },
            {
                title: 'Комментарий (к оценке удобности)',
                key: 'convenienceComment',
                dataIndex: 'convenienceComment'
            }
        ];
    }

    render() {
        return <TableList
            action={getFeedbackResults}
            columns={this.getColumns()}
            filterForm={<FeedbackFilter />} />;
    }
}
