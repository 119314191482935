import React, { Component, Fragment } from 'react';
import { withFormWrapper } from '@meconsultant/common';
import { Button, Form } from 'antd';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import * as yup from 'yup';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import Input from './formComponents/Input';

const ScaleItem = styled.div`
    display: flex;
    align-items: center;
    .ant-form-item {
        width: 100%;
        margin-bottom: 0;
        margin-right: 10px;
    }
`;

class MotivationSettingsForm extends Component {
    renderScaleFields = ({ fields }) => {
        return <div>
            { fields.map((name, index) =>
                <ScaleItem key={name}>
                    <Field
                        name={name}
                        component={Input}
                        displayError={false} />
                    <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => fields.remove(index)} />
                </ScaleItem>
            )}
            <Button icon={<PlusOutlined />} onClick={() => fields.push()}>
                Добавить значение
            </Button>
        </div>
    }

    render() {
        const { isSubmitting } = this.props;

        return <Fragment>
            <Form.Item label='Шкала'>
                <FieldArray name='motivation.scale'>
                    { this.renderScaleFields }
                </FieldArray>
            </Form.Item>
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    motivation: yup.object().shape({
        scale: yup.array().of(yup.string().required())
    })
});

export default withFormWrapper(MotivationSettingsForm, {
    validationSchema,
    mapPropsToValues: ({ data }) => ({
        ...(data || {}),
        id: 'editor'
    })
});
