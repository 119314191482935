import React, { Component, Fragment } from 'react';
import { Modal, Upload, message, Alert, Select } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { pathEq, toPairs, includes, filter } from 'ramda';
import { InboxOutlined } from '@ant-design/icons';

import { getCompanies, getUser, postParticipantsFile } from '../../actions/asyncActions';
import { connect } from 'react-redux';
import { CLIENT_ADMIN, CLIENT_MANAGER } from '../../constants/roles';

class ParticipantsImportModal extends Component {
    state = {
        company: null
    };

    beforeUpload = (file) => {
        const { dispatch } = this.props.postParticipantsFile;
        const fd = new FormData();
        fd.append('file', file);
        dispatch({company: this.state.company, file: fd});

        return false;
    }

    renderError = () => {
        const { postParticipantsFile: { meta: { error }}} = this.props;
        let message = null;

        if (!error.data.errors) {
            message = 'Не удалось импортировать респондентов';
        } else if (pathEq([0, 'message'], 'Wrong headers', error.data.errors)) {
            message = `Не найдены обязательные заголовки: ${error.data.errors[0].attributes.required}`;
        } else {
            message = error.data.errors.map(({ message, attributes }, index) => {
                const rows = filter(([row]) => !isNaN(Number(row)), toPairs(attributes));

                return <div key={`err-${index}`}>
                    <div>{ message }</div>
                    { rows.map(([line, item]) =>
                        <div key={`err-${index}-line-${line}`}>
                            Строка { line }:
                            <div>{ (item.errors || []).map((err, i) => <span key={`err-${index}-line-${line}-e-${i}`}>{ err } { i < item.errors.length - 1 && ', ' }</span>)}</div>
                            <div>{ toPairs(item.columns || []).map(([colName, colErrors], i) =>
                                <div key={`err-${index}-line-${line}-col-${i}`}>
                                    Поле { colName }: { colErrors.map((errText, errIndex) => <span key={`err-${index}-line-${line}-col-${i}-e${errIndex}`}>{ errText } { errIndex < colErrors.length - 1 && ', ' }</span>)}
                                </div>
                            )}</div>
                        </div>
                    )}
                </div>;
            });
        }

        return <Alert
            type='error'
            style={{ marginBottom: 10 }}
            message={message} />
    }

    onChangeCompany = company => this.setState({ company });

    render() {
        const { modal, postParticipantsFile: { meta: { error }}, user, getCompanies } = this.props;

        return (
            <Modal
                {...modal}
                title={'Импорт участников'}
                footer={null}
            >
                { !includes(CLIENT_ADMIN, user.roles) && !includes(CLIENT_MANAGER, user.roles) &&
                    <Select
                        style={{ width: '100%', marginBottom: 15 }}
                        value={this.state.company}
                        onChange={this.onChangeCompany}
                        loading={getCompanies.meta.pending}
                        placeholder='Компания'>
                        { (getCompanies.data.items || []).map(item =>
                            <Select.Option key={item.id} value={item.id}>
                                { item.name }
                            </Select.Option>
                        )}
                    </Select>
                }
                { error && this.renderError() }
                { (includes(CLIENT_ADMIN, user.roles) || includes(CLIENT_MANAGER, user.roles) ||
                    (!includes(CLIENT_ADMIN, user.roles) && !includes(CLIENT_MANAGER, user.roles) && this.state.company)) &&
                    <Fragment>
                        <Upload.Dragger
                            showUploadList={false}
                            multiple={false}
                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            beforeUpload={this.beforeUpload}
                        >
                            <p className='ant-upload-drag-icon'><InboxOutlined /></p>
                            <p className='ant-upload-text'>Кликните или перетащите сюда файл в формате excel</p>
                        </Upload.Dragger>
                        <div className='participants-import-sample'>
                            <a href='/files/participants.xlsx'>Пример файла для импорта</a>
                        </div>
                    </Fragment>
                }
            </Modal>
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(withAsyncActions(({ user }) => ({
    getCompanies: getCompanies
        .withOptions({ dispatchOnMount: !includes(CLIENT_ADMIN, user.roles) && !includes(CLIENT_MANAGER, user.roles), resetOnUnmount: true }),
    postParticipantsFile: postParticipantsFile
        .withSuccessHandler(({ close }) => {
            message.success('Участники импортированы успешно!')
            close();
        })
        .withOptions({ resetOnUnmount: true })
}))(ParticipantsImportModal));
