import React, { Component } from 'react';
import { Modal, message, Button } from 'antd';
import { toSuccess, withAsyncActions } from 'react-async-client';
import { takeEvery } from 'redux-saga/effects';
import styled from 'styled-components';
import { CheckOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { any, find, includes, pathOr, propEq } from 'ramda';

import { getEventParticpation, getParticipants, postEventParticipation } from '../../actions/asyncActions';
import { POST_EVENT_PARTICIPATION } from '../../constants/actionTypes';
import TableStateList from '../TableStateList';
import ParticipantFilter from '../filters/ParticipantFilter';

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }
`;

class AddButtonComponent extends Component {
    onClick = () => {
        this.props.postEventParticipation.dispatch({
            participant: this.props.participant,
            event: this.props.event
        });
    }

    render() {
        const { postEventParticipation } = this.props;

        return postEventParticipation.meta.lastSucceedAt ?
            <Button type='primary' icon={<CheckOutlined />} shape='circle' /> :
            <Button
                shape='circle'
                icon={postEventParticipation.meta.pending ? <LoadingOutlined /> : <PlusOutlined />}
                disabled={postEventParticipation.meta.pending}
                onClick={this.onClick} />;
    }
}

const AddButton = withAsyncActions({
    postEventParticipation: postEventParticipation
        .withParams(({ participant }) => ({ participant }))
        .withSuccessHandler(() => message.success('Участник успешно записан'))
        .withErrorHandler(() => message.error('Не удалось записать участника'))
        .withOptions({ resetOnUnmount: true })
})(AddButtonComponent);

class EventParticipantsAddModal extends Component {
    getColumns = () => {
        return [
            {
                title: 'Участник',
                key: 'name',
                render: item => `${item.lastName} ${item.firstName} ${item.middleName || ''}`
            },
            {
                title: 'Email',
                key: 'email',
                dataIndex: 'email'
            },
            {
                title: 'Роль',
                key: 'role',
                dataIndex: ['_embedded', 'companyRole', 'name']
            },
            {
                key: 'action',
                align: 'right',
                render: item => {
                    const availableThemes = pathOr([], ['themes'], find(propEq('id', 'events'), pathOr([], ['_embedded', 'companyRole', 'modules'], item)));

                    if (!includes(this.props.params.theme, availableThemes)) {
                        return null;
                    }

                    return any(propEq('participant', item.id), this.props.getEventParticpation.data.items || []) ?
                        <Button type='primary' icon={<CheckOutlined />} shape='circle' /> :
                        <AddButton participant={item.id} event={this.props.params.event} />
                }
            }
        ];
    }

    render() {
        const { modal, params } = this.props;

        return <StyledModal
            {...modal}
            title='Добавить участника'
            footer={null}>
            <TableStateList
                action={getParticipants}
                columns={this.getColumns()}
                filterForm={({ setFilters }) => <ParticipantFilter hideCompany company={params.company} setFilters={setFilters} />}
                staticFilter={{ company: params.company, enabled: true, depersonalized: false }} />
        </StyledModal>;
    }
}

export default withAsyncActions({
    getEventParticpation: getEventParticpation
        .withParams(() => ({ type: 'add' }))
        .withPayload(({ params }) => ({
            limit: 0,
            q: {
                event: params.event
            }
        }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(POST_EVENT_PARTICIPATION)], () => {
                getProps().getEventParticpation.refresh();
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(EventParticipantsAddModal);
