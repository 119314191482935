import { connect } from 'react-redux';
import { Resume, ResumeComponent as CommonResumeComponent } from '@meconsultant/common';

import { getUser } from '../../../actions/asyncActions';

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export const ResumeComponent = connect(stateToProps)(CommonResumeComponent);

export default connect(stateToProps)(Resume);
