import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

import { withFieldWrapper } from '@meconsultant/common';

class SwitchComponent extends Component {
    static propTypes = {
        input: PropTypes.object,
        onChange: PropTypes.func
    };

    render() {
        const { input: { value }, onChange } = this.props;

        return <Switch
            onChange={onChange}
            checked={!!value} />
    }
}

export default withFieldWrapper(SwitchComponent);
