import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { putConsultationTitle } from '../../actions/asyncActions';
import ConsultationTitleForm from '../forms/ConsultationTitleForm';

class ConsultationTitleModal extends Component {
    close = () => {
        this.props.params.onClose && this.props.params.onClose();
        this.props.close();
    }

    render() {
        const { params, modal, putConsultationTitle } = this.props;

        return <Modal
            {...modal}
            onCancel={this.close}
            title='Изменить тему встречи'
            footer={null}>
            { params.item && (
                <ConsultationTitleForm
                    item={params.item}
                    isPromo={params.item.type === 'promotion'}
                    formAction={putConsultationTitle}
                />
            )}
        </Modal>;
    }
}

export default withAsyncActions({
    putConsultationTitle: putConsultationTitle
        .withSuccessHandler(({ close }) => {
            message.success('Тема консультации была успешно отредактирована');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(ConsultationTitleModal);
