"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRequiredFields = void 0;

var _ramda = require("ramda");

var getRequiredFields = function getRequiredFields(schema) {
  var requiredFields = [];

  if (!schema) {
    return requiredFields;
  }

  var fn = function fn(fields, path) {
    return (0, _ramda.forEachObjIndexed)(function (field, key) {
      var fieldPath = path ? "".concat(path, ".").concat(key) : key;
      field.fields ? fn(field.fields, fieldPath) : (0, _ramda.has)('required', field._exclusive) && requiredFields.push(fieldPath);
    }, fields);
  };

  fn(schema.fields);
  return requiredFields;
};

exports.getRequiredFields = getRequiredFields;