import { message } from 'antd';
import { pathEq } from 'ramda';
import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Link } from 'react-router-dom';
import { postRecovery } from '../actions/asyncActions';

import RecoveryForm from './forms/RecoveryForm';

class Recovery extends Component {
    render() {
        return <div>
            <RecoveryForm formAction={this.props.postRecovery} />
            <div style={{ marginTop: 15 }}>
                <Link to='/'>
                    Войти
                </Link>
            </div>
        </div>
    }
}

export default withAsyncActions({
    postRecovery: postRecovery
        .withSuccessHandler(({ history }) => {
            message.success('Ссылка на восстановление пароля была успешно отправлена');
            history.push('/');
        })
        .withErrorHandler(({ postRecovery: { meta }}) =>
            message.error(
                pathEq(['error', 'data', 'errors', 'email', 0, 'message'], 'User with that email not found.', meta) ?
                'Пользователь с данным email не существует' :
                'Не удалось отправить ссылку на восстановление пароля'
            )
        )
        .withOptions({ resetOnUnmount: true })
})(Recovery);
