
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Button } from 'antd';
import styled from 'styled-components';
import { LeftOutlined } from '@ant-design/icons';

import { pushRollbarError } from '../utils/rollbar';

const clearState = {
    error: false,
    path: '',
};

const Container = styled.div`
    text-align: center;
    background: #fff;
    padding: 30px;
`;

export default class RouteComponent extends Component {
    state = clearState;

    componentDidCatch(error) {
        this.setState({
            error: true,
            path: this.props.path,
        });

        pushRollbarError(error);
    }

    componentDidUpdate() {
        if (this.state.error && this.props.path !== this.state.path) {
            this.setState(clearState);
        }
    }

    resetError = () => {
        window.history.back();
    }

    render() {
        return this.state.error ?
            <Container>
                <p>Не удалось отобразить данные</p>
                <Button
                    onClick={this.resetError}
                    type='primary'
                    icon={<LeftOutlined />}>
                    Назад
                </Button>
            </Container>
        : <Route {...this.props} />;
    }
}
