import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { Col, Row } from 'antd';

import withFilterForm from '../hocs/withFilterForm';
import Select from '../forms/formComponents/Select';
import SearchSelect from '../forms/formComponents/SearchSelect';
import { ADMIN } from '../../constants/roles';
import { getAdmins, getEventThemes, getParticipants, getUser } from '../../actions/asyncActions';
import { includes } from 'ramda';
import { connect } from 'react-redux';

class CalendarFilter extends Component {
    render() {
        const isConsultant = includes(ADMIN, this.props.user.roles) && !this.props.user.superAdmin;
        return (
            <Row gutter={16}>
                { !isConsultant &&
                    <Col span={6}>
                        <Field
                            name='consultant'
                            component={SearchSelect}
                            action={getAdmins}
                            placeholder='Консультант'
                            namePath='name'
                            getNamePath={item => `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`}
                            filter={{ superAdmin: false }}
                            allowClear
                        />
                    </Col>
                }
                <Col span={6}>
                    <Field
                        name='participant'
                        component={SearchSelect}
                        action={getParticipants}
                        placeholder='Участник'
                        namePath='name'
                        filter={{ depersonalized: false }}
                        getNamePath={item => `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`}
                        allowClear
                    />
                </Col>
                <Col span={6}>
                    <Field
                        name='module'
                        component={Select}
                        placeholder='Сервис'
                        options={[
                            { id: 'events', name: 'Вебинары и семинары' },
                            { id: 'consultations', name: 'Консультации' }
                        ]}
                    />
                </Col>
                <Col span={6}>
                    <Field
                        name='themes'
                        component={SearchSelect}
                        placeholder='Тема'
                        action={getEventThemes}
                        namePath='title'
                        isMulti
                        allowClear />
                </Col>
            </Row>
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(withFilterForm(CalendarFilter));
