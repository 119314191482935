
import React, { Component } from 'react';
import { LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Radio, Row, Spin, Col } from 'antd';
import { asyncConnect } from 'react-async-client';
import { withStateHandlers } from 'recompose';
import styled from 'styled-components';
import moment from 'moment';
import { sort, toUpper, path, find, propEq } from 'ramda';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import ruLocale from '@fullcalendar/core/locales/ru';

import { getConsultations, getEvents } from '../actions/asyncActions';
import CalendarFilter from './filters/CalendarFilter';
import { openConsultationViewModal, openEventViewModal, openAddMeetingModal } from '../actions/modalActions';

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-weight: bold;
    .ant-radio-button-wrapper {
        border-color: #2B3D4F !important;
        &:hover {
            color: #2B3D4F !important;
        }
    }
    .ant-btn-primary,
    .ant-radio-button-wrapper-checked {
        font-weight: bold;
        background: #2B3D4F !important;
        border-color: #2B3D4F !important;
        &:hover {
            color: #fff!important;
        }
    }
    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: #2B3D4F !important;
    }
    h3 {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 0;
    }
`;

const Filters = styled(Row)`
    padding: 15px;
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 0;
`;

const ListItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    border-top: 1px solid #E8E8E8;
`;

const ListItemDescription = styled.div`
    display: flex;
    align-items: center;
`;

const ListItemDay = styled.div`
    font-size: 36px;
    font-weight: 600;
    color: #2B3D4F;
`;

const ListItemMonthWrapper = styled.div`
    text-align: left;
    margin-left: 25px;
`;

const ListItemMonth = styled.div`
    font-weight: 600;
    color: #2B3D4F;
    font-size: 18px;
`;

const ListItemTime = styled.div`
    color: #A7B9CF;
`;

const ListItemTitle = styled.div`
    font-size: 20px;
    color: #2B3D4F;
    font-weight: 600;
    textDecoration: underline;
    margin-left: 45px;
    cursor: pointer;
`;

const ListItemIcon = styled.div`
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CalendarWrapper = styled.div`
    padding: 0 15px 15px 15px;
`;

class Calendar extends Component {
    prev = () => {
        const { type } = this.props;
        const selectedDate = this.props.selectedDate.clone().subtract(1, (type === 'list' || type === 'dayGridMonth') ? 'month' : type === 'timeGridWeek' ? 'week' : 'day');

        this.props.setSelectedDate(selectedDate);
    };

    next = () => {
        const { type } = this.props;
        const selectedDate = this.props.selectedDate.clone().add(1, (type === 'list' || type === 'dayGridMonth') ? 'month' : type === 'timeGridWeek' ? 'week' : 'day');

        this.props.setSelectedDate(selectedDate);
    };

    getHeaderString = () => {
        const { selectedDate, type } = this.props;

        return toUpper((type === 'list' || type === 'dayGridMonth') ? moment(selectedDate).format('MMMM YYYY') :
            type === 'timeGridWeek' ? `${moment(selectedDate).startOf('week').format('DD MMMM YYYY')} - ${moment(selectedDate).endOf('week').format('DD MMMM YYYY')}` :
            moment(selectedDate).format('DD MMMM YYYY'));
    }

    renderConsultationListItem = (item, last) => {
        const consultant = path(['_embedded', 'consultant'], item);

        return <ListItem key={`consultation-${item.id}`} last={last}>
            <ListItemDescription>
                <ListItemDescription>
                    <ListItemDay>{ moment(item.start).format('DD') }</ListItemDay>
                    <ListItemMonthWrapper>
                        <ListItemMonth>{ moment(item.start).format('MMMM') }, { moment(item.start).format('dd') }</ListItemMonth>
                        <ListItemTime>{ moment(item.start).format('HH:mm') }-{ moment(item.end).format('HH:mm') }</ListItemTime>
                    </ListItemMonthWrapper>
                </ListItemDescription>
                <ListItemTitle onClick={() => this.props.openConsultationViewModal({ item })}>
                    { `Консультация (${consultant.lastName} ${consultant.firstName}${consultant.middleName ? ` ${consultant.middleName}` : ''})` }
                </ListItemTitle>
            </ListItemDescription>
            <ListItemIcon>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.222 0.657528C1.16848 0.846404 0.444083 1.67125 0.32801 2.73893C0.178123 4.11764 0.0192349 6.4766 0.0217969 10.2728C0.0243589 14.0691 0.186431 16.4277 0.338178 17.8061C0.455693 18.8735 1.1812 19.6969 2.23498 19.8836C3.17148 20.0496 4.5786 20.2308 6.62453 20.3517L6.62618 22.8066C6.62687 23.8158 7.79044 24.3757 8.57525 23.7444L12.6228 20.4889C17.4553 20.4595 20.2672 20.1397 21.8068 19.8637C22.8603 19.6748 23.5847 18.85 23.7008 17.7823C23.8506 16.4036 24.0095 14.0446 24.007 10.2484C24.0044 6.45218 23.8423 4.09355 23.6906 2.71515C23.5731 1.6477 22.8476 0.824335 21.7938 0.637604C20.1911 0.353597 17.21 0.0249767 12.0075 0.0302726C6.80498 0.0355685 3.82434 0.370258 2.222 0.657528Z" fill="#BA9B66" fillOpacity="0.2"/>
                    <path d="M9.6941 9.91765C9.51747 10.0853 9.43263 10.3122 9.43957 10.5982L9.43882 10.5982L9.43971 11.9275C9.44007 12.4498 9.8511 12.8729 10.3578 12.8723L11.2156 12.8715C11.7223 12.8709 12.1327 12.4471 12.1324 11.9248L12.1322 11.6323L12.2607 11.5703C12.8359 11.2894 13.346 10.9978 13.7909 10.6956C14.2359 10.3933 14.5966 10.0647 14.8731 9.70955C15.161 9.35313 15.3634 8.95841 15.4804 8.52538C15.6075 8.07921 15.6409 7.57167 15.5805 7.00279C15.4572 5.8413 15.0026 4.95718 14.2168 4.35042C13.4424 3.74236 12.3769 3.51507 11.0202 3.66856C10.5488 3.72189 10.1112 3.81938 9.70739 3.96102C9.30229 4.09081 8.95397 4.26216 8.66242 4.47505C8.37087 4.68795 8.14695 4.93518 7.99068 5.21673C7.84591 5.49698 7.79177 5.80896 7.82826 6.15266C7.85635 6.41722 7.9431 6.65095 8.07887 6.84821C8.36441 7.26303 8.93408 7.12635 9.34523 6.84492C9.44826 6.7744 9.5535 6.70842 9.66098 6.64698C9.99978 6.44073 10.4049 6.31094 10.8763 6.25761C11.3936 6.19908 11.7754 6.28782 12.0215 6.52385C12.2792 6.75858 12.4243 7.03001 12.4571 7.33816C12.4936 7.68186 12.4209 7.98394 12.2391 8.24439C12.0561 8.49299 11.799 8.70198 11.468 8.87138L10.4747 9.37956C10.1551 9.54765 9.89493 9.72701 9.6941 9.91765Z" fill="#BA9B66"/>
                    <path d="M9.0922 15.737C9.09186 15.2362 9.23616 14.8307 9.52511 14.5204C9.82561 14.2101 10.2361 14.0547 10.7566 14.0542C11.277 14.0536 11.682 14.2082 11.9713 14.5179C12.2722 14.8276 12.4229 15.2328 12.4232 15.7336C12.4236 16.2344 12.2735 16.6399 11.973 16.9502C11.684 17.2605 11.2793 17.4159 10.7588 17.4164C10.2384 17.417 9.82767 17.2624 9.52675 16.9527C9.23739 16.643 9.09254 16.2378 9.0922 15.737Z" fill="#BA9B66"/>
                </svg>
            </ListItemIcon>
        </ListItem>;
    }

    renderEventListItem = (item, last) => {
        return <ListItem key={`event-${item.id}`} last={last}>
            <ListItemDescription>
                <ListItemDescription>
                    <ListItemDay>{ moment(item.date).format('DD') }</ListItemDay>
                    <ListItemMonthWrapper>
                        <ListItemMonth>{ moment(item.date).format('MMMM') }, { moment(item.date).format('dd') }</ListItemMonth>
                        <ListItemTime>{ moment(item.date).format('HH:mm') }-{ moment(item.date).add(item.duration, 'minutes').format('HH:mm') }</ListItemTime>
                    </ListItemMonthWrapper>
                </ListItemDescription>
                <ListItemTitle onClick={() => this.props.openEventViewModal(item)}>
                    { path(['_embedded', 'theme', 'title'], item) }
                </ListItemTitle>
            </ListItemDescription>
            <ListItemIcon>
                <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.9943 18.6874C17.794 18.6791 20.7956 20.6658 22.5971 22.6538C24.2458 24.4731 22.797 26.9011 20.3466 27.0651C18.3878 27.1961 15.8893 27.307 13.0055 27.3119C10.1217 27.3169 7.62294 27.2147 5.66372 27.0904C3.21296 26.9349 1.75791 24.5119 3.40188 22.6868C5.19822 20.6927 8.1947 18.6957 12.9943 18.6874Z" fill="#75AD56"/>
                    <ellipse rx="11.9843" ry="11.979" transform="matrix(0.999996 -0.00172589 0.00129048 0.999997 12.9998 11.9996)" fill="#E2FFD1"/>
                    <path fillRulerule="evenodd" clipRule="evenodd" d="M13.0099 19.9865C17.4225 19.9789 20.995 16.3971 20.9893 11.9864C20.9836 7.57566 17.4019 4.00624 12.9892 4.01386C8.57664 4.02148 5.00413 7.60324 5.00982 12.014C5.01551 16.4247 8.59725 19.9941 13.0099 19.9865ZM13.0083 17.9902C16.3178 17.9845 18.9973 15.2981 18.993 11.9899C18.9887 8.68169 16.3024 6.00449 12.9928 6.0102C9.68327 6.01591 7.00382 8.70237 7.00809 12.0106C7.01236 15.3188 9.69874 17.996 13.0083 17.9902Z" fill="#75AD56"/>
                    <path fillRulerule="evenodd" clipRule="evenodd" d="M13.0051 15.9924C15.2112 15.9885 16.9973 14.1978 16.9945 11.9927C16.9942 11.7926 16.9792 11.5959 16.9506 11.4037C16.8919 11.0108 16.3647 10.933 15.9962 11.0816C15.8124 11.1557 15.6117 11.1966 15.4014 11.197C14.519 11.1985 13.8027 10.4847 13.8015 9.6026C13.8013 9.39134 13.842 9.18958 13.9163 9.00488C14.0647 8.63611 13.9865 8.10869 13.5934 8.05034C13.3981 8.02134 13.1982 8.00649 12.9948 8.00684C10.7887 8.01065 9.00255 9.80135 9.0054 12.0065C9.00824 14.2116 10.799 15.9962 13.0051 15.9924Z" fill="#75AD56"/>
                </svg>
            </ListItemIcon>
        </ListItem>;
    }

    renderList = () => {
        const { getEvents: { data, meta }, getConsultations, filters } = this.props;
        let items = [];

        if (!filters.module || filters.module === 'events') {
            items = items.concat((data.items || []).map(item => ({ ...item, type: 'event' })));
        }

        if (!filters.module || filters.module === 'consultations') {
            items = items.concat((getConsultations.data.items || []).map(item => ({ ...item, type: 'consultation' })));
        }

        items = sort((a, b) => new Date(b.type === 'event' ? b.date : b.start) - new Date(a.type === 'event' ? a.date : a.start), items);

        return (meta.pending || getConsultations.meta.pending) ? <div style={{ textAlign: 'center', padding: 15 }}><Spin /></div> : <div>
            { items.length ? <div>
                { items.map((item, index) => {
                    switch (item.type) {
                        case 'event':
                            return this.renderEventListItem(item, index === items.length - 1);
                        case 'consultation':
                            return this.renderConsultationListItem(item, index === items.length - 1);
                        default:
                            return null;
                    }
                })}
            </div> : <div style={{ textAlign: 'center', padding: 15 }}>Нет доступных событий</div> }
        </div>
    }

    render() {
        const { setSelectedDate, type, setType, setFilters, selectedDate, getConsultations, getEvents, filters, openAddMeetingModal } = this.props;
        const events = (!filters.module || filters.module === 'events') ? (getEvents.data.items || []).map(item => ({
            id: item.id,
            title: path(['_embedded', 'theme', 'title'], item),
            start: moment(item.date).format('YYYY-MM-DDTHH:mm:ss'),
            end: moment(item.date).add(item.duration, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
            allDay: false,
            classNames: ['event']
        })) : [];
        const consultations = (!filters.module || filters.module === 'consultations') ? (getConsultations.data.items || []).map(item => {
            const consultant = path(['_embedded', 'consultant'], item);

            return {
                id: item.id,
                title: `Консультация (${consultant.lastName} ${consultant.firstName}${consultant.middleName ? ` ${consultant.middleName}` : ''})`,
                start: moment(item.start).format('YYYY-MM-DDTHH:mm:ss'),
                end: moment(item.end).format('YYYY-MM-DDTHH:mm:ss'),
                allDay: false,
                classNames: ['consultation']
            };
        }) : [];

        return <div>
            <Filters gutter={8}>
                <Col span={20}>
                    <CalendarFilter setFilters={setFilters} withoutWrapper />
                </Col>
                <Col span={4} align='right'>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => openAddMeetingModal({
                        onClose: () => {
                            this.props.getConsultations.refresh();
                        }
                    })}>Добавить</Button>
                </Col>
            </Filters>
            <Header>
                <div>
                    <Button.Group>
                        <Button type='primary' icon={<LeftOutlined />} onClick={this.prev} />
                        <Button type='primary' icon={<RightOutlined />} onClick={this.next} />
                    </Button.Group>
                    <Button type='primary' style={{ marginLeft: 8 }} onClick={() => setSelectedDate(moment())}>Сегодня</Button>
                </div>
                <h3>{ this.getHeaderString() }</h3>
                <Radio.Group buttonStyle="solid" value={type} onChange={e => setType(e.target.value)}>
                    <Radio.Button value='dayGridMonth'>Месяц</Radio.Button>
                    <Radio.Button value='timeGridWeek'>Неделя</Radio.Button>
                    <Radio.Button value='timeGridDay'>День</Radio.Button>
                    <Radio.Button value='list'>Список</Radio.Button>
                </Radio.Group>
            </Header>
            { type === 'list' ?
                this.renderList() :
                <CalendarWrapper>
                    <FullCalendar
                        initialDate={selectedDate.format('YYYY-MM-DD')}
                        plugins={[ dayGridPlugin, timeGridPlugin ]}
                        key={`${this.props.type}-${this.props.minDate.toString()}-${this.props.maxDate.toString()}`}
                        initialView={this.props.type}
                        locales={[ruLocale]}
                        locale='ru'
                        events={[
                            ...events,
                            ...consultations
                        ]}
                        headerToolbar={false}
                        allDaySlot={false}
                        slotLabelFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            omitZeroMinute: false,
                            meridiem: 'short'
                        }}
                        eventTimeFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            meridiem: false
                        }}
                        displayEventTime={false}
                        eventClick={info => {
                            const className = info.event.classNames[0];

                            switch(className) {
                                case 'event':
                                    this.props.openEventViewModal(find(propEq('id', info.event.id), getEvents.data.items || []));
                                    return;
                                case 'consultation':
                                    this.props.openConsultationViewModal({
                                        item: find(propEq('id', info.event.id), getConsultations.data.items || []),
                                        onCloseCancel: () => {
                                            this.props.getConsultations.refresh();
                                        }
                                    });
                                    return;
                                default:
                                    return;
                            }
                        }}
                    />
                </CalendarWrapper>
            }
        </div>;
    }
}

export default withStateHandlers({
    type: 'dayGridMonth',
    selectedDate: moment(),
    minDate: moment().startOf('month').toDate(),
    maxDate: moment().endOf('month').toDate(),
    filters: {}
}, {
    setType: ({ selectedDate }) => type => ({
        type,
        minDate: (type === 'dayGridMonth' || type === 'list') ? selectedDate.clone().startOf('month').toDate() : type === 'timeGridWeek' ? selectedDate.clone().startOf('week').toDate() : selectedDate.clone().startOf('day'),
        maxDate: (type === 'dayGridMonth' || type === 'list') ? selectedDate.clone().endOf('month').toDate() : type === 'timeGridWeek' ? selectedDate.clone().endOf('week').toDate() : selectedDate.clone().endOf('day')
    }),
    setSelectedDate: ({ type }) => selectedDate => ({
        selectedDate,
        minDate: (type === 'dayGridMonth' || type === 'list') ? selectedDate.clone().startOf('month').toDate() : type === 'timeGridWeek' ? selectedDate.clone().startOf('week').toDate() : selectedDate.clone().startOf('day'),
        maxDate: (type === 'dayGridMonth' || type === 'list') ? selectedDate.clone().endOf('month').toDate() : type === 'timeGridWeek' ? selectedDate.clone().endOf('week').toDate() : selectedDate.clone().endOf('day')
    }),
    setFilters: () => filters => ({ filters })
})(asyncConnect({
    getEvents: getEvents
        .withPayload(({ filters, minDate, maxDate }) => ({
            limit: 0,
            q: {
                minDate,
                maxDate,
                participant: filters.participant,
                themes: filters.themes
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
    getConsultations: getConsultations
        .withPayload(({ minDate, maxDate, filters }) => ({
            limit: 0,
            q: {
                minDate,
                maxDate,
                consultant: filters.consultant,
                participant: filters.participant
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
}, null, { openConsultationViewModal, openEventViewModal, openAddMeetingModal })(Calendar));
