import { Select, Spin } from 'antd';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { withState } from 'recompose';
import moment from 'moment';

import { getCompanyStatisticDyncamicUsage } from '../../../actions/asyncActions';
import { Block, BlockContent, BlockHeader } from '../modules/WebinarsByCompany';
import ReactHighcharts from 'react-highcharts';
import { pathOr, range } from 'ramda';

const MONTHS = [
    { label: 'Январь', value: 0 },
    { label: 'Февраль', value: 1 },
    { label: 'Март', value: 2 },
    { label: 'Апрель', value: 3 },
    { label: 'Май', value: 4 },
    { label: 'Июнь', value: 5 },
    { label: 'Июль', value: 6 },
    { label: 'Август', value: 7 },
    { label: 'Сентябрь', value: 8 },
    { label: 'Октябрь', value: 9 },
    { label: 'Ноябрь', value: 10 },
    { label: 'Декабрь', value: 11 },
];

class DynamicUsage extends Component {
    getConfig = () => {
        const { getCompanyStatisticDyncamicUsage: { data }, month } = this.props;
        const days = range(1, moment.utc().set('month', month).daysInMonth() + 1);

        return {
            chart: {
                type: 'column'
            },
            title: {
                text: null
            },
            xAxis: {
                categories: days,
                gridLineDashStyle: 'dash',
                gridLineWidth: 1
            },
            yAxis: [{
                allowDecimals: false,
                min: 0,
                title: {
                    text: 'кол-во событий'
                },
                gridLineDashStyle: 'dash',
                gridLineWidth: 1
            }],
            credits: {
                enabled: false
            },
            tooltip: {
                enabled: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Консультации',
                color: '#959EA7',
                data: days.map((_, index) => pathOr(0, ['consultations', index, 'count'], data))
            }, {
                name: 'Вебинары',
                color: '#2B3D4F',
                data: days.map((_, index) => pathOr(0, ['events', index, 'count'], data))
            }, {
                name: 'Профессиональная поддержка',
                color: '#47AAC4',
                data: days.map((_, index) => pathOr(0, ['promotions', index, 'count'], data))
            }]
        };
    }

    render() {
        const { month, setMonth, getCompanyStatisticDyncamicUsage: { meta }} = this.props;

        return <Block>
            <BlockHeader>
                <h3>ДИНАМИКА ИСПОЛЬЗОВАНИЯ СЕРВИСОВ</h3>
                <Select
                    value={month || undefined}
                    onChange={value => setMonth(value)}
                    placeholder='Месяц'
                    style={{ width: 250 }}
                    allowClear={false}>
                    { MONTHS.map(item => <Select.Option key={item.value} value={item.value}>{ item.label }</Select.Option>) }
                </Select>
            </BlockHeader>
            <BlockContent>
                { meta.pending ? <Spin /> : <div>
                    <ReactHighcharts config={this.getConfig()} />
                </div> }
            </BlockContent>
        </Block>;
    }
}

export default withState('month', 'setMonth', () => moment.utc().startOf('month').month())(asyncConnect({
    getCompanyStatisticDyncamicUsage: getCompanyStatisticDyncamicUsage
        .withPayload(({ id, month }) => ({
            id,
            q: {
                company: id,
                minDate: moment.utc().set('month', month).startOf('month'),
                maxDate: moment.utc().set('month', month).endOf('month')
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(DynamicUsage));
