export const GENDER = [
    { name: 'Мужской', id: 'male' },
    { name: 'Женский', id: 'female' }
];

export const YEARS = [
    { name: 'до года', id: 1 },
    { name: '1 год', id: 2 },
    { name: '2 года', id: 3 },
    { name: '3 года', id: 4 },
    { name: '4 года', id: 5 },
    { name: '5 лет', id: 6 },
    { name: '6 лет', id: 7 },
    { name: '7 лет', id: 8 },
    { name: 'более 7 лет', id: 9 }
];

export const JOB_BREAK = [
    { name: 'нет', id: 'none' },
    { name: 'да, был(а) в декрете', id: 'maternity' },
    { name: 'да, был длительный больничный', id: 'sick' },
    { name: 'да, по другим причинам', id: 'other' }
];

export const CAREER_EXPECTATION = [
    { name: 'Планирует искать работу в прежней сфере', id: 'same' },
    { name: 'Хочет сменить профессию / индустрию', id: 'different_industry' },
    { name: 'Хочет работать в другой стране', id: 'different_country' },
    { name: 'Хочет открыть свой бизнес', id: 'own_business' }
];

export const MODULES = [
    { name: 'Возрастной кандидат', id: 'aged_candidate' },
    { name: 'Молодой кандидат', id: 'young_candidate' },
    { name: 'Работал в одной компании', id: 'single_company' },
    { name: 'Долго не искал работу', id: 'long_without_search_job' },
    { name: 'Декретный отпуск', id: 'maternity_leave' },
    { name: 'Смена карьеры', id: 'change_career' },
    { name: 'Смена локации', id: 'change_country' },
    { name: 'Собственный бизнес', id: 'own_business' },
    { name: 'Стресс', id: 'stress' },
];

export const INVITES_ACTIVITY = [
    { value: 'Все', id: '' },
    { value: 'Активированные', id: false },
    { value: 'Неактивированные', id: true }
];

export const ADMIN_ENABLED = [
    { value: 'Активные', id: true },
    { value: 'Неактивные', id: false }
];

export const ADMIN_CLIENT_ENABLED = [
    { value: 'Все', id: '' },
    { value: 'Администратор клиента', id: true },
    { value: 'Менеджер клиента', id: false }
];

export const PARTICIPATION_STATUS = [
    { value: 'Все', id: '' },
    { value: 'Ожидают', id: 'pending' },
    { value: 'Созданные', id: 'approved' },
    { value: 'Отклоненные', id: 'rejected' }
];

export const DOCUMENTS_TYPES = [
    { value: 'Word', id: 'docx' },
    { value: 'Excel', id: 'xlsx' },
    { value: 'Powerpoint', id: 'xlsx' },
    { value: 'PDF', id: 'pdf' },
    { value: 'JPG', id: 'jpg' },
];

export const CONSULTATION_TITLES = [
    {
        name: 'Оценка профессионального опыта, мотивации и личностных качеств​',
        id: 'Консультация #name Оценка профессионального опыта, мотивации и личностных качеств #company'
    },
    {
        name: 'Составление конкурентоспособного резюме​',
        id: 'Консультация #name Составление конкурентоспособного резюме​ #company'
    },
    {
        name: 'Подготовка сопроводительных писем​',
        id: 'Консультация #name Подготовка сопроводительных писем ​#company'
    },
    {
        name: 'Освоение инструментов поиска работы​',
        id: 'Консультация #name Освоение инструментов поиска работы #company'
    },
    {
        name: 'Подготовка к переговорам с работодателем​',
        id: 'Консультация #name Подготовка к переговорам с работодателем #company'
    },
    {
        name: 'Развитие навыков самопрезентации',
        id: 'Консультация #name Развитие навыков самопрезентации #company'
    },
    {
        name: 'Прохождение интервью, ассессмент-центра, тестирования​',
        id: 'Консультация #name Прохождение интервью, ассессмент-центра, тестирования #company'
    },
    {
        name: 'Оценка предложения и принятие решения​',
        id: 'Консультация #name Оценка предложения и принятие решения #company'
    },
    {
        name: 'Оценка ситуации на рынке труда',
        id: 'Консультация #name Оценка ситуации на рынке труда #company'
    },
    {
        name: 'Постановка целей поиска работы',
        id: 'Консультация #name Постановка целей поиска работы company'
    },
    {
        name: 'Профессиональная ориентация',
        id: 'Консультация #name Профессиональная ориентация #company'
    },
    {
        name: 'Планирование карьеры',
        id: 'Консультация #name Планирование карьеры #company'
    },
    {
        name: '__________________________',
        id: 'Консультация #name #company'
    },
];

export const CONSULTATION_PROMOTION_TITLES = [
    {
        name: 'Горячая линия',
        id: 'Горячая линия #name #company'
    },
    {
        name: 'Горячая линия',
        id: 'Сопровождение на рынке труда #name #company'
    },
    {
        name: 'Горячая линия',
        id: 'Профессиональная поддержка #name #company'
    },
];

export const COMPANY_BILLING_BY_PARTICIPANT = 'billing_type_by_participant';

export const COMPANY_BILLING = [
    { name: 'По сервисам', id: 'billing_type_by_service' },
    { name: 'По участнику', id: COMPANY_BILLING_BY_PARTICIPANT },
];

