import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Alert, Button, Table, Tooltip, message, Row, Col } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { find, propEq, path, toLower, without, append, values, flatten, uniq, pickBy, keys } from 'ramda';
import { withState } from 'recompose';
import styled from 'styled-components';
import axios from 'axios';
import fileDownload from 'js-file-download';

import { PARTICIPANT_STATUSES } from '../constants/statuses';
import { getEmployeeRequest, getParticipantsList } from '../actions/asyncActions';

const Request = styled.div`
    padding: 8px;
`;

class LinksEmployeeList extends Component {
    downloadRequest = (item) => {
        axios({
            url: '/admin/employees_list_requests/export/participants.xlsx',
            method: 'POST',
            responseType: 'blob',
            data: {
                participants: uniq(flatten(values(this.props.selected))),
            }
        })
            .then(response => {
                fileDownload(response, 'participants.xlsx');
            })
            .catch((err) => message.error('Не удалось загрузить файл участников'));
    }

    expandedRowRender = (item) => {
        return <ParticipantsTable {...this.props} item={item} />;
    }

    getColumns = () => {
        return [
            {
              title: 'Уровень должности',
              dataIndex: 'position',
              key: 'position',
            },
            {
              title: 'Отрасль',
              dataIndex: 'industryName',
              key: 'industryName',
            },
            {
              title: 'Направление',
              dataIndex: 'functionName',
              key: 'functionName',
            },
            {
              title: 'Локация',
              dataIndex: 'location',
              key: 'location',
            },
            {
              title: 'Дата увольнения',
              dataIndex: 'programStartedAt',
              key: 'programStartedAt',
              width: '120px',
              render: date => date ? moment(date).format('MM.YYYY') : '',
            },
        ];
    }

    render() {
        const { getEmployeeRequest: { data, meta } } = this.props;

        return <div>
            { meta.error && (
                <Alert style={{ marginBottom: 15 }} type='error' message={path(['message'], meta.error.data) || 'Не удалось загрузить заявку'} />
            )}
            { data && meta.success && (
                <Row className='toolbar-row'>
                    <Col xs={18}>
                        <Request>ФИО: <b>{data.name}</b> Клиент:  <b>{data.company}</b> Должность: <b>{data.position}</b> - <a href={`mailto:${data.email}`}>{data.email}</a> <b>{data.phone}</b></Request>
                    </Col>
                    <Col xs={6}>
                        <Button
                            disabled={!path(['length'], flatten(values(this.props.selected)))}
                            icon={<FileExcelOutlined />}
                            onClick={this.downloadRequest}
                        />
                    </Col>
                </Row>
            )}
            <Table
                columns={this.getColumns()}
                dataSource={path(['data', 'selectedEmployees'], this.props.getEmployeeRequest) || []}
                loading={meta.pending}
                expandable={{
                expandedRowRender: this.expandedRowRender,
                expandedRowKeys: this.props.expanded || [],
                onExpand: (expanded, item) => {
                    const id = JSON.stringify(item);

                    if (expanded) {
                        this.props.setExpanded(append(id, this.props.expanded));
                    } else {
                        this.props.setExpanded(without([id], this.props.expanded));
                    }

                }
                }}
                rowKey={item => JSON.stringify(item)}
                pagination={false}
            />
        </div>
    }
}

class Participants extends Component {
    render() {
        const columns = [
            {
                title: 'Участник',
                key: 'name',
                render: (item) => {
                    const additionalStatusData = find(propEq('id', item.additionalStatus), PARTICIPANT_STATUSES);
                    const statusData = find(propEq('id', item.status), PARTICIPANT_STATUSES);
                    return (
                        <Tooltip title={additionalStatusData ? (
                            <div>
                                {toLower(statusData ? statusData.title : '')} - { additionalStatusData.icon } { toLower(additionalStatusData.secondLevel || additionalStatusData.title) }
                            </div>
                        ) : null}>
                            <Link to={`/participants/${item.id}`} target="_blank">{item.lastName || ''} {item.firstName || ''} {item.middleName || ''}</Link> ({path(['_embedded', 'company', 'name'], item)})
                        </Tooltip>
                    );
                }
            },
            {
                title: 'Email',
                key: 'email',
                dataIndex: ['email'],
            },
            {
                title: 'Телефон',
                key: 'phone',
                dataIndex: ['phone'],
            },
            {
                title: 'Отрасль',
                key: 'industryName',
                dataIndex: 'industryName',
                render: (industryName, item) => industryName || path(['_embedded', 'company', 'industryName'], item)
            },
            {
                title: 'Должность',
                key: 'position',
                dataIndex: 'position',
            },
            {
                title: 'Функция',
                key: 'functionName',
                dataIndex: 'functionName',
            },
            {
                title: 'Локация',
                key: 'location',
                dataIndex: 'location',
            },
            {
              title: 'Дата увольнения',
              dataIndex: 'programStartedAt',
              key: 'programStartedAt',
              width: '120px',
              render: date => date ? moment(date).format('DD.MM.YYYY') : '',
            },
        ];

        return (
            <Table
                columns={columns}
                rowSelection={{
                  selectedRowKeys: this.props.selected[JSON.stringify(this.props.item)],
                  onChange: (newSelectedRowKeys) => this.props.setSelected({
                    ...this.props.selected,
                    [JSON.stringify(this.props.item)]: newSelectedRowKeys,
                  }),
                }}
                dataSource={path(['data', 'items'], this.props.getParticipants) || []}
                loading={this.props.getParticipants.meta.pending}
                pagination={false}
                rowKey='id'
            />
        );
    }
}

const ParticipantsTable = withAsyncActions({
    getParticipants: getParticipantsList
        .withParams(({ item }) => JSON.stringify(item))
        .withPayload(({ item }) => ({
            limit: 0,
            q: {
                location: item.location || undefined,
                industry: item.industry || undefined,
                function: item.function || undefined,
                position: item.position || undefined,
                minProgramStartedAt: item.programStartedAt ? moment(item.programStartedAt).clone().startOf('month').toDate() : undefined,
                maxProgramStartedAt: item.programStartedAt ? moment(item.programStartedAt).clone().endOf('month').toDate() : undefined,
                nullableFields: keys(pickBy(Boolean, {
                    location: item.location === null,
                    industry: item.industry === null,
                    function: item.function === null,
                    position: item.position === null,
                    minProgramStartedAt: item.programStartedAt === null,
                    maxProgramStartedAt: item.programStartedAt === null,
                })),
                depersonalized: false,
                status: 'in_work',
                additionalStatus: 'active',
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(Participants);

export default withState('expanded', 'setExpanded', [])(withAsyncActions({
    getEmployeeRequest: getEmployeeRequest
        .withPayload(({ match }) => match.params.id)
        .withSuccessHandler((props, action) => {
            props.setExpanded((path(['payload', 'selectedEmployees'], action) || []).map(item => JSON.stringify(item)));
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(withState('selected', 'setSelected', {})(LinksEmployeeList)));

