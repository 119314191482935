import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { Col, Row } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { ListenerField } from '@meconsultant/common';
import { pathOr, find, propEq, flatten } from 'ramda';

import withFilterForm from '../hocs/withFilterForm';
import SearchSelect from '../forms/formComponents/SearchSelect';
import { getStages } from '../../actions/asyncActions';

class FeedbackFilter extends Component {
    onChangeStage = () => {
        this.props.form.change('materials', null);
    }

    render() {
        return (
            <Row gutter={16}>
                <Col span={12}>
                    <Field
                        name='stage'
                        component={SearchSelect}
                        placeholder='Этап'
                        action={getStages}
                        namePath='title'
                        allowClear
                        onChange={this.onChangeStage}
                    />
                </Col>
                <ListenerField listenFieldName='stage'>
                    { ({ stage }) =>
                        stage &&
                            <Col span={12}>
                                <Field
                                    name='materials'
                                    component={SearchSelect}
                                    placeholder='Материалы'
                                    namePath='title'
                                    options={flatten(pathOr([], ['themes'], find(propEq('id', stage), this.props.getStages.data.items || [])).map(({ materials }) => materials))}
                                    allowClear
                                    isMulti
                                />
                            </Col>
                    }
                </ListenerField>
            </Row>
        );
    }
}

export default withFilterForm(withAsyncActions({
    getStages: getStages
        .withParams(() => ({ name: 'stage' }))
})(FeedbackFilter));
