import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Badge, Button, Progress, Tooltip } from 'antd';
import { prop, propEq, find } from 'ramda';
import cx from 'classnames';
import { withAsyncActions } from 'react-async-client';
import styled from 'styled-components';
import { EyeOutlined, LikeOutlined, CopyOutlined, StarOutlined, MessageOutlined, CrownOutlined, FileTextOutlined, EditOutlined } from '@ant-design/icons';

import { MODULES } from '../constants/dictionaries';
import { getMaterialsStatistics, getFeedbackStatistics } from '../actions/asyncActions';

const StatisticItem = styled.span`
    margin-right: 7px;
    .anticon {
        margin-right: 2px;
    }
`;

class Stage extends Component {
    static propTypes = {
        stage: PropTypes.object,
        onOpenMaterial: PropTypes.func,
        edit: PropTypes.bool,
        onEdit: PropTypes.func,
        hideTitle: PropTypes.bool
    };

    static defaultProps = {
        stages: []
    };

    getStatistic = id => {
        return find(propEq('material', id), this.props.getMaterialsStatistics.data.items || []) || {};
    }

    getFeedbackStatistic = id => {
        return find(propEq('material', id), this.props.getFeedbackStatistics.data.items || []) || {};
    }

    renderMaterial = material => {
        const { onOpenMaterial, edit } = this.props;
        const { id, viewed, published, title, modules, lead } = material;
        const statistic = this.getStatistic(id);
        const feedbackStatistic = this.getFeedbackStatistic(id);

        return <div
            key={id}
            className={cx('stages-material', { viewed })}>
            <Badge status={(edit ? published : viewed) ? 'success' : 'default'} />
            <span>
                { title }
                <span style={{ marginLeft: 15, color: '#aaa', fontSize: 12 }}>
                    { !!statistic.countViews &&
                        <Tooltip title='Количество просмотров материала'>
                            <StatisticItem><EyeOutlined />{ statistic.countViews }</StatisticItem>
                        </Tooltip>
                    }
                    { !!statistic.countFavorites &&
                        <Tooltip title='Количество добавлений в избранное'>
                            <StatisticItem><StarOutlined />{ statistic.countFavorites }</StatisticItem>
                        </Tooltip>
                    }
                    { !!feedbackStatistic.usefulness &&
                        <Tooltip title='Оценка полезности'>
                            <StatisticItem><LikeOutlined />{ feedbackStatistic.usefulness }</StatisticItem>
                        </Tooltip>
                    }
                    { !!feedbackStatistic.convenience &&
                        <Tooltip title='Оценка удобности'>
                            <StatisticItem><CrownOutlined />{ feedbackStatistic.convenience }</StatisticItem>
                        </Tooltip>
                    }
                    { !!feedbackStatistic.countReviews &&
                        <Tooltip title='Количество отзывов'>
                            <StatisticItem><MessageOutlined />{ feedbackStatistic.countReviews }</StatisticItem>
                        </Tooltip>
                    }
                </span>
            </span>
            <div style={{ color: '#bbb', marginLeft: 25 }}>{ lead }</div>
            { !!(modules || []).length &&
                <div className='stages-material-modules-container'>
                    <CopyOutlined />
                    <div className='stages-material-modules'>
                        { modules.map((module, index) =>
                            <span key={module}>
                                { !!index && ', ' }{ prop('name', find(propEq('id', module), MODULES)) }
                            </span>
                        )}
                    </div>
                </div>
            }
            <Button
                className='stages-material-edit-btn'
                icon={<FileTextOutlined />}
                type='circle'
                onClick={() => onOpenMaterial(material)} />
        </div>;
    }

    renderThemes = themes => {
        return (themes || []).map((theme, index) =>
            <Collapse.Panel
                key={`theme-${index}`}
                header={theme.title}
                disabled={!theme.materials.length}
                showArrow={!!theme.materials.length}>
                { theme.materials.map(this.renderMaterial) }
            </Collapse.Panel>
        );
    }

    render() {
        const { edit, onEdit, stage, hideTitle } = this.props;

        return <div className='stages-content'>
            { !hideTitle &&
                <div className='stages-title'>
                    <h3>{ stage.title }</h3>
                    { edit ?
                        <Button
                            icon={<EditOutlined />}
                            type='circle'
                            onClick={() => onEdit(stage)} /> :
                        <Progress
                            type='circle'
                            percent={stage.progress}
                            width={35} />
                    }
                </div>
            }
            <Collapse bordered={false}>
                { this.renderThemes(stage.themes) }
            </Collapse>
        </div>;
    }
}

export default withAsyncActions({
    getMaterialsStatistics: getMaterialsStatistics
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getFeedbackStatistics: getFeedbackStatistics
        .withPayload(({ stage }) => ({
            q: {
                stage: stage.id
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(Stage);
