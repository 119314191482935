"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CHECK_LIST_RESULTS = exports.CHECK_LIST_BAD_RESULT = exports.CHECK_LIST_LOW_RESULT = exports.CHECK_LIST_HIGT_RESULT = void 0;
var CHECK_LIST_HIGT_RESULT = 3;
exports.CHECK_LIST_HIGT_RESULT = CHECK_LIST_HIGT_RESULT;
var CHECK_LIST_LOW_RESULT = 2;
exports.CHECK_LIST_LOW_RESULT = CHECK_LIST_LOW_RESULT;
var CHECK_LIST_BAD_RESULT = 1;
exports.CHECK_LIST_BAD_RESULT = CHECK_LIST_BAD_RESULT;
var CHECK_LIST_RESULTS = [{
  id: CHECK_LIST_BAD_RESULT,
  label: '0',
  icon: null
}, {
  id: CHECK_LIST_LOW_RESULT,
  label: '0.5',
  icon: null
}, {
  id: CHECK_LIST_HIGT_RESULT,
  label: '1',
  icon: null
}];
exports.CHECK_LIST_RESULTS = CHECK_LIST_RESULTS;