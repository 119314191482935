import { Button, Radio } from 'antd';
import { filter, find, includes, pathOr, propEq } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FileExcelOutlined } from '@ant-design/icons';

import { getToken } from '../../utils/token';
import { getUser } from '../../actions/asyncActions';
import { SUPER_ADMIN } from '../../constants/roles';
import ConsultantsStatistic from './ConsultantsStatistic';
import DynamicUsageStatistic from './DynamicUsageStatistic';

import ModulesStatistic from './ModulesStatistic';
import ParticipantsStatistic from './ParticipantsStatistic';

const Header = styled.div`
    padding: 0 15px 15px;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
`;

class CompanyStatistic extends Component {
    state = {
        tab: 'participants'
    };

    onChangeTab = e => this.setState({ tab: e.target.value });

    renderTab = () => {
        const { company } = this.props;
        const themes = pathOr([], ['_embedded', 'themes'], find(propEq('id', 'events'), pathOr([], ['modules'], company)));

        switch (this.state.tab) {
            case 'participants':
                return <ParticipantsStatistic company={company.id} />;
            case 'modules':
                return <ModulesStatistic company={company.id} themes={themes} modules={pathOr([], ['modules'], company).map(({ id }) => id)} />;
            case 'dynamic':
                return <DynamicUsageStatistic company={company.id} />;
            case 'consultants':
                return <ConsultantsStatistic company={company.id} />;
            default:
                return null;
        }
    }

    render() {
        const { loaded, user, company } = this.props;
        const isSuperAdmin = includes(SUPER_ADMIN, user.roles);

        return <div>
            <Header>
                <Radio.Group
                    options={filter(({hide}) => !hide, [
                        { label: 'по участникам', value: 'participants' },
                        { label: 'по сервисам', value: 'modules' },
                        { label: 'по динамике сервисов', value: 'dynamic', hide: !isSuperAdmin },
                        { label: 'по консультантам', value: 'consultants', hide: !isSuperAdmin }
                    ])}
                    onChange={this.onChangeTab}
                    value={this.state.tab}
                    optionType="button"
                    buttonStyle="solid"
                />
                <Button
                    type='primary'
                    icon={<FileExcelOutlined />}
                    href={`/api/admin/company_statistic/${company.id}/statistic.xlsx?token=${getToken()}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    download
                    disabled={!company.id}
                >
                    Скачать
                </Button>
            </Header>
            { loaded && this.renderTab() }
        </div>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(CompanyStatistic);
