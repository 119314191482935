import UrlPattern from 'url-pattern';
import { getToken } from '../utils/token';

const construct = url => new UrlPattern('(*)' + url);

export const BASE_URL = '/api';

export const FILE_URL = `${BASE_URL}/files`;
export const getUploadUrl = () => `${FILE_URL}?token=${getToken()}`;
export const getFileView = id => `${FILE_URL}/${id}/view?token=${getToken()}`;

export const LOGIN = construct('/admin/login_check');
export const USER = construct('/sessions/current');
export const RECOVERY = construct('/password_recovery(/:id)');
export const COMPANIES = construct('/admin/companies(/:id)');
export const PARTICIPANTS = construct('/admin/participants(/:id)');
export const PARTICIPANT_LIMIT = construct('/admin/participants/:id/:module');
export const PARTICIPATIONS_REQUESTS = construct('/admin/participation_requests(/:id/:action)');
export const PARTICIPANTS_IMPORT = construct('/admin/participants/import(/:company)');
export const FILES = construct('/files(/:id)');
export const ADMINS = construct('/admin/admins(/:id)');
export const STAGES = construct('/admin/stages(/:id)');
export const MATERIALS = construct('/admin/materials(/:id)');
export const MOOD = construct('/admin/mood');
export const FAQ = construct('/admin/faqs(/:id)');
export const PARTICIPANT_STAGES = construct('/participant/stages');
export const PARTICIPANT_MATERIAL = construct('/participant/materials/:id');
export const INVITES = construct('/admin/invites');
export const TOPICS = construct('/admin/topics(/:id)');
export const SETTINGS = construct('/settings(/:id)');
export const MENTIONS = construct('/admin/mentions(/:id)');
export const NEWS = construct('/admin/news(/:id)');
export const REFRESH_TOKEN = construct('/refresh_token');
export const MATERIALS_STATISTICS = construct('/admin/statistic/materials');
export const FEEDBACK_RESULTS = construct('/admin/results/feedback');
export const FEEDBACK_STATISTICS = construct('/admin/statistic/feedback');
export const INDUSTRY_DICTIONARY = construct('/admin/dictionaries/industry');
export const FUNCTIONS_DICTIONARY = construct('/admin/dictionaries/participant_functions');
export const ADMIN_MODULES = construct('/admin/modules');
export const COMPANY_SETTINGS = construct('/admin/companies/:company/settings(/:path)');
export const ADMIN_CLIENTS = construct('/admin/clients(/:id)');
export const COMPANY_ROLES = construct('/admin/company_roles(/:id)');
export const INVITES_IMPORT = construct('/admin/invites/import(/:company)');
export const TEST_THEMES = construct('/admin/test_themes(/:id)');
export const EVENT_THEMES = construct('/admin/event_themes(/:id)');
export const EVENTS = construct('/admin/events(/:id)');
export const EVENT_PARTICIPATION = construct('/admin/event_participations(/:id)');
export const EVENT_PARTICIPATION_ATTEND = construct('/admin/event_participations/:id/attend');
export const EVENT_PARTICIPATION_ATTEND_CANCEL = construct('/admin/event_participations/:id/cancel_attend');
export const DEPERSONALIZE = construct('/admin/participants/:id/depersonalize');
export const PARTICIPANT_STATISTIC = construct('/admin/participants/:id/statistic');
export const PARTICIPANT_STATUSES = construct('/admin/participant-statuses(/:id)');
export const PASSWORD = construct('/registration/password/:id');
export const RESEND_EMAIL = construct('/admin/invites/:id/resend_email');
export const RESEND_EMAIL_PARTICIPANT = construct('/admin/participants/:id/resend_set_password_email');
export const PARTICIPANT_CONTENT_STAGES = construct('/admin/modules/content/stages/:id');
export const CONSULTATION_DURATIONS = construct('/admin/company_roles/consultation_durations');
export const SET_CONSULTANT = construct('/admin/participants/set_consultant');
export const PROMOTION_DURATIONS = construct('/admin/company_roles/promotion_durations');
export const PROMOTIONS = construct('/admin/promotions/:id');
export const PROMOTION_SERVICE_TYPES = construct('/admin/company_roles/promotion_service_types');
export const PROMOTION_REQUESTS = construct('/admin/promotion_requests(/:id)');
export const PROMOTION_REQUESTS_PARTICIPANT = construct('/admin/promotion_requests/:participant/:id');
export const PROMOTION_REQUESTS_STATUSES = construct('/admin/promotion_requests/statuses');
export const PROMOTION_REQUESTS_THEMES = construct('/admin/promotion_requests/themes');
export const CONSULTATIONS = construct('/admin/consultations(/:id)');
export const CONSULTATION_TITLE = construct('/admin/calcom/event/:id');
export const CALCOM = construct('/admin/calcom/:id');
export const PARTICIPANTS_DOCUMENTS = construct('/admin/participants/:id/documents');
export const PARTICIPANTS_DOCUMENTS_FILE = construct('/admin/participants/:id/documents/:file');
export const PARTICIPANTS_LIST = construct('/admin/participants');
export const PARTICIPANTS_HISTORY = construct('/admin/participants/:id/history');
export const PARTICIPANTS_SUMMARY = construct('/admin/participants/summary/:id');
export const PARTICIPANTS_COMMENT = construct('/admin/participants/:id/comment');
export const PARTICIPANTS_STATUSES = construct('/admin/dictionaries/participant_statuses');
export const PARTICIPANT_FEEDBACKS = construct('/admin/participant_feedbacks(/:id)');
export const PARTICIPANT_FEEDBACKS_EVENT = construct('/admin/participant_feedbacks/event/:id');
export const PARTICIPANT_FEEDBACKS_CONSULTATION = construct('/admin/participant_feedbacks/consultation/:id');
export const COMPANY_BILLING = construct('/admin/companies/:id/billing');
export const COMPANY_STATISTIC_EVENTS = construct('/admin/company_statistic/:id/events');
export const COMPANY_STATISTIC_EVENTS_AVERAGE_ATTENDANCE = construct('/admin/company_statistic/:id/events_average_attendance');
export const COMPANY_STATISTIC_CONSULTATIONS = construct('/admin/company_statistic/:id/consultations');
export const COMPANY_STATISTIC_CONTENT = construct('/admin/company_statistic/:id/content');
export const COMPANY_STATISTIC_PROMOTION = construct('/admin/company_statistic/:id/promotion');
export const COMPANY_STATISTIC_EVENTS_THEMES = construct('/admin/company_statistic/:id/events_themes');
export const COMPANY_STATISTIC_EVENTS_SPEAKERS = construct('/admin/company_statistic/:id/events_speakers');
export const COMPANY_STATISTIC_PROMOTION_ROLES = construct('/admin/company_statistic/:id/promotion_roles');
export const COMPANY_STATISTIC_CONSULTATIONS_ROLES = construct('/admin/company_statistic/:id/consultations_roles');
export const COMPANY_STATISTIC_PROMOTION_MONTHS = construct('/admin/company_statistic/:id/promotion_months');
export const COMPANY_STATISTIC_PROMOTION_CONSULTANT = construct('/admin/company_statistic/:id/promotion_consultants');
export const COMPANY_STATISTIC_PARTICIPANTS = construct('/admin/company_statistic/:id/participants');
export const COMPANY_STATISTIC_STATUSES = construct('/admin/company_statistic/:id/statuses');
export const COMPANY_STATISTIC_PARTICIPANTS_AVERAGE_LIFETIME = construct('/admin/company_statistic/:id/participants_average_lifetime');
export const COMPANY_STATISTIC_DYNAMIC_USAGE = construct('/admin/company_statistic/dynamic-usage');
export const COMPANY_STATISTIC_CONSULTANTS = construct('/admin/company_statistic/consultants');
export const EMPLOYEE_LINKS = construct('/admin/employees_list_links(/:id)');
export const EMPLOYEE_LIST = construct('/admin/employees_list_requests');
export const EMPLOYEE_REQUEST = construct('/admin/employees_list_requests/:id');
export const MESSAGE_TEMPLATES = construct('/admin/message_templates(/:id)');
export const PARTICIPANT_SEND_RESUME = construct('/admin/participants/:id/send-resume');
