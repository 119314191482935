"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFileView = void 0;

var getFileView = function getFileView(id) {
  return "/api/files/".concat(id, "/view?token=").concat(localStorage.getItem('token'));
};

exports.getFileView = getFileView;