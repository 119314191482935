import React, { Component } from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';

import FeedbackList from './FeedbackList';
import FeedbackStatistics from './FeedbackStatistics';
import Route from './Route';

const StyledTabs = styled(Tabs)`
    .ant-tabs-bar {
        margin: 0;
    }
`;

const TABS = [
    { key: '', name: 'Отзывы', component: FeedbackList },
    { key: 'statistics', name: 'Статистика', component: FeedbackStatistics }
];

class Feedbacks extends Component {
    onChangeTab = key => {
        const { history } = this.props;

        history.replace(`/feedbacks${key === 'main' ? '' : `/${key}`}`);
    }

    render() {
        const { match: { params: { type }}} = this.props;

        return <StyledTabs onChange={this.onChangeTab} animated={false} activeKey={type || 'main'}>
            { TABS.map(tab =>
                <Tabs.TabPane key={tab.key || 'main'} tab={tab.name}>
                    <Route path={`/feedbacks/${tab.key}`} exact component={tab.component} />
                </Tabs.TabPane>
            )}
        </StyledTabs>;
    }
}

export default Feedbacks;
