import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

import { withFieldWrapper } from '@meconsultant/common';

class Checkboxes extends Component {
    static propTypes = {
        input: PropTypes.object,
        onChange: PropTypes.func,
        options: PropTypes.array
    };

    static defaultProps = {
        options: []
    };

    onChange = values => {
        this.props.onChange(values.length ? values : null);
    }

    render() {
        const { input: { value }, options } = this.props;

        return <Checkbox.Group
            className='checkboxes'
            value={value || []}
            onChange={this.onChange}>
            { options.map(option =>
                <Checkbox
                    key={option.id}
                    value={option.id}>
                    { option.name }
                </Checkbox>
            )}
        </Checkbox.Group>;
    }
}

export default withFieldWrapper(Checkboxes);
