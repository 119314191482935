import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postMessageTemplate, putMessageTemplate } from '../../actions/asyncActions';
import { withRouter } from 'react-router';
import CompanyMessageTemplateForm from '../forms/CompanyMessageTemplateForm';

class CompanyMessageTemplateModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        params: PropTypes.object,
        postMessageTemplate:  PropTypes.object,
        putMessageTemplate: PropTypes.object,
    };

    render() {
        const { modal, params = {}, putMessageTemplate, postMessageTemplate } = this.props;

        return <Modal
            {...modal}
            title={'Шаблон приветственного письма'}
            width={900}
            className='modal-editor-message'
            footer={null}>
            <CompanyMessageTemplateForm
                formAction={params.id ? putMessageTemplate : postMessageTemplate}
                item={params} />
        </Modal>;
    }
}

export default withRouter(withAsyncActions({
    postMessageTemplate: postMessageTemplate
        .withSuccessHandler(({ close }) => {
            message.success('Шаблон был успешно сохранен');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putMessageTemplate: putMessageTemplate
        .withSuccessHandler(({ close }) => {
            message.success('Шаблон был успешно сохранен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(CompanyMessageTemplateModal));
