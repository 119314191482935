import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'antd';
import moment from 'moment';

import { withFieldWrapper } from '@meconsultant/common';

class DateTimePickerComponent extends Component {
    static propTypes = {
        input: PropTypes.object,
        onChange: PropTypes.func,
        required: PropTypes.bool,
        size: PropTypes.string,
        placeholder: PropTypes.string,
    };

    onChange = time => {
        this.props.onChange(time ? {
            timeFrom: time[0].toDate(),
            timeTo: time[1].toDate()
        } : null);
    }

    render() {
        const { input: { value }, required, placeholder } = this.props;

        return (
            <TimePicker.RangePicker
                format='HH:mm'
                value={value && [moment(value.timeFrom), moment(value.timeTo)]}
                onChange={this.onChange}
                allowClear={!required}
                getPopupContainer={trigger => trigger.parentNode}
                placeholder={placeholder ? placeholder : ['с', 'до']}
            />
        );
    }
}

export default withFieldWrapper(DateTimePickerComponent);
