import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { FormGenerator } from '@experium/react-editor';
import { is } from 'ramda';

import { getParticipantMaterial } from '../../actions/asyncActions';
import { getFileView } from '../../constants/urls';
import { components } from '../forms/formComponents/FormBuidlerField';
import { getToken } from '../../utils/token';

class MaterialViewModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        params: PropTypes.object,
        getParticipantMaterial: PropTypes.object
    };

    render() {
        const { modal, params, getParticipantMaterial } = this.props;
        const data = params.data || getParticipantMaterial.data;

        return <Modal
            {...modal}
            title={data.title || params.title}
            footer={null}>
            <FormGenerator
                data={data.content}
                values={is(Array, data.result) ? {} : data.result}
                downloadUrl={getFileView}
                components={components}
                authParams={{
                    token: getToken()
                }}
                view />
        </Modal>;
    }
}

export default withAsyncActions(({ params }) => ({
    getParticipantMaterial: getParticipantMaterial
        .withPayload(({ params: { id, email }}) => ({
            id,
            params: { _switch_user: email }
        }))
        .withOptions({ dispatchOnMount: !params.data, resetOnUnmount: true })
}))(MaterialViewModal);
