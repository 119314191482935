import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postEventThemes, putEventThemes } from '../../actions/asyncActions';
import WebinarForm from '../forms/WebinarForm';

class WebinarModal extends Component {
    render() {
        const { modal, params, putEventThemes, postEventThemes } = this.props;

        return <Modal
            {...modal}
            title={`${params ? 'Редактировать' : 'Добавить'} тему вебинара`}
            footer={null}>
            <WebinarForm
                formAction={params ? putEventThemes : postEventThemes}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postEventThemes: postEventThemes
        .withSuccessHandler(({ close }) => {
            message.success('Тема вебинара успешно добавлена');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putEventThemes: putEventThemes
        .withSuccessHandler(({ close }) => {
            message.success('Тема вебинара успешно изменена');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(WebinarModal);
