import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { Col, Row } from 'antd';

import withFilterForm from '../hocs/withFilterForm';
import SearchInput from '../forms/formComponents/SearchInput';
import SearchSelect from '../forms/formComponents/SearchSelect';
import { ADMIN_ENABLED } from '../../constants/dictionaries';

class AdminFilter extends Component {
    render() {
        return (
            <Row gutter={16}>
                <Col span={12}>
                    <Field
                        name='name'
                        component={SearchInput}
                        placeholder='Администратор'
                    />
                </Col>
                <Col span={12}>
                    <Field
                        name='enabled'
                        component={SearchSelect}
                        options={ADMIN_ENABLED} />
                </Col>
            </Row>
        );
    }
}

export default withFilterForm(AdminFilter);
