import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withAsyncActions } from 'react-async-client';
import { Link } from 'react-router-dom';

import { postReset } from '../actions/asyncActions';
import ResetForm from './forms/ResetForm';

class Reset extends Component {
    static propTypes = {
        postReset: PropTypes.object,
        match: PropTypes.object
    };

    render() {
        const { postReset, match: { params: { id }}} = this.props;

        return <Fragment>
            <ResetForm
                formAction={postReset}
                id={id} />
            <div style={{ marginTop: 15 }}>
                <Link to='/'>
                    Войти
                </Link>
            </div>
        </Fragment>;
    }
}

export default withAsyncActions({
    postReset: postReset
        .withOptions({ resetOnUnmount: true })
})(Reset);
