import { Col, Progress, Row, Select, Spin } from 'antd';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { connect } from 'react-redux';

import { getCompanyRoles, getCompanyStatisticPromotionMonths, getUser } from '../../../actions/asyncActions';
import { Block, BlockContent, BlockHeader } from './WebinarsByCompany';
import { keys, path, pathOr } from 'ramda';
import { withState } from 'recompose';

class PromotionByMonths extends Component {
    render() {
        const { getCompanyStatisticPromotionMonths: { meta, data }, companyRole, setCompanyRole, getCompanyRoles } = this.props;
        const months = keys(pathOr({}, ['months'], data));

        return <Block>
            <BlockHeader>
                <h3>ПРОФЕССИОНАЛЬНАЯ ПОДДЕРЖКА ПО МЕСЯЦАМ</h3>
                <Select
                    value={companyRole || undefined}
                    onChange={value => setCompanyRole(value)}
                    style={{ width: 250 }}
                    allowClear={false}>
                    { pathOr([], ['data', 'items'], getCompanyRoles).map(item => <Select.Option key={item.id} value={item.id}>{ item.name }</Select.Option>) }
                </Select>
            </BlockHeader>
            <BlockContent>
                { meta.pending ? <Spin /> : <Row gutter={16}>
                    { months.map(month =>
                        <Col key={`month-${month}`} sm={12}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ display: 'inline-block', width: '25%', marginTop: 3 }}>{ month } месяц</div>
                                <div style={{ display: 'inline-block', width: '68%' }}><Progress percent={data.months[month].countPromotionsPercents} strokeColor='#2B3D4F' trailColor='#A7B9CF' /></div>
                                <div style={{ display: 'inline-block', width: '7%', textAlign: 'right', marginTop: 5 }}><strong>{ data.months[month].countPromotions }</strong></div>
                            </div>
                        </Col>
                    )}
                </Row> }
            </BlockContent>
        </Block>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default withState('companyRole', 'setCompanyRole', null)(connect(stateToProps)(asyncConnect({
    getCompanyRoles: getCompanyRoles
        .withPayload(() => ({
            limit: 0
        }))
        .withSuccessHandler(({ setCompanyRole, getCompanyRoles }) => {
            setCompanyRole(path([0, 'id'], getCompanyRoles.data.items));
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getCompanyStatisticPromotionMonths: getCompanyStatisticPromotionMonths
        .withPayload(({ id, user, companyRole }) => ({
            id: id || user.company,
            q: {
                companyRole
            }
        }))
        .withOptions({ resetOnUnmount: true, dispatchOnUpdate: true })
})(PromotionByMonths)));
