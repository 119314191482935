import { createAction } from 'react-async-client';

import * as actions from '../constants/actionTypes';

export const closeModal = createAction(actions.CLOSE_MODAL);
export const hideModal = createAction(actions.HIDE_MODAL);
export const showModal = createAction(actions.SHOW_MODAL);

export const openCompanyModal = createAction(actions.OPEN_COMPANY_MODAL);
export const openParticipantModal = createAction(actions.OPEN_PARTICIPANT_MODAL);
export const openAdminModal = createAction(actions.OPEN_ADMIN_MODAL);
export const openStageModal = createAction(actions.OPEN_STAGE_MODAL);
export const openMaterialModal = createAction(actions.OPEN_MATERIAL_MODAL);
export const openCandidateFormModal = createAction(actions.OPEN_CANDIDATE_FORM_MODAL);
export const openFAQModal = createAction(actions.OPEN_FAQ_MODAL);
export const openMaterialViewModal = createAction(actions.OPEN_MATERIAL_VIEW_MODAL);
export const openParticipantImportModal = createAction(actions.OPEN_PARTICIPANT_IMPORT_MODAL);
export const openParticipationRequestModal = createAction(actions.OPEN_PARTICIPATION_REQUEST_MODAL);
export const openTopicModal = createAction(actions.OPEN_TOPIC_MODAL);
export const openMentionModal = createAction(actions.OPEN_MENTION_MODAL);
export const openNewsModal = createAction(actions.OPEN_NEWS_MODAL);
export const openCompanySettingsModal = createAction(actions.OPEN_COMPANY_SETTINGS_MODAL);
export const openAdminClientModal = createAction(actions.OPEN_ADMIN_CLIENT_MODAL);
export const openCompanyRolesModal = createAction(actions.OPEN_COMPANY_ROLES_MODAL);
export const openImportInvitesModal = createAction(actions.OPEN_IMPORT_INVITES_MODAL);
export const openInviteModal = createAction(actions.OPEN_INVITE_MODAL);
export const openWebinarModal = createAction(actions.OPEN_WEBINAR_MODAL);
export const openTestModal = createAction(actions.OPEN_TEST_MODAL);
export const openCompanyWebinarModal = createAction(actions.OPEN_COMPANY_WEBINAR_MODAL);
export const openEventParticipantsModal = createAction(actions.OPEN_EVENT_PARTICIPANTS_MODAL);
export const openParticipantStatusModal = createAction(actions.OPEN_PARTICIPANT_STATUS_MODAL);
export const openEventPartcipantsAddModal = createAction(actions.OPEN_EVENT_PARTICIPANTS_ADD_MODAL);
export const openConsultantSelectModal = createAction(actions.OPEN_CONSULTANT_SELECT_MODAL);
export const openConsultationViewModal = createAction(actions.OPEN_CONSULTATION_VIEW_MODAL);
export const openConsultationTitleModal = createAction(actions.OPEN_CONSULTATION_TITLE_MODAL);
export const openEventViewModal = createAction(actions.OPEN_EVENT_VIEW_MODAL);
export const openAddMeetingModal = createAction(actions.OPEN_ADD_MEETING_MODAL);
export const openCancelConsultationModal = createAction(actions.OPEN_CANCEL_CONSULTATION_MODAL);
export const openStatusCommentModal = createAction(actions.OPEN_STATUS_COMMENT_MODAL);
export const openParticipantCommentModal = createAction(actions.OPEN_PARTICIPANT_COMMENT_MODAL);
export const openParticipantsTableSettingsModal = createAction(actions.OPEN_PARTICIPANTS_TABLE_SETTINGS_MODAL);
export const openFeedbackModal = createAction(actions.OPEN_FEEDBACK_MODAL);
export const openParticipantFeedbackModal = createAction(actions.OPEN_PARTICIPANT_FEEDBACK_MODAL);
export const openParticipantDocumentModal = createAction(actions.OPEN_PARTICIPANT_DOCUMENT_MODAL);
export const openLinkModal = createAction(actions.OPEN_LINK_MODAL);
export const openLinkPreviewModal = createAction(actions.OPEN_LINK_PREVIEW_MODAL);
export const openCompanyMessageTemplateModal = createAction(actions.OPEN_COMPANY_MESSAGE_TEMPLATE_MODAL);
