 import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import Companies from './Companies';
import NoMatch from './NoMatch';
import Participant from './Participant';
import ParticipationRequests from './ParticipationRequests';
import Admin from './Admin';
import Stages from './Stages';
import FAQ from './FAQ';
import Invites from './Invites';
import Stage from './Stage';
import Settings from './settings/Settings';
import Mentions from './Mentions';
import News from './News';
import Statistics from './Statistics';
import Feedbacks from './Feedbacks';
import Links from './Links';
import AdminsTabs from './AdminsTabs';
import ClientAdmin from './ClientAdmin';
import SecureRoute from './SecureRoute';
import { ADMINS, CLIENT_ADMIN, CLIENT_MANAGER, SUPER_ADMIN } from '../constants/roles';
import CompanyRoles from './CompanyRoles';
import Company from './Company';
import WebinarThemes from './WebinarThemes';
import TestThemes from './TestThemes';
import CompanyWebinars from './CompanyWebinars';
import Calendar from './Calendar';
import ManagerParticipants from './ManagerParticipants';
import ClientAdminDashboard from './statistics/ClientAdminDashboard';
import { connect } from 'react-redux';
import { getUser } from '../actions/asyncActions';
import { includes } from 'ramda';

const UserRoutes = ({ roles }) => (
    <Switch>
        <SecureRoute path='/' exact component={includes(CLIENT_ADMIN, roles) || includes(CLIENT_MANAGER, roles) ? ClientAdminDashboard : Companies} roles={[...ADMINS, CLIENT_ADMIN, CLIENT_MANAGER]} />
        <SecureRoute path='/company/:id' component={Company} roles={ADMINS} />
        <SecureRoute path='/participants/:id' component={Participant} />
        <SecureRoute path='/participants' component={ManagerParticipants} roles={[CLIENT_ADMIN, CLIENT_MANAGER]} />
        <SecureRoute path='/participation_requests' component={ParticipationRequests} roles={[CLIENT_ADMIN, CLIENT_MANAGER]} />
        <SecureRoute path='/admin/:id' component={Admin} roles={[SUPER_ADMIN]} />
        <SecureRoute path='/admins' component={AdminsTabs} roles={[SUPER_ADMIN]} />
        <SecureRoute path='/client/:id' component={ClientAdmin} roles={ADMINS} />
        {/* <SecureRoute path='/topics' component={Topics} /> */}
        <SecureRoute path='/stages/:id/:type?' component={Stage} roles={ADMINS} />
        <SecureRoute path='/stages' component={Stages} roles={ADMINS} />
        <SecureRoute path='/statistics' component={Statistics} roles={ADMINS} />
        <SecureRoute path='/news' component={News} roles={ADMINS} />
        <SecureRoute path='/faq' component={FAQ} roles={ADMINS} />
        <SecureRoute path='/mentions' component={Mentions} roles={ADMINS} />
        <SecureRoute path='/feedbacks/:type?' component={Feedbacks} roles={ADMINS} />
        <SecureRoute path='/links/:type?' component={Links} roles={[SUPER_ADMIN]} />
        <SecureRoute path='/invites' component={Invites} roles={[CLIENT_ADMIN, CLIENT_MANAGER]} />
        <SecureRoute path='/settings/:type?' component={Settings} roles={ADMINS} />
        <SecureRoute path='/roles' component={CompanyRoles} roles={[CLIENT_ADMIN, CLIENT_MANAGER]} />
        <SecureRoute path='/themes' component={WebinarThemes} roles={[...ADMINS, CLIENT_MANAGER]} />
        <SecureRoute path='/test_themes' component={TestThemes} roles={[...ADMINS, CLIENT_MANAGER]} />
        <SecureRoute path='/webinars' component={CompanyWebinars} roles={[CLIENT_MANAGER, CLIENT_ADMIN]} />
        <SecureRoute path='/calendar' component={Calendar} roles={ADMINS} />

        <Route component={NoMatch} />
    </Switch>
);

const stateToProps = state => ({
    roles: getUser.selectData(state).roles || []
});

export default connect(stateToProps)(UserRoutes);
