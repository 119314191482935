import React, { Component } from 'react';
import { Button, Col, Row, Spin } from 'antd';
import { isNil, path, pathOr } from 'ramda';

import { getName } from '@meconsultant/common/lib/utils/resume';
import { LoaderWrapper } from './Stages';
import { getMessageTemplates, getUser } from '../actions/asyncActions';
import { asyncConnect } from 'react-async-client/lib/asyncConnect';
import { toSuccess } from 'react-async-client/lib/actionHelpers';
import { takeEvery } from 'redux-saga/effects';
import { POST_MESSAGE_TEMPLATE, PUT_MESSAGE_TEMPLATE } from '../constants/actionTypes';
import { openCompanyMessageTemplateModal } from '../actions/modalActions';
import { MessageTemplate } from './forms/CompanyMessageTemplateForm';

class CompanyInfo extends Component {
    renderRow = (label, value) => {
        return !isNil(value) && <Row className='detail-row'>
            <Col span={8}>{ label }</Col>
            <Col span={16}>{ value }</Col>
        </Row>;
    }

    render() {
        const { getCompany: { data: company, meta }, openCompanyMessageTemplateModal, user, getMessageTemplates } = this.props;
        const consultants = path(['_embedded', 'consultants'], company);
        const participantInviteTemplate = path(['data', 'items', 0], getMessageTemplates);

        return meta.pending && !meta.lastSucceedAt ?
            <LoaderWrapper><p><Spin /></p></LoaderWrapper> :
            <div className='detail-block clearfix'>
                <div className='detail-info-block'>
                    { this.renderRow('Название', company.name) }
                    { this.renderRow('Отрасль', company.industryName) }
                    { this.renderRow('Срок начала взаимодействия', company.dateStart ? new Date(company.dateStart).toLocaleDateString('ru') : null) }
                    { this.renderRow('Срок окончания взаимодействия', company.dateFinish ? new Date(company.dateFinish).toLocaleDateString('ru') : null) }
                    { this.renderRow('Сервисы', company.modules && company.modules.length ? company.modules.map(item =>
                        <div key={`module-${item.id}`}>
                            { item.title } { item.themes ? `(${pathOr([], ['_embedded', 'themes'], item).reduce((res, cur, index) => index > 0 ? `${res}, ${cur.title}` : cur.title, '')})` : '' }
                        </div>
                    ) : null)}
                    { this.renderRow('Консультанты', consultants && consultants.length ? consultants.reduce((res, consultant, i) => res + (i ? ', ' : '') + getName(consultant.lastName, consultant.firstName, consultant.middleName), '') : null) }
                </div>
                <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'space-between', paddingTop: 15, paddingBottom: 0, marginBottom: 10 }}>
                    <h1>Шаблон приветственного письма</h1>
                    {user.superAdmin &&
                        <Button
                            type='primary'
                            onClick={() => openCompanyMessageTemplateModal(participantInviteTemplate || { company: company.id, type: 'participant_invite' })}
                            disabled={getMessageTemplates.meta.pending}
                        >
                            { participantInviteTemplate ? 'Редактировать' : 'Добавить' }
                        </Button>
                    }
                </div>
                { getMessageTemplates.meta.pending ? <p><Spin /></p> : participantInviteTemplate ?
                    <MessageTemplate
                        welcomeText={participantInviteTemplate.welcomeText}
                        contentText={participantInviteTemplate.content}
                        farewellText={<div style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: participantInviteTemplate.farewellText }} />} /> :
                    <div style={{ textAlign: 'center', padding: 10 }}>Нет добавленного шаблона</div>
                }
            </div>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    getMessageTemplates: getMessageTemplates
        .withPayload(({ getCompany }) => ({
            q: {
                company: getCompany.data.id,
                type: 'participant_invite'
            }
        }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(POST_MESSAGE_TEMPLATE), toSuccess(PUT_MESSAGE_TEMPLATE)], () => {
                getProps().getMessageTemplates.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, dispatchOnUpdate: true, resetOnUnmount: true }),
}, stateToProps, { openCompanyMessageTemplateModal })(CompanyInfo);
