import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { includes, omit, path, toPairs } from 'ramda';
import { withFormWrapper, GENDER, Textarea, DOCUMENT_TYPES } from '@meconsultant/common';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import Input from './formComponents/Input';
import SearchSelect from './formComponents/SearchSelect';
import { getUser, getCompanyRoles, getAdmins, getIndustryDictionary, getFunctionsDictionary } from '../../actions/asyncActions';
import RadioButtons from './formComponents/RadioButtons';
import DatePicker from './formComponents/DatePicker';
import { ADMIN, SUPER_ADMIN } from '../../constants/roles';
import { PhoneInput } from './formComponents/MaskedInput';
import File from './formComponents/File';

export const isAdminRole = props => includes(ADMIN, props.user.roles) || includes(SUPER_ADMIN, props.user.roles);

const parseDict = items => items ? toPairs(items).map(([id, value]) => ({ id, value })) : [];

const ParticipantDate = styled(Col)`
    .ant-picker {
        width: 90%;
    }
`;

class ParticipantForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting, values, item, user } = this.props;

        return <Fragment>
            <Field
                name='companyRole'
                component={SearchSelect}
                label='Роль'
                action={getCompanyRoles}
                filter={values.company ? { company: values.company } : null}
                namePath='name' />
            <Field
                name='lastName'
                component={Input}
                label='Фамилия'
                disabled={item.depersonalized} />
            <Field
                name='firstName'
                component={Input}
                label='Имя'
                disabled={item.depersonalized} />
            <Field
                name='middleName'
                component={Input}
                label='Отчество'
                disabled={item.depersonalized} />
            <Field
                name='gender'
                component={RadioButtons}
                label='Пол'
                options={GENDER} />
            <Field
                name='email'
                component={Input}
                label='Email'
                type='email'
                disabled={item.depersonalized} />
            <Field
                name='phone'
                component={PhoneInput}
                label='Телефон'
                disabled={item.depersonalized} />
            <Field
                name='location'
                component={Input}
                label='Город' />
            <Field
                name='industry'
                component={SearchSelect}
                label='Отрасль'
                action={getIndustryDictionary}
                parseAsync={parseDict}
                allowClear />
            <Field
                name='position'
                component={Input}
                label='Должность' />
            <Field
                name='function'
                component={SearchSelect}
                label='Функция'
                action={getFunctionsDictionary}
                parseAsync={parseDict}
                allowClear />
            {isAdminRole(this.props) && user.superAdmin &&
                <Field
                    name='consultant'
                    component={SearchSelect}
                    action={getAdmins}
                    label='Консультант'
                    namePath='name'
                    filter={{ company: item.company }}
                    getNamePath={item => `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`}
                    allowClear
                />
            }
            <Row>
                <ParticipantDate sm={8} xs={24}>
                    <Field
                        name='programStartedAt'
                        component={DatePicker}
                        label='Дата увольнения' />
                </ParticipantDate>
            </Row>
            <Row>
                <ParticipantDate sm={8} xs={24}>
                    <Field
                    name='expiredAt'
                    component={DatePicker}
                    label='Дата окончания доступа' />
                </ParticipantDate>
            </Row>
            { isAdminRole(this.props) &&
                <Row>
                    <ParticipantDate sm={8} xs={24}>
                        <Field
                            name='programFinishedAt'
                            component={DatePicker}
                            label='Дата завершения программы'
                            min={moment().startOf('day')}
                            allowClear={false} />
                    </ParticipantDate>
                </Row>
            }
            <Row>
                <ParticipantDate sm={8} xs={24}>
                    <Field
                        name='employedAt'
                        component={DatePicker}
                        label='Дата трудоустройства' />
                </ParticipantDate>
                <Col sm={16} xs={24}>
                    <Field
                        name='employedIn'
                        component={Input}
                        label='Компания' />
                </Col>
            </Row>
            <h2>Информация для Experium</h2>
            <Field
                name='resume'
                component={File}
                accept={DOCUMENT_TYPES}
                file={path(['_embedded', 'resume'], values)}
                label='Резюме' />
            <Field
                name='info'
                component={Textarea}
                label='Сведения о кандидате'
                size='large'
                autosize={{minRows: 2}}
            />
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
        </Fragment>;
    }
}

const validationSchema = props => {
    const isAdmin = isAdminRole(props);
    const { depersonalized } = props.item;

    return yup.object().shape({
        lastName: depersonalized ? yup.string().nullable() : yup.string().nullable().required(),
        firstName: depersonalized ? yup.string().nullable() : yup.string().nullable().required(),
        email: depersonalized ? yup.string().nullable() : yup.string().email().nullable().required(),
        company: isAdmin ? yup.string().nullable().required() : yup.string().nullable(),
        companyRole: yup.string().nullable().required()
    });
};

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(withFormWrapper(ParticipantForm, {
    mapPropsToValues: props => {
        const isAdmin = isAdminRole(props);
        const item = (isAdmin || props.item.id) ? props.item : {
            company: props.user.company
        };

        return omit(['createdAt', 'updatedAt'], item);
    },
    validationSchema,
    subscriptions: { values: true }
}));
