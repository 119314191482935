import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { connect } from 'react-redux';

import { openLinkPreviewModal } from '../../actions/modalActions';
import { postEmployeetLink, putEmployeeLink, deleteEmployeeLink } from '../../actions/asyncActions';
import LinkForm from '../forms/LinkForm';
import { withRouter } from 'react-router';

class LinkModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        params: PropTypes.object,
        postEmployeetLink:  PropTypes.object,
        putEmployeeLink: PropTypes.object,
        deleteEmployeeLink: PropTypes.object
    };

    render() {
        const { modal, params, postEmployeetLink, putEmployeeLink, deleteEmployeeLink } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать ссылку' : 'Добавить ссылку'}
            footer={null}>
            <LinkForm
                formAction={params ? putEmployeeLink : postEmployeetLink}
                deleteEmployeeLink={deleteEmployeeLink}
                item={params} />
        </Modal>;
    }
}

export default withRouter(connect(null, { openLinkPreviewModal })(withAsyncActions({
    postEmployeetLink: postEmployeetLink
        .withSuccessHandler(({ close, postEmployeetLink, openLinkPreviewModal }) => {
            const item = {
                ...postEmployeetLink.data,
            };
            message.success('Ссылка была успешно добавлена');
            close();
            openLinkPreviewModal(item);
        })
        .withOptions({ resetOnUnmount: true }),
    putEmployeeLink: putEmployeeLink
        .withSuccessHandler(({ close }) => {
            message.success('Ссылка была успешно отредактирована');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    deleteEmployeeLink: deleteEmployeeLink
        .withSuccessHandler(({ close, params, history }) => {
            message.success('Ссылка была успешно удалена');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
})(LinkModal)));
