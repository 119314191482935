import { Col, Row, Select } from 'antd';
import { pathOr } from 'ramda';
import React, { Component, Fragment } from 'react';
import { asyncConnect } from 'react-async-client';
import { getAdmins, getUser } from '../../actions/asyncActions';
import ConsultationsByCompany from './modules/ConsultationsByCompany';
import ConsultationsByRole from './modules/ConsultationsByRole';
import PromotionByCompany from './modules/PromotionByCompany';
import WebinarsByCompany from './modules/WebinarsByCompany';
import { Content } from './ModulesStatistic';
import Statuses from './participants/Statuses';

class ConsultantsStatistic extends Component {
    state = {
        consultant: null
    }

    onChangeConsultant = consultant => this.setState({ consultant });

    render() {
        const { company, getAdmins } = this.props;
        const consultants = pathOr([], ['items'], getAdmins.data);

        return <Content>
            <Row>
                <Col xs={24} sm={8}>
                    <Select
                        value={this.state.consultant}
                        onChange={this.onChangeConsultant}
                        placeholder='Консультант'
                        style={{ width: '100%' }}>
                        { consultants.map(consultant =>
                            <Select.Option key={consultant.id} value={consultant.id}>
                                { consultant.lastName } { consultant.firstName } { consultant.middleName || '' }
                            </Select.Option>
                        )}
                    </Select>
                </Col>
            </Row>
            { this.state.consultant ?
                <Fragment>
                    <Row gutter={15}>
                        <Col sm={12}>
                            <Statuses id={company} consultant={this.state.consultant} />
                        </Col>
                        <Col sm={12}>
                            <ConsultationsByCompany id={company} consultant={this.state.consultant} />
                            <WebinarsByCompany id={company} consultant={this.state.consultant} />
                            <PromotionByCompany id={company} consultant={this.state.consultant} />
                        </Col>
                    </Row>
                    <ConsultationsByRole id={company} consultant={this.state.consultant} />
                </Fragment> :
                <div style={{ padding: 15, textAlign: 'center' }}>Выберите консультанта</div>
            }
        </Content>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    getAdmins: getAdmins
        .withPayload(() => ({
            limit: 0,
            q: {
                enabled: true
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, stateToProps)(ConsultantsStatistic);
