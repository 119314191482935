import { Select, Spin } from 'antd';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { connect } from 'react-redux';
import ReactHighcharts from 'react-highcharts';

import { getCompanyStatisticEventsSpeakers, getUser } from '../../../actions/asyncActions';
import { Block, BlockContent, BlockHeader } from './WebinarsByCompany';
import { find, path, pathOr, propEq, values } from 'ramda';
import { withState } from 'recompose';

class WebinarsBySpeakers extends Component {
    getConfig = () => {
        const { getCompanyStatisticEventsSpeakers: { data }} = this.props;
        const average = Math.ceil(values(pathOr({}, ['speakers'], data)).map(item => item.countAverageParticipants).reduce((res, cur) => res + cur, 0) / values(pathOr({}, ['speakers'], data)).length);

        return {
            chart: {
                type: 'column'
            },
            title: {
                text: null
            },
            xAxis: {
                categories: values(pathOr({}, ['speakers'], data)).map(item => item.name),
                gridLineDashStyle: 'dash',
                gridLineWidth: 1
            },
            yAxis: [{
                allowDecimals: false,
                min: 0,
                title: {
                    text: 'Вебинары'
                },
                gridLineDashStyle: 'dash',
                gridLineWidth: 1
            }, {
                allowDecimals: false,
                min: 0,
                title: {
                    text: 'Среднее количество участников'
                },
                opposite: true,
                gridLineDashStyle: 'dash',
                gridLineWidth: 1
            }],
            credits: {
                enabled: false
            },
            tooltip: {
                enabled: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Вебинары (план)',
                color: '#959EA7',
                data: values(pathOr({}, ['speakers'], data)).map(item => item.countEventsScheduled)
            }, {
                name: 'Вебинары (факт)',
                color: '#2B3D4F',
                data: values(pathOr({}, ['speakers'], data)).map(item => item.countEvents)
            }, {
                name: 'Среднее количество участников',
                color: '#47AAC4',
                data: values(pathOr({}, ['speakers'], data)).map(item => item.countAverageParticipants)
            }, {
                name: null,
                type: 'line',
                data: values(pathOr({}, ['speakers'], data)).map(() => average),
                marker: {
                    enabled: false
                },
                color: '#2B3D4F',
                dashStyle: 'ShortDash',
                lineWidth: 1,
                yAxis: 1,
                enableMouseTracking: false,
                showInLegend: false,
            }]
        };
    }

    render() {
        const { eventTheme, setEventTheme, themes, user, getCompanyStatisticEventsSpeakers: { meta }} = this.props;
        const eventThemes = themes || pathOr([], ['_embedded', 'themes'], find(propEq('id', 'events'), pathOr([], ['_embedded', 'company', 'modules'], user)));

        return <Block>
            <BlockHeader>
                <h3>ВЕБИНАРЫ ПО СПИКЕРАМ</h3>
                <Select
                    value={eventTheme || undefined}
                    onChange={value => setEventTheme(value)}
                    style={{ width: 250 }}
                    allowClear={false}>
                    { eventThemes.map(item => <Select.Option key={item.id} value={item.id}>{ item.title }</Select.Option>) }
                </Select>
            </BlockHeader>
            <BlockContent>
                { meta.pending ? <Spin /> : <div>
                    <ReactHighcharts config={this.getConfig()} />
                </div> }
            </BlockContent>
        </Block>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(withState('eventTheme', 'setEventTheme', ({ user, themes }) => {
    const eventThemes = themes || pathOr([], ['_embedded', 'themes'], find(propEq('id', 'events'), pathOr([], ['_embedded', 'company', 'modules'], user)));
    return path([0, 'id'], eventThemes);
})(asyncConnect({
    getCompanyStatisticEventsSpeakers: getCompanyStatisticEventsSpeakers
        .withPayload(({ id, user, eventTheme }) => ({
            id: id || user.company,
            q: {
                eventTheme
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(WebinarsBySpeakers)));
