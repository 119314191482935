import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Button } from 'antd';
import * as yup from 'yup';
import { MailOutlined, LockOutlined } from '@ant-design/icons';

import { withFormWrapper } from '@meconsultant/common';
import Input from './formComponents/Input';

class LoginForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting } = this.props;

        return <Fragment>
            <Field
                name='username'
                component={Input}
                placeholder='E-mail'
                htmlType='email'
                icon={<MailOutlined />} />
            <Field
                name='password'
                component={Input}
                placeholder='Пароль'
                htmlType='password'
                icon={<LockOutlined />} />
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Войти
            </Button>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    username: yup.string().email().required(),
    password: yup.string().required()
});

export default withFormWrapper(LoginForm, {
    validationSchema
});
