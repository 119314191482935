import { Select, Spin } from 'antd';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { connect } from 'react-redux';
import ReactHighcharts from 'react-highcharts';

import { getCompanyRoles, getCompanyStatisticPromotionRoles, getUser } from '../../../actions/asyncActions';
import { Block, BlockContent, BlockHeader } from './WebinarsByCompany';
import { keys, path, pathOr, values } from 'ramda';
import { withState } from 'recompose';

class ConsultationsByRole extends Component {
    getConfig = () => {
        const { getCompanyStatisticPromotionRoles: { data }} = this.props;

        return {
            chart: {
                type: 'column'
            },
            title: {
                text: null
            },
            xAxis: {
                categories: keys(pathOr({}, ['promotions'], data)),
                gridLineDashStyle: 'dash',
                title: {
                    text: 'Программы'
                },
                gridLineWidth: 1
            },
            yAxis: [{
                allowDecimals: false,
                min: 0,
                title: {
                    text: 'Участники'
                },
                gridLineDashStyle: 'dash',
                gridLineWidth: 1
            }],
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    stacking: 'normal'
                }
            },
            tooltip: {
                formatter: function () {
                    return `<b>Программа №${this.x}</b><br><span>Кол-во участников: <b>${pathOr(0, ['promotions', this.x, 'countParticipants'], data)} (${pathOr(0, ['consultations', this.x, 'countParticipantsPercents'], data)}%)</b></span>`;
                }
            },
            series: [{
                name: 'Количество участников',
                color: '#959EA7',
                showInLegend: false,
                data: values(pathOr({}, ['promotions'], data)).map(item => item.countParticipants),
                stack: 'promotion'
            }, {
                name: 'Количество участников (%)',
                color: '#2B3D4F',
                showInLegend: false,
                data: values(pathOr({}, ['promotions'], data)).map(item => item.countParticipantsPercents),
                stack: 'promotion',
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    borderWidth: 0,
                    style: {
                        fontSize: 10,
                        fontWeight: 'normal'
                    },
                    formatter: function() {
                        return this.y ? `${this.y}%` : null;
                    }
                }
            }]
        };
    }

    render() {
        const { getCompanyStatisticPromotionRoles: { meta }, companyRole, setCompanyRole, getCompanyRoles } = this.props;

        return <Block>
            <BlockHeader>
                <h3>ПРОФЕССИОНАЛЬНАЯ ПОДДЕРЖКА ПО ПРОГРАММАМ</h3>
                <Select
                    value={companyRole || undefined}
                    onChange={value => setCompanyRole(value)}
                    style={{ width: 250 }}
                    allowClear={false}>
                    { pathOr([], ['data', 'items'], getCompanyRoles).map(item => <Select.Option key={item.id} value={item.id}>{ item.name }</Select.Option>) }
                </Select>
            </BlockHeader>
            <BlockContent>
                { meta.pending ? <Spin /> : <div>
                    <ReactHighcharts config={this.getConfig()} />
                </div> }
            </BlockContent>
        </Block>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default withState('companyRole', 'setCompanyRole', null)(connect(stateToProps)(asyncConnect({
    getCompanyRoles: getCompanyRoles
        .withPayload(({ id }) => ({
            q: {
                company: id
            },
            limit: 0
        }))
        .withSuccessHandler(({ setCompanyRole, getCompanyRoles }) => {
            setCompanyRole(path([0, 'id'], getCompanyRoles.data.items));
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getCompanyStatisticPromotionRoles: getCompanyStatisticPromotionRoles
        .withPayload(({ id, user, companyRole }) => ({
            id: id || user.company,
            q: {
                companyRole
            }
        }))
        .withOptions({ resetOnUnmount: true, dispatchOnUpdate: true })
})(ConsultationsByRole)));
