import React, { Component } from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { withFieldWrapper } from '@meconsultant/common';

class Checkbox extends Component {
    onChange = e => this.props.onChange(e.target.checked);

    render() {
        const { input: { value }, text } = this.props;

        return <AntdCheckbox
            checked={!!value}
            onChange={this.onChange}>
            { text }
        </AntdCheckbox>;
    }
}

export default withFieldWrapper(Checkbox);
