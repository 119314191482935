import { CopyOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Avatar, Button, message } from 'antd';
import { find, includes, path, pathOr, propEq } from 'ramda';
import React, { Component, Fragment } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import pluralize from 'pluralize-ru';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getEvents, getUser } from '../actions/asyncActions';
import TableList from './TableList';
import { getFileView } from '../constants/urls';
import { openCompanyWebinarModal, openEventParticipantsModal } from '../actions/modalActions';
import { DELETE_EVENT, POST_EVENT, POST_EVENT_PARTICIPATION, PUT_EVENT, PUT_EVENT_PARTICIPATION_ATTEND, PUT_EVENT_PARTICIPATION_ATTEND_CANCEL } from '../constants/actionTypes';
import CompanyWebinarsFilter from './filters/CompanyWebinarsFilter';
import { CLIENT_MANAGER } from '../constants/roles';
import locationIcon from '../assets/img/location.svg';
import { getOnlineIcon } from '../utils/events';

export const Centered = styled.div`
    display: flex;
    align-items: center;
    img {
        margin-right: 5px;
    }
`;

class CompanyWebinars extends Component {
    onCopy = (_, result) => {
        result ? message.success('Ссылка успешно скопирована') : message.error('Не удалось скопировать ссылку');
    };

    getColumns = () => {
        return [
            {
                key: 'theme',
                render: item => <div>
                    {!includes(CLIENT_MANAGER, this.props.user.roles || []) && this.props.user.superAdmin ?
                        <span className='span-link' onClick={() => this.props.openCompanyWebinarModal({ item, themes: this.getThemes() })} style={{ fontWeight: 600 }}>{path(['_embedded', 'theme', 'title'], item)}</span> :
                        <span style={{ fontWeight: 600 }}>{path(['_embedded', 'theme', 'title'], item)}</span>
                    }
                    {item.link ?
                        (getOnlineIcon(item.link) ? <CopyToClipboard text={item.link} onCopy={this.onCopy}>
                            <Centered><img src={getOnlineIcon(item.link).icon} alt='icon' /> {getOnlineIcon(item.link).text} <CopyOutlined style={{ color: '#C2CFE0', cursor: 'pointer', marginLeft: 5 }} /></Centered>
                        </CopyToClipboard> : <CopyToClipboard text={item.link} onCopy={this.onCopy}>
                            <CopyOutlined style={{ color: '#C2CFE0', cursor: 'pointer', marginLeft: 5 }} />
                        </CopyToClipboard>) : null
                    }
                    {item.locationName && <Centered><img src={locationIcon} alt='location' /> {item.locationName}</Centered>}
                </div>
            },
            {
                key: 'date',
                dataIndex: 'date',
                align: 'center',
                render: (date, item) => <div>
                    <div style={{ color: '#333333', textAlign: 'center' }}>{moment(date).format('DD.MM.YYYY')}</div>
                    <div style={{ fontSize: 12, color: '#333333', textAlign: 'center' }}>{moment(date).format('HH:mm')} - {moment(date).add(item.duration, 'minutes').format('HH:mm')}</div>
                </div>
            },
            {
                key: 'duration',
                dataIndex: 'duration',
                align: 'center',
                render: duration => {
                    const hours = Math.floor(duration / 60);
                    const minutes = duration - hours * 60;

                    return <div style={{ fontSize: 11, fontWeight: 600, color: '#fff', background: '#90A0B7', padding: '3px 8px', display: 'inline-block' }}>
                        {`${hours ? `${hours} ${pluralize(hours, '', 'час', 'часа', 'часов')} ` : ''}${minutes || ''} ${pluralize(minutes, '', 'минута', 'минуты', 'минут')}`}
                    </div>;
                }
            },
            {
                key: 'speakers',
                dataIndex: ['_embedded', 'speakers'],
                render: (speakers, item) => (speakers || []).map(speaker =>
                    <div key={`speaker-${item.id}-${speaker.id}`} style={{ marginBottom: 5 }}>
                        <Avatar size='small' src={speaker.photo ? getFileView(speaker.photo) : null} icon={<UserOutlined />} /> {speaker.lastName} {speaker.firstName} {speaker.middleName || ''}
                    </div>
                )
            },
            {
                key: 'amount',
                align: 'center',
                render: item => {
                    const amount = item.countAttended + item.countScheduled;

                    return <div style={{ textAlign: 'center', lineHeight: 1.1, cursor: 'pointer' }} onClick={() => this.props.openEventParticipantsModal({
                        title: path(['_embedded', 'theme', 'title'], item),
                        id: item.id,
                        company: item.company,
                        theme: item.theme
                    })}>
                        <div className="span-link" style={{ fontSize: 24, fontWeight: 500 }}>{amount}</div>
                        <div style={{ fontSize: 11, color: '#90A0B7' }}>{pluralize(amount, 'участников', 'участник', 'участника', 'участников')}</div>
                    </div>;
                }
            }
        ];
    }

    getThemes = () => this.props.company ? pathOr([], ['_embedded', 'themes'], find(propEq('id', 'events'), this.props.company.modules || [])) : [];

    render() {
        const { company, openCompanyWebinarModal, user } = this.props;

        return <div>
            {!includes(CLIENT_MANAGER, user.roles || []) && <Fragment>
                <Alert
                    style={{ margin: 15, marginTop: 0 }}
                    message='Мероприятия помогут разобраться в том, что из себя представляет современный рынок труда, с чего начать поиск работы, как эффективно взаимодействовать с компаниями-работодателями и управлять карьерой.' />
                <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'space-between', padding: 15, paddingTop: 0, paddingBottom: 0 }}>
                    <h1>Список вебинаров и семинаров</h1>
                    {user.superAdmin &&
                        <Button
                            type='primary'
                            onClick={() => openCompanyWebinarModal({ item: { company: company.id }, themes: this.getThemes() })}>
                            Добавить
                        </Button>
                    }
                </div>
            </Fragment>}
            <TableList
                action={getEvents}
                columns={this.getColumns()}
                staticFilter={{ company: company ? company.id : null }}
                showHeader={false}
                filterForm={<CompanyWebinarsFilter themes={includes(CLIENT_MANAGER, user.roles || []) ?
                    pathOr([], ['_embedded', 'themes'], find(propEq('id', 'events'), pathOr([], ['_embedded', 'company', 'modules'], user))) :
                    pathOr([], ['_embedded', 'themes'], find(propEq('id', 'events'), pathOr([], ['modules'], company)))} />}
                refreshActions={[POST_EVENT, PUT_EVENT, DELETE_EVENT, PUT_EVENT_PARTICIPATION_ATTEND, PUT_EVENT_PARTICIPATION_ATTEND_CANCEL, POST_EVENT_PARTICIPATION]} />
        </div>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps, { openCompanyWebinarModal, openEventParticipantsModal })(CompanyWebinars);
