import { Spin } from 'antd';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import ReactHighcharts from 'react-highcharts';
import { connect } from 'react-redux';
import { pathOr, filter } from 'ramda';

import { getCompanyStatisticParticipants, getUser } from '../../../actions/asyncActions';
import { Block, BlockContent, BlockHeader } from '../modules/WebinarsByCompany';

const PARTICIPANTS_CHART_COLORS = ['#2B3D4F', '#A7B9CF', '#47AAC4'];

class Participants extends Component {
    getConfig = () => {
        const { getCompanyStatisticParticipants } = this.props;

        return {
            chart: {
                type: 'pie',
                width: 280
            },
            title: {
                text: pathOr(0, ['countParticipants'], getCompanyStatisticParticipants.data),
                align: 'center',
                verticalAlign: 'middle',
                y: 10,
                style: {
                    fontSize: 30,
                    fontWeight: 'bold'
                }
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    innerSize: '60%',
                    dataLabels: {
                        enabled: true,
                        distance: -30,
                        style: {
                            fontWeight: 'bold',
                            color: 'white',
                            textOutline: 0,
                            fontSize: 12
                        },
                        formatter: function() {
                            return '<span>' + this.point.y + '%</span>'
                        }
                    },
                }
            },
            colors: PARTICIPANTS_CHART_COLORS,
            series: [{
                data: filter(({ y }) => !!y, pathOr([], ['roles'], getCompanyStatisticParticipants.data).map(item => ({
                    name: item.name,
                    y: item.countParticipantsPercents,
                    value: item.countParticipantsPercents
                }))),
                enableMouseTracking: false
            }]
        }
    }

    render() {
        const { getCompanyStatisticParticipants: { meta, data }} = this.props;

        return <Block>
            <BlockHeader>
                <h3>УЧАСТНИКИ</h3>
                <h3>{ data.countParticipants }</h3>
            </BlockHeader>
            <BlockContent>
                { meta.pending ? <Spin /> :
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ReactHighcharts
                                config={this.getConfig()} />
                            <div style={{ width: '100%', marginLeft: 10 }}>
                                { pathOr([], ['roles'], data).map((role, index) => <div style={{ display: 'flex', justifyContent: 'space-between' }} key={`role-${index}`}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ background: PARTICIPANTS_CHART_COLORS[index], width: 8, height: 8, borderRadius: '50%', marginRight: 5 }} />
                                        { role.name }
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div>{ role.countParticipantsPercents }%</div>
                                        <div style={{ width: 33, textAlign: 'right' }}><strong>{ role.countParticipants }</strong></div>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                }
            </BlockContent>
        </Block>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(asyncConnect({
    getCompanyStatisticParticipants: getCompanyStatisticParticipants
        .withPayload(({ id, user }) => id || user.company)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(Participants));
