import { Progress, Spin } from 'antd';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getCompanyStatisticPromotion, getUser } from '../../../actions/asyncActions';
import { Block, BlockContent, BlockHeader, FactDesc, FactText, Info, InfoBlock, InfoDivider, PlanDesc, PlanText } from './WebinarsByCompany';

const ActiveText = styled(PlanText)`
    color: #47AAC4;
`;

const ActiveDesc = styled(PlanDesc)`
    color: #47AAC4;
`;

class PromotionByCompany extends Component {
    render() {
        const { getCompanyStatisticPromotion: { meta, data }, consultant } = this.props;

        return <Block>
            <BlockHeader>
                <h3>{ consultant ? 'ПРОФЕССИОНАЛЬНАЯ ПОДДЕРЖКА' : 'ПРОФЕССИОНАЛЬНАЯ ПОДДЕРЖКА ПО КОМПАНИИ' }</h3>
            </BlockHeader>
            <BlockContent>
                { meta.pending ? <Spin /> : <div>
                    <Info>
                        <InfoBlock>
                            <FactText>{ data.countFinishedPromotions }</FactText>
                            <FactDesc>завершенные</FactDesc>
                        </InfoBlock>
                        <InfoDivider />
                        <InfoBlock>
                            <ActiveText>{ data.countActivePromotions }</ActiveText>
                            <ActiveDesc>активные</ActiveDesc>
                        </InfoBlock>
                        <InfoDivider />
                        <InfoBlock>
                            <PlanText>{ data.countParticipants }</PlanText>
                            <PlanDesc>всего участников</PlanDesc>
                        </InfoBlock>
                    </Info>
                    <Progress
                        percent={data.countPromotionsPercents}
                        success={{ percent: Math.round(data.countActivePromotions * 100 / data.countParticipants), strokeColor: '#47AAC4' }}
                        strokeColor='#2B3D4F'
                        trailColor='#A7B9CF' />
                </div> }
            </BlockContent>
        </Block>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(asyncConnect({
    getCompanyStatisticPromotion: getCompanyStatisticPromotion
        .withPayload(({ id, user, consultant }) => ({
            id: id || user.company,
            q: {
                consultant
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(PromotionByCompany));
