import { Spin } from 'antd';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { connect } from 'react-redux';
import ReactHighcharts from 'react-highcharts';

import { getCompanyStatisticParticipantsAverageLifetime, getUser } from '../../../actions/asyncActions';
import { Block, BlockContent, BlockHeader } from '../modules/WebinarsByCompany';
import {  pathOr } from 'ramda';

class ParticipantsAverageLifetime extends Component {
    getConfig = () => {
        const { getCompanyStatisticParticipantsAverageLifetime: { data }} = this.props;

        return {
            chart: {
                type: 'column'
            },
            title: {
                text: null
            },
            xAxis: {
                categories: pathOr([], ['roles'], data).map(({ name }) => name),
                gridLineDashStyle: 'dash',
                title: {
                    text: null
                },
                gridLineWidth: 1
            },
            yAxis: [{
                allowDecimals: false,
                min: 0,
                title: {
                    text: 'Кол-во дней'
                },
                gridLineDashStyle: 'dash',
                gridLineWidth: 1
            }],
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    stacking: 'normal'
                }
            },
            series: [{
                color: '#2B3D4F',
                showInLegend: false,
                data: pathOr([], ['roles'], data).map(item => item.averageLifeTime),
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    borderWidth: 0,
                    style: {
                        fontSize: 10,
                        fontWeight: 'normal'
                    }
                }
            }]
        };
    }

    render() {
        const { getCompanyStatisticParticipantsAverageLifetime: { meta }} = this.props;

        return <Block>
            <BlockHeader>
                <h3>СРОК ПРОХОЖДЕНИЯ ПРОГРАММЫ</h3>
            </BlockHeader>
            <BlockContent>
                { meta.pending ? <Spin /> : <div>
                    <ReactHighcharts config={this.getConfig()} />
                </div> }
            </BlockContent>
        </Block>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(asyncConnect({
    getCompanyStatisticParticipantsAverageLifetime: getCompanyStatisticParticipantsAverageLifetime
        .withPayload(({ id, user }) => id || user.company)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(ParticipantsAverageLifetime));
