import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import ParticipationRequestForm from '../forms/ParticipationRequestForm';
import { putParticipationRequests } from '../../actions/asyncActions';

class ParticipationRequestModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        params: PropTypes.object,
        putParticipationRequests: PropTypes.object,
    };

    render() {
        const { modal, putParticipationRequests, params } = this.props;

        return <Modal
            {...modal}
            title={'Добавить участника'}
            footer={null}>
            <ParticipationRequestForm
                item={params}
                formAction={putParticipationRequests} />
        </Modal>
    }
}

export default withAsyncActions({
    putParticipationRequests: putParticipationRequests
        .withSuccessHandler(({ close }) => {
            message.success('Участник был успешно добавлен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(ParticipationRequestModal);
