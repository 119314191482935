import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { putSetConsultant } from '../../actions/asyncActions';
import ConsultantSelectForm from '../forms/ConsultantSelectForm';

class ConsultantSelectModal extends Component {
    render() {
        const { modal, params, putSetConsultant, close } = this.props;

        return <Modal
            {...modal}
            title='Редактировать консультанта'
            footer={null}
            centered
            closable={false}>
            { !params.hideAmount && <div style={{ marginBottom: 10 }}>Назначить консультанта у выбранных участников: <strong>{ params.participants.length }</strong></div>}
            <ConsultantSelectForm
                formAction={putSetConsultant}
                close={close}
                item={{
                    participants: params.participants,
                    consultant: params.consultant
                }} />
        </Modal>;
    }
}

export default withAsyncActions({
    putSetConsultant: putSetConsultant
        .withParams(() => ({ type: 'modal' }))
        .withSuccessHandler(({ close, params }) => {
            message.success('Консультант успешно назначен');
            params.onSuccess && params.onSuccess();
            close();
        })
        .withErrorHandler(() => message.error('Не удалось назначить консультанта'))
        .withOptions({ resetOnUnmount: true })
})(ConsultantSelectModal);
