import React, { Component } from 'react';

import TableList from './TableList';
import { getMaterialsStatistics } from '../actions/asyncActions';
import { FileExcelOutlined } from '@ant-design/icons';
import { getToken } from '../utils/token';
import { Button } from 'antd';

export default class Statistics extends Component {
    getColumns = () => {
        return [
            {
                title: 'Материал',
                key: 'material',
                dataIndex: ['_embedded', 'material', 'title']
            },
            {
                title: 'Количество просмотров материала',
                key: 'countViews',
                dataIndex: 'countViews',
                sorter: true
            },
            {
                title: 'Количество добавленний в избранное',
                key: 'countFavorites',
                dataIndex: 'countFavorites',
                sorter: true
            }
        ];
    }

    getButtons = () => {
        return <Button
            type='primary'
            icon={<FileExcelOutlined />}
            href={`/api/admin/statistic/materials.xlsx?token=${getToken()}`}
            target='_blank'
            rel='noopener noreferrer'
            download>
            Скачать
        </Button>;
    }

    render() {
        return <TableList
            buttons={this.getButtons()}
            action={getMaterialsStatistics}
            columns={this.getColumns()}
            rowKey='material' />
    }
}
