import { Modal, Rate } from 'antd';
import React, { Component } from 'react';
import styled from 'styled-components';

const RateStyled = styled(Rate)`
    font-size: 30px;
`;

const Comment = styled.div`
    margin-top: 32px;
    background: #ECF0F4;
    padding: 16px 24px;
    position: relative;
    font-size: 16px;
    font-style: italic;
    color: #2c3d4e;
    svg {
        width: 30px;
        height: 20px;
        position: absolute;
        opacity: .8;
        &.left {
            top: -9px;
            left: -6px;
        }
        &.right {
            bottom: -9px;
            right: -6px;
        }
    }
`;

class FeedbackModal extends Component {
    render() {
        const { params, modal } = this.props;

        return <Modal
            {...modal}
            title={params.header}
            footer={null}>
            <div><RateStyled disabled value={params.feedback.rating} /></div>
            {params.feedback.comment &&
                <Comment>
                    <svg className="left" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.08329 0.0832928H7.33329L6.16663 2.41663V5.91663H9.66663V2.41663H7.91663L9.08329 0.0832928ZM3.24996 0.0832928H1.49996L0.333293 2.41663V5.91663H3.83329V2.41663H2.08329L3.24996 0.0832928Z" fill="#2B3D4F"/>
                    </svg>
                    { params.feedback.comment }
                    <svg className="right" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.916646 5.91665H2.66665L3.83331 3.58331V0.083313H0.333313V3.58331H2.08331L0.916646 5.91665ZM6.74998 5.91665H8.49998L9.66665 3.58331V0.083313H6.16665V3.58331H7.91665L6.74998 5.91665Z" fill="#2B3D4F"/>
                    </svg>
                </Comment>
            }
        </Modal>;
    }
}

export default FeedbackModal;
