import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { withFieldWrapper } from '@meconsultant/common';
import { equals, findIndex, head, last } from 'ramda';
import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    border: 1px solid #d9d9d9;
    height: 32px;
    display: inline-block;
    width: 100%;
`;

const AmountButton = styled.button`
    background: #F5F5F5;
    display: inline-block;
    width: 32px;
    height: 100%;
    text-align: center;
    padding-top: 5px;
    border: none;
    cursor: pointer;
    &:disabled {
        color: #ccc;
        cursor: not-allowed;
    }
`;

const Input = styled.div`
    display: inline-block;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    text-align: center;
    width: calc(100% - 64px);
    height: 100%;
    padding-top: 5px;
`;

class Amount extends Component {
    onPlus = () => {
        const { customOptions, input, onChange } = this.props;

        if (customOptions) {
            const index = findIndex(equals(input.value), customOptions);

            onChange(customOptions[index + 1]);
        } else {
            onChange(Number(input.value || 0) + 1);
        }
    }

    onMinus = () => {
        const { customOptions, input, onChange } = this.props;

        if (customOptions) {
            const index = findIndex(equals(input.value), customOptions);

            onChange(customOptions[index - 1]);
        } else {
            onChange(Number(input.value || 0) - 1);
        }
    }

    render() {
        const { input, customOptions } = this.props;

        return <Wrapper>
            <AmountButton type='button' disabled={customOptions ? head(customOptions) === input.value : !input.value} onClick={this.onMinus}><MinusOutlined /></AmountButton>
            <Input>{ input.value || 0 }</Input>
            <AmountButton type='button' disabled={customOptions ? last(customOptions) === input.value : false} onClick={this.onPlus}><PlusOutlined /></AmountButton>
        </Wrapper>;
    }
}

export default withFieldWrapper(Amount);
