import React from 'react';
import { Row, Col } from 'antd';
import { is } from 'ramda';

const Toolbar = ({ filterForm, buttons, filterSpan, buttonsSpan, setFilters }) => (
    <Row className='toolbar-row'>
        <Col span={filterSpan || (buttons ? 18 : 24)}>
            { is(Function, filterForm) ? filterForm({ setFilters }) : filterForm }
        </Col>
        { buttons && (
            <Col span={buttonsSpan || 6}>
                { buttons }
            </Col>
        )}
    </Row>
);

export default Toolbar;
