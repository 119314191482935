import React from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { withAsyncActions } from 'react-async-client';
import { Spin } from 'antd';

import UserLayout from './user/UserLayout';
import GuestLayout from './guest/GuestLayout';
import { getUser } from '../../actions/asyncActions';

const checkUser = (user, meta) => {
    if (meta.pending && !meta.lastSucceedAt) {
        return null;
    }

    return !isEmpty(user);
}

const SecureLayout = ({ getUser: { data, meta }, ...props }) => {
    const isAuthenticated = checkUser(data, meta);

    if (isAuthenticated === null) {
        return <Spin />;
    }

    return isAuthenticated ? <UserLayout {...props} superAdmin={data.superAdmin} /> : <GuestLayout {...props} />;
}

export default withRouter(
    withAsyncActions({ getUser })(SecureLayout)
);
