import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { equals } from 'ramda';
import { Form } from 'react-final-form';
import { Row, Col, Form as FormComponent } from 'antd';
import { InitialValuesContext } from '@meconsultant/common';

import { extendSearchPath, getFilters } from '../../utils/urls';

const withFilters = WrappedComponent =>
    class WithFiltersComponent extends  Component {
        static propTypes = {
            values: PropTypes.object,
            location: PropTypes.object,
            history: PropTypes.object
        };

        componentDidUpdate({ values }) {
            if (values && !equals(this.props.values, values)) {
                if (this.props.setFilters) {
                    this.props.setFilters(this.props.values);
                } else {
                    this.setLocation();
                }
            }
        }

        setLocation() {
            const { location, history: { replace }, emptyValues } = this.props;
            const params = {
                filter: JSON.stringify({ ...(emptyValues || {}), ...this.props.values }),
                pagination: null
            };

            replace(extendSearchPath(location, params));
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

const withForm = WrappedComponent => {
    const Component = withFilters(WrappedComponent);

    return class WithFormComponent extends Component {
        static propTypes = {
            location: PropTypes.object,
            initFilters: PropTypes.object
        };

        getInitialValues = () => {
            const { location, initFilters } = this.props;

            return getFilters(location) || initFilters || {};
        }

        renderForm = () => {
            const initialValues = this.getInitialValues();

            return <InitialValuesContext.Provider value={initialValues}>
                <Form
                    initialValues={initialValues}
                    onSubmit={() => {}}
                    render={props =>
                        <FormComponent>
                            <Component {...this.props} {...props} />
                        </FormComponent>
                    } />
            </InitialValuesContext.Provider>;
        }

        render() {
            return this.props.withoutWrapper ?
                this.renderForm() :
                <Row>
                    <Col span={24}>
                        <Row gutter={5}>
                            { this.renderForm() }
                        </Row>
                    </Col>
                </Row>;
        }
    }
}

export default WrappedComponent => withRouter(
    withForm(WrappedComponent)
);
