import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';
import ParticipantCommentForm from '../forms/ParticipantCommentForm';
import { putParticipantComment } from '../../actions/asyncActions';

class ParticipantCommentModal extends Component {
    render() {
        const { modal, params, putParticipantComment, close } = this.props;

        return <Modal
            {...modal}
            title={<div>
                <div><strong>Комментарий к участнику</strong></div>
                <div style={{ color: '#A7B9CF' }}>{ params.name }</div>
            </div>}
            footer={null}>
            <ParticipantCommentForm
                formAction={putParticipantComment}
                item={params.item}
                close={close}
                onSuccess={params.onSuccess} />
        </Modal>;
    }
}

export default withAsyncActions({
    putParticipantComment: putParticipantComment
        .withParams(() => ({ type: 'edit' }))
        .withSuccessHandler(({ close, params, putParticipantComment }) => {
            message.success('Комментарий успешно сохранен');
            params.onSuccess(putParticipantComment.data.comment);
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(ParticipantCommentModal);
