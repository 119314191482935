import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Button } from 'antd';
import { Textarea } from '@meconsultant/common';

import { withFormWrapper } from '@meconsultant/common';
import Input from './formComponents/Input';
import Switch from './formComponents/Switch';

class FAQForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting } = this.props;

        return <Fragment>
            <Field
                name='question'
                component={Input}
                label='Вопрос' />
            <Field
                name='answer'
                component={Textarea}
                label='Ответ' />
            <Field
                name='published'
                component={Switch}
                label='Опубликовать' />
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
        </Fragment>;
    }
}

export default withFormWrapper(FAQForm, {
    mapPropsToValues: ({ item }) => item || ({ published: true })
});
