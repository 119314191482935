import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import { logout } from '../../../actions/appActions';
import UserRoutes from '../../UserRoutes';
import Sidebar from './Sidebar';
import Modals from '../../Modals';
import Search from './Search';

const UserLayout = ({ logout, superAdmin }) =>
    <Layout>
        <Layout.Header>
            <span className="logo">
                <svg width="651" height="223" viewBox="0 0 651 223" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.15527e-05 0.00012207H221.809V78.348H207.318V14.4911H14.4911V207.561H207.318V134.96H221.809V222.052H9.15527e-05V0.00012207Z" fill="#F54D2E"/>
                    <path d="M44.824 71.78H57.19L81.052 104.385L103.803 71.876H115.347V141.337H103.175V91.053L81.148 121.967H79.313L57.093 91.391V141.337H44.824V71.78Z" fill="white"/>
                    <path d="M169.883 136.893C164.982 140.672 158.922 142.635 152.735 142.448C149.564 142.69 146.377 142.257 143.385 141.175C140.393 140.094 137.666 138.389 135.382 136.174C133.099 133.96 131.312 131.285 130.14 128.328C128.968 125.371 128.438 122.198 128.584 119.02C128.452 115.882 128.977 112.75 130.125 109.826C131.273 106.902 133.02 104.25 135.253 102.04C137.485 99.83 140.155 98.11 143.091 96.992C146.027 95.874 149.164 95.381 152.301 95.545C155.081 95.433 157.855 95.89 160.452 96.887C163.05 97.885 165.416 99.403 167.406 101.348C169.396 103.293 170.968 105.623 172.025 108.197C173.082 110.771 173.603 113.533 173.554 116.315C173.627 118.401 173.383 120.486 172.83 122.498H140.466C140.968 125.624 142.639 128.441 145.142 130.379C147.645 132.317 150.792 133.229 153.943 132.932C158.495 132.974 162.938 131.533 166.599 128.826L169.883 136.893ZM140.08 114.818H162.106C162.153 113.487 161.916 112.16 161.41 110.928C160.904 109.696 160.141 108.585 159.172 107.671C158.203 106.757 157.05 106.06 155.79 105.626C154.53 105.193 153.192 105.033 151.866 105.157C149.04 104.962 146.246 105.853 144.056 107.648C141.865 109.444 140.443 112.009 140.08 114.818Z" fill="white"/>
                    <path d="M239.297 138.679C233.22 144.232 225.207 147.18 216.981 146.891C195.631 146.891 180.995 132.013 180.995 109.359C180.995 88.444 193.602 71.779 216.643 71.779C224.402 71.499 231.984 74.136 237.896 79.169L232.486 88.83C227.888 85.542 222.342 83.846 216.691 84C203.746 84 193.94 93.66 193.94 109.601C193.94 125.541 203.842 135.201 218.092 135.201C224.15 135.207 230.029 133.145 234.756 129.357L239.297 138.679Z" fill="white"/>
                    <path d="M271.128 95.545C274.289 95.363 277.455 95.84 280.422 96.946C283.39 98.052 286.095 99.764 288.366 101.971C290.637 104.178 292.424 106.834 293.615 109.769C294.805 112.704 295.372 115.854 295.279 119.02C295.365 122.182 294.793 125.327 293.6 128.256C292.407 131.185 290.618 133.835 288.348 136.037C286.078 138.239 283.375 139.946 280.411 141.05C277.446 142.153 274.285 142.629 271.128 142.447C267.946 142.671 264.754 142.226 261.755 141.142C258.756 140.057 256.017 138.357 253.715 136.15C251.413 133.944 249.598 131.28 248.387 128.329C247.177 125.379 246.597 122.208 246.686 119.02C246.59 115.828 247.165 112.652 248.372 109.696C249.58 106.74 251.394 104.07 253.697 101.858C256 99.646 258.741 97.941 261.743 96.854C264.745 95.766 267.942 95.32 271.128 95.545ZM271.128 131.821C272.796 131.865 274.456 131.56 276 130.925C277.544 130.29 278.939 129.34 280.094 128.135C281.249 126.93 282.14 125.496 282.709 123.926C283.277 122.357 283.512 120.686 283.397 119.02C283.492 117.377 283.246 115.732 282.672 114.19C282.09 112.632 281.193 111.21 280.038 110.014C278.883 108.817 277.494 107.871 275.958 107.234C274.422 106.619 272.782 106.307 271.128 106.316C269.473 106.293 267.832 106.612 266.306 107.252C264.78 107.893 263.403 108.841 262.261 110.038C261.118 111.235 260.235 112.655 259.666 114.209C259.097 115.762 258.855 117.417 258.955 119.068C258.862 120.718 259.11 122.37 259.681 123.92C260.252 125.471 261.136 126.888 262.277 128.083C263.418 129.279 264.792 130.227 266.315 130.87C267.837 131.513 269.475 131.836 271.128 131.821Z" fill="white"/>
                    <path d="M305.52 96.608H316.968V101.003C318.669 99.306 320.688 97.96 322.909 97.042C325.117 96.147 327.483 95.704 329.865 95.738C340.83 95.738 348.317 101.003 348.317 115.06V141.337H336.821V117.185C337.044 115.791 336.943 114.365 336.526 113.016C336.108 111.668 335.385 110.434 334.413 109.411C333.441 108.387 332.246 107.602 330.921 107.115C329.596 106.629 328.177 106.455 326.774 106.606C324.929 106.648 323.109 107.041 321.412 107.766C319.733 108.5 318.221 109.569 316.968 110.905V141.385H305.52V96.608Z" fill="white"/>
                    <path d="M358.46 109.166C358.46 100.423 365.657 95.544 374.931 95.544C381.149 95.325 387.227 97.425 391.982 101.437L387.683 109.601C384.165 106.949 379.914 105.447 375.511 105.302C372.033 105.302 369.956 107.186 369.956 109.214C369.956 116.653 394.108 112.547 394.108 128.536C394.108 137.375 386.959 143.027 375.994 143.027C372.513 142.95 369.082 142.185 365.899 140.776C362.715 139.366 359.842 137.34 357.445 134.815L363.049 127.328C366.881 130.652 371.703 132.622 376.767 132.931C380.1 132.931 382.66 131.531 382.66 128.729C382.708 121.483 358.46 125.589 358.46 109.166Z" fill="white"/>
                    <path d="M444.828 141.336H433.38V135.878C431.635 137.957 429.437 139.609 426.955 140.708C424.465 141.875 421.734 142.437 418.985 142.35C410.242 142.35 403.238 136.699 403.238 123.85V96.607H414.734V121.58C414.734 127.569 417.005 131.82 423.284 131.82C425.247 131.769 427.176 131.291 428.936 130.419C430.692 129.465 432.21 128.128 433.38 126.507V96.607H444.828V141.336Z" fill="white"/>
                    <path d="M457.579 71.78H469.075V124.382C469.075 129.213 470.234 131.628 472.939 131.628C474.701 131.572 476.396 130.945 477.769 129.841L480.185 138.583C476.771 141.162 472.577 142.492 468.302 142.351C461.443 142.351 457.724 138.197 457.724 129.019L457.579 71.78Z" fill="white"/>
                    <path d="M486.416 84.193H497.912V96.607H512.403V107.33H497.912V123.657C497.912 128.487 499.555 131.723 503.274 131.723C505.793 131.672 508.216 130.75 510.133 129.115L513.659 137.955C509.819 140.844 505.133 142.389 500.327 142.35C490.135 142.35 486.368 135.781 486.368 124.381L486.416 84.193Z" fill="white"/>
                    <path d="M523.899 99.796C528.751 97.1 534.192 95.64 539.742 95.545C553.074 95.545 557.711 102.308 557.711 114.142V141.337H547.712V136.217C546.104 138.149 544.063 139.676 541.756 140.675C539.448 141.673 536.938 142.115 534.429 141.965C532.57 142.12 530.701 141.874 528.945 141.245C527.19 140.616 525.59 139.617 524.253 138.317C522.916 137.017 521.874 135.446 521.196 133.708C520.518 131.971 520.221 130.109 520.324 128.247C520.324 118.586 527.618 113.756 536.699 113.756C540.303 113.732 543.839 114.738 546.891 116.654C547.181 109.215 545.2 105.109 538.583 105.109C534.466 105.202 530.429 106.261 526.797 108.201L523.899 99.796ZM546.939 128.247V123.658C544.617 122.106 541.856 121.344 539.066 121.484C535.298 121.484 531.869 123.272 531.869 127.039C531.869 130.807 534.67 132.304 538.293 132.304C539.959 132.407 541.624 132.09 543.135 131.381C544.646 130.672 545.954 129.594 546.939 128.247Z" fill="white"/>
                    <path d="M569.497 96.608H580.945V101.004C582.637 99.317 584.637 97.973 586.838 97.043C589.049 96.156 591.412 95.713 593.794 95.739C604.807 95.739 612.246 101.004 612.246 115.06V141.337H600.798V117.186C601.013 115.789 600.904 114.362 600.48 113.014C600.057 111.666 599.329 110.434 598.354 109.412C597.378 108.389 596.181 107.605 594.854 107.119C593.528 106.633 592.107 106.457 590.702 106.607C588.874 106.655 587.071 107.048 585.389 107.766C583.703 108.489 582.189 109.559 580.945 110.906V141.386H569.497V96.608Z" fill="white"/>
                    <path d="M623.115 84.193H634.611V96.607H649.102V107.33H634.611V123.657C634.611 128.487 636.254 131.723 639.973 131.723C642.507 131.675 644.947 130.753 646.88 129.115L650.358 137.955C646.548 140.901 641.842 142.453 637.027 142.35C626.835 142.35 623.067 135.781 623.067 124.381L623.115 84.193Z" fill="white"/>
                </svg>
            </span>
            <div className='header-search'>
                <Search />
            </div>
            <span className='logout-btn' onClick={logout}>
                <LogoutOutlined />Выйти
            </span>
        </Layout.Header>
        <Layout>
            <Sidebar superAdmin={superAdmin} />
            <Layout.Content>
                <div className='user-content'>
                    <UserRoutes />
                </div>
            </Layout.Content>
        </Layout>
        <Modals />
    </Layout>

export default connect(null, { logout })(UserLayout);
