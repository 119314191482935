import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Alert, Button, Col, Row, Select as AntdSelect } from 'antd';
import * as yup from 'yup';
import moment from 'moment';
import { asyncConnect } from 'react-async-client';
import { withState } from 'recompose';
import { filter } from 'ramda';

import { getAdmins, getEvents, getUser } from '../../actions/asyncActions';
import { Textarea, withFormWrapper } from '@meconsultant/common';
import DatePicker from './formComponents/DatePicker';
import InputNumber from './formComponents/InputNumber';
import RangeTimePicker from './formComponents/RangeTimePicker';
import Select from './formComponents/Select';
import Input from './formComponents/Input';
import SearchSelect from './formComponents/SearchSelect';

const TypeSelect = props => {
    return (
        <AntdSelect
            value={props.input.value}
            onChange={v => {
                props.input.onChange(v);
                props.onChange(v === 'online');
            }}
            onBlur={props.onBlur}>
            <AntdSelect.Option value="online">Online</AntdSelect.Option>
            <AntdSelect.Option value="offline">Offline</AntdSelect.Option>
        </AntdSelect>
    );
}

class CompanyWebinarForm extends Component {
    static defaultProps = {
        isSubmitting: PropTypes.bool
    };

    state = {
        linkExist: false
    };

    renderLocationSelect = () => {
        return <Field
            name='locationType'
            component={TypeSelect}
            onChange={this.onChangeLink} />;
    }

    deleteEvent = () => {
        const { deleteEvent, item } = this.props;

        deleteEvent.dispatch(item.id);
    }

    onChangeLink = online => {
        this.props.setLinkExist(false);

        if (online && this.props.values.location) {
            this.onBlurLink(this.props.values.location, online);
        }
    }

    onBlurLink = (value, online) => {
        if (this.props.values.locationType === 'online' || online) {
            this.props.getEvents.dispatch({
                limit: 0,
                q: {
                    link: value
                }
            });
        }
    }

    render() {
        const { isSubmitting, themes, deleteEvent, item, linkExist, values } = this.props;
        const disabled = isSubmitting || deleteEvent.meta.pending;

        return <Fragment>
            <Field
                name='theme'
                component={Select}
                label='Тема вебинара/семинара'
                namePath='title'
                options={themes} />
            <Row gutter={8}>
                <Col span={8}>
                    <Field
                        name='date'
                        component={DatePicker}
                        label='Дата проведения'
                        style={{ width: '100%' }}
                        min={moment()} />
                </Col>
                <Col span={8}>
                    <Field
                        name='time'
                        component={RangeTimePicker}
                        label='Время'
                        required />
                </Col>
                <Col span={8}>
                    <Field
                        name='maxCountParticipants'
                        component={InputNumber}
                        label='Максимально участников'
                        style={{ width: '100%' }} />
                </Col>
            </Row>
            <Field
                name='location'
                addonBefore={this.renderLocationSelect()}
                component={Input}
                label='Место проведения'
                onBlur={this.onBlurLink}
                onChange={() => this.onChangeLink()} />
            { linkExist && values.locationType === 'online' &&
                <Alert style={{ marginBottom: 12 }} type='warning' message='Данная ссылка уже была использована' />
            }
            <Field
                name='speakers'
                component={SearchSelect}
                label='Спикер'
                action={getAdmins}
                getNamePath={item => `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`}
                isMulti />
            <Field
                name='description'
                component={Textarea}
                label='Описание' />
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
            { item.id &&
                <Button
                    danger
                    onClick={this.deleteEvent}
                    disabled={disabled}
                    style={{ marginLeft: 10 }}>
                    Удалить
                </Button>
            }
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    theme: yup.string().nullable().required(),
    date: yup.string().nullable().required(),
    time: yup.object().nullable().required(),
    location: yup.string().when('locationType', {
        is: 'online',
        then: schema => schema.url('Невалидный url (http://example.com)').nullable().required(),
        otherwise: schema => schema.nullable().required()
    }),
    speakers: yup.array().nullable().required(),
    locationType: yup.string().required()
});

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default withState('linkExist', 'setLinkExist', false)(withFormWrapper(asyncConnect({
    getEvents: getEvents
        .withParams(() => ({ type: 'modal' }))
        .withSuccessHandler(({ getEvents, setLinkExist, values }) => {
            const events = filter(({ link }) => link === values.location, getEvents.data.items || []);

            setLinkExist(!!events.length);
        })
        .withOptions({ resetOnUnmount: true })
}, stateToProps)(CompanyWebinarForm), {
    mapPropsToValues: ({ item }) => ({
        ...item,
        locationType: item.type || 'online',
        location: item.type === 'offline' ? item.locationName : item.link,
        time: item.id ? {
            timeFrom: item.date,
            timeTo: moment(item.date).add(item.duration, 'minutes').toDate()
        } : null
    }),
    validationSchema,
    mapBeforeSubmit: item => {
        const { locationType, time, ...values } = item;

        return ({
            ...values,
            type: locationType,
            locationName: locationType === 'offline' ? values.location : null,
            link: locationType === 'online' ? values.location : null,
            duration: moment(time.timeTo).diff(moment(time.timeFrom), 'minutes'),
            date: moment(item.date).hours(moment(time.timeFrom).hours()).minutes(moment(time.timeFrom).minutes()).toDate()
        })
    },
    subscriptions: { values: true }
}));
