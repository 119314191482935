import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postFAQ, putFAQ } from '../../actions/asyncActions';
import FAQForm from '../forms/FAQForm';

class FAQModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        params: PropTypes.object,
        postFAQ: PropTypes.object,
        putFAQ: PropTypes.object
    };

    render() {
        const { modal, params, postFAQ, putFAQ } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать вопрос' : 'Добавить вопрос'}
            footer={null}>
            <FAQForm formAction={params ? putFAQ : postFAQ} item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    putFAQ: putFAQ
        .withSuccessHandler(({ close }) => {
            message.success('Вопрос был успешно сохранен');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    postFAQ: postFAQ
        .withSuccessHandler(({ close }) => {
            message.success('Вопрос был успешно сохранен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(FAQModal);
