import { Progress, Spin } from 'antd';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { connect } from 'react-redux';

import { getCompanyStatisticContent, getUser } from '../../../actions/asyncActions';
import { Block, BlockContent, BlockHeader, FactDesc, FactText, Info, InfoBlock, InfoDivider, PlanDesc, PlanText } from './WebinarsByCompany';

class ContentByCompany extends Component {
    render() {
        const { getCompanyStatisticContent: { meta, data }} = this.props;

        return <Block>
            <BlockHeader>
                <h3>ОБУЧЕНИЕ ПО КОМПАНИИ</h3>
            </BlockHeader>
            <BlockContent>
                { meta.pending ? <Spin /> : <div>
                    <Info>
                        <InfoBlock>
                            <FactText>{ data.countActivatedContent }</FactText>
                            <FactDesc>активированные</FactDesc>
                        </InfoBlock>
                        <InfoDivider />
                        <InfoBlock>
                            <PlanText>{ data.countParticipants }</PlanText>
                            <PlanDesc>всего участников</PlanDesc>
                        </InfoBlock>
                    </Info>
                    <Progress percent={data.countActivatedContentPercents || 0} strokeColor='#2B3D4F' trailColor='#A7B9CF' />
                </div> }
            </BlockContent>
        </Block>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(asyncConnect({
    getCompanyStatisticContent: getCompanyStatisticContent
        .withPayload(({ id, user }) => id || user.company)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(ContentByCompany));
