import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Avatar, Tooltip, message, Dropdown, Menu, Tag, Progress, Rate, DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined, EditOutlined, ImportOutlined, SettingOutlined, FileExcelOutlined } from '@ant-design/icons';
import { any, range, find, includes, path, pathOr, propEq, toLower, is, all, filter, head, toUpper, equals } from 'ramda';
import { asyncConnect, withAsyncActions } from 'react-async-client';
import { withRouter } from 'react-router';
import moment from 'moment';
import cx from 'classnames';
import styled from 'styled-components';

import { getAdmins, getParticipantsSummary, getUser, putPromotions, putResendEmailParticipant, putSetConsultant } from '../actions/asyncActions';
import { openParticipantModal, openParticipantImportModal, openConsultantSelectModal, openParticipantCommentModal, openParticipantsTableSettingsModal, openParticipantFeedbackModal } from '../actions/modalActions';
import TableList from './TableList';
import { DELETE_PARTICIPANT_FEEDBACK, POST_PARTICIPANT, POST_PARTICIPANT_FILE } from '../constants/actionTypes';
import { getFileView } from '../constants/urls';
import ParticipantFilter from './filters/ParticipantFilter';
import { getFilters, getUrlParams } from '../utils/urls';
import { ADMIN, SUPER_ADMIN } from '../constants/roles';
import { withState } from 'recompose';
import { CONSULTATION_ATTEND_STATUSES, THEME_ATTEND_STATUSES } from '../constants/statuses';
import { DateFilter } from './Companies';
import { Status } from './Participant';
import qs from "qs";
import { getToken } from "../utils/token";

const ConsultationTagWrapper = styled.div`
    .consultation-tag-date {
        border: 1px solid ${(({ color }) => color)};
        text-align: center;
        border-top: 0;
    }
    .ant-tag {
        width: 100%;
        text-align: center;
    }
`;

const Wrapper = styled.div`
    .ellipsis {
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .ant-tag-has-color {
        font-weight: bold;
        display: inline-flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        .anticon {
            margin-right: 5px;
        }
    }
    .plan-fact {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        & > div {
            &:first-child {
                margin-right: 16px;
            }
            strong {
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #2B3D4F;
            }
            div {
                font-size: 14px;
                line-height: 18px;
                color: #A7B9CF;
            }
        }
    }
    .common-column {
        background: #2B3D4F;
    }
    .light-common-column {
        background: #4B5F73;
    }
    .events-column {
        background: #549E38;
    }
    .consultations-column {
        background: #BA9B66;
    }
    .promotion-column {
        background: #8C4484;
    }
    .content-column {
        background: #47AAC4;
    }
    thead {
        .ant-table-cell {
            font-weight: bold;
        }
        tr > th {
            padding: 8px 16px;
            font-size: 13px;
            line-height: normal;
        }
        tr:nth-child(1) .ant-table-cell {
            color: #fff;
            text-align: center;
            border-left: 1px solid #F0F0F0;
            border-right: 1px solid #F0F0F0;
        }
        tr:nth-child(2) .ant-table-cell {
            color: #2B3D4F;
        }
        tr {
            &:first-child {
                th:first-child {
                    padding-top: 40px;
                }
                th.ant-table-cell.common-column.ant-table-cell-fix-left.ant-table-cell-fix-left-last:before {
                    content: '';
                    background: #2c3d4e;
                    width: 60px;
                    height: 100%;
                    position: absolute;
                    left: -60px;
                    top: 0;
                    transform: none;
                }
            }
        }
    }
    table {
        .ant-table-column-sorters-with-tooltip {
            position: relative;
            .ant-table-column-sorter {
                position: absolute;
                right: 10px;
            }
        }
        .ant-table-filter-column {
            .ant-table-column-sorters-with-tooltip {
                position: relative;
                .ant-table-column-sorter {
                    position: absolute;
                    right: 0;
                }
            }
        }
        .ant-btn {
            border: 1px solid #2B3D4F;
            color: #2B3D4F;
            font-weight: bold;
        }
        .column-border-left {
            border-left: 1px solid #F0F0F0;
            &::before {
                display: none;
            }
        }
        .column-border-right {
            border-right: 1px solid #F0F0F0;
            &::before {
                display: none;
            }
        }
        .comment-cell {
            position: relative;;
            .comment-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 16px;
                cursor: pointer;
                color: #2B3D4F;
                font-style: italic;
                .comment-overflow {
                    height: 43px;
                    overflow-y: hidden;
                }
                &::before {
                    position: absolute;
                    top: 0;
                    right: 0;
                    content: '';
                    border: 12px solid transparent;
                    border-top: 12px solid #2B3D4F;
                    border-right: 12px solid #2B3D4F;
                }
            }
            .comment-wrapper:hover::after {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: 'открыть комментарий';
                background: rgba(43, 61, 79, .5);
                color: #fff;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
            }
        }
        .feedback-cell {
            position: relative;
            .feedback-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 16px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .feedback-wrapper:hover::after {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: 'просмотр';
                background: rgba(43, 61, 79, .5);
                color: #fff;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
            }
            .feedback-btn-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .consultant-column {
            position: relative;
            .consultant-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 16px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                .consultant-hover {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(43, 61, 79, .5);
                    align-items: center;
                    justify-content: center;
                    display: none;
                    color: #fff;
                }
                &:hover {
                    .consultant-hover  {
                        display: flex;
                    }
                }
                .consultant-hover.visible {
                    display: flex;
                }
            }
        }
        .promotion-date-wrapper {
            text-align: center;
            .ant-picker {
                display: inline;
                border: 1px solid rgba(0,0,0,0);
                transition: none;
                background: transparent;
                .ant-picker-input {
                    width: auto;
                }
                .ant-picker-suffix {
                    opacity: 0;
                }
                input {
                    cursor: pointer;
                }
                &.ant-picker-focused,
                &:hover {
                    border: 1px solid #d9d9d9;
                    .ant-picker-suffix {
                        opacity: 1;
                    }
                }
            }
        }
        .service-cell {
            position: relative;
            .no-service-cell {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                &:before {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    content: 'недоступно для программы участника';
                    background: #F0F0F0;
                    color: #A7B9CF;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-weight: normal;
                }
            }
        }
    }
`;

const DownloadMenuItemLink = styled.a`
    font-weight: normal;
`;

const EmployedCompany = styled.div`
    color: #ccc;
`;

const getTableSettings = settings => {
    try {
        return JSON.parse(settings);
    } catch {
        return null;
    }
};

class Comment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comment: props.item.comment
        };
    }

    componentDidUpdate(prev) {
        if (this.props.item.comment !== prev.item.comment) {
            this.setState({ comment: this.props.item.comment });
        }
    }

    onSuccess = comment => this.setState({ comment });

    render() {
        const { item, openParticipantCommentModal } = this.props;

        return this.state.comment ?
            <div
                className='comment-wrapper'
                onClick={() => openParticipantCommentModal({ onSuccess: this.onSuccess, name: item.fullName, item: { id: item.id, comment: this.state.comment }})}>
                <div className='comment-overflow'>
                    { this.state.comment }
                </div>
            </div> :
            <Button
                onClick={() => openParticipantCommentModal({ onSuccess: this.onSuccess, name: item.fullName, item: { id: item.id } })}>
                ОСТАВИТЬ КОММЕНТАРИЙ
            </Button>
    }
}

class Feedback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            feedback: props.feedback
        };
    }

    componentDidUpdate(prev) {
        if (!equals(this.props.feedback, prev.feedback)) {
            this.setState({ feedback: this.props.feedback });
        }
    }

    onSuccess = feedback => this.setState({ feedback });

    render() {
        const { user, openFeedbackModal, header } = this.props;

        return this.state.feedback.rating ?
            <div
                className='feedback-wrapper'
                onClick={() => openFeedbackModal({ item: this.state.feedback, view: !user.superAdmin, header, onSuccess: this.onSuccess })}>
                <Rate value={this.state.feedback.rating} disabled />
            </div> :
            <div className='feedback-btn-wrapper'>
                { (includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles)) &&
                    <Button onClick={() => openFeedbackModal({ item: this.state.feedback, header, view: false, onSuccess: this.onSuccess, create: true })}>
                        ОСТАВИТЬ ОТЗЫВ
                    </Button>
                }
            </div>
    }
}

class ConsultantComponent extends Component {
    state = {
        visible: false
    };

    onSelect = ({ key }) => {
        const consultant = find(propEq('id', key), this.props.getAdmins.data.items);
        this.props.putSetConsultant.dispatch({
            consultant: key,
            participants: [this.props.participant]
        });
        this.setState({ visible: false });
        this.props.setConsultant({ id: key, firstName: consultant.firstName, lastName: consultant.lastName, photo: consultant.photo });
    }

    componentDidUpdate(prev) {
        if (this.props.item !== prev.item) {
            this.props.setConsultant(this.props.item);
        }
    }

    render() {
        const { getAdmins, consultant, user } = this.props;
        const menu = <Menu
            className='consultant'
            selectable
            defaultSelectedKeys={consultant ? [consultant.id] : []}
            getPopupContainer={trigger => trigger.parentNode}
            onSelect={this.onSelect}>
            { (getAdmins.data.items || []).map(item =>
                <Menu.Item key={item.id}>{ item.lastName } { item.firstName }</Menu.Item>
            )}
        </Menu>;

        return user.superAdmin ? <Tooltip title={consultant ? `${consultant.lastName} ${consultant.firstName}` : null}>
            <Dropdown overlay={menu} trigger={['click']} onVisibleChange={visible => this.setState({ visible })}>
                <div
                    className='consultant-wrapper'>
                    <div className={cx('consultant-hover', {visible: this.state.visible})}><EditOutlined /></div>
                    { consultant ?
                        <Avatar style={{ background: '#2B3D4F' }} src={consultant.photo ? getFileView(consultant.photo) : null}>{ consultant.photo ? null : `${toUpper(head(consultant.lastName))}${toUpper(head(consultant.firstName))}` }</Avatar> : <div />
                    }
                </div>
            </Dropdown>
        </Tooltip> : <Tooltip title={consultant ? `${consultant.lastName} ${consultant.firstName}` : null}>
            { consultant ?
                <Avatar style={{ background: '#2B3D4F' }} src={consultant.photo ? getFileView(consultant.photo) : null}>{ consultant.photo ? null : `${toUpper(head(consultant.lastName))}${toUpper(head(consultant.firstName))}` }</Avatar> : <div />
            }
        </Tooltip>;
    }
}

const Consultant = withState('consultant', 'setConsultant', props => props.item)(asyncConnect({
    getAdmins: getAdmins.withParams(() => ({ name: 'consultant' })),
    putSetConsultant: putSetConsultant
        .withParams(({ participant }) => ({ participant }))
        .withSuccessHandler(() => {
            message.success('Консультант успешно назначен');
        })
        .withErrorHandler(({ setConsultant, item }) => {
            message.error('Не удалось назначить консультанта');
            setConsultant(item);
        })
        .withOptions({ resetOnUnmount: true })
}, state => ({ user: getUser.selectData(state) }))(ConsultantComponent));

class PromotionDateComponent extends Component {
    onChange = date => {
        this.props.setDate(date.toDate());
        this.props.putPromotions.dispatch({
            id: this.props.id,
            type: 'promotion',
            end: this.props.end || date.toDate(),
            start: this.props.start || date.toDate()
        });
    }

    componentDidUpdate(prev) {
        if (this.props.date !== prev.date) {
            this.props.setDate(this.props.date);
        }
    }

    render() {
        return this.props.date ? <div className='promotion-date-wrapper'>
            <DatePicker
                format='DD.MM.YYYY'
                onChange={this.onChange}
                allowClear={false}
                disabledDate={current => this.props.start ? moment(this.props.start).isAfter(current) : moment(this.props.end).isBefore(current)}
                value={moment(this.props.date)} />
        </div> : null;
    }
}

const PromotionDate = withState('date', 'setDate', props => props.date)(withAsyncActions({
    putPromotions: putPromotions
        .withParams(({ id, start }) => ({ id: `${id}-${start ? 'end' : 'start'}` }))
        .withSuccessHandler(({ start }) => message.success(`Дата ${start ? 'окончания' : 'начала'} успешно изменена`))
        .withErrorHandler(({ start }) => message.error(`Не удалось изменить дату ${start ? 'окончания' : 'начала'}`))
        .withOptions({ resetOnUnmount: true })
})(PromotionDateComponent));

class Participants extends Component {
    static propTypes = {
        openParticipantModal: PropTypes.func,
        location: PropTypes.object,
        openParticipantImportModal: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            tableSettings: getTableSettings(localStorage.getItem('participantsTableSettings')) || {}
        };
    }

    onChangeTableSettings = tableSettings => {
        this.setState({ tableSettings });
        localStorage.setItem('participantsTableSettings', tableSettings ? JSON.stringify(tableSettings) : null);
    }

    componentDidUpdate(prev) {
        if (this.props.location.search !== prev.location.search) {
            this.onChangeSelected([]);
        }
    }

    getDownloadLink = () => {
        const { location } = this.props;
        const searchPath = qs.stringify({
            q: JSON.stringify(getFilters(location) || {}),
            token: getToken()
        }, { addQueryPrefix: true, strictNullHandling: true });

        return `/api/admin/participants/export/${this.props.company}/summary.xlsx${searchPath}`;
    }

    hasModule = type => {
        return any(propEq('id', type), this.props.companyData.modules || []);
    }

    isHidden = keys => {
        return is(Array, keys) ? all(key => !!path(['hide', key], this.state.tableSettings), keys) : !!path(['hide', keys], this.state.tableSettings);
    }

    filterHidden = items => filter(({ hide }) => !hide, items);

    getDateFilter = (props, dateField) => {
        return <DateFilter key={`${dateField}-visible-${props.visible}`} {...props} from='minDate' to='maxDate' additionalFilter={{ dateField }} />;
    }

    getWidth = (type, width) => {
        return this.isHidden(type) ? 0 : width;
    }

    getColumns = () => {
        const themes = pathOr([], ['_embedded', 'themes'], find(propEq('id', 'events'), this.props.companyData.modules || []));

        return this.filterHidden([
            {
                title: 'Персональные и контактные данные',
                hide: this.isHidden(['participant', 'contacts']),
                fixed: 'left',
                width: this.getWidth('participant', 240) + this.getWidth('contacts', 200),
                className: 'common-column',
                children: this.filterHidden([
                    {
                        title: 'Участник',
                        key: 'fullName',
                        width: 240,
                        fixed: 'left',
                        sorter: true,
                        hide: this.isHidden('participant'),
                        render: item => {
                            return <div>
                                <div><Link to={`/participants/${item.id}`}>{ item.fullName }</Link></div>
                                { item.lastStatus.status && <Status key={item.id} item={{
                                    participant: item.id,
                                    status: item.lastStatus.status,
                                    additionalStatus: item.lastStatus.additionalStatus
                                }} status={item.lastStatus.status} additionalStatus={item.lastStatus.additionalStatus} editable /> }
                            </div>;
                        }
                    },
                    {
                        title: 'Контакты',
                        key: 'contacts',
                        width: 200,
                        fixed: 'left',
                        className: 'column-border-right',
                        hide: this.isHidden('contacts'),
                        render: item => <div>
                            <Tooltip title={item.email}><div className='ellipsis'>{ item.email }</div></Tooltip>
                            <div>{ item.phone }</div>
                        </div>
                    },
                ])
            },
            {
                title: '',
                hide: this.isHidden('role'),
                width: 180,
                className: 'common-column',
                children: [
                    {
                        title: 'Роль',
                        key: 'companyRoleName',
                        dataIndex: 'companyRoleName',
                        width: 180,
                        hide: this.isHidden('role'),
                        render: companyRoleName => <div>{ companyRoleName }</div>
                    },
                ]
            },
            {
                title: '',
                hide: this.isHidden('location'),
                width: 180,
                className: 'common-column',
                children: [
                    {
                        title: 'Город',
                        key: 'location',
                        width: 180,
                        hide: this.isHidden('location'),
                        render: item => <div>{ item.location }</div>
                    },
                ]
            },
            {
                title: '',
                hide: this.isHidden('consultant'),
                width: 65,
                className: 'light-common-column',
                children: [
                    {
                        title: <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 2.75C8.34969 2.75 6.1875 4.91219 6.1875 7.5625C6.1875 9.19806 7.01938 10.6452 8.27131 11.5156C5.44913 12.617 3.4375 15.367 3.4375 18.5625H4.8125C4.8125 15.5389 7.00631 13.0116 9.88281 12.4823L10.3125 13.75H11.6875L12.1172 12.4823C14.9937 13.0116 17.1875 15.5389 17.1875 18.5625H18.5625C18.5625 15.367 16.5509 12.617 13.7287 11.5156C14.9799 10.6452 15.8125 9.19806 15.8125 7.5625C15.8125 4.91219 13.6503 2.75 11 2.75ZM11 4.125C12.9064 4.125 14.4375 5.65606 14.4375 7.5625C14.4375 9.46894 12.9064 11 11 11C9.09356 11 7.5625 9.46894 7.5625 7.5625C7.5625 5.65606 9.09356 4.125 11 4.125ZM10.3125 14.4375L9.625 18.5625H12.375L11.6875 14.4375H10.3125Z" fill="#2B3D4F"/>
                        </svg>,
                        align: 'center',
                        key: 'consultant',
                        dataIndex: 'consultant',
                        width: 65,
                        className: 'column-border-right column-border-left consultant-column',
                        render: (consultant, item) => <Consultant item={consultant} participant={item.id} />
                    }
                ]
            },
            {
                title: 'Отраслевая и функциональная специализация',
                hide: this.isHidden(['sector', 'position', 'function']),
                className: 'common-column',
                width: this.getWidth('sector', 150) + this.getWidth('position', 150) + this.getWidth('function', 150),
                children: this.filterHidden([
                    {
                        title: 'Отрасль',
                        key: '',
                        dataIndex: 'industryName',
                        className: 'column-border-left',
                        hide: this.isHidden('sector'),
                        width: 150,
                        render: industryName => industryName || path(['industryName'], this.props.companyData)
                    },
                    {
                        title: 'Должность',
                        key: '',
                        dataIndex: 'position',
                        hide: this.isHidden('position'),
                        width: 150
                    },
                    {
                        title: 'Функция',
                        key: '',
                        dataIndex: 'functionName',
                        className: 'column-border-right',
                        hide: this.isHidden('function'),
                        width: 150
                    }
                ])
            },
            {
                title: 'Активность на портале',
                className: 'light-common-column',
                hide: this.isHidden(['registrationAt', 'startedAt', 'firstLogin', 'lastLogin', 'endedAt', 'programFinishedAt', 'employedAt']),
                width: this.getWidth('registrationAt', 258) + this.getWidth('startedAt', 220) + this.getWidth('firstLogin', 190) + this.getWidth('lastLogin', 220) + this.getWidth('endedAt', 220) + this.getWidth('programFinishedAt', 250) + this.getWidth('employedAt', 220),
                children: this.filterHidden([
                    {
                        title: 'Регистрация на портале',
                        key: 'registrationAt',
                        dataIndex: 'registrationAt',
                        render: date => date ? moment(date).format('DD.MM.YYYY') : null,
                        className: 'column-border-left',
                        sorter: true,
                        hide: this.isHidden('registrationAt'),
                        filterDropdown: props => this.getDateFilter(props, 'registrationAt'),
                        align: 'center',
                        width: 258
                    },
                    {
                        title: 'Начало программы',
                        key: 'createdAt',
                        dataIndex: 'createdAt',
                        sorter: true,
                        render: date => date ? moment(date).format('DD.MM.YYYY') : null,
                        hide: this.isHidden('startedAt'),
                        filterDropdown: props => this.getDateFilter(props, 'startedAt'),
                        align: 'center',
                        width: 220
                    },
                    {
                        title: 'Первый вход',
                        key: 'firstLogin',
                        dataIndex: 'firstLogin',
                        sorter: true,
                        render: date => date ? moment(date).format('DD.MM.YYYY') : null,
                        hide: this.isHidden('firstLogin'),
                        filterDropdown: props => this.getDateFilter(props, 'firstLogin'),
                        align: 'center',
                        width: 190
                    },
                    {
                        title: 'Последний вход',
                        key: 'lastLogin',
                        dataIndex: 'lastLogin',
                        sorter: true,
                        render: date => date ? moment(date).format('DD.MM.YYYY') : null,
                        hide: this.isHidden('lastLogin'),
                        filterDropdown: props => this.getDateFilter(props, 'lastLogin'),
                        align: 'center',
                        width: 220
                    },
                    {
                        title: 'Окончание доступа',
                        key: 'expiredAt',
                        dataIndex: 'expiredAt',
                        sorter: true,
                        render: date => date ? moment(date).format('DD.MM.YYYY') : null,
                        hide: this.isHidden('endedAt'),
                        filterDropdown: props => this.getDateFilter(props, 'endedAt'),
                        align: 'center',
                        width: 220
                    },
                    {
                        title: 'Завершение программы',
                        key: 'programFinishedAt',
                        dataIndex: 'programFinishedAt',
                        render: date => date ? moment(date).format('DD.MM.YYYY') : null,
                        className: 'column-border-right',
                        sorter: true,
                        hide: this.isHidden('programFinishedAt'),
                        filterDropdown: props => this.getDateFilter(props, 'programFinishedAt'),
                        align: 'center',
                        width: 250
                    },
                    {
                        title: 'Трудоустроился',
                        key: 'employedAt',
                        dataIndex: 'employedAt',
                        render: (date, item) => <div>
                            {date ? <div>{moment(date).format('DD.MM.YYYY')}</div> : null}
                            {item.employedIn ? <EmployedCompany>{item.employedIn}</EmployedCompany> : null}
                        </div>,
                        className: 'column-border-right',
                        sorter: true,
                        hide: this.isHidden('employedAt'),
                        filterDropdown: props => this.getDateFilter(props, 'employedAt'),
                        align: 'center',
                        width: 220
                    }
                ])
            },
            {
                title: 'Комментарии и взаимодействие',
                className: 'common-column',
                hide: this.isHidden(['lastCommentDate', 'lastComment', 'lastActivity']),
                width: this.getWidth('lastCommentDate', 300) + this.getWidth('lastComment', 300) + this.getWidth('lastActivity', 250),
                children: this.filterHidden([
                    {
                        title: 'Дата последнего комментария по участнику',
                        dataIndex: ['lastStatus', 'createdAt'],
                        key: 'commentDate',
                        render: date => <div style={{ textAlign: 'center' }}>{ date ? moment(date).format('DD.MM.YYYY') : null }</div>,
                        className: 'column-border-left',
                        sorter: true,
                        filterDropdown: props => this.getDateFilter(props, 'lastStatus.createdAt'),
                        hide: this.isHidden('lastCommentDate'),
                        width: 300
                    },
                    {
                        title: 'Краткое содержание последнего комментария',
                        key: 'lastComment',
                        dataIndex: ['lastStatus'],
                        render: item => <div>
                            { item.status !== item.previousStatus &&
                                <div>
                                    { item.previousStatus && <span>
                                        <Status status={item.previousStatus} additionalStatus={item.previousAdditionalStatus} />
                                        <svg style={{ marginRight: 10 }} width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.0672 0.328897C10.1315 -0.374935 8.99724 0.107719 8.92345 1.2365C8.88042 1.89473 8.84214 2.73564 8.81876 3.80195C5.9907 3.8394 3.31705 3.94463 1.96885 4.02268C1.10233 4.07285 0.77297 4.58118 0.696627 5.39937C0.677968 5.59935 0.666504 5.82358 0.666504 6.07269C0.666504 6.34895 0.680602 6.59461 0.702977 6.81052C0.784054 7.59289 1.06995 8.08964 1.89727 8.16231C3.2365 8.27995 5.93996 8.44248 8.81861 8.49965C8.84198 9.56872 8.8803 10.4115 8.9234 11.071C8.99719 12.2 10.1318 12.6827 11.0677 11.9787C11.6988 11.5039 12.4981 10.868 13.4805 10.0262C14.9875 8.73485 15.8483 7.76645 16.3322 7.12108C16.778 6.52668 16.7779 5.78151 16.3321 5.18714C15.8482 4.54182 14.9874 3.57347 13.4805 2.28201C12.4979 1.43983 11.6984 0.80374 11.0672 0.328897Z" fill="#2B3D4F"/>
                                        </svg>
                                    </span> }
                                    <Status status={item.status} additionalStatus={item.additionalStatus} />
                                </div>
                            }
                            { item.comment && <div>{ item.comment }</div> }
                        </div>,
                        hide: this.isHidden('lastComment'),
                        width: 300
                    },
                    {
                        title: 'Дата запланированной активности',
                        key: 'nearestActivity',
                        dataIndex: 'nearestActivity',
                        render: date => <div style={{ textAlign: 'center' }}>{ date ? moment(date).format('DD.MM.YYYY') : null }</div>,
                        className: 'column-border-right',
                        sorter: true,
                        filterDropdown: props => this.getDateFilter(props, 'nearestActivity'),
                        hide: this.isHidden('lastActivity'),
                        width: 250
                    }
                ])
            },
            {
                title: 'Вебинары / семинары',
                className: 'events-column',
                hide: this.isHidden(['theme', 'eventsAmount']) || !this.hasModule('events'),
                width: this.getWidth('eventsAmount', 300) + (themes.length * this.getWidth('theme', 250)),
                children: this.filterHidden([
                    ...themes.map((event, index) => ({
                        title: event.title,
                        key: `event-${event.id}`,
                        dataIndex: ['events', 'themes'],
                        align:  'center',
                        className: `${index === 0 ? 'column-border-left' : null} service-cell`,
                        width: 250,
                        render: themes => {
                            const theme = find(propEq('id', event.id), themes || []);
                            const status = theme ? find(propEq('id', theme.status), THEME_ATTEND_STATUSES) : null;

                            return theme ? <Tag color={status.color}>{ toLower(status.title) }</Tag> : <div className='no-service-cell' />;
                        },
                        hide: this.isHidden('theme')
                    })),
                    {
                        title: 'Фактическое количество вебинаров, которые посетил участник',
                        key: 'eventAmount',
                        dataIndex: 'events',
                        className: 'column-border-right',
                        width: 300,
                        render: events => <div className='plan-fact'>
                            <div>
                                <strong>{ events.attended || 0 }</strong>
                                <div>факт</div>
                            </div>
                            <div>
                                <strong>{ (events.themes || []).length }</strong>
                                <div>план</div>
                            </div>
                        </div>,
                        hide: this.isHidden('eventsAmount')
                    }
                ])
            },
            {
                title: 'Консультации',
                className: 'consultations-column',
                hide: this.isHidden(['consultations', 'consultationAmount']) || !this.hasModule('consultations'),
                width: this.getWidth('consultationAmount', 300) + ((this.props.consultationsAmount ? range(1, this.props.consultationsAmount + 1) : []).length * this.getWidth('consultations', 250)),
                children: this.filterHidden([
                    ...(this.props.consultationsAmount ? range(1, this.props.consultationsAmount + 1) : []).map((consultation, index) => ({
                        title: `Консультация №${consultation}`,
                        key: `consultation-${consultation}`,
                        dataIndex: ['consultations'],
                        className: `service-cell ${index === 0 ? 'column-border-left' : null}`,
                        width: 250,
                        render: ({ amountForRole, consultations }) => {
                            const item = path([index], consultations);
                            const status = index >= (amountForRole || 0) ? null : find(propEq('id', pathOr('not_accepted', ['status'], item)), CONSULTATION_ATTEND_STATUSES);

                            return index >= (amountForRole || 0) ? <div className='no-service-cell' /> : status ? <ConsultationTagWrapper color={status.color}>
                                <Tag color={status.color}>{ toLower(status.title) }</Tag>
                                { item && item.start && <div className='consultation-tag-date'>{ moment(item.start).format('DD.MM.YYYY') }</div>}
                            </ConsultationTagWrapper> : null;
                        },
                        hide: this.isHidden('consultations')
                    })),
                    {
                        title: 'Фактическое количество консультаций, которые посетил участник',
                        key: 'consultationAmount',
                        dataIndex: 'consultations',
                        width: 300,
                        render: consultations => <div className='plan-fact'>
                            <div>
                                <strong>{ consultations.attended || 0 }</strong>
                                <div>факт</div>
                            </div>
                            <div>
                                <strong>{ consultations.amountForRole || 0 }</strong>
                                <div>план</div>
                            </div>
                        </div>,
                        className: 'column-border-right',
                        hide: this.isHidden('consultationAmount')
                    }
                ])
            },
            {
                title: 'Профессиональная поддержка',
                className: 'promotion-column',
                hide: this.isHidden(['promotionPlan', 'promotionStart', 'promotionEnd', 'promotionDays', 'promotionRequestsAmount']) || !this.hasModule('promotion'),
                width: this.getWidth('promotionPlan', 300) + this.getWidth('promotionStart', 300) + this.getWidth('promotionEnd', 300) + this.getWidth('promotionDays', 300) + this.getWidth('promotionRequestsAmount', 150),
                children: this.filterHidden([
                    {
                        title: 'Планируемая дата начала профессиональной поддержки',
                        key: 'promotionPlan',
                        dataIndex: ['promotion', 'plan'],
                        render: date => <div style={{ textAlign: 'center' }}>{ date ? moment(date).format('DD.MM.YYYY') : null }</div>,
                        className: 'column-border-left',
                        sorter: true,
                        filterDropdown: props => this.getDateFilter(props, 'promotion.plan'),
                        hide: this.isHidden('promotionPlan'),
                        width: 300
                    },
                    {
                        title: 'Фактическая дата начала профессиональной поддержки',
                        key: 'promotion.start',
                        dataIndex: ['promotion'],
                        sorter: true,
                        render: promotion => promotion && <PromotionDate key={promotion.id} end={promotion.end} date={promotion.start} id={promotion.id} />,
                        filterDropdown: props => this.getDateFilter(props, 'promotion.start'),
                        hide: this.isHidden('promotionStart'),
                        width: 300
                    },
                    {
                        title: 'Фактическая дата завершения профессиональной поддержки',
                        key: 'promotion.end',
                        dataIndex: ['promotion'],
                        sorter: true,
                        render: promotion => promotion && <PromotionDate key={promotion.id} start={promotion.start} date={promotion.end} id={promotion.id} />,
                        filterDropdown: props => this.getDateFilter(props, 'promotion.end'),
                        hide: this.isHidden('promotionEnd'),
                        width: 300
                    },
                    {
                        title: 'Фактическое оказание дней оказанной профессиональной поддержки',
                        key: 'promotion.daysUsed',
                        dataIndex: 'promotion',
                        render: promotion => <div className='plan-fact'>
                            <div>
                                <strong>{ promotion ? promotion.daysUsed : 0 }</strong>
                                <div>факт</div>
                            </div>
                            <div>
                                <strong>{ promotion ? promotion.duration : 0 }</strong>
                                <div>план</div>
                            </div>
                        </div>,
                        width: 300,
                        hide: this.isHidden('promotionDays')
                    },
                    {
                        title: 'Количество обращений со стороны участника',
                        key: 'promotion.promotionRequestsAmount',
                        dataIndex: ['promotion', 'promotionRequestsAmount'],
                        className: 'column-border-right',
                        width: 150,
                        hide: this.isHidden('promotionRequestsAmount'),
                        render: promotionRequestsAmount => <div style={{ textAlign: 'center' }}>{ promotionRequestsAmount || 0 }</div>
                    }
                ])
            },
            {
                title: 'Курсы',
                className: 'content-column',
                children: [
                    {
                        title: 'Прогресс по прохождению курса',
                        className: 'column-border-right column-border-left',
                        key: 'materialsProgress',
                        sorter: true,
                        dataIndex: ['content', 'materialsProgress'],
                        render: (materialsProgress, item) => item.content && <Progress strokeColor='#47AAC4' percent={materialsProgress} size="small" />
                    }
                ],
                hide: !this.hasModule('content') || this.isHidden('content')
            },
            {
                title: 'Обратная связь',
                className: 'common-column',
                hide: (!this.hasModule('events') && !this.hasModule('consultations') && !this.hasModule('promotion')) || this.isHidden(['eventsFeedback', 'consultationsFeedback', 'promotionFeedback']),
                width: (this.hasModule('events') ? themes.length * this.getWidth('eventsFeedback', 250) : 0) +
                    ((this.props.consultationsAmount ? range(1, this.props.consultationsAmount + 1) : []).length * this.getWidth('consultations', 250)) +
                    (this.hasModule('promotion') ? this.getWidth('promotionFeedback', 250) : 0),
                children: this.filterHidden([
                    {
                        title: 'Вебинары/семинары',
                        className: 'column-border-left',
                        children: [
                            ...(pathOr([], ['_embedded', 'themes'], find(propEq('id', 'events'), this.props.companyData.modules || []))).map(event => ({
                                title: event.title,
                                key: `feedback-event-${event.id}`,
                                width: 250,
                                render: item => {
                                    const theme = find(propEq('id', event.id), pathOr([], ['events', 'themes'], item));
                                    const feedback = find(propEq('themeId', event.id), pathOr([], ['feedbacks', 'events'], item));

                                    return theme ? <Feedback
                                        feedback={feedback || { id: event.id, participant: item.id, module: 'events' }}
                                        openFeedbackModal={this.props.openParticipantFeedbackModal}
                                        user={this.props.user}
                                        header={<div>
                                            <strong>{ event.title }</strong>
                                            <div><strong style={{ color: '#A7B9CF' }}>{ item.fullName }</strong></div>
                                        </div>} /> : <div className='no-service-cell' />;
                                },
                                className: 'feedback-cell service-cell',
                                hide: this.isHidden('theme')
                            }))
                        ],
                        hide: !this.hasModule('events') || this.isHidden('eventsFeedback')
                    },
                    {
                        title: 'Консультации',
                        children: [
                            ...(this.props.consultationsAmount ? range(1, this.props.consultationsAmount + 1) : []).map((consultation, index) => ({
                                title: `Консультация №${consultation}`,
                                key: `consultation-${consultation}`,
                                width: 250,
                                render: item => {
                                    const consultationItem = pathOr(null, ['consultations', 'consultations', index], item);
                                    const amountForRole = pathOr(0, ['consultations', 'amountForRole'], item);
                                    const feedback = consultationItem ? find(propEq('relatedModelId', consultationItem.id), pathOr([], ['feedbacks', 'consultations'], item)) : null;

                                    return index >= (amountForRole || 0) ? <div className='no-service-cell' /> : consultationItem && <Feedback
                                        feedback={feedback || { id: consultationItem.id, participant: item.id, module: 'consultations' }}
                                        openFeedbackModal={this.props.openParticipantFeedbackModal}
                                        user={this.props.user}
                                        header={<div>
                                            <strong>{ `Консультация №${consultation}` }</strong>
                                            <div style={{ color: '#A7B9CF' }}><strong>{ item.fullName }</strong></div>
                                        </div>} />;
                                },
                                className: 'feedback-cell service-cell',
                                hide: this.isHidden('consultations')
                            }))
                        ],
                        hide: !this.hasModule('consultations') || this.isHidden('consultationsFeedback')
                    },
                    {
                        title: 'Профессиональная поддержка/программы',
                        className: 'column-border-right feedback-cell',
                        width: 250,
                        render: item => {
                            const feedback = pathOr(null, ['feedbacks', 'promotion'], item);

                            return item.promotion && <Feedback
                                feedback={feedback || { id: path(['promotion', 'id'], item), participant: item.id, module: 'promotion' }}
                                openFeedbackModal={this.props.openParticipantFeedbackModal}
                                user={this.props.user}
                                header={<div>
                                    <strong>Продвижение</strong>
                                    <div style={{ color: '#A7B9CF' }}><strong>{ item.fullName }</strong></div>
                                </div>} />;
                        },
                        hide: !this.hasModule('promotion') || this.isHidden('promotionFeedback')
                    }
                ])
            },
            {
                title: 'Примечание',
                className: 'light-common-column',
                hide: this.isHidden('comment'),
                width: 280,
                children: [
                    {
                        title: 'Комментарий',
                        key: 'comment',
                        sorter: true,
                        className: 'column-border-left comment-cell',
                        render: item => {
                            return <Comment key={item.id} item={item} openParticipantCommentModal={this.props.openParticipantCommentModal} />;
                        },
                        width: 280
                    }
                ]
            }
        ]);
    }

    onChangeSelected = selectedRowKeys => this.props.setSelectedRowKeys(selectedRowKeys);

    setConsultant = () => {
        this.props.putSetConsultant.dispatch({
            participants: this.props.selectedRowKeys,
            consultant: this.props.user.id
        });
    }

    renderToolbarButtons = () => {
        const { openParticipantModal, openParticipantImportModal, location, openConsultantSelectModal, openParticipantsTableSettingsModal } = this.props;
        const isAdmin = includes(ADMIN, this.props.user.roles) || includes(SUPER_ADMIN, this.props.user.roles);
        const menu = <Menu>
            { isAdmin &&
                <Menu.Item
                    icon={<EditOutlined />}
                    disabled={!this.props.selectedRowKeys.length}
                    onClick={() => this.props.user.superAdmin ? openConsultantSelectModal({
                        participants: this.props.selectedRowKeys,
                        onSuccess: () => this.onChangeSelected([])
                    }) : this.setConsultant()}>
                    Консультант
                </Menu.Item>
            }
            <Menu.Item key='import' icon={<ImportOutlined />} onClick={() => openParticipantImportModal()}>Импортировать</Menu.Item>
            <Menu.Item
                key='export'
                icon={<FileExcelOutlined />}
            >
                <DownloadMenuItemLink
                    href={this.getDownloadLink()}
                    target='_blank'
                    rel='noopener noreferrer'
                    download
                >
                Экспорт
                </DownloadMenuItemLink>
            </Menu.Item>
            <Menu.Item
                key='settings'
                icon={<SettingOutlined />}
                onClick={() => openParticipantsTableSettingsModal({ settings: this.state.tableSettings, onSave: this.onChangeTableSettings })}>
                Настройки
            </Menu.Item>
        </Menu>;

        return (
            <Dropdown.Button
                type='primary'
                onClick={() => openParticipantModal({ company: getUrlParams(location).company || this.props.company })}
                overlay={menu}
            >
                <PlusOutlined /> Добавить
            </Dropdown.Button>
        );
    }

    render() {
        return (
            <Wrapper>
                <TableList
                    action={getParticipantsSummary}
                    staticFilter={{ company: this.props.company }}
                    columns={this.getColumns()}
                    buttons={this.renderToolbarButtons()}
                    refreshActions={[POST_PARTICIPANT, POST_PARTICIPANT_FILE, DELETE_PARTICIPANT_FEEDBACK]}
                    filterForm={<ParticipantFilter company={this.props.company} hideCompany={!!this.props.company} />}
                    scroll={{ x: 'max-content', y: this.props.listLength ? 'calc(100vh - 513px)' : undefined }}
                    rowSelection={{
                        type: 'checkbox',
                        onChange: this.onChangeSelected,
                        selectedRowKeys: this.props.selectedRowKeys
                    }}
                    showSizeChanger
                />
            </Wrapper>
        );
    }
}

const stateToProps = state => {
    const consultationsAmounts = (getParticipantsSummary.selectData(state).items || []).map(path(['consultations', 'amountForRole']));

    return ({
        user: getUser.selectData(state),
        consultationsAmount: consultationsAmounts.length ? Math.max.apply(null, consultationsAmounts) : 0,
        listLength: (getParticipantsSummary.selectData(state).items || []).length
    });
}

export default withState('selectedRowKeys', 'setSelectedRowKeys', [])(withRouter(asyncConnect({
    putResendEmailParticipant: putResendEmailParticipant
        .withSuccessHandler(() => message.success('Письмо регистрации успешно отправлено'))
        .withOptions({ resetOnUnmount: true }),
    putSetConsultant: putSetConsultant
        .withParams(() => ({ type: 'list' }))
        .withSuccessHandler(({ setSelectedRowKeys }) => {
            setSelectedRowKeys([]);
            message.success('Консультант успешно назначен');
        })
        .withErrorHandler(() => message.error('Не удалось назначить консультанта'))
        .withOptions({ resetOnUnmount: true })
}, stateToProps, { openParticipantModal, openParticipantImportModal, openConsultantSelectModal, openParticipantCommentModal, openParticipantsTableSettingsModal, openParticipantFeedbackModal })(Participants)));
