import React, { Component } from 'react';

export const withStateHolder = (WrappedComponent, initialState = {}) => {
    return class StateHolder extends Component {
        constructor(props) {
            super(props);

            this.state = initialState;
            this.holder = {};
        }

        render() {
            return <WrappedComponent
                {...this.props}
                state={this.state}
                setState={this.setState.bind(this)}
                holder={this.holder}
            />;
        }
    };
};

export default withStateHolder;
