import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { Col } from 'antd';

import withFilterForm from '../hocs/withFilterForm';
import SearchInput from '../forms/formComponents/SearchInput';

class NewsFilter extends Component {
    render() {
        return (
            <Col span={12}>
                <Field
                    name='title'
                    component={SearchInput}
                    placeholder='Заголовок'
                />
            </Col>
        );
    }
}

export default withFilterForm(NewsFilter);
