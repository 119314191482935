import React, { Component } from 'react';
import { Modal, message, Spin } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { getParticipantStatus, putParticipantStatuses } from '../../actions/asyncActions';
import StatusCommentForm from '../forms/StatusCommentForm';

class StatusCommentModal extends Component {
    render() {
        const { modal, params, getParticipantStatus, putParticipantStatuses, close } = this.props;

        return <Modal
            {...modal}
            title='Редактирование комментария'
            footer={null}>
            { getParticipantStatus.meta.success ? (
                <StatusCommentForm item={params} formAction={putParticipantStatuses} close={close} />
            ) : getParticipantStatus.meta.error ? (
                <div style={{ textAlign: 'center' }}>
                    { getParticipantStatus.meta.error.status === 422 ? 'Комментарий был удален' : 'Ошибка соединения с сервером' }
                </div>
            ) : (
                <div style={{ textAlign: 'center' }}><Spin /></div>
            )}
        </Modal>;
    }
}

export default withAsyncActions({
    getParticipantStatus: getParticipantStatus
        .withPayload(({ params }) => params)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    putParticipantStatuses: putParticipantStatuses
        .withSuccessHandler(({ close }) => {
            message.success('Комментарий был успешно отредактирован');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(StatusCommentModal);
