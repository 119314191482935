import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { Col, Row } from 'antd';
import { toPairs } from 'ramda';
import { connect } from 'react-redux';

import withFilterForm from '../hocs/withFilterForm';
import SearchInput from '../forms/formComponents/SearchInput';
import SearchSelect from '../forms/formComponents/SearchSelect';
import { getIndustryDictionary, getUser } from '../../actions/asyncActions';

class CompanyFilter extends Component {
    render() {
        const isSuperAdmin = this.props.user.superAdmin;

        return (
            <Row gutter={16}>
                <Col span={isSuperAdmin ? 6 : 6}>
                    <Field
                        name='name'
                        component={SearchInput}
                        placeholder='Название компании'
                    />
                </Col>
                <Col span={isSuperAdmin ? 6 : 6}>
                    <Field
                        name='industry'
                        component={SearchSelect}
                        placeholder='Отрасль'
                        action={getIndustryDictionary}
                        parseAsync={items => items ? toPairs(items).map(([id, value]) => ({ id, value })) : []}
                        allowClear />
                </Col>
            </Row>
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(withFilterForm(CompanyFilter));
