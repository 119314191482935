import 'react-app-polyfill/ie11';
import 'string.prototype.startswith';
import 'ie-array-find-polyfill';
import 'core-js/modules/es6.array.find-index';
import './utils/yupMethods';

import React from 'react';
import ReactDOM from 'react-dom';
import { locale } from 'moment';
import dotenv from 'dotenv';
import 'moment/locale/ru';

import configureStore from './store/configureStore';
import App from './components/App';
import { setBaseUrl } from './utils/http';
import { provideRequestInterceptors, provideResponseInterceptors } from './utils/httpInterceptors';
import rollbarInit from './utils/rollbar';

import './assets/styles/index.sass';

setBaseUrl();
provideRequestInterceptors();
provideResponseInterceptors();
locale('ru');

export const store = configureStore();
const MOUNT_NODE = document.getElementById('root');

if (process.env.NODE_ENV === 'production') {
    rollbarInit({
        token: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
        version: process.env.REACT_APP_VERSION,
        environment: window.location.host
    });

    window._version = {
        version: process.env.REACT_APP_VERSION,
    };
}

dotenv.config();

ReactDOM.render(<App store={store} />, MOUNT_NODE);
