import { Alert, Spin } from 'antd';
import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import { getPassword, postPassword } from '../actions/asyncActions';
import PasswordForm from './forms/PasswordForm';

class Password extends Component {
    render() {
        const { getPassword: { meta, data }, postPassword, match: { params: { id }}} = this.props;

        return meta.pending ? <Spin /> : data.registrationAvailable ?
            <PasswordForm formAction={postPassword} id={id} /> : <Alert type='error' message='Регистрация недоступна' />;
    }
}

export default withAsyncActions({
    getPassword: getPassword
        .withPayload(({ match: { params: { id }}}) => id)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    postPassword: postPassword
        .withOptions({ resetOnUnmount: true })
})(Password);
