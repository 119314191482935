import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postMention, putMention } from '../../actions/asyncActions';
import MentionForm from '../forms/MentionForm';

class MentionModal extends Component {
    render() {
        const { modal, params, putMention, postMention } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать отзыв' : 'Добавить отзыв'}
            footer={null}>
            <MentionForm
                formAction={params ? putMention : postMention}
                data={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postMention: postMention
        .withSuccessHandler(({ close }) => {
            message.success('Отзыв успешно добавлен');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putMention: putMention
        .withSuccessHandler(({ close }) => {
            message.success('Отзыв успешно изменен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(MentionModal);
