import React, { Component } from 'react';
import { Spin } from 'antd';

import StageItem from './StageItem';

export default class StageThemes extends Component {
    render() {
        const { data, meta, stage, openMaterialModal } = this.props;

        return meta.pending && !meta.lastSucceedAt ?
            <Spin /> :
            <StageItem
                stage={data}
                edit
                hideTitle
                onOpenMaterial={({ id }) => openMaterialModal({ id, stage })}
            />;
    }
}
