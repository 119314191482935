import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postCompanyRoles, putCompanyRoles, deleteCompanyRoles } from '../../actions/asyncActions';
import CompanyRolesForm from '../forms/CompanyRolesForm';

class CompanyRolesModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        params: PropTypes.object,
        postCompanyRoles:  PropTypes.object,
        putCompanyRoles: PropTypes.object,
        deleteCompany: PropTypes.object
    };

    render() {
        const { modal, params, postCompanyRoles, putCompanyRoles, deleteCompanyRoles } = this.props;

        return <Modal
            {...modal}
            title={params.item.id ? 'Редактировать роль' : 'Добавить роль'}
            footer={null}>
            <CompanyRolesForm
                formAction={params.item.id ? putCompanyRoles : postCompanyRoles}
                deleteCompanyRoles={deleteCompanyRoles}
                item={params.item}
                company={params.company} />
        </Modal>;
    }
}

export default withAsyncActions({
    postCompanyRoles: postCompanyRoles
        .withSuccessHandler(({ close }) => {
            message.success('Роль была успешно добавлена');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putCompanyRoles: putCompanyRoles
        .withSuccessHandler(({ close }) => {
            message.success('Роль была успешно отредактирована');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    deleteCompanyRoles: deleteCompanyRoles
        .withSuccessHandler(({ close }) => {
            message.success('Роль была успешно удалена');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
})(CompanyRolesModal);
