import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { Col, Row } from 'antd';
import { connect } from 'react-redux';

import withFilterForm from '../hocs/withFilterForm';
import SearchSelect from '../forms/formComponents/SearchSelect';
import SearchInput from '../forms/formComponents/SearchInput';
import { getCompanies, getUser } from '../../actions/asyncActions';
import { includes } from 'ramda';
import { ADMIN, SUPER_ADMIN } from '../../constants/roles';
import { PARTICIPATION_STATUS } from '../../constants/dictionaries';

class ParticipationRequestsFilter extends Component {
    render() {
        const isAdmin = includes(ADMIN, this.props.user.roles) || includes(SUPER_ADMIN, this.props.user.roles);

        return (
            <Fragment>
                <Row gutter={16}>
                    <Col span={isAdmin && !this.props.hideCompany ? 8 : 12}>
                        <Field
                            name='name'
                            component={SearchInput}
                            placeholder='Участник'
                        />
                    </Col>
                    { isAdmin && !this.props.hideCompany &&
                        <Col span={8}>
                            <Field
                                name='company'
                                component={SearchSelect}
                                action={getCompanies}
                                placeholder='Компания'
                                namePath='name'
                                allowClear
                                searchable
                            />
                        </Col>
                    }
                    <Col span={isAdmin && !this.props.hideCompany ? 8 : 12}>
                        <Field
                            name='status'
                            component={SearchSelect}
                            options={PARTICIPATION_STATUS}
                        />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(withFilterForm(ParticipationRequestsFilter));
