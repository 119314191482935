import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { ADMIN, CLIENT_ADMIN, SUPER_ADMIN } from "./roles";

export const PARTICIPANT_STATUSES = [
    { id: 'new', title: 'Новый', color: '#A7B9CF', hide: true, icon: <InfoCircleOutlined />, chartColor: '#A7B9CF' },
    { id: 'active', title: 'Активный', roles: [SUPER_ADMIN, ADMIN], color: '#549E38', icon: <PlayCircleOutlined />, chartColor: '#47AAC4' },
    { id: 'passive', title: 'Пассивный', color: '#BA9B66', icon: <PlayCircleOutlined />, chartColor: '#CAE2FF' },
    { id: 'refused', title: 'Отказ от программы', roles: [SUPER_ADMIN, ADMIN, CLIENT_ADMIN], color: '#F54D2E', icon: <CloseCircleOutlined />, chartColor: '#F54D2E', secondLevel: 'Отказ от программы по другой причине' },
    { id: 'employed_before_program', title: 'Трудоустроенный до программы', roles: [SUPER_ADMIN, ADMIN, CLIENT_ADMIN], color: '#8C4484', icon: <CloseCircleOutlined />, chartColor: '#F2994A' },
    { id: 'employed_in_program', title: 'Трудоустроен во время программы или через месяц после окончания не через АНКОР', roles: [SUPER_ADMIN, ADMIN, CLIENT_ADMIN], color: '#8C4484', icon: <CheckCircleOutlined />, chartColor: '#6FCF97' },
    { id: 'not_get_in_touch', title: 'Не выходит на связь', roles: [SUPER_ADMIN, ADMIN, CLIENT_ADMIN], color: '#F54D2E', icon: <PauseCircleOutlined />, chartColor: '#BA9B66' },
    { id: 'finished', title: 'Программа завершена', roles: [SUPER_ADMIN, ADMIN, CLIENT_ADMIN], color: '#47AAC4', icon: <CheckCircleOutlined />, chartColor: '#219653', secondLevel: 'Не трудоустроен' },
    { id: 'finished_with_active_search', title: 'Не трудоустроен, вел активный поиск после завершения программы', roles: [SUPER_ADMIN, ADMIN, CLIENT_ADMIN], color: '#47AAC4', icon: <CheckCircleOutlined />, chartColor: '#219653' },
    { id: 'finished_without_active_search', title: 'Не трудоустроен, не вел активный поиск после завершения программы', roles: [SUPER_ADMIN, ADMIN, CLIENT_ADMIN], color: '#47AAC4', icon: <CheckCircleOutlined />, chartColor: '#219653' },
    { id: 'pause', title: 'Пауза', roles: [SUPER_ADMIN, ADMIN, CLIENT_ADMIN], color: '#F54D2E', icon: <PauseCircleOutlined />, chartColor: '#BA9B66' },
    { id: 'in_work', title: 'В работе', color: '#BA9B66', icon: <PlayCircleOutlined />, chartColor: '#47AAC4' },
    { id: 'employed_with_ancor', title: 'Трудоустроен во время программы или через месяц через АНКОР', roles: [SUPER_ADMIN, ADMIN, CLIENT_ADMIN], color: '#8C4484', icon: <CloseCircleOutlined />, chartColor: '#27AE60' },
    { id: 'decides', title: 'Принимает решение', roles: [SUPER_ADMIN, ADMIN, CLIENT_ADMIN], color: '#F54D2E', icon: <PauseCircleOutlined />, chartColor: '#FFD34F' }
];

export const THEME_ATTEND_STATUSES = [
    { title: 'Не приглашен', id: '1', color: '#A7B9CF' },
    { title: 'Приглашен', id: '2', color: '#2B3D4F' },
    { title: 'Не подтвердил', id: '3', color: '#8C4484' },
    { title: 'Принимает решение', id: '4', color: '#BA9B66' },
    { title: 'Подтвердил', id: 'scheduled', color: '#47AAC4' },
    { title: 'Посетил', id: 'attended', color: '#549E38' },
    { title: 'Не посетил', id: 'not_participated', color: '#F54D2E' }
];

export const CONSULTATION_ATTEND_STATUSES = [
    { title: 'Не подтвердил', id: 'not_accepted', color: '#A7B9CF' },
    { title: 'Принимает решение', id: '2', color: '#BA9B66' },
    { title: 'Подтвердил', id: '3', color: '#47AAC4' },
    { title: 'Запланировал', id: 'scheduled', color: '#8C4484' },
    { title: 'Прошел', id: 'attended', color: '#549E38' },
    { title: 'Отменил', id: 'cancelled', color: '#F54D2E' },
];
