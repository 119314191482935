import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Rate } from 'antd';
import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { getParticipantFeedbacks } from '../actions/asyncActions';
import { getFileView } from '../constants/urls';
import TableList from './TableList';
import CompanyFeedbacksFilter from './filters/CompanyFeedbacksFilter';
import { openParticipantFeedbackModal } from '../actions/modalActions';
import { DELETE_PARTICIPANT_FEEDBACK, POST_PARTICIPANT_FEEDBACK_CONSULTATION, POST_PARTICIPANT_FEEDBACK_EVENT, PUT_PARTICIPANT_FEEDBACK } from '../constants/actionTypes';
import { path } from 'ramda';

const Wrapper = styled.div`
    .comment-cell {
        position: relative;;
        .comment-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 16px;
            cursor: pointer;
            color: #2B3D4F;
            font-style: italic;
            .comment-overflow {
                height: 43px;
                overflow-y: hidden;
            }
            &::before {
                position: absolute;
                top: 0;
                right: 0;
                content: '';
                border: 12px solid transparent;
                border-top: 12px solid #2B3D4F;
                border-right: 12px solid #2B3D4F;
            }
        }
        .comment-wrapper:hover::after {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: 'открыть отзыв';
            background: rgba(43, 61, 79, .5);
            color: #fff;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            text-align: center;
        }
    }
`;

const SERVICE_TYPES = {
    events: 'Вебинары и семинары',
    testing: 'Тестирования',
    consultations: 'Консультация',
    promotion: 'Продвижение'
};

class CompanyFeedbacks extends Component {
    getColumns = () => {
        return [
            {
                title: 'Участник',
                dataIndex: ['_embedded', 'participant'],
                key: 'participant',
                render: participant => <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar style={{ marginRight: 10 }} src={participant.photo ? getFileView(participant.photo) : null} icon={<UserOutlined />} />
                    <strong>{ participant.lastName } { participant.firstName } { participant.middleName || '' }</strong>
                </div>
            },
            {
                title: 'Сервис',
                key: 'module',
                render: item => {
                    const consultant = path(['_embedded', 'consultant'], item);

                    return <div>
                        <div>{ item.relatedModelData.title || (item.module === 'consultations' && `Консультация (${ consultant.lastName } ${ consultant.firstName } ${ consultant.middleName || '' })`) }</div>
                        <div style={{ fontSize: 12, color: '#A7B9CF' }}>{ SERVICE_TYPES[item.module] }</div>
                    </div>;
                }
            },
            {
                title: 'Дата',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: date => moment.utc(date).format('DD.MM.YYYY')
            },
            {
                title: 'Оценка',
                dataIndex: 'rating',
                key: 'rating',
                render: rating => <Rate value={rating} disabled />
            },
            {
                title: 'Комментарий',
                dataIndex: 'comment',
                key: 'comment',
                className: 'comment-cell',
                render: (comment, item) => {
                    const participant = path(['_embedded', 'participant'], item);
                    const consultant = path(['_embedded', 'consultant'], item);

                    return <div
                        className='comment-wrapper'
                        onClick={() => this.props.openParticipantFeedbackModal({ company: this.props.company, item, header: <div>
                            <strong>{ item.relatedModelData.title || (item.module === 'consultations' ?
                                `Консультация (${ consultant.lastName } ${ consultant.firstName } ${ consultant.middleName || '' })` : 'Продвижение') }
                            </strong>
                            <div><strong style={{ color: '#A7B9CF' }}>{ participant.lastName } { participant.firstName } { participant.middleName || '' }</strong></div>
                        </div> })}>
                        <div className='comment-overflow'>
                            { comment }
                        </div>
                    </div>;
                }
            }
        ];
    }

    renderButtons = () => {
        return <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => this.props.openParticipantFeedbackModal({ company: this.props.company })}>
            Добавить
        </Button>;
    }

    render() {
        return <Wrapper>
            <TableList
                columns={this.getColumns()}
                action={getParticipantFeedbacks}
                staticFilter={{ company: this.props.company }}
                filterForm={<CompanyFeedbacksFilter company={this.props.company} />}
                buttons={this.renderButtons()}
                refreshActions={[POST_PARTICIPANT_FEEDBACK_CONSULTATION, POST_PARTICIPANT_FEEDBACK_EVENT, PUT_PARTICIPANT_FEEDBACK, DELETE_PARTICIPANT_FEEDBACK]} />
        </Wrapper>;
    }
}

export default connect(null, { openParticipantFeedbackModal })(CompanyFeedbacks);
