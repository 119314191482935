import React from 'react';
import { Tabs } from 'antd';
import { contains, path } from 'ramda';
import { Route } from 'react-router';

import Admins from './Admins';
import NoMatch from './NoMatch';
import ClientAdmins from './ClientAdmins';

const AdminsTabs = props => {
    const { location } = props;
    const activeKey = path([0], location.pathname.replace(/\/admins\/?/, '').split('/'));

    const onChange = key => {
        props.history.push(`/admins/${key}`);
    };

    return contains(activeKey, ['', 'clients', 'consultants']) ?
        <Tabs
            className='settings-tabs'
            animated={{ tabPane: false }}
            activeKey={activeKey}
            onChange={onChange}
        >
            <Tabs.TabPane tab='Администраторы' key=''>
                <Route path='/admins' component={Admins} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Консультанты' key='consultants'>
                <Route path='/admins/consultants' render={props => <Admins {...props} consultant />} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Администраторы/менеджеры клиента' key='clients'>
                <Route path='/admins/clients' component={ClientAdmins} />
            </Tabs.TabPane>
        </Tabs> :
        <Route component={NoMatch} />;
}

export default AdminsTabs;
