"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getResumeWithHHData = exports.resumePaths = exports.getHHAuthLink = exports.downloadResumeAsDocx = exports.getYear = exports.getDate = exports.getArrayItems = exports.getName = void 0;

var _docx = require("docx");

var _ramda = require("ramda");

var _moment = _interopRequireDefault(require("moment"));

var _qs = _interopRequireDefault(require("qs"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

var getName = function getName() {
  for (var _len = arguments.length, names = new Array(_len), _key = 0; _key < _len; _key++) {
    names[_key] = arguments[_key];
  }

  return names.filter(Boolean).join(' ');
};

exports.getName = getName;

var getArrayItems = function getArrayItems(items) {
  return items ? (0, _ramda.filter)(function (i) {
    return !(0, _ramda.isEmpty)(i);
  }, items) : [];
};

exports.getArrayItems = getArrayItems;

var getDate = function getDate(date) {
  return (0, _moment["default"])(date).format('DD.MM.YYYY');
};

exports.getDate = getDate;

var getYear = function getYear(date) {
  return date ? (0, _moment["default"])(date).format('YYYY') : '';
};

exports.getYear = getYear;
var personalFields = [{
  field: 'lastName',
  title: 'Фамилия'
}, {
  field: 'firstName',
  title: 'Имя'
}, {
  field: 'middleName',
  title: 'Отчество'
}, {
  field: 'birthDate',
  title: 'Дата рождения'
}, {
  field: 'maritalStatus',
  title: 'Семейное положение'
}];
var contactFields = [{
  field: 'phone',
  title: 'Телефон'
}, {
  field: 'email',
  title: 'Email'
}, {
  field: 'skype',
  title: 'Skype'
}, {
  field: 'address',
  title: 'Адрес'
}];
var intentionFields = [{
  field: 'position',
  title: 'Позиция'
}, {
  field: 'profile',
  title: 'Профиль (краткое описание успешного профессионального опыта)'
}, {
  field: 'salary',
  title: 'Желаемая заработная плата'
}];
var professionalExperienceFields = [{
  field: 'responsibilities',
  "break": true
}];
var workExperienceFields = [{
  field: 'date',
  title: 'Дата',
  parser: function parser(v) {
    var date = v || {};
    return date.dateFrom && date.withPresent ? "".concat(getDate(date.dateFrom), " - \u043F\u043E \u043D\u0430\u0441\u0442\u043E\u044F\u0449\u0435\u0435 \u0432\u0440\u0435\u043C\u044F") : date.dateFrom && date.dateTo ? "".concat(getDate(date.dateFrom), " - ").concat(getDate(date.gateTo)) : '';
  }
}, {
  field: 'company',
  title: 'Компания'
}, {
  field: 'position',
  title: 'Позиция'
}, {
  field: 'responsibilities',
  title: 'Обязанности',
  "break": true
}];
var achivementsFields = [{
  field: 'achievements'
}];
var educationFields = [{
  field: 'date',
  title: 'Дата',
  parser: function parser(v) {
    return v ? "".concat(getDate(v.dateFrom), " - ").concat(getDate(v.gateTo)) : '';
  }
}, {
  field: 'name',
  title: 'Название учебного заведения'
}, {
  field: 'faculty',
  title: 'Факультет'
}, {
  field: 'specialty',
  title: 'Специальность по диплому',
  "break": true
}];
var additionalEducationFields = [{
  field: 'year',
  title: 'Год',
  parser: getYear
}, {
  field: 'name',
  title: 'Название курса'
}, {
  field: 'company',
  title: 'Название компании'
}, {
  field: 'sertificate',
  title: 'Сертификат',
  "break": true
}];
var qualitiesFields = [{
  field: 'qualities'
}];
var recommendationsFields = [{
  field: 'recomendations'
}];

var getItem = function getItem(item, fields, last) {
  return new _docx.Paragraph({
    spacing: item["break"] && !last ? {
      line: 500,
      lineRule: 'auto',
      before: 0,
      after: 0
    } : null,
    children: [new _docx.TextRun({
      text: item.title ? "".concat(item.title, ": ") : '',
      bold: true,
      color: '#262626',
      font: {
        name: 'Arial'
      }
    }), new _docx.TextRun({
      text: item.parser ? item.parser((fields || {})[item.field]) : (fields || {})[item.field],
      color: '#565656',
      font: {
        name: 'Arial'
      }
    })]
  });
};

var downloadResumeAsDocx = function downloadResumeAsDocx(resume) {
  var doc = new _docx.Document({
    title: 'Резюме',
    styles: {
      paragraphStyles: [{
        id: 'titleName',
        name: 'Name',
        basedOn: 'Normal',
        next: 'Normal',
        quickFormat: true,
        run: {
          font: 'Arial',
          size: 44,
          bold: true,
          color: '#9FA0A2'
        }
      }, {
        id: 'resumeText',
        name: 'Title text',
        basedOn: 'Normal',
        next: 'Normal',
        quickFormat: true,
        run: {
          font: 'Arial',
          size: 24,
          color: '#9FA0A2'
        },
        paragraph: {
          spacing: {
            line: 300,
            lineRule: 'auto',
            before: 0,
            after: 0
          }
        }
      }, {
        id: 'blockTitle',
        name: 'Block title',
        basedOn: 'Normal',
        next: 'Normal',
        quickFormat: true,
        run: {
          font: 'Arial',
          size: 30,
          bold: true,
          color: '#565656'
        },
        paragraph: {
          spacing: {
            line: 340,
            lineRule: 'auto',
            before: 440,
            after: 0
          }
        }
      }]
    }
  });
  doc.addSection({
    properties: {},
    children: [new _docx.Paragraph({
      text: getName(resume.lastName, resume.firstName, resume.middleName),
      style: 'titleName'
    }), new _docx.Paragraph({
      text: 'Резюме',
      style: 'resumeText'
    }), new _docx.Paragraph({
      text: 'Персональные данные',
      style: 'blockTitle'
    })].concat(_toConsumableArray((0, _ramda.filter)(function (i) {
      return !!resume[i.field];
    }, personalFields).map(function (item) {
      return getItem(item, resume);
    })), [new _docx.Paragraph({
      text: 'Контакты',
      style: 'blockTitle'
    })], _toConsumableArray((0, _ramda.filter)(function (i) {
      return !!resume[i.field];
    }, contactFields).map(function (item) {
      return getItem(item, resume);
    })), [new _docx.Paragraph({
      text: 'Цель',
      style: 'blockTitle'
    })], _toConsumableArray((0, _ramda.filter)(function (i) {
      return !!resume[i.field];
    }, intentionFields).map(function (item) {
      return getItem(item, resume);
    })), _toConsumableArray(resume.type === 'functional' ? [new _docx.Paragraph({
      text: 'Профессиональный опыт',
      style: 'blockTitle'
    })].concat(_toConsumableArray((0, _ramda.flatten)(getArrayItems(resume.professionalExperience).map(function (item, index) {
      return (0, _ramda.filter)(function (i) {
        return !!item[i.field];
      }, professionalExperienceFields).map(function (i) {
        return getItem(i, item, getArrayItems(resume.professionalExperience).length - 1 === index);
      });
    })))) : []), [new _docx.Paragraph({
      text: 'Опыт работы',
      style: 'blockTitle'
    })], _toConsumableArray((0, _ramda.flatten)(getArrayItems(resume.experience).map(function (item, index) {
      return (0, _ramda.filter)(function (i) {
        return !!item[i.field];
      }, workExperienceFields).map(function (i) {
        return getItem(i, item, getArrayItems(resume.experience).length - 1 === index);
      });
    }))), [new _docx.Paragraph({
      text: 'Достижения',
      style: 'blockTitle'
    })], _toConsumableArray((0, _ramda.filter)(function (i) {
      return !!resume[i.field];
    }, achivementsFields).map(function (item) {
      return getItem(item, resume);
    })), [new _docx.Paragraph({
      text: 'Основное образование',
      style: 'blockTitle'
    })], _toConsumableArray((0, _ramda.flatten)(getArrayItems(resume.education).map(function (item, index) {
      return (0, _ramda.filter)(function (i) {
        return !!item[i.field];
      }, educationFields).map(function (i) {
        return getItem(i, item, getArrayItems(resume.education).length - 1 === index);
      });
    }))), [new _docx.Paragraph({
      text: 'Дополнительное образование',
      style: 'blockTitle'
    })], _toConsumableArray((0, _ramda.flatten)(getArrayItems(resume.additionalEducation).map(function (item, index) {
      return (0, _ramda.filter)(function (i) {
        return !!item[i.field];
      }, additionalEducationFields).map(function (i) {
        return getItem(i, item, getArrayItems(resume.additionalEducation).length - 1 === index);
      });
    }))), [new _docx.Paragraph({
      text: 'Личностные качества и компетенции',
      style: 'blockTitle'
    })], _toConsumableArray((0, _ramda.filter)(function (i) {
      return !!resume[i.field];
    }, qualitiesFields).map(function (item) {
      return getItem(item, resume);
    })), [new _docx.Paragraph({
      text: 'Рекомендации',
      style: 'blockTitle'
    })], _toConsumableArray((0, _ramda.filter)(function (i) {
      return !!resume[i.field];
    }, recommendationsFields).map(function (item) {
      return getItem(item, resume);
    })))
  });

  _docx.Packer.toBlob(doc).then(function (blob) {
    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = 'resume.docx';
    a.click();
  });
};

exports.downloadResumeAsDocx = downloadResumeAsDocx;

var getHHAuthLink = function getHHAuthLink() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var searchPath = _qs["default"].stringify({
    state: JSON.stringify(_objectSpread({}, data))
  });

  return "".concat(process.env.REACT_APP_HH_URI || "".concat(window.location.origin, "/api/external/hh"), "?").concat(searchPath);
};

exports.getHHAuthLink = getHHAuthLink;

var getResumeDate = function getResumeDate(date) {
  return date ? (0, _moment["default"])(date, 'YYYY-MM-DD').toDate() : null;
};

var resumePaths = [{
  field: 'lastName',
  key: 'last_name'
}, {
  field: 'firstName',
  key: 'first_name'
}, {
  field: 'middleName',
  key: 'middle_name'
}, {
  field: 'birthDate',
  key: 'birth_date',
  format: function format(date) {
    return getResumeDate(date);
  }
}, {
  field: 'email',
  key: 'contact',
  format: function format(contact) {
    return (0, _ramda.path)(['value'], (0, _ramda.find)(function (v) {
      return v.type.id === 'email';
    }, contact || []));
  }
}, {
  field: 'phone',
  key: 'contact',
  format: function format(contact) {
    return (0, _ramda.path)(['value', 'formatted'], (0, _ramda.find)(function (v) {
      return v.type.id === 'cell';
    }, contact || []));
  }
}, {
  field: 'skype',
  key: 'site',
  format: function format(site) {
    return (0, _ramda.path)(['url'], (0, _ramda.find)(function (v) {
      return v.type.id === 'skype';
    }, site || []));
  }
}, {
  field: 'position',
  key: 'title'
}, {
  field: 'salary',
  key: 'salary',
  format: function format(salary) {
    return salary ? "".concat(salary.amount).concat(salary.amount ? " ".concat(salary.currency || '') : '') : null;
  }
}, {
  field: 'address',
  key: 'area',
  format: function format(area, data) {
    return "".concat(area.name).concat(data.metro ? ", ".concat(data.metro.name) : '');
  }
}, {
  field: 'experience',
  key: 'experience',
  format: function format(experience) {
    return (experience || []).map(function (item) {
      return {
        company: item.company,
        position: item.position,
        responsibilities: item.description,
        date: item.start && item.end ? {
          dateFrom: getResumeDate(item.start),
          dateTo: getResumeDate(item.end)
        } : item.start ? {
          dateFrom: getResumeDate(item.start),
          withPresent: true
        } : null
      };
    });
  }
}, {
  field: 'education',
  key: 'education',
  format: function format(education) {
    return (education.primary || []).map(function (item) {
      return {
        name: item.name,
        faculty: item.organization,
        specialty: item.result
      };
    });
  }
}, {
  field: 'additionalEducation',
  key: 'education',
  format: function format(education) {
    return (education.attestation || []).map(function (item) {
      return {
        year: item.year ? (0, _moment["default"])(item.year, 'YYYY').toDate() : null,
        name: item.name,
        company: item.organization,
        sertificate: item.result
      };
    });
  }
}];
exports.resumePaths = resumePaths;

var getResumeWithHHData = function getResumeWithHHData(data, hhData) {
  var item = (0, _ramda.clone)(data);
  (0, _ramda.forEach)(function (p) {
    var hhValue = p.format ? p.format(hhData[p.key], hhData) : hhData[p.key];

    if (hhValue) {
      item = (0, _ramda.assocPath)(['content', p.field], hhValue, item);
    }
  }, resumePaths);

  if (!(0, _ramda.path)(['content', 'type'], item)) {
    item = (0, _ramda.assocPath)(['content', 'type'], 'chronological', item);
  }

  return item;
};

exports.getResumeWithHHData = getResumeWithHHData;