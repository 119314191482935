import { Breadcrumb, Button, Spin, Tabs } from 'antd';
import { contains, find, isEmpty, last, propEq } from 'ramda';
import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { getCompany, getMessageTemplates, getUser } from '../actions/asyncActions';
import { openCompanyModal } from '../actions/modalActions';
import { takeEvery } from 'redux-saga/effects';
import { POST_MESSAGE_TEMPLATE, PUT_COMPANY, PUT_MESSAGE_TEMPLATE } from '../constants/actionTypes';
import CompanyInfo from './CompanyInfo';
import { Route } from 'react-router';
import CompanyWebinars from './CompanyWebinars';
import CompanyRoles from './CompanyRoles';
import Participants from './Participants';
import Invites from './Invites';
import ParticipationRequests from './ParticipationRequests';
import CompanyModules from './CompanyModules';
import Promotion from './Promotion';
import ParticipantPromotion from './ParticipantPromotion';
import CompanyFeedbacks from './CompanyFeedbacks';
import Billing from './Billing';
import CompanyStatistic from './statistics/CompanyStatistic';
import { Link } from 'react-router-dom';
import BreadcrumbContext from './contexts/BreadcrumbContext';

const StyledTabs = styled(Tabs)`
    overflow: visible;
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
    background: #EDEDED;
    padding-bottom: 15px;
`;

class Company extends Component {
    state = {
        breadcrumb: null
    };

    onChange = key => {
        this.props.history.push(`/company/${this.props.match.params.id}/${key === 'item_0' ? '' : key}`);
    };

    getTabs = () => {
        const { getCompany: { data, meta }, match } = this.props;

        return [
            { tab: 'Информация', key: '', children: <Route path='/company/:id' render={props => <CompanyInfo {...props} getCompany={this.props.getCompany} />} /> },
            { tab: 'Сервисы', key: 'modules', children: <Route path='/company/:id/modules' render={props => meta.pending && !meta.lastSucceedAt ? <div style={{ textAlign: 'center' }}><Spin /></div> : <CompanyModules {...props} company={data} />} /> },
            { tab: 'Вебинары', key: 'webinars', children: <Route path='/company/:id/webinars' render={props => <CompanyWebinars {...props} company={data} />} /> },
            { tab: 'Профессиональная поддержка', key: 'promotion', children: <Fragment>
                <Route path='/company/:id/promotion/:participant/:promotion' render={props => <ParticipantPromotion {...props} company={match.params.id} />} />
                <Route path='/company/:id/promotion' exact render={props => <Promotion {...props} company={match.params.id} />} />
            </Fragment> },
            { tab: 'Отзывы', key: 'feedbacks', children: <Route path='/company/:id/feedbacks' render={props => <CompanyFeedbacks {...props} company={match.params.id} />} /> },
            { tab: 'Роли', key: 'roles', children: <Route path='/company/:id/roles' render={props => <CompanyRoles {...props} company={data} />} /> },
            { tab: 'Биллинг', key: 'billing', children: <Route path='/company/:id/billing' render={props => <Billing {...props} id={match.params.id} company={data} />} /> },
            { tab: 'Участники', key: 'participants', children: <Route path='/company/:id/participants' render={props => <Participants {...props} company={match.params.id} companyData={data} />} /> },
            { tab: 'Инвайты', key: 'invites', children: <Route path='/company/:id/invites' render={props => <Invites {...props} company={match.params.id} />} /> },
            { tab: 'Заявки', key: 'requests', children: <Route path='/company/:id/requests' render={props => <ParticipationRequests {...props} company={match.params.id} />} /> },
            { tab: 'Аналитика', key: 'statistic', children: <Route path='/company/:id/statistic' render={props => <CompanyStatistic {...props} company={data} loaded={!!meta.lastSucceedAt} />} /> },
        ];
    }

    getCurrentTab = activeKey => {
        const tab = find(propEq('key', activeKey), this.getTabs());

        return <Link to={`/company/${this.props.match.params.id}/${tab.key}`}>
            { tab.tab }
        </Link>
    }

    setBreadcrumb = breadcrumb => this.setState({ breadcrumb });

    render() {
        const { history, getCompany: { data, meta }, openCompanyModal, location, match } = this.props;
        const hasData = !isEmpty(data);
        const activeKey = contains('promotion', location.pathname) ? 'promotion' : last(location.pathname.replace(match.url, '').split('/'));

        return <BreadcrumbContext.Provider value={{ setBreadcrumb: this.setBreadcrumb, breadcrumb: this.state.breadcrumb }}>
            <div>
                { meta.lastSucceedAt &&
                    <StyledBreadcrumb>
                        <Breadcrumb.Item>
                            <Link to='/'>Компании</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/company/${match.params.id}`}>{ data.name }</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            { this.getCurrentTab(activeKey) }
                        </Breadcrumb.Item>
                        { this.state.breadcrumb }
                    </StyledBreadcrumb>
                }
                <div className='toolbar-row detail-toolbar'>
                    <Button shape='circle' icon={<ArrowLeftOutlined />} onClick={() => history.push('/')} />
                    { hasData &&
                        <Fragment>
                            <h1>
                                { data.name }
                            </h1>
                            { !activeKey && this.props.user.superAdmin &&
                                <div className='toolbar-actions'>
                                    <Button
                                        type='primary'
                                        icon={<EditOutlined />}
                                        onClick={() => openCompanyModal(data)}>
                                        Редактировать
                                    </Button>
                                </div>
                            }
                        </Fragment>
                    }
                </div>
                <StyledTabs
                    animated={{ tabPane: false }}
                    activeKey={activeKey}
                    onChange={this.onChange}>
                    { this.getTabs().map(tab =>
                        <Tabs.TabPane tab={tab.tab} key={tab.key}>
                            { tab.children }
                        </Tabs.TabPane>
                    )}
                </StyledTabs>
            </div>
        </BreadcrumbContext.Provider>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    getCompany: getCompany
        .withPayload(({ match }) => ({ id: match.params.id }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_COMPANY)], () => {
                getProps().getCompany.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, dispatchOnUpdate: true, resetOnUnmount: true }),
    getMessageTemplates: getMessageTemplates
        .withPayload(({ match }) => ({
            q: {
                company: match.params.id,
                type: 'participant_invite'
            }
        }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(POST_MESSAGE_TEMPLATE), toSuccess(PUT_MESSAGE_TEMPLATE)], () => {
                getProps().getMessageTemplates.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, dispatchOnUpdate: true, resetOnUnmount: true }),
}, stateToProps, { openCompanyModal })(Company);
