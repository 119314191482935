import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Field } from 'react-final-form';
import * as yup from 'yup';

import { Textarea, withFormWrapper } from '@meconsultant/common';
import Input from './formComponents/Input';
import logo from '../../assets/img/logo.png';
import TemplateEditor, { parseCompanyTemplate } from './formComponents/TemplateEditor';

export const MessageTemplate = props => {
    return <table bgColor="#EDEDED" border={0} cellSpacing={0} style={{ padding: '30px 20px 30px 20px', width: '100%', border: 0, marginBottom: 15 }}>
        <tbody>
            <tr>
                <td style={{ padding: '20px' }}>
                    <table className='message-body' bgColor="#ffffff" border={0} cellspacing={0} style={{ maxWidth: 600, width: '100%', margin: '0 auto', height: 500, border: 0 }}>
                        <tbody>
                            <tr>
                                <td bgColor="#2B3D4F" style={{ textAlign: 'center', height: 80 }}>
                                    <img style={{ display: 'inline-block', width: 152, height: 49, backgroundSize: '152px auto' }} src={logo} border="0" alt="Logo" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '40px 30px 40px 30px' }}>
                                    <table cellSpacing={0} style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <h1 style={{ margin: 0, fontWeight: 'bold', fonSize: 24, lineHeight: '30px', color: '#2B3D4F' }}>
                                                        <span style={{ display: 'inline-block', marginRight: 10, width: 28, height: 16, backgroundSize: '28px auto', backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAgCAYAAABHA7voAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH8SURBVHgB1ZhJUsJAFIb/zmQhanmElCvLKi2PYLlGKzdwuIDDBUQugJ4APYEW4BpvgJVyH2/AQnFl0nYjKEMDIWl84dtk6s2ffq+/dBjmxOfhTplznIOOIF/zNwzMgY+D7WPKcBxoGaaxL8+1B2x5my4DuwIdHBFKuceXN3mhPeBSaFfEwQUZ7G7lyb/pXVnQSLuwcyXKYw90BIbJSv03GDQhS9MJnQB08IhH+6v11+f+m1pKtOW56yJcA5QwlIbDSbQEXIrW5KLigo4gX/WLqgepA1IrQcB7SlCRKmAWlMAjXPaUoCJVQCe0y8iQElQk1oRUgjh4oGNECSoSaSILSjAYTnJV/37awEQzSK0Esajd5mrTw0lm7sFuabqgI1ip+xdxB88UsF3Y9kRRF0HHRCWoiB1Q9h0YK4MS9rdLiEvsgPaXXQRhaXLOG+O+ViYRa5ERu/Mz0dhHoCMwLfMUCZiqCVmadug0xcB10BBbCSqmzmBXCVThZlKCiok9mAUl5C3jGikYW6Lvhd09g0WUQpdK2Jh11RxGOYOy70S4CihJoAQVyoCLqgQVI4vMIitBxUAPLroSVAyUqFQCYTjIDazOcJLfgFlQwrLJLqGZTom2vK1dJ7SaoEOLElQYP7tz6wF0cPGar+cRTmJ1lMD+vTR537n8p5nqa2US30fwvev0uBp4AAAAAElFTkSuQmCC')` }}></span>
                                                        <span>
                                                            { props.welcomeText }
                                                        </span>
                                                    </h1>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div style={{ color: '#2B3D4F', fontSize: 18, lineHeight: '23px' }}>
                                                        { props.contentText ? <div dangerouslySetInnerHTML={{ __html: parseCompanyTemplate(props.contentText, true) }} /> : props.content }
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center', padding: '20px 0 20px 0' }}>
                                                    <span style={{ display: 'inline-block', textTransform: 'uppercase', color: '#fff', textDecoration: 'none', background: '#F54D2E', minWidth: 300, padding: 20, fontSize: 18, textAlign: 'center' }}>зарегистрироваться</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div style={{ color: '#2B3D4F', fontSize: 16, lineHeight: '23px' }}>
                                                        { props.farewellText }
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center', paddingTop: 20 }}>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>;
}

class CompanyMessageTemplateForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool,
        item: PropTypes.object,
    };

    render() {
        const { isSubmitting } = this.props;
        const disabled = isSubmitting;

        return <div className='company-form'>
            <Field
                name='subject'
                component={Input}
                disabled={disabled}
                label='Тема письма' />
            <MessageTemplate
                welcomeText={
                    <div className='message-input-wrap'>
                        <Field
                            name='welcomeText'
                            component={Input}
                            disabled={disabled}
                            placeholder='Приветствие' />
                    </div>
                }
                content={
                    <Field
                        name='content'
                        component={TemplateEditor}
                        disabled={disabled}
                        placeholder='Тело письма' />
                }
                farewellText={
                    <Field
                        name='farewellText'
                        component={Textarea}
                        disabled={disabled}
                        placeholder='Завершение письма' />
                } />
            <Button
                type='primary'
                htmlType='submit'
                disabled={disabled}>
                Сохранить
            </Button>
        </div>;
    }
}

const validationSchema = yup.object().shape({
    subject: yup.string().nullable().required(),
    welcomeText: yup.string().nullable().required(),
    content: yup.string().nullable().required(),
    farewellText: yup.string().nullable().required(),
});

export default withFormWrapper(CompanyMessageTemplateForm, {
    mapPropsToValues: ({ item }) => ({
        ...item,
        content: parseCompanyTemplate(item.content, true),
    }),
    mapBeforeSubmit: (values) => ({
        ...values,
        content: parseCompanyTemplate(values.content),
    }),
    validationSchema,
    subscriptions: { values: true }
});
