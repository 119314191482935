import qs from 'qs';
import { filter, is, isNil, isEmpty, omit, assoc, propOr, includes } from 'ramda';
import { LINES_PER_PAGE } from '../constants/lists';

export const getUrlParams = location => qs.parse(propOr('', 'search', location), { ignoreQueryPrefix: true, strictNullHandling: true });

export const getFilters = location => {
    const params = getUrlParams(location);

    try {
        return JSON.parse(params.filter);
    } catch {
        return null;
    }
};

export const getUrlPagination = location => {
    const params = getUrlParams(location);
    const { offset = 0, limit = LINES_PER_PAGE } = params;

    return ({
        offset: Number(offset),
        limit: Number(limit),
    });
};

export const getSorting = location => {
    const { sorting } = getUrlParams(location);

    return sorting || {};
}

const clearQuery = query => {
    let cleared = filter(v => !isNil(v), query);

    if (cleared.filter) {
        try {
            const parsedFilter = JSON.parse(cleared.filter);
            const filters = filter(f => is(Array, f) ? !!filter(i => !!i, f).length : !isNil(f), parsedFilter);

            cleared = isEmpty(filters) ? omit(['filter'], cleared) : assoc('filter', JSON.stringify(filters), cleared);
        } catch {}
    }

    return cleared;
}

export const extendSearchPath = (location, searchPath = {}) => {
    const current = qs.parse(location.search, { ignoreQueryPrefix: true });
    const query = clearQuery({ ...current, ...searchPath });

    return `${location.pathname}${qs.stringify(query, { addQueryPrefix: true, strictNullHandling: true })}`;
}

export const getCompanyLandingUrl = companyId => {
    return `${window.location.origin.replace('://admin.', includes('meconsultant.ru', window.location.origin) ? '://app.' : '://')}/registration/${companyId}`;
}

export const getLinkUrl = linkId => {
    return `${window.location.origin.replace('://admin.', includes('meconsultant.ru', window.location.origin) ? '://app.' : '://')}/employment/${linkId}`;
}

