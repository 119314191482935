import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { omit } from 'ramda';
import { withFormWrapper, GENDER } from '@meconsultant/common';

import Input from './formComponents/Input';
import Switch from './formComponents/Switch';
import SearchSelect from './formComponents/SearchSelect';
import { getCompanies } from '../../actions/asyncActions';
import RadioButtons from './formComponents/RadioButtons';
import { ADMIN_CLIENT_ROLES_OPTIONS } from '../../constants/roles';
import { PhoneInput } from './formComponents/MaskedInput';

class AdminClientForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting, item } = this.props;

        return <Fragment>
            <Field
                name='company'
                component={SearchSelect}
                label='Компания'
                action={getCompanies}
                namePath='name' />
            <Field
                name='lastName'
                component={Input}
                label='Фамилия' />
            <Field
                name='firstName'
                component={Input}
                label='Имя' />
            <Field
                name='middleName'
                component={Input}
                label='Отчество' />
            <Field
                name='gender'
                component={RadioButtons}
                label='Пол'
                options={GENDER} />
            <Field
                name='email'
                component={Input}
                label='Email'
                type='email' />
            <Field
                name='phone'
                component={PhoneInput}
                label='Телефон' />
            <Field
                name='clientAdmin'
                component={RadioButtons}
                label='Роль'
                options={ADMIN_CLIENT_ROLES_OPTIONS} />
            { item &&
                <Field
                    name='enabled'
                    component={Switch}
                    label='Активен' />
            }
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    email: yup.string().email().required(),
    company: yup.string().required(),
    clientAdmin: yup.boolean().required()
});

export default withFormWrapper(AdminClientForm, {
    mapPropsToValues: ({ item }) => omit(['createdAt', 'updatedAt'], item),
    validationSchema
});
