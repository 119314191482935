import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAsyncActions, toSuccess } from 'react-async-client';
import { takeEvery } from 'redux-saga/effects';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { withState } from 'recompose';

import Table from './Table';
import Toolbar from './Toolbar';
import { getSorting, getFilters } from '../utils/urls';
import { LINES_PER_PAGE } from '../constants/lists';

class TableListComponent extends Component {
    static propTypes = {
        action: PropTypes.func,
        param:  PropTypes.oneOfType([null, PropTypes.object, PropTypes.func]),
        refreshActions: PropTypes.array,
        asyncActions: PropTypes.array
    };

    constructor(props) {
        super(props);

        const action = props.action
            .withDefaultPayload(({ location, initFilters, staticFilter = {}, payload, defaultSort = {}, parseFilter, limit, offset, filters }) => {
                const q = {
                    ...staticFilter,
                    ...(filters || {}),
                    ...(getFilters(location) || initFilters || {})
                };

                return {
                    ...(payload || {}),
                    limit,
                    offset,
                    ...(isEmpty(getSorting(location)) ? defaultSort : getSorting(location)),
                    q: parseFilter ? parseFilter(q) : q
                };
            })
            .withParams(props.param)
            .withOptions({
                dispatchOnMount: true,
                dispatchOnUpdate: true,
                resetOnUnmount: true,
            })
            .withSaga(function* (getProps) {
                const { refreshActions = [] } = getProps();
                const successActions = refreshActions.map(toSuccess);

                yield takeEvery(successActions, () => getProps().action.refresh());
            });

        this.component = withAsyncActions({
            action,
            ...props.asyncActions
        })(ListTableStateless);
    }

    render() {
        const Component = this.component;

        return (
            <Component
                {...this.props}
                setOffset={this.props.setOffset}
                setLimit={this.props.setLimit}
                setFilters={this.props.setFilters}
            />
        );
    }
}

const TableList = withState('intervalRefreshing', 'setIntervalRefreshing', false)(TableListComponent);

class ListTableStateless extends Component {
    static propTypes = {
        action: PropTypes.object,
        setOffset: PropTypes.func,
        state: PropTypes.object,
        columns: PropTypes.array,
        className: PropTypes.string,
        buttons: PropTypes.oneOfType([null, PropTypes.node]),
        filterForm: PropTypes.oneOfType([null, PropTypes.node]),
        rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    };

    render() {
        const {
            action,
            setOffset,
            state,
            columns,
            className,
            buttons,
            filterForm,
            rowKey,
            showHeader,
            filterSpan,
            buttonsSpan,
            setFilters,
            setLimit,
            limit,
            offset
        } = this.props;

        return (
            <div className={className}>
                { (buttons || filterForm) &&
                    <Toolbar buttons={buttons} filterForm={filterForm} filterSpan={filterSpan} buttonsSpan={buttonsSpan} setFilters={setFilters} />
                }
                <Table
                    action={action}
                    setOffset={setOffset}
                    state={state}
                    columns={columns}
                    rowKey={rowKey}
                    showHeader={showHeader}
                    setLimit={setLimit}
                    limit={limit}
                    offset={offset}
                    stateLess
                />
            </div>
        );
    }
}

class TableListState extends Component {
    state = {
        offset: 0,
        limit: LINES_PER_PAGE,
    }

    setOffset = (offset, limit = LINES_PER_PAGE) => {
        this.setState({ offset, limit });
    }

    setLimit = (limit = LINES_PER_PAGE) => {
        this.setState({ limit });
    }

    setFilters = (filters) => {
        this.setState({ filters, offset: 0 });
    }

    render() {
        return (
            <TableList
                {...this.props}
                {...this.state}
                setOffset={this.setOffset}
                setLimit={this.setLimit}
                setFilters={this.setFilters}
            />
        );
    }
}

export default withRouter(TableListState);
