import React, { Component } from 'react';
import { Button, Table } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import moment from 'moment';

import TableList from './TableList';
import { getEmployeeList } from '../actions/asyncActions';

export default class LinksEmployeeList extends Component {
    toEmployees = (item) => {
        this.props.history.push(`/links/list/${item.id}`);
    }

    expandedRowRender = (item) => {
        const columns = [
          {
            title: 'Уровень должности',
            dataIndex: 'position',
            key: 'position',
          },
          {
            title: 'Отрасль',
            dataIndex: 'industryName',
            key: 'industryName',
          },
          {
            title: 'Направление',
            dataIndex: 'functionName',
            key: 'functionName',
          },
          {
            title: 'Локация',
            dataIndex: 'location',
            key: 'location',
          },
          {
            title: 'Дата увольнения',
            dataIndex: 'programStartedAt',
            key: 'programStartedAt',
            width: '120px',
            render: date => date ? moment(date).format('MM.YYYY') : '',
          },
        ];

        return <Table columns={columns} dataSource={item.selectedEmployees || []} pagination={false} />
    }

    getColumns = () => {
        return [
            {
                title: '№',
                key: 'number',
                width: 60,
                dataIndex: ['number'],
            },
            {
                title: 'ФИО Клиента',
                key: 'name',
                dataIndex: ['name'],
            },
            {
                title: 'Клиент',
                key: 'company',
                dataIndex: ['company'],
            },
            {
                title: 'Должность',
                key: 'position',
                dataIndex: ['position'],
            },
            {
                title: 'Email',
                key: 'email',
                dataIndex: ['email'],
            },
            {
                title: 'Телефон',
                key: 'phone',
                dataIndex: ['phone'],
            },
            {
                key: 'actions',
                align: 'right',
                width: 180,
                render: (item, index) => <Button icon={<FileOutlined />} onClick={() => this.toEmployees(item, index)}>Перейти в заявку</Button>
            }
        ];
    }

    render() {
        return <TableList
            columns={this.getColumns()}
            action={getEmployeeList}
            expandable={{
              expandedRowRender: this.expandedRowRender,
            }}
            rowKey='id' />
    }
}

