import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Avatar, Breadcrumb, Dropdown, Input, Menu, message, Spin, Upload } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { getPromotionRequest, getPromotionRequestsStatuses, getPromotionRequestsThemes, getUser, postPromotionRequests } from '../actions/asyncActions';
import { STATUSES } from './Promotion';
import { DeleteOutlined, EditOutlined, LoadingOutlined, PaperClipOutlined, UserOutlined } from '@ant-design/icons';
import { FILE_URL, getFileView } from '../constants/urls';
import { getJWTToken, getToken } from '../utils/token';
import { includes, pathOr, append, remove } from 'ramda';
import { withState } from 'recompose';
import { takeEvery } from 'redux-saga/effects';
import { POST_PROMOTION_REQUESTS } from '../constants/actionTypes';
import { ADMIN } from '../constants/roles';
import withBreadcrumbContext from './hocs/withBreadcrumbContext';

const Header = styled.div`
    padding: 0 23px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ItemHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .ant-avatar.ant-avatar-icon {
        margin-right: 15px;
    }
`;

const Item = styled.div`
    margin: 0 23px;
    padding: 23px 0;
    border-top: 1px solid #EBEFF2;
`;

const FileWrapper = styled.a`
    color: #F54D2E;
    font-weight: bold;
    text-decoration-line: underline;
    cursor: pointer;
    display: inline-block;
`;

const ConsultantComment = styled.div`
    border-top: 1px dashed #A7B9CF;
    margin: 0 23px;
    padding: 23px 0;
`;

const CommentWrapper = styled.div`
    margin: 0 23px;
    padding: 8px 0;
    border-top: 1px dashed #A7B9CF;
    display: flex;
`;

const SendArea = styled(Input.TextArea)`
    width: 100%;
    border: none;
    resize: none;
    border-right: 1px solid #A7B9CF;
    padding-left: 0;
    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
        border-right: 1px solid #A7B9CF;
    }
`;

const SendCommentBtn = styled.button`
    background: #fff;
    border: none;
    cursor: pointer;
    margin-left: 15px;
`;

const DropdownWrapper = styled.div`
    .ant-btn {
        background: ${({ background }) => background};
        border-color: ${({ background }) => background};
    }
    .ant-btn-group .ant-btn-primary:last-child:not(:first-child), .ant-btn-group .ant-btn-primary + .ant-btn-primary {
        border-left-color: #fff;
    }
`;

class ParticipantPromotion extends Component {
    state = {
        fileLoading: false
    };

    componentDidUpdate() {
        if (!this.props.breadcrumb && this.props.getPromotionRequestsThemes.meta.lastSucceedAt && this.props.getPromotionRequest.meta.lastSucceedAt) {
            this.props.setBreadcrumb(
                <Breadcrumb.Item>
                    { this.props.getPromotionRequestsThemes.data[this.props.getPromotionRequest.data.theme] }
                </Breadcrumb.Item>
            );
        }
    }

    onChangeComment = e => {
        this.props.setComment(e.target.value || '');
    }

    sendComment = () => {
        this.props.postPromotionRequestComment.dispatch({
            id: this.props.match.params.promotion,
            comment: this.props.comment,
            consultantFiles: this.props.consultantFiles.map(file => file.response.id)
        });
    }

    changeStatus = status => {
        this.props.postPromotionRequestStatus.dispatch({
            id: this.props.match.params.promotion,
            status
        });
    }

    onChangeFiles = info => {
        const { status } = info.file;

        switch(status) {
            case 'uploading':
                this.setState({ fileLoading: true });
                break;
            case 'done':
                this.setState({ fileLoading: false });
                this.props.setConsultantFiles(append(info.file, this.props.consultantFiles || []));
                break;
            case 'error':
                this.setState({ fileLoading: false });
                message.error('Не удалось загрузить файл');
                break;
            default:
                return;
        }
    }

    deleteFile = index => {
        this.props.setConsultantFiles(remove(index, 1, this.props.consultantFiles));
    }

    render() {
        const { getPromotionRequest: { data, meta }, getPromotionRequestsThemes, getPromotionRequestsStatuses, user } = this.props;
        const participant = pathOr({}, ['_embedded', 'participant'], data);
        const consultant = pathOr({}, ['_embedded', 'consultant'], data);
        const isConsultant = (includes(ADMIN, user.roles) && !user.superAdmin) || user.id === participant.consultant;
        const menu = <Menu>
            <Menu.ItemGroup title="Статус">
                { getPromotionRequestsStatuses.data.map(status =>
                    <Menu.Item key={status} onClick={() => this.changeStatus(status)}>{ STATUSES[status].text } </Menu.Item>
                )}
            </Menu.ItemGroup>
        </Menu>;

        return meta.pending && !meta.lastSucceedAt ?
            <div style={{ textAlign: 'center', padding: 15 }}><Spin /></div> :
            ( meta.lastSucceedAt && <div>
                <Header>
                    <div><strong>{ getPromotionRequestsThemes.data[data.theme] }</strong></div>
                    <div style={{ textAlign: 'center' }}>
                        <div>{ moment(data.createdAt).format('DD.MM.YYYY') }</div>
                        <div>{ moment(data.createdAt).format('HH:mm') }</div>
                    </div>
                    <DropdownWrapper background={STATUSES[data.status].background}>
                        { isConsultant && <Dropdown.Button type="primary" overlay={menu} icon={this.props.postPromotionRequestStatus.meta.pending ? <LoadingOutlined /> : <EditOutlined />}>
                            { STATUSES[data.status].text }
                        </Dropdown.Button>}
                    </DropdownWrapper>
                </Header>
                <Item>
                    <ItemHeader>
                        <Avatar size={44} icon={<UserOutlined />} src={participant.photo ? getFileView(participant.photo) : null} />
                        <div>
                            <div><strong style={{ color: '#2B3D4F' }}>{ participant.lastName } { participant.firstName }</strong></div>
                            <div style={{ color: '#A7B9CF' }}>{ moment(data.createdAt).format('DD.MM.YYYY') } в { moment(data.createdAt).format('HH:mm') }</div>
                        </div>
                    </ItemHeader>
                    <div>{ data.text }</div>
                    { pathOr([], ['_embedded', 'files'], data).map(file =>
                        <div style={{ marginTop: 10 }} key={file.id}>
                            <FileWrapper
                                download
                                href={`${FILE_URL}/${file.id}/download?token=${getToken()}`}
                                target='_blank'
                                rel='noopener noreferrer'>
                                <PaperClipOutlined /> { file.name }
                            </FileWrapper>
                        </div>
                    )}
                </Item>
                { data.comment ?
                    <ConsultantComment>
                        <ItemHeader>
                            <Avatar size={44} icon={<UserOutlined />} src={consultant.photo ? getFileView(consultant.photo) : null} />
                            <div>
                                <div><strong style={{ color: '#2B3D4F' }}>{ consultant.lastName } { consultant.firstName }</strong></div>
                                <div style={{ color: '#A7B9CF' }}>{ moment(data.updatedAt).format('DD.MM.YYYY') } в { moment(data.updatedAt).format('HH:mm') }</div>
                            </div>
                        </ItemHeader>
                        <div>{ data.comment }</div>
                        { pathOr([], ['_embedded', 'consultantFiles'], data).map(file =>
                            <div style={{ marginTop: 10 }} key={file.id}>
                                <FileWrapper
                                    download
                                    href={`${FILE_URL}/${file.id}/download?token=${getToken()}`}
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    <PaperClipOutlined /> { file.name }
                                </FileWrapper>
                            </div>
                        )}
                    </ConsultantComment> :
                    isConsultant && <Fragment>
                        <CommentWrapper>
                            <SendArea value={this.props.comment} placeholder='Введите сообщение...' onChange={this.onChangeComment} autoSize />
                            <SendCommentBtn>
                                <Upload
                                    onChange={this.onChangeFiles}
                                    showUploadList={false}
                                    action={FILE_URL}
                                    accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*'
                                    headers={{ Authorization: getJWTToken() }}
                                    disabled={this.state.fileLoading}>
                                    { this.state.fileLoading ?
                                        <LoadingOutlined /> :
                                        <svg width="16" height="33" viewBox="0 0 16 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.8182 8.15823V24.8855C13.8182 28.1 11.2145 30.7037 8 30.7037C4.78545 30.7037 2.18182 28.1 2.18182 24.8855V6.70368C2.18182 4.69641 3.81091 3.06732 5.81818 3.06732C7.82545 3.06732 9.45455 4.69641 9.45455 6.70368V21.9764C9.45455 22.7764 8.8 23.431 8 23.431C7.2 23.431 6.54545 22.7764 6.54545 21.9764V8.15823H4.36364V21.9764C4.36364 23.9837 5.99273 25.6128 8 25.6128C10.0073 25.6128 11.6364 23.9837 11.6364 21.9764V6.70368C11.6364 3.48913 9.03273 0.885498 5.81818 0.885498C2.60364 0.885498 0 3.48913 0 6.70368V24.8855C0 29.3073 3.57818 32.8855 8 32.8855C12.4218 32.8855 16 29.3073 16 24.8855V8.15823H13.8182Z" fill="#A7B9CF"/>
                                        </svg>
                                    }
                                </Upload>
                            </SendCommentBtn>
                            <SendCommentBtn disabled={!this.props.comment} onClick={this.sendComment}>
                                { this.props.postPromotionRequestComment.meta.pending ?
                                    <LoadingOutlined /> :
                                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M29.2123 16.4762L3.07163 3.36997C2.96538 3.31685 2.84351 3.30435 2.72788 3.33247C2.46226 3.3981 2.29663 3.66685 2.36226 3.9356L5.05601 14.9419C5.09663 15.1075 5.21851 15.2419 5.38101 15.295L9.99664 16.8794L5.38414 18.4637C5.22163 18.52 5.09976 18.6512 5.06226 18.8169L2.36226 29.8387C2.33413 29.9544 2.34663 30.0762 2.39976 30.1794C2.52163 30.4262 2.82163 30.5262 3.07163 30.4044L29.2123 17.3731C29.3091 17.3262 29.3873 17.245 29.4373 17.1512C29.5591 16.9012 29.4591 16.6012 29.2123 16.4762ZM5.44351 26.7075L7.01539 20.2825L16.2404 17.1169C16.3123 17.0919 16.3716 17.0356 16.3966 16.9606C16.4404 16.8294 16.3716 16.6887 16.2404 16.6419L7.01539 13.4794L5.44976 7.07935L25.0748 16.92L5.44351 26.7075Z" fill="#A7B9CF"/>
                                    </svg>
                                }
                            </SendCommentBtn>
                        </CommentWrapper>
                        { !!this.props.consultantFiles.length &&
                            <div style={{ padding: 23, paddingTop: 0 }}>
                                { this.props.consultantFiles.map((file, index) =>
                                    <div key={`file-${index}`}><FileWrapper><PaperClipOutlined /> { file.name }</FileWrapper> <DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => this.deletemultiFile(index)} /></div>
                                )}
                            </div>
                        }
                    </Fragment>
                }
            </div>)
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default withBreadcrumbContext(withState('consultantFiles', 'setConsultantFiles', [])(withState('comment', 'setComment', '')(asyncConnect({
    getPromotionRequestsThemes: getPromotionRequestsThemes
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getPromotionRequestsStatuses: getPromotionRequestsStatuses
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getPromotionRequest: getPromotionRequest
        .withPayload(({ match }) => ({ id: match.params.promotion, participant: match.params.participant }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(POST_PROMOTION_REQUESTS)], () => {
                getProps().getPromotionRequest.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    postPromotionRequestComment: postPromotionRequests
        .withParams(() => ({ type: 'comment' }))
        .withSuccessHandler(() => message.success('Комментарий успешно отправлен'))
        .withErrorHandler(() => message.error('Не удалось отправить комментарий'))
        .withOptions({ resetOnUnmount: true }),
    postPromotionRequestStatus: postPromotionRequests
        .withParams(() => ({ type: 'status' }))
        .withSuccessHandler(() => message.success('Статус успешно изменен'))
        .withErrorHandler(() => message.error('Не удалось изменить статус'))
        .withOptions({ resetOnUnmount: true })
}, stateToProps)(ParticipantPromotion))));
