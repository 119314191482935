import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { withFieldWrapper } from '@meconsultant/common';

class SelectComponent extends Component {
    static propTypes = {
        input: PropTypes.object,
        required: PropTypes.bool,
        options: PropTypes.array,
        onChange: PropTypes.func,
        mode: PropTypes.string,
        namePath: PropTypes.string
    };

    static defaultProps = {
        options: [],
        mode: 'default',
        namePath: 'name'
    };

    render() {
        const { input: { value }, required, options, onChange, mode, namePath, placeholder, disableClear, disabled, loading } = this.props;

        return <Select
            mode={mode}
            value={value && !!(options || []).length ? value : undefined}
            allowClear={!required && !disableClear}
            onChange={onChange}
            notFoundContent='Ничего не найдено'
            placeholder={placeholder}
            disabled={disabled}
            loading={loading}
            getPopupContainer={trigger => trigger.parentNode}>
            { (options || []).map(option =>
                <Select.Option key={option.id} value={option.id}>
                    { option[namePath] }
                </Select.Option>
            )}
        </Select>;
    }
}

export default withFieldWrapper(SelectComponent);
