import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postNews, putNews } from '../../actions/asyncActions';
import NewsForm from '../forms/NewsForm';

class NewsModal extends Component {
    render() {
        const { modal, putNews, postNews, params } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать новость' : 'Добавить новость'}
            footer={null}>
            <NewsForm
                formAction={params ? putNews : postNews}
                data={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postNews: postNews
        .withSuccessHandler(({ close }) => {
            message.success('Новость успешно добавлена');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putNews: putNews
        .withSuccessHandler(({ close }) => {
            message.success('Новость успешно изменена');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(NewsModal);
