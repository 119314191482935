import React from 'react';

import GuestRoutes from '../../GuestRoutes';

const GuestLayout = () => (
    <div className='guest-content'>
        <div className='guest-layout'>
            <div className='guest-block'>
                <svg className='guest-logo' width="152" height="50" viewBox="0 0 152 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1047_7001)">
                        <path d="M49.2763 30.6335V49.412H0.586426V0.588074H49.2763V17.3122" stroke="#F54D2E" strokeWidth="3" strokeMiterlimit="10"/>
                        <path d="M9.41431 15.7715H12.3191L17.9176 23.4358L23.2581 15.7911H25.9753V32.139H23.1174V20.2995L17.9411 27.5796H17.5071L12.2957 20.3779V32.139H9.41431V15.7715Z" fill="#2B3D4E"/>
                        <path d="M38.7829 31.0883C37.6298 31.9744 36.2083 32.4367 34.756 32.3977C31.3586 32.3977 29.0872 30.1984 29.0872 26.8779C29.0872 23.8278 31.1905 21.358 34.6622 21.358C37.3989 21.358 39.6508 23.2986 39.6508 26.2467C39.6696 26.7357 39.6143 27.2248 39.4866 27.6972H31.8747C32.1093 29.2654 33.3721 30.1513 35.0336 30.1513C36.103 30.1675 37.1482 29.8315 38.0088 29.1948L38.7829 31.0883ZM31.7809 25.8978H36.9571C36.9806 24.6119 36.0892 23.6044 34.5449 23.6044C32.9264 23.6044 32.0623 24.5178 31.7809 25.8978Z" fill="#2B3D4E"/>
                        <path d="M55.082 30.5512C54.0537 31.6489 51.9543 32.3977 49.8353 32.3977C44.8232 32.3977 41.3828 29.0537 41.3828 23.9572C41.3828 19.2528 44.3541 15.5128 49.7649 15.5128C51.8957 15.5128 53.6745 16.191 54.7536 17.175L53.4751 19.3038C52.3887 18.5522 51.0921 18.1664 49.7728 18.2022C46.7272 18.2022 44.4323 20.4015 44.4323 23.9572C44.4323 27.3679 46.7507 29.7084 50.1012 29.7084C51.5124 29.7144 52.8864 29.2542 54.0107 28.399L55.082 30.5512Z" fill="#2B3D4E"/>
                        <path d="M62.5532 21.358C66.0406 21.358 68.2886 23.7102 68.2886 26.8779C68.2886 30.0455 66.0406 32.3977 62.5532 32.3977C59.0659 32.3977 56.814 30.0455 56.814 26.8779C56.814 23.7102 59.062 21.358 62.5532 21.358ZM62.5532 29.8926C64.2148 29.8926 65.4346 28.7165 65.4346 26.8779C65.4556 26.4881 65.3963 26.0983 65.2603 25.7326C65.1243 25.3669 64.9145 25.0332 64.6441 24.7525C64.3737 24.4718 64.0484 24.25 63.6887 24.101C63.3289 23.9521 62.9424 23.8791 62.5532 23.8867C60.9581 23.8867 59.6953 25.0628 59.6953 26.8779C59.6953 28.6303 60.9034 29.8926 62.5532 29.8926Z" fill="#2B3D4E"/>
                        <path d="M70.6304 21.6168H73.3241V22.6518C73.7214 22.2555 74.1929 21.942 74.7116 21.7294C75.2303 21.5168 75.7858 21.4092 76.3462 21.4129C78.9226 21.4129 80.678 22.6518 80.678 25.9488V32.139H77.9843V26.4349C77.9843 24.7491 77.1203 23.9572 75.619 23.9572C75.1885 23.9593 74.763 24.0494 74.3684 24.222C73.9738 24.3945 73.6185 24.6459 73.3241 24.9608V32.139H70.6304V21.6168Z" fill="#2B3D4E"/>
                        <path d="M83.0666 24.5649C83.0666 22.5067 84.7517 21.358 86.9293 21.358C88.9662 21.358 90.2798 22.0833 90.9366 22.6909L89.928 24.6119C89.1034 23.9855 88.1041 23.6332 87.07 23.6044C86.249 23.6044 85.7603 24.0474 85.7603 24.5178C85.7603 26.2702 91.4488 25.3019 91.4488 29.0301C91.4488 31.1118 89.7637 32.3977 87.1873 32.3977C85.2325 32.3977 83.2778 31.3431 82.7852 30.5943L84.0949 28.842C84.7282 29.5202 86.3194 30.1514 87.375 30.1514C88.1569 30.1514 88.7551 29.826 88.7551 29.1713C88.7551 27.462 83.0666 28.399 83.0666 24.5649Z" fill="#2B3D4E"/>
                        <path d="M103.346 32.1389H100.652V30.8531C100.243 31.3494 99.7278 31.7463 99.1441 32.0139C98.5603 32.2814 97.9236 32.4127 97.282 32.3977C95.2216 32.3977 93.5796 31.0648 93.5796 28.0461V21.6168H96.2733V27.4973C96.2733 28.9007 96.8128 29.9044 98.2867 29.9044C98.7529 29.886 99.2086 29.7605 99.6187 29.5375C100.029 29.3146 100.382 29.0001 100.652 28.6185V21.6168H103.346V32.1389Z" fill="#2B3D4E"/>
                        <path d="M106.344 15.7715H109.038V28.1402C109.038 29.3163 109.316 29.8495 109.949 29.8495C110.352 29.8301 110.739 29.6817 111.052 29.4261L111.614 31.4843C110.815 32.0937 109.831 32.408 108.827 32.3742C107.208 32.3742 106.344 31.3902 106.344 29.2379V15.7715Z" fill="#2B3D4E"/>
                        <path d="M113.112 18.6922H115.806V21.6168H119.199V24.1415H115.806V27.9756C115.806 29.1242 116.196 29.873 117.068 29.873C117.66 29.8595 118.23 29.6437 118.683 29.2614L119.504 31.3431C118.604 32.0253 117.504 32.388 116.376 32.3742C113.988 32.3742 113.1 30.8296 113.1 28.1402L113.112 18.6922Z" fill="#2B3D4E"/>
                        <path d="M121.916 22.3656C123.054 21.7243 124.333 21.3779 125.638 21.358C128.766 21.358 129.856 22.9497 129.856 25.7331V32.139H127.511V30.9237C126.948 31.7077 125.822 32.2801 124.383 32.2801C122.463 32.2801 121.079 31.104 121.079 29.0537C121.079 26.7367 122.788 25.639 124.923 25.639C125.767 25.6293 126.597 25.8648 127.311 26.3173C127.382 24.5649 126.92 23.6044 125.357 23.6044C124.391 23.6224 123.443 23.871 122.592 24.3296L121.916 22.3656ZM127.327 29.0537V27.9756C126.782 27.6064 126.131 27.426 125.474 27.462C124.586 27.462 123.789 27.8854 123.789 28.7714C123.789 29.5555 124.446 30.0102 125.29 30.0102C125.683 30.0383 126.076 29.9652 126.433 29.7978C126.789 29.6303 127.097 29.3742 127.327 29.0537Z" fill="#2B3D4E"/>
                        <path d="M132.617 21.6168H135.31V22.6518C135.708 22.2555 136.179 21.942 136.698 21.7294C137.217 21.5168 137.772 21.4092 138.332 21.4129C140.909 21.4129 142.664 22.6518 142.664 25.9488V32.139H139.971V26.4349C139.971 24.7491 139.107 23.9572 137.605 23.9572C137.175 23.9593 136.749 24.0494 136.355 24.222C135.96 24.3945 135.605 24.6459 135.31 24.9608V32.139H132.617V21.6168Z" fill="#2B3D4E"/>
                        <path d="M145.17 18.6922H147.864V21.6168H151.258V24.1415H147.864V27.9756C147.864 29.1242 148.255 29.873 149.127 29.873C149.72 29.8585 150.29 29.6429 150.745 29.2614L151.562 31.3431C150.664 32.0274 149.563 32.3904 148.435 32.3742C146.046 32.3742 145.155 30.8296 145.155 28.1402L145.17 18.6922Z" fill="#2B3D4E"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1047_7001">
                            <rect width="152" height="50" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                <GuestRoutes />
            </div>
        </div>
    </div>
);

export default GuestLayout;
