import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { Button, Avatar, message, Popconfirm } from 'antd';
import { UserOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import TableList from './TableList';
import { getMentions, deleteMention } from '../actions/asyncActions';
import { openMentionModal } from '../actions/modalActions';
import { POST_MENTION, PUT_MENTION, DELETE_MENTION } from '../constants/actionTypes';
import { getFileView } from '../constants/urls';
import MentionFilter from './filters/MentionFilter';

class Mentions extends Component {
    getColumns = () => {
        return [
            {
                key: 'avatar',
                width: 32,
                render: item => <Avatar size={32} src={item.personAvatar ? getFileView(item.personAvatar) : null} icon={<UserOutlined />} />
            },
            {
                key: 'personName',
                dataIndex: 'personName',
                title: 'Имя пользователя',
                render: (name, item) => <span className='span-link' onClick={() => this.props.openMentionModal(item)}>{ name }</span>
            },
            {
                key: 'text',
                dataIndex: 'text',
                title: 'Отзыв'
            },
            {
                key: 'actions',
                render: item =>
                    <Popconfirm
                        title='Вы уверены, что хотите удалить отзыв?'
                        okText='Да'
                        cancelText='Нет'
                        onConfirm={() => this.delete(item.id)}>
                        <Button
                            icon={<DeleteOutlined />}
                            danger />
                    </Popconfirm>
            }
        ];
    }

    delete = id => {
        this.props.deleteMention.dispatch(id);
    }

    renderButtons = () => {
        return <Button type='primary' icon={<PlusOutlined />} onClick={() => this.props.openMentionModal()}>
            Добавить
        </Button>;
    }

    render() {
        return <TableList
            action={getMentions}
            columns={this.getColumns()}
            buttons={this.renderButtons()}
            refreshActions={[POST_MENTION, PUT_MENTION, DELETE_MENTION]}
            filterForm={<MentionFilter />} />
    }
}

export default asyncConnect({
    deleteMention: deleteMention
        .withSuccessHandler(() => message.success('Отзыв успешно добавлен'))
        .withOptions({ resetOnUnmount: true })
}, null, { openMentionModal })(Mentions);
