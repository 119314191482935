import React, { Component, Fragment } from 'react';
import { pathOr, assocPath, toPairs, forEach, remove } from 'ramda';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { withState } from 'recompose';
import { toSuccess, withAsyncActions } from 'react-async-client';
import { takeEvery } from 'redux-saga/effects';
import { Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import MaterialTheme from './MaterialTheme';
import { reorder, move } from '../../../utils/lists';
import { putStage, getStage } from '../../../actions/asyncActions';
import { AddMaterial, AddTheme } from './AddButtons';
import EditMaterial, { EditButton } from './EditMaterial';
import { PUT_STAGE, PATCH_STAGE } from '../../../constants/actionTypes';

const DeleteThemeButton = styled.div`
    width: 30px;
    height: 30px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    position: absolute;
    background: #fff;
    top: -15px;
    right: -15px;
    align-items: center;
    justify-content: center;
    display: ${({ visible }) => visible ? 'flex' : 'none'};
    cursor: pointer;
`;

const Theme = styled.div`
    background: #fafafa;
    padding: 10px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    position: relative;
    &:hover {
        ${DeleteThemeButton} {
            display: flex;
        }
    }
`;

const Materials = styled.div`
    margin-top: 10px;
`;

const Material = styled.div`
    background: ${({ selected }) => selected ? '#D6ECF9' : '#fff'};
    padding: 10px;
    border: 1px dashed #ddd;
    position: relative;
    &:hover {
        ${EditButton} {
            display: flex;
        }
    }
`;

class MaterialSelector extends Component {
    materials = {};

    state = {
        deletePopconfirm: null
    };

    onDragEnd = ({ source, destination, type }) => {
        if (!destination) {
            return;
        }

        if (type === 'theme') {
            if (source.index !== destination.index) {
                const themes = reorder(
                    this.props.themes,
                    source.index,
                    destination.index
                );

                this.props.setThemes(themes);
                this.props.putStage.dispatch({
                    ...this.props.getStage.data,
                    themes
                });
            }
        } else {
            if (source.droppableId === destination.droppableId) {
                if (source.index !== destination.index) {
                    const { materials } = this.props.themes[source.droppableId];
                    const reordered = reorder(
                        materials,
                        source.index,
                        destination.index
                    );
                    const themes = assocPath([Number(source.droppableId), 'materials'], reordered, this.props.themes);

                    this.props.setThemes(themes);
                    this.props.putStage.dispatch({
                        ...this.props.getStage.data,
                        themes
                    });
                }
            } else {
                const result = move(
                    this.props.themes[source.droppableId].materials,
                    this.props.themes[destination.droppableId].materials,
                    source,
                    destination
                );

                let themes = this.props.themes;

                forEach(([ index, materials ]) => {
                    themes = assocPath([Number(index), 'materials'], materials, themes);
                }, toPairs(result));

                this.props.setThemes(themes);
                this.props.putStage.dispatch({
                    ...this.props.getStage.data,
                    themes
                });
            }
        }
    }

    renderMaterial = (material, i, index) => {
        const { setSelected, selected, stage, hasChanges } = this.props;

        const item = <Material
            ref={node => this.materials[material.id] = node}
            onClick={() => !hasChanges && setSelected(material.id)}
            selected={selected === material.id}>
            { material.title }
            <EditMaterial
                getElementRef={() => this.materials[material.id]}
                material={material}
                materialIndex={i}
                themeIndex={index}
                stage={stage}
                setSelected={setSelected} />
        </Material>;

        return hasChanges && material.id !== selected ?
            <Popconfirm
                title='Есть несохраненные изменения. Хотите продолжить?'
                okText='Да'
                cancelText='Нет'
                onConfirm={() => setSelected(material.id)}
                placement='right'>
                { item }
            </Popconfirm> : item;
    }

    renderMaterials = (materials, index) => {
        const { putStage, getStage: { data }} = this.props;

        return <Materials>
            <Droppable droppableId={index.toString()} type='material'>
                { provided =>
                    <div ref={provided.innerRef}>
                        { materials.map((material, i) =>
                            <Draggable key={material.id} draggableId={material.id} index={i}>
                                { provided =>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{ ...provided.draggableProps.style, paddingBottom: 10 }}>
                                        { this.renderMaterial(material, i, index) }
                                    </div>
                                }
                            </Draggable>
                        )}
                        { provided.placeholder }
                    </div>
                }
            </Droppable>
            <AddMaterial index={index} stage={data} putStage={putStage} />
        </Materials>;
    }

    deleteTheme = index => {
        const themes = remove(index, 1, this.props.themes);

        this.props.setThemes(themes);
        this.props.putStage.dispatch({
            ...this.props.getStage.data,
            themes
        });
    }

    onVisibleChange = (visible, index) => {
        this.setState({ deletePopconfirm: visible ? index : null });
    }

    render() {
        const { getStage: { data }, themes, putStage } = this.props;

        return <Fragment>
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId='theme' type='theme'>
                    { provided =>
                        <div ref={provided.innerRef}>
                            { themes.map((theme, index) =>
                                <Draggable key={`theme-${index}`} draggableId={`theme-${index}`} index={index}>
                                    { provided =>
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{ ...provided.draggableProps.style, paddingBottom: 10 }}>
                                            <Theme>
                                                <Popconfirm
                                                    title='Вы уверены, что хотите удалить тему?'
                                                    okText='Да'
                                                    cancelText='Нет'
                                                    onConfirm={() => this.deleteTheme(index)}
                                                    onVisibleChange={(visible) => this.onVisibleChange(visible, index)}>
                                                    <DeleteThemeButton visible={index === this.state.deletePopconfirm}>
                                                        <DeleteOutlined />
                                                    </DeleteThemeButton>
                                                </Popconfirm>
                                                <MaterialTheme
                                                    theme={theme}
                                                    stage={data}
                                                    index={index}
                                                    putStage={putStage}
                                                    placeholder='Введите заголовок темы' />
                                                { this.renderMaterials(theme.materials, index) }
                                            </Theme>
                                        </div>
                                    }
                                </Draggable>
                            )}
                            { provided.placeholder }
                        </div>
                    }
                </Droppable>
            </DragDropContext>
            <AddTheme stage={data} putStage={putStage} />
        </Fragment>;
    }
}

export default withState('themes', 'setThemes', [])(
    withAsyncActions({
        getStage: getStage
            .withParams(() => ({ type: 'modal' }))
            .withPayload(({ stage }) => stage)
            .withSaga(function* (getProps) {
                yield takeEvery([toSuccess(PUT_STAGE), toSuccess(PATCH_STAGE)], () => {
                    getProps().getStage.refresh();
                });
            })
            .withSuccessHandler(({ getStage: { data }, setThemes }) => setThemes(pathOr([], ['themes'], data)))
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
        putStage: putStage
            .withOptions({ resetOnUnmount: true })
    })(MaterialSelector)
);
