import React, { Component } from 'react';
import { Tabs } from 'antd';

import MainSettings from './MainSettings';
import MotivationSettings from './MotivationSettings';
import CriteriaSettings from './CriteriaSettings';
import Route from '../Route';

const TABS = [
    { key: '', name: 'Общие настройки', component: MainSettings },
    { key: 'motivation', name: 'Анализ мотивации', component: MotivationSettings },
    { key: 'criteria', name: 'Выбор критериев и категорий для поиска компании-работодателя', component: CriteriaSettings }
]

export default class Settings extends Component {
    onChangeTab = key => {
        const { history } = this.props;

        history.replace(`/settings${key === 'main' ? '' : `/${key}`}`);
    }

    render() {
        const { match: { params: { type }}} = this.props;

        return <Tabs onChange={this.onChangeTab} animated={false} activeKey={type || 'main'}>
            { TABS.map(tab =>
                <Tabs.TabPane key={tab.key || 'main'} tab={tab.name}>
                    <Route path={`/settings/${tab.key}`} exact component={tab.component} />
                </Tabs.TabPane>
            )}
        </Tabs>;
    }
}
