import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser } from '../../actions/asyncActions';
import DynamicUsage from './dynamic/DynamicUsage';
import { Content } from './ModulesStatistic';

class DynamicUsageStatistic extends Component {
    render() {
        return <Content>
            <DynamicUsage id={this.props.company} />
        </Content>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(DynamicUsageStatistic);
