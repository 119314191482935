import React, { Component } from 'react'
import { withFieldWrapper } from '@meconsultant/common';
import { Cascader as AntdCascader } from 'antd';
import { any, append, drop, filter, forEach, last, path, propEq } from 'ramda';
import { FormSpy } from 'react-final-form';

class CascaderComponent extends Component {
    static defaultProps = {
        options: [],
        additionalNames: [],
        changeOnSelect: true
    };

    getNotChangeOnSelectValue = () => {
        const { input: { value }, options } = this.props;

        if (!value) {
            return null;
        }

        const getParents = (id, items, values) => {
            let vals = values || [];

            forEach(i => {
                if (any(propEq('id', id), i.children || [])) {
                    vals = append(i.id, vals);
                } else {
                    vals = getParents(id, i.children || [], vals);
                }
            }, items);

            return vals;
        }

        return [...getParents(value, options), value];
    }

    getValue = () => {
        const { input: { value }, additionalNames, values, changeOnSelect } = this.props;

        return changeOnSelect ? filter(i => !!i, [value, ...additionalNames.map(name => values[name])]) : this.getNotChangeOnSelectValue();
    }

    onChange = values => {
        const { additionalNames, onChange, form, changeOnSelect } = this.props;

        if (changeOnSelect) {
            onChange(path([0], values));

            form.batch(() => {
                const additionalValues = drop(1, values);

                additionalNames.forEach((name, index) =>
                    form.change(name, additionalValues[index])
                )
            });
        } else {
            onChange(last(values));
        }
    }

    render() {
        const { options, placeholder, changeOnSelect, loading } = this.props;

        return <AntdCascader
            value={!loading ? this.getValue() : null}
            options={options}
            disabled={loading}
            style={{ width: '100%' }}
            onChange={this.onChange}
            placeholder={loading ? 'Загрузка...' : placeholder}
            notFoundContent='Ничего не найдено'
            changeOnSelect={changeOnSelect} />;
;
    }
}

const Cascader = props =>
    <FormSpy subscription={{ values: true }}>
        { ({ form, values }) => <CascaderComponent {...props} form={form} values={values} /> }
    </FormSpy>;

export default withFieldWrapper(Cascader);

