import React, { Component, Fragment } from 'react';
import { Button } from 'antd';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { withFormWrapper, Input, Textarea } from '@meconsultant/common';

class WebinarForm extends Component {
    render() {
        const { isSubmitting } = this.props;

        return <Fragment>
            <Field
                name='title'
                component={Input}
                label='Название' />
            <Field
                name='description'
                component={Textarea}
                label='Описание' />
            <Button
                disabled={isSubmitting}
                type='primary'
                htmlType='submit'>
                Сохранить
            </Button>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    title: yup.string().required()
});

export default withFormWrapper(WebinarForm, {
    validationSchema,
    mapPropsToValues: ({ item }) => item
});
