import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { Col, Row } from 'antd';

import withFilterForm from '../hocs/withFilterForm';
import {getCompanies, getUser} from '../../actions/asyncActions';
import SearchSelect from '../forms/formComponents/SearchSelect';
import { INVITES_ACTIVITY } from '../../constants/dictionaries';
import { connect } from 'react-redux';
import { ADMIN, SUPER_ADMIN } from '../../constants/roles';
import { includes } from 'ramda';

class InviteFilter extends Component {
    render() {
        const { user, hideCompany } = this.props;

        return (
            <Row gutter={16}>
                { (includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles)) && !hideCompany &&
                    <Col span={12}>
                        <Field
                            name='company'
                            component={SearchSelect}
                            action={getCompanies}
                            placeholder='Компания'
                            namePath='name'
                        />
                    </Col>
                }
                <Col span={12}>
                    <Field
                        name='active'
                        component={SearchSelect}
                        options={INVITES_ACTIVITY} />
                </Col>
            </Row>
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(withFilterForm(InviteFilter));
