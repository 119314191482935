import axios from 'axios';

import * as urls from '../../constants/urls';

export const postRefreshTokenHandler = ({ payload }) => axios.post(urls.REFRESH_TOKEN.stringify(), {
    refresh_token: payload
});

export const postLoginHandler = ({ payload }) => axios.post(urls.LOGIN.stringify(), payload);
export const postRecoveryHandler = ({ payload }) => axios.post(urls.RECOVERY.stringify(), payload);
export const postResetHandler = ({ payload: { id, password }}) => axios.post(urls.RECOVERY.stringify({ id }), { password });

export const getUserHandler = () => axios.get(urls.USER.stringify(), {
    params: {
        relations: ['company', 'company.modules.themes']
    }
});

export const getCompaniesHandler = ({ payload }) => axios.get(urls.COMPANIES.stringify(), {
    params: {
        relations: ['consultants', 'modules.themes'],
        ...payload
    }
});
export const postCompanyHandler = ({ payload }) => axios.post(urls.COMPANIES.stringify(), payload);
export const putCompanyHandler = ({ payload }) => axios.put(urls.COMPANIES.stringify({ id: payload.id }), payload);
export const deleteCompanyHandler = ({ payload }) => axios.delete(urls.COMPANIES.stringify({ id: payload }));
export const getCompanyHandler = ({ payload }) => axios.get(urls.COMPANIES.stringify({ id: payload.id }), {
    params: {
        relations: ['consultants', 'modules.themes'],
        ...payload
    }
});

export const getParticipantsHandler = ({ payload }) => axios.get(urls.PARTICIPANTS.stringify(), {
    params: {
        relations: ['company', 'companyRole', 'company.modules.themes', 'consultant'],
        ...payload
    }
});
export const getParticipantHandler = ({ payload }) => axios.get(urls.PARTICIPANTS.stringify({ id: payload }), {
    params: {
        relations: ['company', 'photo', 'documents', 'documents.createdBy', 'companyRole', 'company.modules.themes', 'modules.themes', 'consultant', 'resume']
    }
});
export const getParticipantLimitHandler = ({ payload }) => payload ? axios.get(urls.PARTICIPANT_LIMIT.stringify(payload)) : null;
export const postParticipantHandler = ({ payload }) => axios.post(urls.PARTICIPANTS.stringify(), payload);
export const putParticipantHandler = ({ payload }) => axios.put(urls.PARTICIPANTS.stringify({ id: payload.id }), payload);
export const deleteParticipantHandler = ({ payload }) => axios.delete(urls.PARTICIPANTS.stringify({ id: payload }));
export const getParticipantDocumentsHandler = ({ payload }) => axios.get(urls.PARTICIPANTS_DOCUMENTS.stringify({ id: payload.q.id }), {
    params: {
        ...payload,
        relations: []
    }
});
export const postParticipantDocumentsHandler = ({ payload }) => {
    const formData = new FormData();

    formData.append('file', payload.document);

    return axios.post(urls.PARTICIPANTS_DOCUMENTS.stringify({ id: payload.id }), formData, {
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    });
};
export const deleteParticipantDocumentsHandler = ({ payload }) => axios.delete(urls.PARTICIPANTS_DOCUMENTS_FILE.stringify(payload));

export const getParticipationRequestsHandler = ({ payload }) => axios.get(urls.PARTICIPATIONS_REQUESTS.stringify(), {
    params: {
        relations: ['company', 'photo', 'companyRole', 'consultant'],
        ...payload
    }
});
export const putParticipationRequestsHandler = ({ payload }) => axios.put(urls.PARTICIPATIONS_REQUESTS.stringify({ id: payload.id, action: payload.action }), payload);

export const deleteFileHandler = ({ payload }) => axios.delete(urls.FILES.stringify({ id: payload }));

export const getAdminsHandler = ({ payload }) => axios.get(urls.ADMINS.stringify(), { params: payload });
export const getAdminHandler = ({ payload }) => axios.get(urls.ADMINS.stringify({ id: payload }));
export const postAdminHandler = ({ payload }) => axios.post(urls.ADMINS.stringify(), payload);
export const putAdminHandler = ({ payload }) => axios.put(urls.ADMINS.stringify({ id: payload.id }), payload);
export const patchAdminHandler = ({ payload }) => axios.patch(urls.ADMINS.stringify({ id: payload.id }), payload.data);

export const getAdminClientsHandler = ({ payload }) => axios.get(urls.ADMIN_CLIENTS.stringify(), {
    params: {
        ...payload,
        relations: ['company']
    }
});
export const getAdminClientHandler = ({ payload }) => axios.get(urls.ADMIN_CLIENTS.stringify({ id: payload }), {
    params: {
        relations: ['company']
    }
});
export const postAdminClientHandler = ({ payload }) => axios.post(urls.ADMIN_CLIENTS.stringify(), payload);
export const putAdminClientHandler = ({ payload }) => axios.put(urls.ADMIN_CLIENTS.stringify({ id: payload.id }), payload);
export const patchAdminClientHandler = ({ payload }) => axios.patch(urls.ADMIN_CLIENTS.stringify({ id: payload.id }), payload.data);

export const getStagesHandler = ({ payload }) => axios.get(urls.STAGES.stringify(), {
    params: {
        ...payload,
        relations: ['topics']
    }
});
export const putStageHandler = ({ payload }) => axios.put(urls.STAGES.stringify({ id: payload.id }), payload);
export const getStageHandler = ({ payload }) => axios.get(urls.STAGES.stringify({ id: payload }), {
    params: {
        relations: ['topics']
    }
});
export const patchStageHandler = ({ payload: { id, data }}) => axios.patch(urls.STAGES.stringify({ id }), data);
export const postStageHandler = ({ payload }) => axios.post(urls.STAGES.stringify(), payload);
export const deleteStageHandler = ({ payload }) => axios.delete(urls.STAGES.stringify({ id: payload }));

export const putMaterialHandler = ({ payload }) => axios.put(urls.MATERIALS.stringify({ id: payload.id }), payload);
export const getMaterialHandler = ({ payload }) => axios.get(urls.MATERIALS.stringify({ id: payload }));

export const getMoodHandler = ({ payload }) => axios.get(urls.MOOD.stringify(), {
    params: payload
});

export const getFAQHandler = () => axios.get(urls.FAQ.stringify());
export const postFAQHandler = ({ payload }) => axios.post(urls.FAQ.stringify(), payload);
export const putFAQHandler = ({ payload }) => axios.put(urls.FAQ.stringify({ id: payload.id }), payload);

export const getParticipantStagesHandler = ({ payload }) => axios.get(urls.PARTICIPANT_STAGES.stringify(), {
    params: payload
});
export const getParticipantMaterialHandler = ({ payload: { id, params }}) => axios.get(urls.PARTICIPANT_MATERIAL.stringify({ id }), { params });
export const postParticipantFileHandler = ({ payload }) => axios.post(urls.PARTICIPANTS_IMPORT.stringify({ company: payload.company }), payload.file);

export const getInvitesHandler = ({ payload }) => axios.get(urls.INVITES.stringify(), { params: {
    ...payload,
    relations: ['company', 'createdBy', 'participant', 'consultant']
}});
export const postInvitesHandler = ({ payload }) => axios.post(urls.INVITES.stringify(), payload);

export const getTopicsHandler = ({ payload }) => axios.get(urls.TOPICS.stringify(), {
    params: payload
});
export const postTopicHandler = ({ payload }) => axios.post(urls.TOPICS.stringify(), payload);
export const putTopicHandler = ({ payload }) => axios.put(urls.TOPICS.stringify({ id: payload.id }), payload);
export const deleteTopicHandler = ({ payload }) => axios.delete(urls.TOPICS.stringify({ id: payload }));

export const getSettingsHandler = () => axios.get(urls.SETTINGS.stringify());
export const putSettingsPathHandler = ({ payload }) => axios.put(urls.SETTINGS.stringify({ id: payload.id }), payload);
export const getSettingsPathHandler = ({ payload }) => axios.get(urls.SETTINGS.stringify({ id: payload }));

export const getMentionsHandler = ({ payload }) => axios.get(urls.MENTIONS.stringify(), { params: payload });
export const postMentionHandler = ({ payload }) => axios.post(urls.MENTIONS.stringify(), payload);
export const putMentionHandler = ({ payload }) => axios.put(urls.MENTIONS.stringify({ id: payload.id }), payload);
export const deleteMentionHandler = ({ payload }) => axios.delete(urls.MENTIONS.stringify({ id: payload }));

export const getNewsHandler = ({ payload }) => axios.get(urls.NEWS.stringify(), { params: payload });
export const postNewsHandler = ({ payload }) => axios.post(urls.NEWS.stringify(), payload);
export const putNewsHandler = ({ payload }) => axios.put(urls.NEWS.stringify({ id: payload.id }), payload);
export const deleteNewsHandler = ({ payload }) => axios.delete(urls.NEWS.stringify({ id: payload }));

export const getMaterialsStatisticsHandler = ({ payload }) => axios.get(urls.MATERIALS_STATISTICS.stringify(), {
    params: {
        ...payload,
        relations: ['material']
    }
});

export const getFeedbackResultsHandler = ({ payload }) => axios.get(urls.FEEDBACK_RESULTS.stringify(), {
    params: {
        ...payload,
        relations: ['material', 'participant']
    }
});
export const getFeedbackStatisticsHandler = ({ payload }) => axios.get(urls.FEEDBACK_STATISTICS.stringify(), {
    params: {
        ...payload,
        relations: ['material']
    }
});

export const getIndustryDictionaryHandler = () => axios.get(urls.INDUSTRY_DICTIONARY.stringify());
export const getFunctionsDictionaryHandler = () => axios.get(urls.FUNCTIONS_DICTIONARY.stringify());

export const getAdminModulesHandler = () => axios.get(urls.ADMIN_MODULES.stringify());
export const getCompanySettingsHandler = ({ payload }) => axios.get(urls.COMPANY_SETTINGS.stringify({ company: payload.company, path: payload.path }));
export const putCompanySettingsHandler = ({ payload: { company, path, ...payload } }) => axios.put(urls.COMPANY_SETTINGS.stringify({ company, path }), payload);

export const getCompanyRolesHandler = ({ payload }) => axios.get(urls.COMPANY_ROLES.stringify(), {
    params: {
        ...payload,
        relations: ['company', 'modules.themes']
    }
});
export const postCompanyRolesHandler = ({ payload }) => axios.post(urls.COMPANY_ROLES.stringify(), payload);
export const putCompanyRolesHandler = ({ payload }) => axios.put(urls.COMPANY_ROLES.stringify({ id: payload.id }), payload);
export const deleteCompanyRolesHandler = ({ payload }) => axios.delete(urls.COMPANY_ROLES.stringify({ id: payload }));

export const postInvitesImportHandler = ({ payload }) => axios.post(urls.INVITES_IMPORT.stringify({ company: payload.company }), payload.file);

export const getTestThemesHandler = ({ payload }) => axios.get(urls.TEST_THEMES.stringify(), {
    params: {
        ...payload
    }
});
export const postTestThemesHandler = ({ payload }) => axios.post(urls.TEST_THEMES.stringify(), payload);
export const putTestThemesHandler = ({ payload }) => axios.put(urls.TEST_THEMES.stringify({ id: payload.id }), payload);
export const deleteTestThemesHandler = ({ payload }) => axios.delete(urls.TEST_THEMES.stringify({ id: payload }));

export const getEventThemesHandler = ({ payload }) => axios.get(urls.EVENT_THEMES.stringify(), {
    params: {
        ...payload
    }
});
export const postEventThemesHandler = ({ payload }) => axios.post(urls.EVENT_THEMES.stringify(), payload);
export const putEventThemesHandler = ({ payload }) => axios.put(urls.EVENT_THEMES.stringify({ id: payload.id }), payload);
export const deleteEventThemesHandler = ({ payload }) => axios.delete(urls.EVENT_THEMES.stringify({ id: payload }));

export const getEventsHandler = ({ payload }) => axios.get(urls.EVENTS.stringify(), {
    params: {
        relations: ['theme', 'speakers'],
        ...payload
    }
});
export const getEventHandler = ({ payload }) => axios.get(urls.EVENTS.stringify({ id: payload }), {
    params: {
        relations: ['theme', 'speakers']
    }
});
export const postEventHandler = ({ payload }) => axios.post(urls.EVENTS.stringify(), payload);
export const putEventHandler = ({ payload }) => axios.put(urls.EVENTS.stringify({ id: payload.id }), payload);
export const deleteEventHandler = ({ payload }) => axios.delete(urls.EVENTS.stringify({ id: payload }));

export const getEventParticpationHandler = ({ payload }) => axios.get(urls.EVENT_PARTICIPATION.stringify(), {
    params: {
        relations: ['participant', 'event', 'event.theme', 'event.speakers'],
        ...payload
    }
});
export const deleteEventParticipationHandler = ({ payload }) => axios.delete(urls.EVENT_PARTICIPATION.stringify({ id: payload }));
export const postEventParticipationHandler = ({ payload }) => axios.post(urls.EVENT_PARTICIPATION.stringify(), payload);
export const putEventParticipationAttendHandler = ({ payload }) => axios.put(urls.EVENT_PARTICIPATION_ATTEND.stringify({ id: payload }));
export const putEventParticipationAttendCancelHandler = ({ payload }) => axios.put(urls.EVENT_PARTICIPATION_ATTEND_CANCEL.stringify({ id: payload }));

export const putDepersonalizeHandler = ({ payload }) => axios.put(urls.DEPERSONALIZE.stringify({ id: payload }));
export const getParticipantStatisticHandler = ({ payload }) => axios.get(urls.PARTICIPANT_STATISTIC.stringify({ id: payload }), {
    params: {
        relations: ['events.themes.available', 'events.themes.attended']
    }
});

export const getParticipantStatusesHandler = ({ payload }) => axios.get(urls.PARTICIPANT_STATUSES.stringify(), {
    params: {
        relations: ['createdBy'],
        ...payload
    }
});
export const getParticipantStatusHandler = ({ payload }) =>
    axios.get(urls.PARTICIPANT_STATUSES.stringify({ id: payload.id }))
        .catch(error => {
            return Promise.reject({
                ...error,
                status: 422
            });
        })
;
export const postParticipantStatusesHandler = ({ payload }) => axios.post(urls.PARTICIPANT_STATUSES.stringify(), payload);
export const putParticipantStatusesHandler = ({ payload }) => axios.put(urls.PARTICIPANT_STATUSES.stringify({ id: payload.id }), payload);
export const deleteParticipantStatusesHandler = ({ payload }) => axios.delete(urls.PARTICIPANT_STATUSES.stringify({ id: payload }));

export const getPasswordHandler = ({ payload }) => axios.get(urls.PASSWORD.stringify({ id: payload }));
export const postPasswordHandler = ({ payload }) => axios.post(urls.PASSWORD.stringify({ id: payload.id }), payload);

export const putResendEmailHandler = ({ payload }) => axios.put(urls.RESEND_EMAIL.stringify({ id: payload }));
export const putResendEmailParticipantHandler = ({ payload }) => axios.put(urls.RESEND_EMAIL_PARTICIPANT.stringify({ id: payload }));

export const getParticipantContentStagesHandler = ({ payload }) => axios.get(urls.PARTICIPANT_CONTENT_STAGES.stringify({ id: payload }));

export const getConsultationDurationsHandler = () => axios.get(urls.CONSULTATION_DURATIONS.stringify());

export const putSetConsultantHandler = ({ payload }) => axios.put(urls.SET_CONSULTANT.stringify(), payload);

export const getPromotionDurationsHandler = () => axios.get(urls.PROMOTION_DURATIONS.stringify());
export const postPromotionsHandler = ({ payload }) => axios.post(urls.PROMOTIONS.stringify({ id: payload.id }), payload);
export const putPromotionsHandler = ({ payload }) => axios.put(urls.PROMOTIONS.stringify({ id: payload.id }), payload);

export const getPromotionServiceTypesHandler = () => axios.get(urls.PROMOTION_SERVICE_TYPES.stringify());

export const getPromotionRequestsHandler = ({ payload }) => axios.get(urls.PROMOTION_REQUESTS.stringify(), {
    params: {
        relations: ['participant', 'consultant'],
        ...payload
    }
});
export const getPromotionRequestHandler = ({ payload }) => axios.get(urls.PROMOTION_REQUESTS_PARTICIPANT.stringify({ participant: payload.participant, id: payload.id }), {
    params: {
        relations: ['participant', 'files', 'consultant', 'consultantFiles', 'participant.consultant']
    }
});
export const postPromotionRequestsHandler = ({ payload }) => axios.put(urls.PROMOTION_REQUESTS.stringify({ id: payload.id }), payload);
export const getPromotionRequestsStatusesHandler = () => axios.get(urls.PROMOTION_REQUESTS_STATUSES.stringify());
export const getPromotionRequestsThemesHandler = () => axios.get(urls.PROMOTION_REQUESTS_THEMES.stringify());
export const getConsultationsHandler = ({ payload }) => axios.get(urls.CONSULTATIONS.stringify(), {
    params: {
        ...payload,
        relations: ['consultant', 'participant', 'participant.company']
    }
});
export const getConsultationHandler = ({ payload }) => axios.get(urls.CONSULTATIONS.stringify({ id: payload }));
export const putConsultationTitleHandler = ({ payload }) => axios.put(urls.CONSULTATION_TITLE.stringify({ id: payload.id }), payload)

export const postCalcomHandler = ({ payload }) => axios.post(urls.CALCOM.stringify({ id: payload }));

export const getParticipantsHistoryHandler = ({ payload }) => axios.get(urls.PARTICIPANTS_HISTORY.stringify({ id: payload.q.id }), {
    params: {
        ...payload,
        relations: ['participant', 'consultant', 'createdBy', 'updatedBy']
    }
});
export const getParticipantsListHandler = ({ payload }) => axios.get(urls.PARTICIPANTS_LIST.stringify(), {
    params: {
        ...payload,
        relations: ['company']
    }
});
export const getParticipantsSummaryHandler = ({ payload }) => axios.get(urls.PARTICIPANTS_SUMMARY.stringify({ id: payload.q.company }), {
    params: {
        ...payload,
        relations: ['company']
    }
});
export const putParticipantCommentHandler = ({ payload }) => axios.put(urls.PARTICIPANTS_COMMENT.stringify({ id: payload.id }), payload);
export const getParticipantsStatusesHandler = () => axios.get(urls.PARTICIPANTS_STATUSES.stringify());

export const getParticipantFeedbacksHandler = ({ payload }) => axios.get(urls.PARTICIPANT_FEEDBACKS.stringify(), {
    params: {
        ...payload,
        relations: ['participant', 'consultant']
    }
});
export const postParticipantFeedbackEventHandler = ({ payload }) => axios.post(payload.module === 'events' ? urls.PARTICIPANT_FEEDBACKS_EVENT.stringify({ id: payload.id }) : urls.PARTICIPANT_FEEDBACKS_CONSULTATION.stringify({ id: payload.id }), payload);
export const postParticipantFeedbackConsultationHandler = ({ payload }) => axios.post(urls.PARTICIPANT_FEEDBACKS_CONSULTATION.stringify({ id: payload.id }), payload);
export const putParticipantFeedbackHandler = ({ payload }) => axios.put(urls.PARTICIPANT_FEEDBACKS.stringify({ id: payload.id }), payload);
export const deleteParticipantFeedbackHandler = ({ payload }) => axios.delete(urls.PARTICIPANT_FEEDBACKS.stringify({ id: payload }));

export const getCompanyBillingHandler = ({ payload }) => axios.get(urls.COMPANY_BILLING.stringify({ id: payload.id }), {
    params: {
        ...payload.params,
    }
});

export const getCompanyStatisticEventsHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_EVENTS.stringify({ id: payload.id }), { params: payload });
export const getCompanyStatisticEventsAverageAttendanceHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_EVENTS_AVERAGE_ATTENDANCE.stringify({ id: payload.id }), { params: payload });
export const getCompanyStatisticConsultationsHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_CONSULTATIONS.stringify({ id: payload.id }), { params: payload });
export const getCompanyStatisticContentHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_CONTENT.stringify({ id: payload }));
export const getCompanyStatisticPromotionHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_PROMOTION.stringify({ id: payload.id }), { params: payload });
export const getCompanyStatisticEventsThemesHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_EVENTS_THEMES.stringify({ id: payload }));
export const getCompanyStatisticEventsSpeakersHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_EVENTS_SPEAKERS.stringify({ id: payload.id }), { params: payload });
export const getCompanyStatisticPromotionRolesHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_PROMOTION_ROLES.stringify({ id: payload.id }), { params: payload });
export const getCompanyStatisticConsultationsRolesHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_CONSULTATIONS_ROLES.stringify({ id: payload.id }), { params: payload });
export const getCompanyStatisticPromotionMonthsHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_PROMOTION_MONTHS.stringify({ id: payload.id }), { params: payload });
export const getCompanyStatisticPromotionConsultantHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_PROMOTION_CONSULTANT.stringify({ id: payload }));
export const getCompanyStatisticParticipantsHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_PARTICIPANTS.stringify({ id: payload }));
export const getCompanyStatisticStatusesHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_STATUSES.stringify({ id: payload.id }), { params: payload });
export const getCompanyStatisticParticipantsAverageLifetimeHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_PARTICIPANTS_AVERAGE_LIFETIME.stringify({ id: payload }));
export const getCompanyStatisticDyncamicUsageHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_DYNAMIC_USAGE.stringify(), { params: payload });
export const getCompanyStatisticConsultantsHandler = ({ payload }) => axios.get(urls.COMPANY_STATISTIC_CONSULTANTS.stringify(), { params: payload });

export const getEmployeeLinksHandler = ({ payload }) => axios.get(urls.EMPLOYEE_LINKS.stringify(), {
    params: payload,
});
export const getEmployeeLinkHandler = ({ payload }) => axios.get(urls.EMPLOYEE_LINKS.stringify({ id: payload }));
export const postEmployeeLinkHandler = ({ payload }) => axios.post(urls.EMPLOYEE_LINKS.stringify(), payload);
export const putEmployeeLinkHandler = ({ payload }) => axios.put(urls.EMPLOYEE_LINKS.stringify({ id: payload.id }), payload);
export const deleteEmployeeLinkHandler = ({ payload }) => axios.delete(urls.EMPLOYEE_LINKS.stringify({ id: payload }));
export const getEmployeeListHandler = ({ payload }) => axios.get(urls.EMPLOYEE_LIST.stringify(), {
    params: payload
});
export const getEmployeeRequestHandler = ({ payload }) => axios.get(urls.EMPLOYEE_REQUEST.stringify({ id: payload }));

export const getMessageTemplatesHandler = ({ payload }) => axios.get(urls.MESSAGE_TEMPLATES.stringify(), { params: payload });
export const getMessageTemplateHandler = ({ payload }) => axios.get(urls.MESSAGE_TEMPLATES.stringify({ id: payload }));
export const postMessageTemplateHandler = ({ payload }) => axios.post(urls.MESSAGE_TEMPLATES.stringify(), payload);
export const putMessageTemplateHandler = ({ payload }) => axios.put(urls.MESSAGE_TEMPLATES.stringify({ id: payload.id }), payload);

export const postParticipantSendResumeHandler = ({ payload }) => axios.post(urls.PARTICIPANT_SEND_RESUME.stringify({ id: payload }));
