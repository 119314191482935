"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PERSONALITY_ANALYSIS_DATA = void 0;
var personalityAnalysisData = ['Аккуратный', 'Предприимчивый', 'Артистичный', 'Ассертивный (настойчивый)', 'Критичный', 'Обязательный', 'Коммуникабельный', 'Уверенный', 'Сочувствующий', 'Креативный', 'Любопытный', 'Преданный делу', 'Надежный', 'Эффективный', 'Эмоциональный', 'Энергичный', 'Увлекающий', 'Исполнительный', 'Экспрессивный', 'Адаптирующийся', 'Работоспособный', 'Ориентированный на качество', 'Независимый', 'Любознательный', 'Интуитивный', 'Добрый', 'Лидер', 'Уравновешенный', 'Лояльный', 'Оригинальный', 'Ориентированный на людей', 'Перфекционист', 'Убеждающий', 'Практичный', 'Продуктивный', 'Рациональный', 'Ответственный', 'Отзывчивый', 'Контролирующий', 'Организованный', 'Начинающий', 'С чувством юмора', 'Дружелюбный', 'Твердый, устойчивый', 'Терпимый', 'Внимательный', 'Влиятельный', 'Системный', 'Анализирующий', 'Воодушевляющий'];
var PERSONALITY_ANALYSIS_DATA = {
  male: personalityAnalysisData,
  female: personalityAnalysisData.map(function (text) {
    return text.replace(/ый|ий/gi, 'ая').replace(/ийся/gi, 'аяся');
  })
};
exports.PERSONALITY_ANALYSIS_DATA = PERSONALITY_ANALYSIS_DATA;