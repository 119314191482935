import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Field } from 'react-final-form';
import * as yup from 'yup';

import { withFormWrapper } from '@meconsultant/common';
import Input from './formComponents/Input';

class LinkForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool,
        item: PropTypes.object,
        deleteEmployeeLink: PropTypes.object
    };

    deleteEmployeeLink = () => {
        const { deleteEmployeeLink, item } = this.props;

        deleteEmployeeLink.dispatch(item.id);
    }

    render() {
        const { isSubmitting, item, deleteEmployeeLink } = this.props;
        const disabled = isSubmitting || deleteEmployeeLink.meta.pending;

        return <div className='company-form'>
            <Field
                name='title'
                component={Input}
                label='Название' />
            { item &&
                <Field
                    name='authCode'
                    component={Input}
                    label='Пароль для ссылки'
                    disabled />
            }
            <Button
                type='primary'
                htmlType='submit'
                disabled={disabled}>
                { item ? 'Сохранить' : 'Сформировать' }
            </Button>
            { item &&
                <Button
                    danger
                    onClick={this.deleteEmployeeLink}
                    disabled={disabled}>
                    Удалить
                </Button>
            }
        </div>;
    }
}

const validationSchema = yup.object().shape({
    title: yup.string().required(),
});

export default withFormWrapper(LinkForm, {
    mapPropsToValues: ({ item }) => item,
    validationSchema,
    subscriptions: { values: true }
});
