import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAsyncActions } from 'react-async-client';
import { message, Row } from 'antd';

import LoginForm from './forms/LoginForm';
import { postLogin } from '../actions/asyncActions';
import { DEFAULT_LOGIN_ERROR } from '../constants/errors';
import { Link } from 'react-router-dom';

class Login extends Component {
    static propTypes = {
        postLogin: PropTypes.object
    };

    render() {
        return <div>
            <LoginForm formAction={this.props.postLogin} defaultError={DEFAULT_LOGIN_ERROR} />
            <Row style={{ display: 'flex', marginTop: 15 }} justify="center">
                <Link to='/recovery'>
                    Забыли пароль?
                </Link>
            </Row>
        </div>;
    }
}

export default withAsyncActions({
    postLogin: postLogin
        .withErrorHandler(() => message.error('Не удалось выполнить вход'))
        .withOptions({ resetOnUnmount: true })
})(Login)
