import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { Col, Row } from 'antd';
import { findIndex, includes, prepend, propEq, remove, toPairs } from 'ramda';
import { connect } from 'react-redux';

import withFilterForm from '../hocs/withFilterForm';
import SearchSelect from '../forms/formComponents/SearchSelect';
import { getAdmins, getPromotionRequestsStatuses, getPromotionRequestsThemes, getUser } from '../../actions/asyncActions';
import { STATUSES } from '../Promotion';
import { ADMIN } from '../../constants/roles';

class PromotionFilter extends Component {
    render() {
        const { user } = this.props;

        return (
            <Row gutter={16}>
                <Col span={8}>
                    <Field
                        name='theme'
                        component={SearchSelect}
                        placeholder='Тема запроса'
                        action={getPromotionRequestsThemes}
                        parseAsync={items => toPairs(items).map(([id, value]) => ({ id, value }))}
                        allowClear />
                </Col>
                <Col span={8}>
                    <Field
                        name='status'
                        component={SearchSelect}
                        placeholder='Статус'
                        action={getPromotionRequestsStatuses}
                        parseAsync={data => data.map(id => ({ id, value: STATUSES[id].text }))}
                        allowClear />
                </Col>
                <Col span={8}>
                    <Field
                        name='consultant'
                        component={SearchSelect}
                        action={getAdmins}
                        placeholder='Консультант'
                        namePath='name'
                        getNamePath={item => `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`}
                        filter={{ superAdmin: false }}
                        parseItemsAfter={items => (includes(ADMIN, user.roles) && !user.superAdmin) ?
                            prepend({ value: user.id, label: 'Мои' }, remove(findIndex(propEq('value', user.id), items), 1, items)) : items
                        }
                        allowClear
                    />
                </Col>
            </Row>
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(withFilterForm(PromotionFilter));
