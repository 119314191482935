import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Tooltip, message, DatePicker } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { PlusOutlined, SettingOutlined, LinkOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import moment from 'moment';

import { getCompanies, getUser } from '../actions/asyncActions';
import TableList from './TableList';
import { openCompanyModal, openCompanySettingsModal } from '../actions/modalActions';
import { POST_COMPANY, PUT_COMPANY, DELETE_COMPANY } from '../constants/actionTypes';
import { extendSearchPath, getCompanyLandingUrl, getFilters } from '../utils/urls';
import CompanyFilter from './filters/CompanyFilter';
import { getName } from '@meconsultant/common/lib/utils/resume';
import { assocPath, dissocPath, equals, path } from 'ramda';

const FilterWrapper = styled.div`
    .filter-wrapper > div > div,
    .ant-picker-dropdown {
        position: static !important;
    }
    .filter-wrapper {
        display: flex;
        flex-direction: column-reverse;
    }
    .ant-picker-panel-container {
        box-shadow: none;
    }
    .ant-picker-active-bar {
        display: none;
    }
    .ant-picker {
        margin: 10px;
    }
`;

class DateFilterComponent extends Component {
    constructor(props) {
        super(props);

        const value = (props.additionalFilter ? props.additionalFilter.dateField === path(['dateField'], getFilters(props.location)) : true) &&
            (path(props.from.split('.'), getFilters(props.location) || {}) || path(props.to.split('.'), getFilters(props.location) || {})) ? {
            from: path(props.from.split('.'), getFilters(props.location) || {}),
            to: path(props.to.split('.'), getFilters(props.location) || {})
        } : null;

        this.state = {
            value
        };

        value && props.setSelectedKeys([value]);
        this.wrapper = null;
    }

    componentDidUpdate(prev) {
        if (this.props.additionalFilter &&
            (this.props.additionalFilter.dateField !== path(['dateField'], getFilters(this.props.location))) &&
            !equals(getFilters(this.props.location), getFilters(prev.location))
        ) {
            this.reset();
        }
    }

    reset = () => {
        this.props.clearFilters();
        this.setState({ value: null });
        this.submit(null);
    }

    submit = value => {
        const { location, history: { replace }, confirm, from, to, additionalFilter } = this.props;
        let filterValue = {
            ...getFilters(location) || {},
            ...(value && additionalFilter ? additionalFilter : {})
        };
        filterValue = value ? assocPath(from.split('.'), value.from, filterValue) : dissocPath(from.split('.'), filterValue);
        filterValue = value ? assocPath(to.split('.'), value.to, filterValue) : dissocPath(to.split('.'), filterValue);

        const params = {
            filter: JSON.stringify(filterValue)
        };

        confirm();
        replace(extendSearchPath(location, params));
    }

    onChange = date => {
        this.props.setSelectedKeys(date ? [date] : null);
        this.setState({
            value: date ? {
                from: date[0].startOf('day').toDate(),
                to: date[1].endOf('day').toDate()
            } : null
        });
    }

    render() {
        const { value } = this.state;

        return <FilterWrapper>
            <div className='filter-wrapper'>
                <div ref={node => this.wrapper = node} />
                <DatePicker.RangePicker
                    format='DD.MM.YYYY'
                    value={value && [moment(value.from), moment(value.to)]}
                    onChange={this.onChange}
                    allowClear
                    getPopupContainer={trigger => this.wrapper || trigger.parentNode}
                    placeholder={['от', 'до']}
                    open
                />
            </div>
            <div style={{ display: 'flex', padding: 10, paddingTop: 5, justifyContent: 'end' }}>
                <Button style={{ marginRight: 5 }} onClick={this.reset}>Сбросить</Button>
                <Button type='primary' onClick={() => this.submit(this.state.value)}>ОК</Button>
            </div>
        </FilterWrapper>;
    }
}

export const DateFilter = withRouter(DateFilterComponent);

class Companies extends Component {
    static propTypes = {
        openCompanyModal: PropTypes.func
    };

    getDateFilter = (props, from, to) => {
        return <DateFilter key={`${from}-${to}-visible-${props.visible}`} {...props} from={from} to={to} />;
    }

    getColumns = () => {
        return [
            {
                title: 'Название',
                dataIndex: 'name',
                key: 'name',
                render: (name, data) => <Link to={`/company/${data.id}`}>{ name }</Link>
            },
            {
                title: 'Отрасль',
                dataIndex: 'industryName',
                key: 'industryName'
            },
            {
                title: 'Кол-во участников',
                dataIndex: 'countParticipants',
                key: 'countParticipants',
                render: (count, { id }) => <Link to={`/company/${id}/participants`}>{count}</Link>
            },
            {
                title: 'Консультанты',
                dataIndex: ['_embedded', 'consultants'],
                key: 'consultant',
                width: 250,
                render: consultants => (consultants || []).map(consultant => <div key={consultant.id}>{ getName(consultant.lastName, consultant.firstName, consultant.middleName) }</div>)
            },
            {
                title: 'Срок начала взаимодействия',
                dataIndex: 'dateStart',
                key: 'dateStart',
                render: dateStart => dateStart ? new Date(dateStart).toLocaleDateString('ru') : null,
                filterDropdown: props => this.getDateFilter(props, 'minDateStart', 'maxDateStart')
            },
            {
                title: 'Срок окончания взаимодействия',
                dataIndex: 'dateFinish',
                key: 'dateFinish',
                render: dateFinish => dateFinish ? new Date(dateFinish).toLocaleDateString('ru') : null,
                filterDropdown: props => this.getDateFilter(props, 'minDateFinish', 'maxDateFinish')
            },
            {
                title: 'Коды',
                key: 'invites',
                render: ({ id, countActiveInvites, totalCountInvites }) => (
                    <Link to={`/invites?company=${id}`}>{`${countActiveInvites} / ${totalCountInvites}`}</Link>
                ),
            },
            {
                key: 'actions',
                align: 'right',
                render: item => (
                    <Button.Group>
                        <CopyToClipboard
                            onCopy={(event, result) => result ? message.success('Ссылка скопирована в буфер обмена') : message.error('Неудалось скопировать ссылку')}
                            text={getCompanyLandingUrl(item.id)}
                        >
                            <Tooltip title={`URL лендинга: ${getCompanyLandingUrl(item.id)}`} placement='topRight'>
                                <Button icon={<LinkOutlined />} />
                            </Tooltip>
                        </CopyToClipboard>
                        <Button icon={<SettingOutlined />} onClick={() => this.props.openCompanySettingsModal({ company: item.id, path: 'landing' })} />
                    </Button.Group>
                )
            }
        ];
    }

    renderToolbarButtons = () => {
        return this.props.user.superAdmin && <Button icon={<PlusOutlined />} type='primary' onClick={() => this.props.openCompanyModal()}>
            Добавить
        </Button>
    }

    render() {
        return (
            <TableList
                action={getCompanies}
                columns={this.getColumns()}
                buttons={this.renderToolbarButtons()}
                refreshActions={[POST_COMPANY, PUT_COMPANY, DELETE_COMPANY]}
                filterForm={<CompanyFilter />}
            />
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps, { openCompanyModal, openCompanySettingsModal })(Companies);
