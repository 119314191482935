import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postParticipantDocuments } from '../../actions/asyncActions';
import DocumentForm from '../forms/DocumentForm';

class DocomentModal extends Component {
    render() {
        const { modal, postParticipantDocuments, params } = this.props;

        return <Modal
            {...modal}
            title='Загрузить документ'
            footer={null}>
            <DocumentForm formAction={postParticipantDocuments} item={params} cancel={() => modal.onCancel()} />
        </Modal>;
    }
}

export default withAsyncActions({
    postParticipantDocuments: postParticipantDocuments
        .withSuccessHandler(({ close }) => {
            message.success('Документ успешно добавлен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(DocomentModal);
