import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { asyncConnect, toSuccess } from 'react-async-client';
import { takeEvery } from 'redux-saga/effects';
import { Card, Row, Col, Button, Spin } from 'antd';
import styled from 'styled-components';
import { pluralize } from 'numeralize-ru';
import { pathOr } from 'ramda';
import { withState } from 'recompose';
import qs from 'qs';
import { PictureOutlined, PlusOutlined } from '@ant-design/icons';

import { getStages, getTopics } from '../actions/asyncActions';
import { PUT_STAGE, PUT_MATERIAL, POST_STAGE } from '../constants/actionTypes';
import { Image } from './forms/formComponents/ImageLoader';
import { openStageModal } from '../actions/modalActions';

const NoImage = styled.div`
    width: 100%;
    height: 250px;
    background: #e5e5e5;
    font-size: 100px;
    color: #d3d3d3;
    display: flex !important;
    align-items: center;
    justify-content: center;
`;

const StyledCard = styled(Card)`
    cursor: pointer;
    &.ant-card {
        margin-bottom: 15px;
    }
`;

const CardImage = styled(Image)`
    width: 100%;
`;

export const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 15px;
`;

class Stages extends Component {
    static propTypes = {
        getStages: PropTypes.object,
        openStageModal: PropTypes.func,
        openMaterialModal: PropTypes.func,
        history: PropTypes.object,
        getTopics: PropTypes.object,
        location: PropTypes.object,
        selectedTopics: PropTypes.array,
        setTopics: PropTypes.func
    };

    renderStage = item => {
        const { history } = this.props;
        const themes = pathOr([], ['themes'], item);
        const materialsLength = themes.reduce((res, cur) => res + cur.materials.length, 0);
        const topics = pathOr([], ['_embedded', 'topics'], item);

        return <Col sm={8} xs={24} key={item.id}>
            <StyledCard
                onClick={() => history.push(`/stages/${item.id}`)}
                cover={
                    item.image ?
                        <CardImage image={item.image} /> :
                        <NoImage>
                           <PictureOutlined />
                        </NoImage>
                }>
                <Card.Meta
                    title={item.title}
                    description={
                        <div>
                            <div>{ topics.map((topic, index) => <span key={topic.id}>{ `${topic.title}${index < topics.length - 1 ? ', ' : ''}` }</span>) }</div>
                            <div>{ `${themes.length} ${pluralize(themes.length, 'тема', 'темы', 'тем')}, ${materialsLength} ${pluralize(materialsLength, 'материал', 'материала', 'материалов')}` }</div>
                        </div>
                    } />
            </StyledCard>
        </Col>;
    }

    onChangeTopics = topics => {
        this.props.history.replace(topics.length ? `/stages?${qs.stringify({ topics })}` : '/stages');
        this.props.setTopics(topics);
    }

    render() {
        const { getStages: { data: { items = [] }, meta }, openStageModal } = this.props;

        return (
            <Fragment>
                <Row className='toolbar-row'>
                    <Col span={24}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            {/* <Select
                            style={{ width: 300 }}
                            placeholder='Категории этапов'
                            mode='multiple'
                            value={getTopics.meta.lastSucceedAt ? selectedTopics : []}
                            loading={getTopics.meta.pending}
                            onChange={this.onChangeTopics}
                            allowClear>
                            { topics.map(topic =>
                                <Select.Option value={topic.id} key={topic.id}>
                                    { topic.title }
                                </Select.Option>
                            )}
                        </Select> */}
                            <Button
                                type='primary'
                                icon={<PlusOutlined />}
                                onClick={() => openStageModal()}>
                                Добавить
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16} className='stages'>
                    { meta.pending && !meta.lastSucceedAt ? <LoaderWrapper><Spin /></LoaderWrapper> : items.map(this.renderStage) }
                </Row>
            </Fragment>
        );
    }
}

export default withState('selectedTopics', 'setTopics', ({ location: { search }}) => qs.parse(search, { ignoreQueryPrefix: true }).topics)(
    asyncConnect({
        getTopics: getTopics
            .withOptions({ resetOnUnmount: true, dispatchOnMount: true }),
        getStages: getStages
            .withSaga(function* (getProps) {
                yield takeEvery([toSuccess(PUT_STAGE), toSuccess(PUT_MATERIAL), toSuccess(POST_STAGE)], () => {
                    getProps().getStages.refresh();
                });
            })
            .withPayload(({ selectedTopics }) => ({
                q: {
                    topics: selectedTopics
                }
            }))
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
    }, null, { openStageModal })(Stages)
);
