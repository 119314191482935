import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { Col, Row } from 'antd';
import { connect } from 'react-redux';
import { includes } from 'ramda';

import withFilterForm from '../hocs/withFilterForm';
import SearchInput from '../forms/formComponents/SearchInput';
import SearchSelect from '../forms/formComponents/SearchSelect';
import { getCompanies, getUser } from '../../actions/asyncActions';
import { ADMIN, SUPER_ADMIN } from '../../constants/roles';

class CompanyRolesFilter extends Component {
    render() {
        const { user, hideCompany } = this.props;

        return (
            <Row gutter={16}>
                <Col span={12}>
                    <Field
                        name='name'
                        component={SearchInput}
                        placeholder='Название'
                    />
                </Col>
                { (includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles)) && !hideCompany && <Col span={12}>
                    <Field
                        name='company'
                        component={SearchSelect}
                        placeholder='Компания'
                        action={getCompanies}
                        namePath='name'
                        allowClear />
                </Col>}
            </Row>
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(withFilterForm(CompanyRolesFilter));
