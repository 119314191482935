"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.COVER_LETTER_FIELDS = void 0;
var COVER_LETTER_FIELDS = [{
  name: 'welcome',
  label: 'Приветствие',
  placeholder: 'Поздоровайтесь с работодателем и представьтесь сами.'
}, {
  name: 'purpose',
  label: 'Цель',
  placeholder: 'Укажите цель Вашего обращения.'
}, {
  name: 'motivation',
  label: 'Интерес и мотивация',
  placeholder: 'Отразите то, что Вас привлекло в вакансии и чем интересен данный работодатель.'
}, {
  name: 'presentation',
  label: 'Презентация себя',
  placeholder: 'Кратко опишите свой профессиональный опыт, ключевые достижения и компетенции, которые соответствуют требованиям вакансии.'
}, {
  name: 'feedback',
  label: 'Запрос на обратную связь',
  placeholder: 'Задайте вопрос, в какие сроки Вы можете рассчитывать на обратную связь со стороны работодателя.'
}, {
  name: 'thanks',
  label: 'Благодарность',
  placeholder: 'Поблагодарите работодателя за уделенное время.'
}, {
  name: 'contact',
  label: 'Контактная информация',
  placeholder: 'Отразите дополнительные контакты для связи.'
}];
exports.COVER_LETTER_FIELDS = COVER_LETTER_FIELDS;