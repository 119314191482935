import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { Row, Col } from 'antd';

import withFilterForm from '../hocs/withFilterForm';
import SearchInput from '../forms/formComponents/SearchInput';

class MentionFilter extends Component {
    render() {
        return (
            <Row gutter={16}>
                <Col span={12}>
                    <Field
                        name='personName'
                        component={SearchInput}
                        placeholder='Имя пользователя'
                    />
                </Col>
                <Col span={12}>
                    <Field
                        name='text'
                        component={SearchInput}
                        placeholder='Текст'
                    />
                </Col>
            </Row>
        );
    }
}

export default withFilterForm(MentionFilter);
