import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { putAdminClient, postAdminClient } from '../../actions/asyncActions';
import AdminClientForm from '../forms/AdminClientForm';
import { path } from 'ramda';

class AdminClientModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        postAdminClient: PropTypes.object,
        putAdminClient: PropTypes.object,
        params: PropTypes.object
    };

    render() {
        const { modal, postAdminClient, params, putAdminClient } = this.props;

        return <Modal
            {...modal}
            title={params ? 'Редактировать администратора/менеджера клиента' : 'Добавить администратора/менеджера клиента'}
            footer={null}>
            <AdminClientForm
                item={params}
                formAction={path(['id'], params) ? putAdminClient : postAdminClient} />
        </Modal>
    }
}

export default withAsyncActions({
    postAdminClient: postAdminClient
        .withSuccessHandler(({ close }) => {
            message.success('Администратор/менеджер клиента был успешно добавлен');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putAdminClient: putAdminClient
        .withSuccessHandler(({ close }) => {
            message.success('Администратор/менеджер клиента был успешно отредактирован');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(AdminClientModal);
