import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { Button } from 'antd';

import { withFormWrapper } from '@meconsultant/common';
import Input from './formComponents/Input';
import Checkbox from './formComponents/Checkbox';
import { Link } from 'react-router-dom';

class PasswordForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting, values } = this.props;

        return <Fragment>
            <Field
                name='password'
                component={Input}
                placeholder='Пароль'
                htmlType='password' />
            <Field
                name='passwordRepeat'
                component={Input}
                placeholder='Пароль повторно'
                htmlType='password'
                extra='Пароль должен содержать более 6 символов, строчные и заглавные буквы латинского алфавита и цифры'
            />
            <Field
                name='personalAgreement'
                component={Checkbox}
                text={<p>Настоящим во исполнение требований Закона «О персональных данных» № 152-ФЗ от 27.07.2006 г. подтверждаю, что ознакомлен и принимаю условия <Link target='_blank' to='/offer'>Публичной оферты</Link>, регламентирующей цели, порядок и сроки обработки моих персональных данных ООО «АНКОР Консалтинг» (юридический адрес: 109544, г Москва, б-р Энтузиастов, 2, помещение 2 этаж 13, ИНН 7701872919), именуемым в дальнейшем "Оператор".</p>}
                label={<span />} />
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting || !values.personalAgreement}>
                Отправить
            </Button>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    password: yup.string().match('passwordRepeat').min(6).required(),
    passwordRepeat: yup.string().match('password').min(6).required(),
});

export default withFormWrapper(PasswordForm, {
    validationSchema,
    mapPropsToValues: ({ id }) => ({ id }),
    subscriptions: { values: true }
});
