"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GENDER = void 0;
var GENDER = [{
  id: 'male',
  name: 'Мужской'
}, {
  id: 'female',
  name: 'Женский'
}];
exports.GENDER = GENDER;