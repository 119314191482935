import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { withFieldWrapper } from '@meconsultant/common';

class InputSearchComponent extends Component {
    static propTypes = {
        placeholder: PropTypes.string,
        field: PropTypes.object,
        onChange: PropTypes.func,
        input: PropTypes.object,
        disabled: PropTypes.bool,
    };

    render() {
        const { placeholder, onChange: onSearch, input: { value }, disabled } = this.props;

        return (
            <Input.Search
                defaultValue={value}
                onSearch={onSearch}
                placeholder={placeholder}
                disabled={disabled}
            />
        );
    }
}

export default withFieldWrapper(InputSearchComponent);
