import { Alert, Col, Row, Spin, Table, Button, Tooltip } from 'antd';
import { MailOutlined, PhoneOutlined, UserOutlined,  PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import { head, find, isNil, path, propEq } from 'ramda';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import moment from 'moment';
import { getFileIcon, getFileSize } from '@meconsultant/common';

import { putParticipant, deleteParticipantDocuments } from '../../actions/asyncActions';
import { openParticipantDocumentModal } from '../../actions/modalActions';
import { GENDER } from '../../constants/dictionaries';
import { FILE_URL } from '../../constants/urls';
import { getToken } from '../../utils/token';
import Photo from '../Photo';
import { LoaderWrapper } from '../Stages';
import styled from 'styled-components';

const WrapInfo = styled.div`
    margin: 8px 0;
`;

const Title = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #2B3D4F;
    margin: 0 0 5px;
`;

const SubTitle = styled.div`
    font-size: 16px;
    line-height: 20px;
    color: #9C9FA3;
    margin: 0 0 4px;
`;

const InfoRow = styled.div`
    .anticon {
        margin-right: 5px;
    }
`;

const TitleH3 = styled.h3`
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: #333;
`;

const LabelCol = styled(Col)`
    font-weight: 300;
`;

const TableWrap = styled.div`
    margin: 24px -20px;
`;

const DeleteLink = styled.div`
    cursor: pointer;
`;

class ProfileInfo extends Component {
    renderRow = (label, value) => {
        return !isNil(value) && <Row>
            <LabelCol span={8}>{ label }</LabelCol>
            <Col span={16}>{ value }</Col>
        </Row>;
    }

    render() {
        const { getParticipant: { meta, data } } = this.props;
        const consultant = path(['_embedded', 'consultant'], data);
        const dataSource = [
            {
                key: "1",
                col1: (data.registrationAt ? new Date(data.registrationAt).toLocaleDateString('ru') : null),
                col6: (data.programStartedAt ? new Date(data.programStartedAt).toLocaleDateString('ru') : null),
                col2: (data.firstLogin ? new Date(data.firstLogin).toLocaleDateString('ru') : null),
                col3: (data.lastLogin ? new Date(data.lastLogin).toLocaleDateString('ru') : null),
                col4: (data.expiredAt ? new Date(data.expiredAt).toLocaleDateString('ru') : 'бессрочно'),
                col5: (data.programFinishedAt ? new Date(data.programFinishedAt).toLocaleDateString('ru') : null),
                col7: (data.employedAt ? (
                    <Tooltip title={data.employedIn} placement='left'>
                        {new Date(data.employedAt).toLocaleDateString('ru')}
                    </Tooltip>
                ) : null)
            },
        ];
        const columns = [
            {
                title: "Регистрация",
                dataIndex: "col1",
                key: "col1",
            },
            {
                title: "Увольнение",
                dataIndex: "col6",
                key: "col6",
            },
            {
                title: "Первый вход",
                dataIndex: "col2",
                key: "col2",
            },
            {
                title: "Последний вход",
                dataIndex: "col3",
                key: "col3",
            },
            {
                title: "Окончание доступа",
                dataIndex: "col4",
                key: "col4",
            },
            {
                title: "Завершения программы",
                dataIndex: "col5",
                key: "col5",
            },
            {
                title: "Трудоустройства",
                dataIndex: "col7",
                key: "col7",
            },
        ];

        const documentsColumns = [
            {
                title: 'Название',
                key: 'name',
                dataIndex: 'name',
                width: '52%',
                render: (name, item) => {
                    let icon = getFileIcon(item.mimeType);

                    return (
                        <a
                            download
                            href={`${FILE_URL}/${item.id}/download?token=${getToken()}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {icon} <b>{name}</b>
                        </a>
                    );
                }
            },
            {
                title: 'Дата',
                key: 'uploadDate',
                dataIndex: 'uploadDate',
                align: 'center',
                render: date => date && <div>
                    <div>{ moment(date).format('DD.MM.YYYY') }</div>
                </div>
            },
            {
                title: 'Размер',
                key: 'length',
                dataIndex: 'length',
                render: size => <div>
                    {getFileSize(size)}
                </div>
            },
            {
                title: 'Автор',
                key: 'user',
                dataIndex: ['createdBy'],
                render: user => user ? <div>
                    { user.lastName } { head(user.firstName) }.{ user.middleName ? `${head(user.middleName)}.` : '' }
                </div> : null,
            },
            {
                key: 'actions',
                render: (item) => (
                    <DeleteLink onClick={() => this.props.deleteParticipantDocuments.dispatch({
                        id: data.id,
                        file: item.id,
                    })}>
                        <DeleteOutlined />
                    </DeleteLink>
                )
            },
        ];

        return meta.pending && !meta.lastSucceedAt ?
            <LoaderWrapper><Spin /></LoaderWrapper> :
            <div className='detail-block clearfix'>
                { data.depersonalized &&
                    <Alert style={{ marginBottom: 15 }} type='info' message='Персональные данные участника удалены' />
                }
                <div className='detail-photo-block'>
                    <Photo item={data} action={putParticipant} />
                </div>
                <WrapInfo>
                    <Title>{data.lastName} {data.firstName} {data.middleName}</Title>
                    <Row>
                        <Col span={12}>
                            <SubTitle>{ data.location && `${data.location},` } {data.position}</SubTitle>
                            <InfoRow><MailOutlined /> {data.email}</InfoRow>
                            <InfoRow><PhoneOutlined /> {data.phone}</InfoRow>
                            <InfoRow><UserOutlined /> {data.gender && (data.gender ? path(['name'], find(propEq('id', data.gender), GENDER)) : null)}</InfoRow>
                        </Col>
                        <Col span={12}>
                            <TitleH3>Дополнительная информация</TitleH3>
                            { this.renderRow('Компания', path(['_embedded', 'company', 'name'], data)) }
                            { this.renderRow('Роль', path(['_embedded', 'companyRole', 'name'], data)) }
                            { !!consultant && this.renderRow('Консультант', `${consultant.lastName} ${consultant.firstName} ${consultant.middleName || ''}`) }
                        </Col>
                    </Row>
                </WrapInfo>
                <TableWrap>
                    <Table dataSource={dataSource} columns={columns} pagination={false} />
                    <Table
                        rowKey='id'
                        dataSource={path(['_embedded', 'documents'], data) || []}
                        columns={documentsColumns}
                        pagination={false}
                        locale={{
                            emptyText: ' '
                        }}
                    />
                </TableWrap>
                <Button
                    type='primary'
                    icon={<PaperClipOutlined />}
                    onClick={() => this.props.openParticipantDocumentModal({ id: data.id })}
                >
                    Загрузить файл
                </Button>
            </div>;
    }
}

export default asyncConnect({
    deleteParticipantDocuments: deleteParticipantDocuments
        .withParams(() => ({ type: 'list' }))
        .withOptions({ resetOnUnmount: true })
}, null, { openParticipantDocumentModal })(ProfileInfo);
