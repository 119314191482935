import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';

import { withFieldWrapper } from '@meconsultant/common';

class DatePickerComponent extends Component {
    static propTypes = {
        input: PropTypes.object,
        onChange: PropTypes.func,
        required: PropTypes.bool,
        min: PropTypes.object,
        max: PropTypes.object
    };

    static defaultProps = {
        allowClear: true
    };

    onChange = date => {
        this.props.onChange(date ? (this.props.endOfDay ? date.utc().endOf('day').toDate() : date.utc().startOf('day').toDate()) : null);
    }

    getDisabledDate = date => {
        const { min, max } = this.props;

        return (min && min.isAfter(date)) || (max && max.isBefore(date));
    }

    render() {
        const { input: { value }, required, style, placeholder, allowClear } = this.props;

        return <DatePicker
            value={value ? moment.utc(value) : null}
            format='DD.MM.YYYY'
            onChange={this.onChange}
            allowClear={!required && allowClear}
            getCalendarContainer={trigger => trigger.parentNode}
            disabledDate={this.getDisabledDate}
            placeholder={placeholder}
            style={style} />;
    }
}

export default withFieldWrapper(DatePickerComponent);
