import { Progress, Spin } from 'antd';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { connect } from 'react-redux';

import { getCompanyStatisticConsultations, getUser } from '../../../actions/asyncActions';
import { Block, BlockContent, BlockHeader, FactDesc, FactText, Info, InfoBlock, InfoDivider, PlanDesc, PlanText } from './WebinarsByCompany';

class ConsultationsByCompany extends Component {
    render() {
        const { getCompanyStatisticConsultations: { meta, data }, consultant } = this.props;

        return <Block>
            <BlockHeader>
                <h3>{ consultant ? 'КОНСУЛЬТАЦИИ' : 'КОНСУЛЬТАЦИИ ПО КОМПАНИИ' }</h3>
            </BlockHeader>
            <BlockContent>
                { meta.pending ? <Spin /> : <div>
                    <Info>
                        <InfoBlock>
                            <FactText>{ data.countConsultations }</FactText>
                            <FactDesc>факт</FactDesc>
                        </InfoBlock>
                        <InfoDivider />
                        <InfoBlock>
                            <PlanText>{ data.countConsultationsExpected }</PlanText>
                            <PlanDesc>план</PlanDesc>
                        </InfoBlock>
                    </Info>
                    <Progress percent={data.countConsultationsPercents || 0} strokeColor='#2B3D4F' trailColor='#A7B9CF' />
                </div> }
            </BlockContent>
        </Block>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(asyncConnect({
    getCompanyStatisticConsultations: getCompanyStatisticConsultations
        .withPayload(({ id, user, consultant }) => ({
            id: id || user.company,
            q: {
                consultant
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(ConsultationsByCompany));
