import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message, Spin, Table, Tag, Popconfirm, Select, Button } from 'antd';
import { asyncConnect, toSuccess, withAsyncActions } from 'react-async-client';
import styled from 'styled-components';
import { includes } from 'ramda';

import { deleteEventParticipation, getEventParticpation, getUser, putEventParticipationAttend, putEventParticipationAttendCancel } from '../../actions/asyncActions';
import { Link } from 'react-router-dom';
import { CheckCircleTwoTone, CloseCircleTwoTone, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { takeEvery } from 'redux-saga/effects';
import { DELETE_EVENT_PARTICIPATION, POST_EVENT_PARTICIPATION, PUT_EVENT_PARTICIPATION_ATTEND, PUT_EVENT_PARTICIPATION_ATTEND_CANCEL } from '../../constants/actionTypes';
import { CLIENT_MANAGER } from '../../constants/roles';
import { openEventPartcipantsAddModal } from '../../actions/modalActions';

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }
`;

export const EVENT_PARTICIPANT_STATUSES = [
    { id: 'attended', value: 'Присутствовал', color: '#549E38' },
    { id: 'scheduled', value: 'Отсутствовал', color: '#A7B9CF' },
];

class StatusSelectComponent extends Component {
    state = {
        value: null
    };

    setEdit = value => {
        this.props.setEdit(value);
        this.setState({ value: value ? (this.props.item.status === 'attended' ? 'attended' : 'scheduled') : null });
    }

    setStatus = () => {
        const actions = {
            attended: this.props.putEventParticipationAttend,
            scheduled: this.props.putEventParticipationAttendCancel
        };

        actions[this.state.value].dispatch(this.props.edit);
    }

    render() {
        const { item } = this.props;

        return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
                {
                    this.props.edit === item.id ?
                        <Select style={{ width: 150 }} placeholder='Статус' value={this.state.value} onChange={value => this.setState({ value })}>
                            { EVENT_PARTICIPANT_STATUSES.map(status =>
                                <Select.Option key={status.id} value={status.id}>{ status.value }</Select.Option>
                            )}
                        </Select> :
                        (item.status === 'attended' ? <Tag color='#549E38'>присутствовал</Tag> : <Tag color='#A7B9CF'>отсутствовал</Tag>)
                }
            </div>
            <div>
                {
                    this.props.edit === item.id ?
                    <div>
                        <CloseCircleTwoTone style={{ cursor: 'pointer', marginRight: 10 }} twoToneColor='' onClick={() => this.setEdit(null)} />
                        <CheckCircleTwoTone style={{ cursor: 'pointer' }} twoToneColor='#549E38' onClick={this.setStatus} />
                    </div> :
                    <div>
                        <Popconfirm
                            title='Вы уверены, что хотите удалить участника'
                            onConfirm={() => this.props.deleteEventParticipation.dispatch(item.id)}
                            okText='Да'
                            cancelText='Нет'
                            placement='left'>
                            <DeleteOutlined style={{ color: '#F54D2E', cursor: 'pointer', marginRight: 10 }} />
                        </Popconfirm>
                        <EditOutlined style={{ color: '#C2CFE0', cursor: 'point' }} onClick={() => this.setEdit(item.id)} />
                    </div>
                }
            </div>
        </div>
    }
}

export const StatusSelect = withAsyncActions({
    deleteEventParticipation: deleteEventParticipation
        .withParams(({ item }) => ({ id: item.id }))
        .withSuccessHandler(() => message.success('Участник успешно удален'))
        .withErrorHandler(() => message.delete('Не удалось удалить уастника'))
        .withOptions({ resetOnUnmount: true }),
    putEventParticipationAttend: putEventParticipationAttend
        .withParams(({ item }) => ({ id: item.id }))
        .withSuccessHandler(({ setEdit }) => {
            setEdit(null);
            message.success('Статус посещения мероприятия успешно изменен');
        })
        .withErrorHandler(() => message.error('Не удалось изменить статус посещения мероприятия'))
        .withOptions({ resetOnUnmount: true }),
    putEventParticipationAttendCancel: putEventParticipationAttendCancel
    .withParams(({ item }) => ({ id: item.id }))
        .withSuccessHandler(({ setEdit }) => {
            setEdit(null);
            message.success('Статус посещения мероприятия успешно изменен');
        })
        .withErrorHandler(() => message.error('Не удалось изменить статус посещения мероприятия'))
        .withOptions({ resetOnUnmount: true })
})(StatusSelectComponent);

class EventParticipantsModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        params: PropTypes.object
    };

    state = {
        edit: null
    };

    setEdit = edit => this.setState({ edit });

    render() {
        const { modal, params, getEventParticpation: { data, meta }, user } = this.props;

        return <StyledModal
            {...modal}
            title={params.title}
            footer={null}>
            { meta.pending && !meta.lastSucceedAt ?
                <div style={{ padding: 20, textAlign: 'center' }}><Spin /></div> :
                <div>
                    <div style={{ textAlign: 'right', padding: 15, borderBottom: '1px solid #f0f0f0' }}>
                        <Button
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => this.props.openEventPartcipantsAddModal({ event: params.id, company: params.company, theme: params.theme })}>
                            Добавить участника
                        </Button>
                    </div>
                    <Table
                        dataSource={data.items || []}
                        rowKey='id'
                        columns={[
                            {
                                dataIndex: ['_embedded', 'participant'],
                                key: 'participant',
                                render: participant => <Link to={`/participants/${participant.id}`}>{ participant.lastName } { participant.firstName } { participant.middleName }</Link>
                            },
                            {
                                key: 'status',
                                render: item => !includes(CLIENT_MANAGER, user.roles || []) ?
                                    <StatusSelect item={item} edit={this.state.edit} setEdit={this.setEdit} /> :
                                    (item.status === 'attended' ? <Tag color='green'>присутствовал</Tag> : <Tag color='red'>отсутствовал</Tag>)
                            }
                        ]}
                        showHeader={false}
                        pagination={false} />
                </div>
            }
        </StyledModal>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    getEventParticpation: getEventParticpation
        .withPayload(({ params }) => ({
            limit: 0,
            q: {
                event: params.id
            }
        }))
        .withSaga(function* (getProps) {
            yield takeEvery([
                toSuccess(PUT_EVENT_PARTICIPATION_ATTEND),
                toSuccess(PUT_EVENT_PARTICIPATION_ATTEND_CANCEL),
                toSuccess(DELETE_EVENT_PARTICIPATION),
                toSuccess(POST_EVENT_PARTICIPATION)
            ], () => {
                getProps().getEventParticpation.refresh();
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, stateToProps, { openEventPartcipantsAddModal })(EventParticipantsModal);
