import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { asyncConnect, toSuccess } from 'react-async-client';
import { head, isEmpty } from 'ramda';
import { Button, Row, Col } from 'antd';
import { takeEvery } from 'redux-saga/effects';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';

import { openAdminModal } from '../actions/modalActions';
import { getAdmin, putAdmin } from '../actions/asyncActions';
import { PUT_ADMIN } from '../constants/actionTypes';
import Photo from './Photo';

class Admin extends Component {
    static propTypes = {
        openAdminModal: PropTypes.func,
        getAdmin: PropTypes.object,
        history: PropTypes.object,
    };

    renderRow = (label, value) => {
        return !!value && <Row className='detail-row'>
            <Col span={8}>{ label }</Col>
            <Col span={16}>{ value }</Col>
        </Row>;
    }

    renderContent = () => {
        const { data } = this.props.getAdmin;

        return <div className='detail-block clearfix'>
            <div className='detail-photo-block'>
                <Photo item={data} action={putAdmin} />
            </div>
            <div className='detail-info-block'>
                { this.renderRow('Фамилия', data.lastName) }
                { this.renderRow('Имя', data.firstName) }
                { this.renderRow('Отчество', data.middleName) }
                { this.renderRow('Email', data.email) }
                { this.renderRow('Телефон', data.phone) }
                { this.renderRow('Активен', data.enabled ? 'Да' : 'Нет') }
            </div>
        </div>;
    }

    render() {
        const { getAdmin: { data }, history, openAdminModal } = this.props;
        const hasData = !isEmpty(data);

        return <div>
            <div className='toolbar-row detail-toolbar'>
                <Button shape='circle' icon={<ArrowLeftOutlined />} onClick={history.goBack} />
                { hasData &&
                    <Fragment>
                        <h1>
                            { data.lastName } { head(data.firstName) }.{ data.middleName ? `${head(data.middleName)}.` : '' }
                        </h1>
                        <div className='toolbar-actions'>
                            <Button
                                type='primary'
                                icon={<EditOutlined />}
                                onClick={() => openAdminModal(data)}>
                                Редактировать
                            </Button>
                        </div>
                    </Fragment>
                }
            </div>
            { hasData && this.renderContent() }
        </div>;
    }
}

export default asyncConnect({
    getAdmin: getAdmin
        .withPayload(({ match }) => match.params.id)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_ADMIN)], () => {
                getProps().getAdmin.refresh();
            });
        })
}, null, { openAdminModal })(Admin);
