import { Select, Spin } from 'antd';
import pluralize from 'pluralize-ru';
import { pathOr, propEq, find } from 'ramda';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { connect } from 'react-redux';
import { withState } from 'recompose';
import styled from 'styled-components';
import { getCompanyStatisticEventsAverageAttendance, getUser } from '../../../actions/asyncActions';
import { Block, BlockContent, BlockHeader } from './WebinarsByCompany';

const AverageBlock = styled.div`
    text-align: center;
    line-height: 1;
    margin-bottom: 8px;
`;

const AverageText = styled.div`
    color: #2B3D4F;
    font-weight: bold;
    font-size: 32px;
`;

const AverageDesc = styled.div`
    font-size: 18px;
    color: #A7B9CF;
`;

const DescBlock = styled.div`
    text-align: center;
`;

class EventsAverageAttendance extends Component {
    render() {
        const { eventTheme, setEventTheme, themes, user, getCompanyStatisticEventsAverageAttendance: { meta, data }} = this.props;
        const eventThemes = themes || pathOr([], ['_embedded', 'themes'], find(propEq('id', 'events'), pathOr([], ['_embedded', 'company', 'modules'], user)));

        return <Block>
            <BlockHeader>
                <h3>СРЕДНЯЯ ПОСЕЩАЕМОСТЬ ВЕБИНАРОВ</h3>
                <Select
                    value={eventTheme || undefined}
                    onChange={value => setEventTheme(value)}
                    placeholder='Все темы'
                    style={{ width: 250 }}
                    allowClear>
                    { eventThemes.map(item => <Select.Option key={item.id} value={item.id}>{ item.title }</Select.Option>) }
                </Select>
            </BlockHeader>
            <BlockContent>
                { meta.pending ? <Spin /> : <div>
                    <AverageBlock>
                        <AverageText>{ data.countAverageParticipated }</AverageText>
                        <AverageDesc>{ pluralize(data.countAverageParticipated, 'участников', 'участник', 'участника', 'участников') }</AverageDesc>
                    </AverageBlock>
                    <DescBlock>
                        <span style={{ color: '#2B3D4F' }}>{ data.countParticipated } </span>
                        <span style={{ color: '#A7B9CF' }}>{ pluralize(data.countParticipated, 'участников', 'участник', 'участника', 'участников') } вебинаров / </span>
                        <span style={{ color: '#2B3D4F' }}>{ data.countEvents } </span>
                        <span style={{ color: '#A7B9CF' }}>{ pluralize(data.countEvents, 'вебинаров', 'вебинар', 'вебинара', 'вебинаров') }</span>
                    </DescBlock>
                </div> }
            </BlockContent>
        </Block>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default withState('eventTheme', 'setEventTheme', null)(connect(stateToProps)(asyncConnect({
    getCompanyStatisticEventsAverageAttendance: getCompanyStatisticEventsAverageAttendance
        .withPayload(({ id, user, eventTheme }) => ({
            id: id || user.company,
            q: {
                eventTheme
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(EventsAverageAttendance)));
