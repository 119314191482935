import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Button, message, Tooltip } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { filter, includes, omit } from 'ramda';
import qs from 'qs';
import { FileExcelOutlined, PlusOutlined, UploadOutlined, LinkOutlined, SendOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { getInvites, getUser, putResendEmail } from '../actions/asyncActions';
import InviteFilter from './filters/InviteFilter';
import TableList from './TableList';
import { getFilters, getCompanyLandingUrl } from '../utils/urls';
import { getToken } from '../utils/token';
import { openImportInvitesModal, openInviteModal } from '../actions/modalActions';
import { POST_INVITES, POST_INVITES_IMPORT } from '../constants/actionTypes';
import { ADMIN, SUPER_ADMIN, CLIENT_ADMIN, CLIENT_MANAGER } from '../constants/roles';
import { asyncConnect } from 'react-async-client';

const getName = (...names) => names.filter(Boolean).join(' ');
const initFilters = { active: false };

class Invites extends Component {
    static propTypes = {
        location: PropTypes.object,
    };

    getColumns = () => {
        const { user } = this.props;
        const columns = [
            {
                title: 'Компания',
                dataIndex: ['_embedded', 'company', 'name'],
                key: 'company',
                hide: !(includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles)) || this.props.company
            },
            {
                title: 'Создал',
                dataIndex: ['_embedded', 'createdBy'],
                key: 'createdBy',
                render: ({ lastName, firstName, middleName }) => getName(lastName, firstName, middleName),
            },
            {
                title: 'Пользователь',
                dataIndex: ['_embedded', 'participant'],
                key: 'participant',
                render: participant => participant ?
                    <Link to={`/participants/${participant.id}`}>{ participant.lastName } { participant.firstName } { participant.middleName || '' }</Link> : null
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email'
            },
            {
                title: 'Консультант',
                dataIndex: ['_embedded', 'consultant'],
                key: 'consultant',
                render: consultant => consultant ? <span>{ consultant.lastName } { consultant.firstName } { consultant.middleName || '' }</span> : null
            },
            {
                title: 'Активирован',
                dataIndex: 'activatedAt',
                key: 'activatedAt',
                render: date => date && new Date(date).toLocaleDateString('ru'),
            },
            {
                title: 'Дата создания',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: date => new Date(date).toLocaleDateString('ru'),
            },
            {
                key: 'actions',
                align: 'right',
                render: item => !item.activatedAt && <Tooltip title='Отправить приглашение повторно' placement='left'>
                    <Button
                        icon={<SendOutlined />}
                        onClick={() => this.props.putResendEmail.dispatch(item.id)} />
                </Tooltip>
            }
        ];

        return filter(({ hide }) => !hide, columns);
    }

    getDownloadLink = () => {
        const { location } = this.props;
        const searchPath = qs.stringify({
            q: JSON.stringify(this.parseFilter(getFilters(location) || initFilters || {})),
            token: getToken()
        }, { addQueryPrefix: true, strictNullHandling: true });

        return `/api/admin/invites.xlsx${searchPath}`;
    }

    parseFilter = values => ((values || {}).active === '') ? omit(['active'], values) : values;

    renderToolbarButtons = () => {
        const { user } = this.props;
        return (
            <Button.Group>
                { (includes(CLIENT_ADMIN, user.roles) || includes(CLIENT_MANAGER, user.roles)) && (
                    <CopyToClipboard
                        onCopy={(event, result) => result ? message.success('Ссылка скопирована в буфер обмена') : message.error('Неудалось скопировать ссылку')}
                        text={getCompanyLandingUrl(user.company)}
                    >
                        <Button icon={<LinkOutlined />}>
                            URL лендинга
                        </Button>
                    </CopyToClipboard>
                )}
                <Button
                    icon={<UploadOutlined />}
                    onClick={() => this.props.openImportInvitesModal()}>
                    Импорт
                </Button>
                <Button
                    icon={<FileExcelOutlined />}
                    href={this.getDownloadLink()}
                    target='_blank'
                    rel='noopener noreferrer'
                    download>
                    Экспорт
                </Button>
                <Button
                    icon={<PlusOutlined />}
                    type='primary'
                    onClick={() => this.props.openInviteModal({ company: this.props.company })}>
                    Добавить
                </Button>
            </Button.Group>
        );
    }

    render() {
        return (
            <TableList
                className='invites'
                action={getInvites}
                staticFilter={{ company: this.props.company }}
                columns={this.getColumns()}
                buttons={this.renderToolbarButtons()}
                filterForm={<InviteFilter hideCompany={!!this.props.company} initFilters={initFilters} emptyValues={{ active: '' }} />}
                initFilters={initFilters}
                parseFilter={this.parseFilter}
                refreshActions={[POST_INVITES_IMPORT, POST_INVITES]}
                filterSpan={12}
                buttonsSpan={12}
            />
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    putResendEmail: putResendEmail
        .withSuccessHandler(() => message.success('Приглашение успешно отправлено'))
        .withOptions({ resetOnUnmount: true })
}, stateToProps, { openImportInvitesModal, openInviteModal })(withRouter(Invites));
