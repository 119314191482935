import { List, Spin } from 'antd';
import { pathOr } from 'ramda';
import React, { Component } from 'react';

import { LoaderWrapper } from '../Stages';

class Statistic extends Component {
    render() {
        const { getParticipant: { data, meta }} = this.props;

        return meta.pending && !meta.lastSucceedAt ?
            <LoaderWrapper><Spin /></LoaderWrapper> :
            <div className='detail-block clearfix'>
                <h3>Мероприятия</h3>
                <div><strong>Запланированные:</strong> { pathOr(0, ['events', 'countScheduled'], data) }</div>
                <div style={{ marginBottom: 20 }}><strong>Посещенные:</strong> { pathOr(0, ['events', 'countAttended'], data) }</div>
                <hr />
                <h3>Вебинары: доступные темы</h3>
                <List
                    itemLayout="horizontal"
                    dataSource={pathOr([], ['themes', '_embedded', 'available'], data)}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.title}
                                description={item.description}
                            />
                        </List.Item>
                    )}
                />
            </div>;
    }
}

export default Statistic;
