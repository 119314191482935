import { Select } from 'antd';
import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import debounce from 'debounce';

import { getParticipants } from '../../../actions/asyncActions';
import { path } from 'ramda';
import { withRouter } from 'react-router';

class Search extends Component {
    state = {
        value: null
    };

    onSearch = debounce(value => {
        if (value.length > 2) {
            this.props.getParticipants.refresh({
                limit: 0,
                q: {
                    name: value
                }
            })
        } else {
            this.props.getParticipants.reset();
        }
    }, 400);

    onChange = value => this.setState({ value });

    onSelect = value => {
        this.props.history.push(`/participants/${value}`);
        this.setState({ value: null });
        this.props.getParticipants.reset();
    }

    render() {
        const { getParticipants: { data, meta }} = this.props;

        return <Select
            style={{ width: 400 }}
            showSearch
            value={this.state.value}
            placeholder='Поиск'
            showArrow={false}
            filterOption={false}
            onSearch={this.onSearch}
            onChange={this.onChange}
            onSelect={this.onSelect}
            notFoundContent={this.state.value ? 'Ничего не найдено' : null}
            loading={meta.pending}
        >
            { (data.items || []).map(item =>
                <Select.Option key={item.id}>
                    <div>{item.lastName} {item.firstName} {item.middleName || ''}</div>
                    <div><small>{ path(['_embedded', 'company', 'name'], item) }</small></div>
                </Select.Option>
            )}
        </Select>;
    }
}

export default withRouter(withAsyncActions({
    getParticipants: getParticipants
        .withParams(() => ({ type: 'search' }))
        .withOptions({ resetOnUnmount: true })
})(Search));
