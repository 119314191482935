import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

import { withFieldWrapper } from '@meconsultant/common';

class RadioButtons extends Component {
    static propTypes = {
        input: PropTypes.object,
        onChange: PropTypes.func,
        options: PropTypes.array,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        options: []
    };

    onChange = e => {
        this.props.onChange(e.target.value);
    }

    render() {
        const { input: { value }, options, disabled } = this.props;

        return <Radio.Group
            value={value}
            disabled={disabled}
            onChange={this.onChange}>
            { options.map(option =>
                <Radio.Button
                    key={option.id}
                    value={option.id}>
                    { option.name }
                </Radio.Button>
            )}
        </Radio.Group>
    }
}

export default withFieldWrapper(RadioButtons);
