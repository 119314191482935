import React from 'react';
import { Redirect } from 'react-router-dom';
import { any, includes } from 'ramda';
import { connect } from 'react-redux';

import Route from './Route';
import { getUser } from '../actions/asyncActions';

const SecureRoute = ({ roles, user, ...props }) => {
    const hasRole = !roles || any(role => includes(role, user.roles || []), roles);

    return hasRole ?
        <Route {...props} /> :
        <Redirect to='/participants' />
};

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(SecureRoute);
