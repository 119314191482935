import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postTopic, putTopic } from '../../actions/asyncActions';
import TopicForm from '../forms/TopicForm';

class TopicModal extends Component {
    render() {
        const { modal, params, putTopic, postTopic } = this.props;

        return <Modal
            {...modal}
            title={`${params ? 'Редактировать' : 'Добавить'} категорию этапа`}
            footer={null}>
            <TopicForm
                formAction={params ? putTopic : postTopic}
                item={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postTopic: postTopic
        .withSuccessHandler(({ close }) => {
            message.success('Категория этапа успешно добавлена');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putTopic: putTopic
        .withSuccessHandler(({ close }) => {
            message.success('Категория этапа успешно изменена');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(TopicModal);
