import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { Col, Row } from 'antd';

import withFilterForm from '../hocs/withFilterForm';
import Select from '../forms/formComponents/Select';
import DatePicker from '../forms/formComponents/DatePicker';

class ParticipantHistory extends Component {
    render() {
        return (
            <Row gutter={16}>
                <Col span={8}>
                    <Field
                        name='modules'
                        component={Select}
                        placeholder='Сервис'
                        options={this.props.modules}
                        namePath='title'
                        mode='multiple'
                    />
                </Col>
                <Col span={8}>
                    <Field
                        name='minDate'
                        component={DatePicker}
                        placeholder='с'
                        style={{ width: '100%' }}
                    />
                </Col>
                <Col span={8}>
                    <Field
                        name='maxDate'
                        component={DatePicker}
                        placeholder='по'
                        style={{ width: '100%' }}
                        endOfDay
                    />
                </Col>
            </Row>
        );
    }
}

export default withFilterForm(ParticipantHistory);
