import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import moment from 'moment';
import styled from 'styled-components';
import { filter } from 'ramda';

import { getPromotionRequests, getPromotionRequestsThemes } from '../actions/asyncActions';
import TableList from './TableList';
import PromotionFilter from './filters/PromotionFilter';
import { getFileView } from '../constants/urls';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export const Status = styled.div`
    color: #fff;
    background: ${({ background }) => background};
    width: 140px;
    padding: 4px 8px;
    font-weight: bold;
`;

export const STATUSES = {
    pending: {
        text: 'на рассмотрении',
        background: '#47AAC4'
    },
    processing: {
        text: 'обрабатывается',
        background: '#A7B9CF'
    },
    completed: {
        text: 'выполнен',
        background: '#549E38'
    }
};

class Promotion extends Component {
    getColumns = () => {
        return filter(({ hide }) => !hide, [
            {
                title: 'Запрос',
                key: 'request',
                dataIndex: 'theme',
                render: (theme, item) => <div>
                    <Link to={this.props.company ? `/company/${this.props.company}/promotion/${item.participant}/${item.id}` : `/participants/${item.participant}/promotion/${item.id}`}><strong style={{ fontSize: 14, color: '#2B3D4F' }}>{ this.props.getPromotionRequestsThemes.data[theme] }</strong></Link>
                </div>
            },
            {
                title: 'Дата',
                key: 'createdAt',
                dataIndex: 'createdAt',
                align: 'center',
                render: date => <div>
                    <div>{ moment(date).format('DD.MM.YYYY') }</div>
                    <div style={{ fontSize: 12, color: '#333333', textAlign: 'center' }}>{ moment(date).format('HH:mm') }</div>
                </div>
            },
            {
                title: 'Участник',
                key: 'participant',
                dataIndex: ['_embedded', 'participant'],
                render: participant => <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar size={24} src={participant.photo ? getFileView(participant.photo) : null} icon={<UserOutlined />} /><span style={{ marginLeft: 5 }}>{ participant.lastName } { participant.firstName }</span>
                </div>,
                hide: !!this.props.participant
            },
            {
                title: 'Консультант',
                key: 'consultant',
                dataIndex: ['_embedded', 'consultant'],
                render: consultant => consultant ? <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar size={24} src={consultant.photo ? getFileView(consultant.photo) : null} icon={<UserOutlined />} /><span style={{ marginLeft: 5 }}>{ consultant.lastName } { consultant.firstName }</span>
                </div> : null,
            },
            {
                title: 'Статус',
                key: 'status',
                dataIndex: 'status',
                align: 'center',
                width: 140,
                render: status => <Status color={STATUSES[status].color} background={STATUSES[status].background}>
                    { STATUSES[status].text }
                </Status>
            }
        ]);
    }

    render() {
        return <TableList
            action={getPromotionRequests}
            columns={this.getColumns()}
            staticFilter={{ participant: this.props.participant, company: this.props.company }}
            filterForm={<PromotionFilter />} />
    }
}

export default withAsyncActions({
    getPromotionRequestsThemes: getPromotionRequestsThemes
        .withParams(() => ({ type: 'list' }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(Promotion);
