import { Spin } from 'antd';
import { find, flatten, keys, path, pathOr, propEq, toPairs } from 'ramda';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import ReactHighcharts from 'react-highcharts';
import { connect } from 'react-redux';

import { getCompanyStatisticPromotionConsultant, getParticipantsStatuses, getUser } from '../../../actions/asyncActions';
import { PARTICIPANT_STATUSES } from '../../../constants/statuses';
import { Block, BlockContent, BlockHeader } from './WebinarsByCompany';

class PromotionByConsultant extends Component {
    getConfig = () => {
        const { getCompanyStatisticPromotionConsultant: { data }, getParticipantsStatuses } = this.props;
        const series = flatten(toPairs(getParticipantsStatuses.data)
            .map(([key, values]) => (values.length ? values.map(k => ({ key: k, stack: k })) : [{ key, stack: key }])
            .map(({ stack, key }) => ({
                name: (path(['secondLevel'], find(propEq('id', key), PARTICIPANT_STATUSES)) || path(['title'], find(propEq('id', key), PARTICIPANT_STATUSES))),
                color: path(['chartColor'], find(propEq('id', key), PARTICIPANT_STATUSES)),
                stack,
                data: keys(pathOr({}, ['consultants'], data)).map(consultant => pathOr(0, ['consultants', consultant, 'statuses', key, 'count'], data))
            }))));

        return {
            chart: {
                type: 'column'
            },
            title: {
                text: null
            },
            xAxis: {
                categories: keys(pathOr({}, ['consultants'], data)).map(key => data.consultants[key].name),
                gridLineDashStyle: 'dash',
                gridLineWidth: 1
            },
            yAxis: [{
                allowDecimals: false,
                min: 0,
                gridLineDashStyle: 'dash',
                gridLineWidth: 1,
                title: {
                    text: null
                }
            }],
            credits: {
                enabled: false
            },
            tooltip: {
                enabled: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    stacking: 'normal'
                }
            },
            series
        };
    }

    render() {
        const { getCompanyStatisticPromotionConsultant: { meta }} = this.props;

        return <Block>
            <BlockHeader>
                <h3>ПРОФЕССИОНАЛЬНАЯ ПОДДЕРЖКА ПО КОНСУЛЬТАНТУ</h3>
            </BlockHeader>
            <BlockContent>
                { meta.pending ? <Spin /> : <div>
                    <ReactHighcharts config={this.getConfig()} />
                </div> }
            </BlockContent>
        </Block>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(asyncConnect({
    getParticipantsStatuses: getParticipantsStatuses
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getCompanyStatisticPromotionConsultant: getCompanyStatisticPromotionConsultant
        .withPayload(({ id, user }) => id || user.company)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(PromotionByConsultant));
