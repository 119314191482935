import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, message } from 'antd';
import { EditOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import TableList from './TableList';
import EnabledSwitch from './EnabledSwitch';
import { getEmployeeLinks, putEmployeeLink } from '../actions/asyncActions';
import { openLinkModal, openLinkPreviewModal } from '../actions/modalActions';
import { POST_EMPLOYEE_LINK, PUT_EMPLOYEE_LINK, DELETE_EMPLOYEE_LINK } from '../constants/actionTypes';
import { getLinkUrl } from '../utils/urls';

class LinksList extends Component {
    onCopy = (_, result) => {
        result ? message.success('Ссылка успешно скопирована') : message.error('Не удалось скопировать ссылку');
    };

    getColumns = () => {
        return [
            {
                title: 'Название',
                key: 'title',
                dataIndex: ['title'],
            },
            {
                title: 'Пароль',
                key: 'authCode',
                dataIndex: ['authCode'],
            },
            {
                title: 'Ссылка',
                key: 'link',
                dataIndex: ['id'],
                render: (id, item) => (
                    <span>
                        <a href={getLinkUrl(id)} target='_blank' rel="noopener noreferrer">
                            Перейти
                        </a>
                        <CopyToClipboard text={getLinkUrl(id)} onCopy={this.onCopy}>
                            <CopyOutlined style={{ color: '#C2CFE0', cursor: 'pointer', marginLeft: 5 }} />
                        </CopyToClipboard>
                        <EditOutlined style={{ color: '#C2CFE0', cursor: 'pointer', marginLeft: 5 }} onClick={() => this.props.openLinkPreviewModal(item)} />
                    </span>
                )
            },
            {
                title: 'Активна',
                dataIndex: 'active',
                key: 'active',
                width: 100,
                render: (active, item) => (
                    <EnabledSwitch
                        item={item}
                        enabled={active}
                        itemField='active'
                        actionItem={putEmployeeLink} />
                )
            },
            {
                key: 'actions',
                align: 'right',
                width: 55,
                render: item => <Button icon={<EditOutlined />} onClick={() => this.props.openLinkModal(item)} />
            }
        ];
    }

    renderToolbarButtons = () => {
        return <Button icon={<PlusOutlined />} type='primary' onClick={() => this.props.openLinkModal()}>
            Добавить
        </Button>
    }

    render() {
        return <TableList
            action={getEmployeeLinks}
            columns={this.getColumns()}
            buttons={this.renderToolbarButtons()}
            refreshActions={[POST_EMPLOYEE_LINK, PUT_EMPLOYEE_LINK, DELETE_EMPLOYEE_LINK]}
            rowKey='id' />
    }
}

export default connect(null, { openLinkModal, openLinkPreviewModal })(LinksList);
