import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Slider } from 'antd';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';

import { withFieldWrapper } from '@meconsultant/common';

class MoodSlider extends Component {
    static propTypes = {
        input: PropTypes.object,
        onChange: PropTypes.func
    };

    render() {
        const { input: { value }, onChange } = this.props;

        return <div className='mood-slider'>
            <FrownOutlined />
            <Slider
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100} />
            <SmileOutlined />
        </div>;
    }
}

export default withFieldWrapper(MoodSlider);
