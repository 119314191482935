import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { withFieldWrapper } from '@meconsultant/common';

class EditorComponent extends Component {
    render() {
        const { input: { value }, onChange } = this.props;

        return <Editor
            value={value}
            onEditorChange={onChange}
            init={{
                plugins: 'link image code lists autoresize media',
                language: 'ru',
                menubar: false,
                forced_root_block: false,
                language_url: '/translations/ru.json',
                toolbar: 'undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | forecolor backcolor | bullist numlist outdent indent | link image media code blockquote | removeformat',
                images_upload_handler: (info, success) => {
                    const fr = new FileReader();

                    fr.readAsDataURL(info.blob());
                    fr.onload = () => success(fr.result);
                }
            }}
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        />;
    }
}

export default withFieldWrapper(EditorComponent);
